import styled from "@emotion/styled";
import { FunctionComponent } from "react";
import { Modal } from "../atoms/Modal";
import { Input } from "../atoms/Input";
import { Button } from "../atoms/Button";
import { EMAIL_REGEX } from "util/constants";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useForgotPassword } from "hooks/useForgotPassword";
import { useToast } from "hooks/useToast";
import { ForgotPasswordErrorCodes, ForgotPasswordMutation } from "generated/graphql";

type Props = {
  visible: boolean;
  onClose: () => void;
};

export const ForgotPasswordModal: FunctionComponent<Props> = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const showToast = useToast();
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit
  } = useForm<{ email: string }>();

  const onCompleted = ({ forgotPassword }: ForgotPasswordMutation) => {
    if (forgotPassword.__typename === "ForgotPasswordSuccessResponse") {
      showToast({ text: t("forgotPasswordModal.success"), type: "success", delay: 7000 });
      reset();
      onClose();
    } else if (forgotPassword.__typename === "ForgotPasswordErrorResponse" && forgotPassword.code === ForgotPasswordErrorCodes.EMAIL_UNKNOWN) {
      showToast({ text: t("forgotPasswordModal.emailUnknown"), type: "error" });
    } else {
      showToast({ text: t("forgotPasswordModal.unknownError"), type: "error" });
    }
  };

  const onError = () => {
    showToast({ text: t("forgotPasswordModal.unknownError"), type: "error" });
  };

  const { forgotPassword } = useForgotPassword(onCompleted, onError);

  return (
    <Modal visible={visible} onClose={onClose} title={t("forgotPasswordModal.title")}>
      <Form
        onSubmit={handleSubmit(({ email }) => {
          forgotPassword(email);
        })}
      >
        <Input
          type="email"
          autoComplete="email"
          placeholder={t("form.placeholder.email")}
          error={errors.email?.message}
          {...register("email", {
            required: { value: true, message: t("form.error.emailRequired") },
            pattern: { value: EMAIL_REGEX, message: t("form.error.emailInvalid") }
          })}
        />
        <Button variant="primary" onClick={() => {}} fullWidth>
          {t("common.continue")}
        </Button>
      </Form>
    </Modal>
  );
};

const Form = styled.form``;
