import { Grid, useMediaQuery } from "@geist-ui/core";
import { SportsClubBg } from "assets";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { ProgressBar } from "components/atoms/ProgressBar";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { useCreateSportsClubMutation, useSetAttributionMutation } from "generated/graphql";
import { useToast } from "hooks/useToast";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";

export const SportsClubCreation: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showToast = useToast();
  const { sportsClubName, setSportsClubName, setSportsClubId, attribution } = useOnboardingStore();

  const [mutateAttribution] = useSetAttributionMutation();
  const [mutate, { loading }] = useCreateSportsClubMutation({
    onCompleted: (data) => {
      setSportsClubId(data.createSportsClub._id)
      navigate({ pathname: "../sports-club-logo" });
    },
    onError: (error) => {
      console.log('error', error)
      showToast({ text: t("pages.teamOnboarding.sportsClubCreation.error"), type: "error" });
    }
  })

  const goToNextStep = () => {
    if (!sportsClubName) {
      return;
    }

    mutateAttribution({ variables: { input: { referredBy: attribution?.referredBy, refByAffiliateId: attribution?.refByAffiliateId } } });
    mutate({ variables: { name: sportsClubName, attribution } })
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={SportsClubBg} />}>
        {isXS ? <ProgressBar currentStep={1} totalSteps={14} /> : null}
        <OnboardingStep title={t("pages.teamOnboarding.sportsClubCreation.title")} subtitle={t("pages.teamOnboarding.sportsClubCreation.subTitle")}>
          <Grid.Container gap={2} direction={isXS ? "column" : "row"} justify="center">
            <Grid sm={24} xs={24} width="100%">
              <Input
                placeholder={t("pages.teamOnboarding.sportsClubCreation.sportsClubName")}
                value={sportsClubName}
                onChange={(e) => setSportsClubName(e.target.value)}
              />
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={2} marginBottom={4}>
            <Grid sm={24} xs={24}>
              <Button type="button" variant={sportsClubName ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
                {t("common.continue")}
              </Button>
            </Grid>
          </Grid.Container>
        </OnboardingStep>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={1} totalSteps={14} /> : null}
      {loading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
};
