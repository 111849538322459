import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Select as SelectComponent, SelectProps, SelectOptionProps } from "@geist-ui/core";
import { FunctionComponent } from "react";

export const Select: FunctionComponent<SelectProps> = (props) => {
  const theme = useTheme()

  return (
    <SelectContainer>
      <SelectComponent {...props} placeholder={undefined} style={{ flex: 1, width: "100%", maxWidth: "100%", height: 52, background: "transparent", padding: 0, paddingTop: 8, borderRadius: 20, backgroundColor: theme.colors.dark, borderColor: theme.colors["sonic-silver"] }} />
      {props.placeholder ? <Placeholder floating={props.value !== undefined} >
        {props.placeholder}
      </Placeholder> : null}
    </SelectContainer>
  );
};

export const SelectOption: FunctionComponent<SelectOptionProps> = (props) => <SelectComponent.Option {...props} style={{ fontSize: 13, padding: "0 16px" }} />;

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`

const Placeholder = styled.label<{ floating: boolean; error?: boolean }>`
  position: absolute;
  top: 50%;
  left: 20px;
  font-size: 15px;
  font-weight: 500;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: left top;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.light50Percent};
  ${({ floating }) =>
    floating &&
    `
    transform: translateY(-80%) scale(0.65);
    text-transform: uppercase;
    font-style: italic;
    letter-spacing: 1.5px;
  `}
  pointer-events: none;
`;

