import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type UploadFile = globalThis.File;
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: number;
  JSON: string;
  JSONObject: object;
  Upload: UploadFile;
};

export type Absence = {
  __typename: "Absence";
  createdAt: Scalars["Date"];
  creator: User;
  description: Maybe<Scalars["String"]>;
  end: Scalars["Date"];
  id: Scalars["ID"];
  start: Scalars["Date"];
  user: User;
};

export type AbsenceInput = {
  description?: InputMaybe<Scalars["String"]>;
  end: Scalars["Date"];
  start: Scalars["Date"];
};

export type AbsenceUpdatedNotification = Notification & {
  __typename: "AbsenceUpdatedNotification";
  date: Scalars["Date"];
  id: Scalars["String"];
};

export type AccessToken = {
  __typename: "AccessToken";
  _id: Scalars["ID"];
  token: Scalars["String"];
  userId: Scalars["ID"];
};

export enum AccessTokenErrorCode {
  TOKEN_INVALID = "TOKEN_INVALID"
}

export type AccessTokenErrorResponse = {
  __typename: "AccessTokenErrorResponse";
  code: AccessTokenErrorCode;
};

export type AccessTokenGenerateResponse = AccessTokenErrorResponse | AccessTokenGenerateSuccessResponse;

export type AccessTokenGenerateSuccessResponse = {
  __typename: "AccessTokenGenerateSuccessResponse";
  token: Scalars["String"];
};

export type AccessTokenRetrieveResponse = AccessTokenErrorResponse | AccessTokenRetrieveSuccessResponse;

export type AccessTokenRetrieveSuccessResponse = {
  __typename: "AccessTokenRetrieveSuccessResponse";
  token: Scalars["String"];
  user: User;
  userId: Scalars["String"];
};

export type Achievement = {
  __typename: "Achievement";
  _id: Scalars["ID"];
  achievedAt: Maybe<Scalars["Date"]>;
  achievementType: Scalars["String"];
  criterias: Array<Criterias>;
  description: Scalars["String"];
  dialogShown: Maybe<Scalars["Boolean"]>;
  icon: Scalars["String"];
  instruction: Scalars["String"];
  name: Scalars["String"];
  shareText: Scalars["String"];
};

export enum AchievementType {
  BLOG = "BLOG",
  /** @deprecated Please use TEAMS instead */
  CLUBS = "CLUBS",
  PROGRAMS = "PROGRAMS",
  TEAMS = "TEAMS",
  WORKOUTS = "WORKOUTS"
}

export type Activity = {
  _id: Scalars["String"];
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export type ActivityEdge = {
  __typename: "ActivityEdge";
  cursor: Scalars["ID"];
  node: ActivityNode;
};

export type ActivityFilter = {
  from: Scalars["Date"];
  to: Scalars["Date"];
  types: Array<ActivityType>;
};

export type ActivityNode = {
  __typename: "ActivityNode";
  activity: ActivityUnion;
  user: User;
};

export type ActivityReaction = {
  __typename: "ActivityReaction";
  reaction: Reaction;
  user: User;
  userId: Scalars["ID"];
};

export type ActivityReactionNotification = Notification & {
  __typename: "ActivityReactionNotification";
  activityId: Scalars["String"];
  date: Scalars["Date"];
  id: Scalars["String"];
};

export enum ActivityType {
  B42_GAMECHANGER_FINISHED = "B42_GAMECHANGER_FINISHED",
  BOUGHT_PREMIUM = "BOUGHT_PREMIUM",
  CHALLENGE_COMPLETED = "CHALLENGE_COMPLETED",
  CHALLENGE_JOINED = "CHALLENGE_JOINED",
  FINISHED_CUSTOM_WORKOUT = "FINISHED_CUSTOM_WORKOUT",
  FINISHED_EXTERNAL_SPORTS_WORKOUT = "FINISHED_EXTERNAL_SPORTS_WORKOUT",
  FINISHED_PERFORMANCE_TEST = "FINISHED_PERFORMANCE_TEST",
  FINISHED_PROGRAM = "FINISHED_PROGRAM",
  FINISHED_RUN_WORKOUT = "FINISHED_RUN_WORKOUT",
  FINISHED_WORKOUT = "FINISHED_WORKOUT",
  INSTALLED_APP = "INSTALLED_APP",
  /** @deprecated Please use TEAM_MEMBER_ADDED instead */
  JOINED_CLUB = "JOINED_CLUB",
  /** @deprecated Exists just for backward compatibility */
  PLANNED_PROGRAM_WORKOUT = "PLANNED_PROGRAM_WORKOUT",
  READ_BLOG_POST = "READ_BLOG_POST",
  STARTED_APP = "STARTED_APP",
  STARTED_PROGRAM = "STARTED_PROGRAM",
  TEAM_ADMIN_ASSIGNED = "TEAM_ADMIN_ASSIGNED",
  TEAM_AMBITION_CHANGED = "TEAM_AMBITION_CHANGED",
  TEAM_CHALLENGE_COMPLETED = "TEAM_CHALLENGE_COMPLETED",
  TEAM_LOGO_CHANGED = "TEAM_LOGO_CHANGED",
  TEAM_MEMBER_JOINED = "TEAM_MEMBER_JOINED",
  TEAM_MEMBER_KICKED = "TEAM_MEMBER_KICKED",
  TEAM_MEMBER_LEFT = "TEAM_MEMBER_LEFT",
  TEAM_NAME_CHANGED = "TEAM_NAME_CHANGED"
}

export type ActivityUnion =
  | B42GamechangerFinishedActivity
  | BoughtPremiumActivity
  | CompletedChallengeActivity
  | CompletedTeamChallengeActivity
  | FinishedCustomWorkoutActivity
  | FinishedExternalSportsWorkoutActivity
  | FinishedPerformanceTestActivity
  | FinishedProgramActivity
  | FinishedRunWorkoutActivity
  | FinishedWorkoutActivity
  | InstalledAppActivity
  | JoinedChallengeActivity
  | JoinedClubActivity
  | PlannedProgramWorkoutActivity
  | ReadBlogPostActivity
  | StartedAppActivity
  | StartedProgramActivity
  | TeamAdminAssignedActivity
  | TeamAmbitionChangedActivity
  | TeamLogoChangedActivity
  | TeamMemberJoinedActivity
  | TeamMemberKickedActivity
  | TeamMemberLeftActivity
  | TeamNameChangedActivity;

export type ActivityWorkoutUnion = BodyWeightWorkout | ChampionWorkout | StaminaWorkout;

export enum AgeGroup {
  FROM_18_TO_24 = "FROM_18_TO_24",
  FROM_25_TO_29 = "FROM_25_TO_29",
  FROM_30_TO_39 = "FROM_30_TO_39",
  OVER_40 = "OVER_40",
  UNDER_12 = "UNDER_12",
  UNDER_18 = "UNDER_18"
}

export type AppleLocationValue = {
  altitude: Scalars["Float"];
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
  speed: Scalars["Float"];
  speedAccuracy: Scalars["Float"];
  timestamp: Scalars["String"];
};

export type AppleRunWorkoutResultInput = {
  /** distance in miles */
  distance: Scalars["Float"];
  /** ISO date string */
  end: Scalars["String"];
  locations: Array<AppleLocationValue>;
  /** id from Apple Health */
  sourceId: Scalars["String"];
  /** `sourceName` from Apple Health, e.g. Connect, Polar or Strava */
  sourceName?: InputMaybe<Scalars["String"]>;
  /** ISO date string */
  start: Scalars["String"];
};

export type AppleWorkoutResultInput = {
  /** ISO date string */
  end: Scalars["String"];
  sourceId: Scalars["String"];
  /** `sourceName` from Apple Health, e.g. Connect, Polar or Strava */
  sourceName?: InputMaybe<Scalars["String"]>;
  sport: Scalars["String"];
  /** ISO date string */
  start: Scalars["String"];
};

export type Asset = {
  __typename: "Asset";
  processing: Maybe<Scalars["Boolean"]>;
  /** If asset is a video, URI to thumbnail */
  thumbnail: Maybe<Scalars["String"]>;
  uri: Scalars["String"];
};

export type AttendeeUnion = Club | User;

export enum AvailableLanguage {
  DE = "de",
  EN = "en",
  ES = "es",
  FR = "fr",
  IT = "it",
  JA = "ja",
  KO = "ko",
  PT = "pt",
  TR = "tr"
}

export enum B42ClubRankingFilter {
  GLOBAL = "GLOBAL"
}

/** - DEPRECATED - */
export type B42ClubRankingFilterInput = {
  filter: B42ClubRankingFilter;
};

export type B42ClubScore = {
  __typename: "B42ClubScore";
  defense: Scalars["Int"];
  forward: Scalars["Int"];
  midfield: Scalars["Int"];
  total: Scalars["Int"];
};

export type B42CoachProgram = {
  __typename: "B42CoachProgram";
  _id: Scalars["ID"];
  name: Scalars["String"];
  /** Which performance test the user should do on entry and on completion. */
  relatedPerformanceTestId: Maybe<PerformanceTestId>;
  type: B42CoachProgramType;
  weeks: Array<B42CoachProgramWeek>;
};

export type B42CoachProgramState = {
  __typename: "B42CoachProgramState";
  _id: Scalars["ID"];
  b42CoachProgram: B42CoachProgram;
  /** Date on which user cancelled the program (Either by directly cancelling it or by resetting it) */
  cancellationDate: Maybe<Scalars["Date"]>;
  /** Date on which user completed the program */
  completionDate: Maybe<Scalars["Date"]>;
  current: Scalars["Boolean"];
  difficulty: WorkoutDifficulty;
  /** Result if user did a performance test at the beginning */
  entryPerformanceTestResult: Maybe<SinglePerformanceTestResult>;
  /** All workouts the user has done as part of this program state */
  finishedWorkouts: Array<B42CoachProgramWorkoutDataRecord>;
  startDate: Scalars["Date"];
};

export enum B42CoachProgramType {
  MOBILITY = "MOBILITY",
  SPEED = "SPEED",
  STABILITY = "STABILITY",
  STAMINA = "STAMINA",
  STRENGTH = "STRENGTH"
}

export type B42CoachProgramWeek = {
  __typename: "B42CoachProgramWeek";
  /** Zero-based week index */
  index: Scalars["Int"];
  workouts: Array<WorkoutUnion>;
};

export type B42CoachProgramWorkoutDataRecord = {
  __typename: "B42CoachProgramWorkoutDataRecord";
  _id: Scalars["ID"];
  b42CoachProgramState: B42CoachProgramState;
  /** When workout was finished */
  date: Scalars["Date"];
  elapsedTime: Maybe<Scalars["Int"]>;
  elapsedTimePerExercise: Maybe<Array<ElapsedTimeForExercise>>;
  weekId: Scalars["Int"];
  workoutDifficulty: Maybe<WorkoutDifficulty>;
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type B42CoachProgramWorkoutDataRecordInput = {
  b42CoachProgramStateId: Scalars["ID"];
  elapsedTime?: InputMaybe<Scalars["Int"]>;
  elapsedTimePerExercise?: InputMaybe<Array<ElapsedTimeForExerciseInput>>;
  weekId: Scalars["Int"];
  workoutDifficulty?: InputMaybe<WorkoutDifficulty>;
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type B42Gamechanger = {
  __typename: "B42Gamechanger";
  assignedBy: Maybe<User>;
  /** Week number (one based) */
  currentWeek: Scalars["Int"];
  difficulty: WorkoutDifficulty;
  finishDate: Maybe<Scalars["Date"]>;
  firstFocus: B42GamechangerFocus;
  id: Scalars["ID"];
  isFinished: Scalars["Boolean"];
  menstruationMode: Maybe<Scalars["Boolean"]>;
  menstruationWeek: Maybe<B42GamechangerMenstruationWeek>;
  positionSpecific: Maybe<Scalars["Boolean"]>;
  /** Progress in percentage (0-100) */
  progressPct: Scalars["Int"];
  seasonPhase: SeasonPhase;
  secondFocus: Maybe<B42GamechangerFocus>;
  startDate: Scalars["Date"];
  /**  Availabe after B42Gamechanger is finished */
  stats: Maybe<B42GamechangerStats>;
  user: User;
  weeks: Array<B42GamechangerWeek>;
};

export type B42GamechangerBaseTrainingDay = {
  id: Scalars["ID"];
  isFinished: Scalars["Boolean"];
};

export type B42GamechangerChampionsWeekTrainingDay_MultiWorkouts = B42GamechangerBaseTrainingDay & {
  __typename: "B42GamechangerChampionsWeekTrainingDay_MultiWorkouts";
  id: Scalars["ID"];
  isFinished: Scalars["Boolean"];
  workouts: Array<B42GamechangerChampionsWeekTrainingDay_MultiWorkouts_Workout>;
};

export type B42GamechangerChampionsWeekTrainingDay_MultiWorkouts_Workout = {
  __typename: "B42GamechangerChampionsWeekTrainingDay_MultiWorkouts_Workout";
  /** Elapsed time in seconds. Only existing if workout is finished. */
  elapsedMillis: Maybe<Scalars["Int"]>;
  /** Elapsed time in seconds for same workout in first week. */
  elapsedMillisFirstWeek: Maybe<Scalars["Int"]>;
  isFinished: Scalars["Boolean"];
  workout: WorkoutUnion;
};

export type B42GamechangerChampionsWeekTrainingDay_PerformanceTest = B42GamechangerBaseTrainingDay & {
  __typename: "B42GamechangerChampionsWeekTrainingDay_PerformanceTest";
  id: Scalars["ID"];
  isFinished: Scalars["Boolean"];
  performanceTest: PerformanceTest;
};

export type B42GamechangerChampionsWeekTrainingDay_Workout = B42GamechangerBaseTrainingDay & {
  __typename: "B42GamechangerChampionsWeekTrainingDay_Workout";
  /** Elapsed time in seconds. Only existing if training day is finished. */
  elapsedMillis: Maybe<Scalars["Int"]>;
  /** Elapsed time in seconds for same workout in first week. */
  elapsedMillisFirstWeek: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  /** Whether this training day is compared with training day of first to calculate improvement */
  isBenchmarking: Scalars["Boolean"];
  isFinished: Scalars["Boolean"];
  workout: WorkoutUnion;
};

export type B42GamechangerFinishedActivity = Activity & {
  __typename: "B42GamechangerFinishedActivity";
  _id: Scalars["String"];
  b42Gamechanger: B42Gamechanger;
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export enum B42GamechangerFocus {
  MOBILITY = "MOBILITY",
  SPEED = "SPEED",
  STAMINA = "STAMINA",
  STRENGTH = "STRENGTH"
}

export type B42GamechangerInput = {
  daysPerWeek: Scalars["Int"];
  difficulty: WorkoutDifficulty;
  firstFocus: B42GamechangerFocus;
  /** Defines if user wants to train position specific */
  positionSpecific?: InputMaybe<Scalars["Boolean"]>;
  seasonPhase: SeasonPhase;
  secondFocus?: InputMaybe<B42GamechangerFocus>;
};

export type B42GamechangerMenstruationWeek = {
  __typename: "B42GamechangerMenstruationWeek";
  trainingDays: Array<B42GamechangerTrainingDay>;
};

export type B42GamechangerPerformanceTestInput = {
  b42GamechangerId: Scalars["ID"];
  performanceTestResult: PerformanceTestResultInput;
  trainingDayId: Scalars["ID"];
};

export type B42GamechangerRegularTrainingDay = B42GamechangerBaseTrainingDay & {
  __typename: "B42GamechangerRegularTrainingDay";
  canToggleAlternativeWorkout: Scalars["Boolean"];
  /**
   * Elapsed time in seconds. Only existing if training day is finished.
   * @deprecated Use field `workoutResult`
   */
  elapsedMillis: Maybe<Scalars["Int"]>;
  /**
   * If `true`, it can be toggled with `toggleB42GamechangerWorkoutAlternative`
   * @deprecated Use `canToggleAlternativeWorkout` instead
   */
  hasAlternativeWorkout: Scalars["Boolean"];
  id: Scalars["ID"];
  /** Whether this training day is used in championsweek to calculate improvement */
  isBenchmarking: Scalars["Boolean"];
  isFinished: Scalars["Boolean"];
  workout: WorkoutUnion;
  /** Exists only if training day is finished */
  workoutResult: Maybe<WorkoutResult>;
};

export type B42GamechangerStats = {
  __typename: "B42GamechangerStats";
  b42ScoreAfter: B42Score;
  b42ScoreBefore: B42Score;
  /** 0 - 100 */
  improvementFirstFocusPct: Scalars["Int"];
  /** 0 - 100 */
  improvementSecondFocusPct: Maybe<Scalars["Int"]>;
  nmbOfMinutesTrained: Scalars["Int"];
  nmbWorkoutsDoneFirstFocus: Scalars["Int"];
  nmbWorkoutsDoneSecondFocus: Maybe<Scalars["Int"]>;
  workoutImprovements: Array<B42GamechangerStatsWorkoutImprovement>;
};

export type B42GamechangerStatsWorkoutImprovement = {
  __typename: "B42GamechangerStatsWorkoutImprovement";
  timeSecsEnd: Scalars["Int"];
  timeSecsStart: Scalars["Int"];
  workoutName: Scalars["String"];
};

export type B42GamechangerTrainingDay =
  | B42GamechangerChampionsWeekTrainingDay_MultiWorkouts
  | B42GamechangerChampionsWeekTrainingDay_PerformanceTest
  | B42GamechangerChampionsWeekTrainingDay_Workout
  | B42GamechangerRegularTrainingDay;

export type B42GamechangerWeek = {
  __typename: "B42GamechangerWeek";
  isFinished: Scalars["Boolean"];
  lockState: LockState;
  trainingDays: Array<B42GamechangerTrainingDay>;
};

export type B42GamechangerWorkoutInput = {
  b42GamechangerId: Scalars["ID"];
  trainingDayId: Scalars["ID"];
  workoutInput: FinishWorkoutInput;
};

export type B42GamechangerWorkoutReminder = Reminder & {
  __typename: "B42GamechangerWorkoutReminder";
  b42GamechangerId: Scalars["String"];
  difficulty: WorkoutDifficulty;
  id: Scalars["ID"];
  reminderDate: Scalars["Date"];
  trainingDayId: Scalars["String"];
  type: Scalars["String"];
  workoutId: Scalars["ID"];
  workoutType: WorkoutType;
};

export type B42Score = {
  __typename: "B42Score";
  ballTotal: Scalars["Int"];
  ballcontrol: Scalars["Int"];
  ballskills: Scalars["Int"];
  /** @deprecated Use strength to combine all strength related values */
  coreStrength: Scalars["Int"];
  dribbling: Scalars["Int"];
  isBallcontrolValidated: Scalars["Boolean"];
  isBallskillsValidated: Scalars["Boolean"];
  isDribblingValidated: Scalars["Boolean"];
  isMobilityValidated: Scalars["Boolean"];
  isPassingValidated: Scalars["Boolean"];
  isSpeedValidated: Scalars["Boolean"];
  isStaminaValidated: Scalars["Boolean"];
  isStrengthValidated: Scalars["Boolean"];
  isTotalValidated: Scalars["Boolean"];
  /** @deprecated Use strength to combine all strength related values */
  lowerBodyStrength: Scalars["Int"];
  mobility: Scalars["Int"];
  passing: Scalars["Int"];
  speed: Scalars["Int"];
  stamina: Scalars["Int"];
  strength: Scalars["Int"];
  /** Total of athletic scores */
  total: Scalars["Int"];
  /** @deprecated Use strength to combine all strength related values */
  upperBodyStrength: Scalars["Int"];
};

export type B42ScoreBasedClubRankingConnection = {
  __typename: "B42ScoreBasedClubRankingConnection";
  currentClubEdge: Maybe<B42ScoreBasedClubRankingEdge>;
  edges: Array<B42ScoreBasedClubRankingEdge>;
  totalCount: Scalars["Int"];
};

/** - DEPRECATED - */
export type B42ScoreBasedClubRankingEdge = {
  __typename: "B42ScoreBasedClubRankingEdge";
  cursor: Scalars["ID"];
  node: B42ScoreBasedClubRankingNode;
};

/** - DEPRECATED - */
export type B42ScoreBasedClubRankingNode = {
  __typename: "B42ScoreBasedClubRankingNode";
  b42ScoreTotal: Scalars["Int"];
  club: Club;
  rank: Scalars["Int"];
};

/** - DEPRECATED - */
export type B42ScoreBasedTeamRankingConnection = {
  __typename: "B42ScoreBasedTeamRankingConnection";
  currentTeamEdge: Maybe<B42ScoreBasedTeamRankingEdge>;
  edges: Array<B42ScoreBasedTeamRankingEdge>;
  totalCount: Scalars["Int"];
};

export type B42ScoreBasedTeamRankingEdge = {
  __typename: "B42ScoreBasedTeamRankingEdge";
  cursor: Scalars["ID"];
  node: B42ScoreBasedTeamRankingNode;
};

export type B42ScoreBasedTeamRankingNode = {
  __typename: "B42ScoreBasedTeamRankingNode";
  b42ScoreTotal: Scalars["Int"];
  rank: Scalars["Int"];
  team: Team;
};

export type B42ScoreBasedUserRankingConnection = {
  __typename: "B42ScoreBasedUserRankingConnection";
  currentUserEdge: Maybe<B42ScoreBasedUserRankingEdge>;
  edges: Array<B42ScoreBasedUserRankingEdge>;
  totalCount: Scalars["Int"];
};

export type B42ScoreBasedUserRankingEdge = {
  __typename: "B42ScoreBasedUserRankingEdge";
  cursor: Scalars["ID"];
  node: B42ScoreBasedUserRankingNode;
};

export type B42ScoreBasedUserRankingNode = {
  __typename: "B42ScoreBasedUserRankingNode";
  b42ScoreTotal: Scalars["Int"];
  rank: Scalars["Int"];
  user: User;
};

export type B42ScoreSingleResult = {
  __typename: "B42ScoreSingleResult";
  date: Scalars["Date"];
  /** If testId is not set then it the total score is calculated */
  testId: Maybe<PerformanceTestId>;
  value: Scalars["Int"];
};

export enum B42TeamRankingFilter {
  GLOBAL = "GLOBAL"
}

export type B42TeamRankingFilterInput = {
  filter: B42TeamRankingFilter;
};

export type B42TeamScore = {
  __typename: "B42TeamScore";
  defense: Scalars["Int"];
  forward: Scalars["Int"];
  midfield: Scalars["Int"];
  total: Scalars["Int"];
};

export enum B42UserRankingFilter {
  /** @deprecated Please use TEAM instead */
  CLUB = "CLUB",
  GLOBAL = "GLOBAL",
  POSITION = "POSITION",
  TEAM = "TEAM"
}

export type B42UserRankingFilterInput = {
  filter: B42UserRankingFilter;
};

export type BannerAd = {
  __typename: "BannerAd";
  BannerAdId: Scalars["ID"];
  callToActionUrl: Maybe<Scalars["String"]>;
  image: Asset;
  type: BannerAdType;
};

export enum BannerAdType {
  BANNER = "BANNER",
  POWERED_BY = "POWERED_BY"
}

export type BaseExercise = {
  coverImage: Maybe<Asset>;
  equipments: Array<Equipment>;
  id: Scalars["String"];
  /** @deprecated Gender specific Image in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  image: Maybe<Asset>;
  /** @deprecated Gender specific Image in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  imageGirls: Maybe<Asset>;
  instruction: Scalars["String"];
  keypoints: Array<Scalars["String"]>;
  name: Scalars["String"];
  type: ExerciseType;
  /** @deprecated Gender specific video in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  video: Maybe<Asset>;
  /** @deprecated Gender specific video in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  videoGirls: Maybe<Asset>;
  videos: Maybe<Array<Asset>>;
};

export type BasePerformanceTest = {
  _id: PerformanceTestId;
  averageDuration: Scalars["Int"];
  heading: Scalars["String"];
  introduction: Scalars["String"];
  name: Scalars["String"];
};

export type BasePerformanceTestExercise = {
  _id: PerformanceTestExerciseId;
  /** Keypoint what user shouldn't do */
  donts: Array<Scalars["String"]>;
  /** Keypoints what user should do */
  dos: Array<Scalars["String"]>;
  equipment: Array<Scalars["String"]>;
  equipmentShort: Array<Scalars["String"]>;
  /**
   * Performance test specific instruction
   * @deprecated No longer supported
   */
  instruction: Scalars["String"];
  /**
   * Performance test specific insctruction keypoints
   * @deprecated No longer supported
   */
  instructionKeypoints: Array<Maybe<Scalars["String"]>>;
  name: Scalars["String"];
  /**
   * If yes, the test has to be done once per side
   * @deprecated No longer supported
   */
  perSide: Maybe<Scalars["Boolean"]>;
  previewImage: Asset;
  scoreCategory: Array<Scalars["String"]>;
  scoreCategoryShort: Array<Scalars["String"]>;
  shortIntroduction: Scalars["String"];
  video: Asset;
};

export type BaseProgramState = {
  current: Scalars["Boolean"];
  startDate: Scalars["Date"];
};

export type BaseRunWorkout = {
  _id: Scalars["String"];
  type: RunType;
};

export type BaseWorkout = {
  avgUserScore: Maybe<Scalars["Float"]>;
  categories: Array<WorkoutCategory>;
  coverImage: Asset;
  exercises: Array<ExerciseUnion>;
  finalExercises: Maybe<Array<ExerciseUnion>>;
  gender: Maybe<Gender>;
  id: Scalars["String"];
  isFreeWorkout: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  nmbUserVotes: Maybe<Scalars["Int"]>;
  recommended: Maybe<Scalars["Boolean"]>;
  recommendedPositions: Maybe<Array<Position>>;
  source: WorkoutSource;
  tags: Array<WorkoutTag>;
  time: WorkoutTime;
  type: WorkoutType;
};

export type BasicSportsClubTeam = {
  __typename: "BasicSportsClubTeam";
  logo: Maybe<File>;
  sportsClubId: Scalars["ID"];
  sportsClubName: Scalars["String"];
  teamId: Scalars["ID"];
  teamName: Scalars["String"];
};

export type BlogPost = {
  __typename: "BlogPost";
  _id: Scalars["String"];
  /** @deprecated No longer supported */
  availableLangs: Array<Maybe<Scalars["String"]>>;
  /** @deprecated Show post in a webview via URL instead */
  content: Maybe<Scalars["String"]>;
  createdAt: Scalars["Date"];
  /** @deprecated Use `previewImage` instead. */
  featureImage: File;
  /** @deprecated New blog posts have string IDs. If you request this field only blog posts with numeric ID will be shown. */
  id: Scalars["Int"];
  /** @deprecated No longer supported */
  meta: BlogPostMeta;
  previewImage: Asset;
  slug: Scalars["String"];
  status: BlogPostStatus;
  title: Scalars["String"];
  updatedAt: Scalars["Date"];
  url: Scalars["String"];
};

export type BlogPostFilter = {
  availableForLang?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<BlogPostStatus>;
};

export type BlogPostMeta = {
  __typename: "BlogPostMeta";
  openGraph: Maybe<OpenGraphData>;
  seo: Maybe<SeoData>;
};

export enum BlogPostStatus {
  DRAFT = "DRAFT",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC"
}

export type BodyWeightWorkout = BaseWorkout & {
  __typename: "BodyWeightWorkout";
  avgUserScore: Maybe<Scalars["Float"]>;
  categories: Array<WorkoutCategory>;
  coverImage: Asset;
  exercises: Array<ExerciseUnion>;
  finalExercises: Maybe<Array<ExerciseUnion>>;
  gender: Maybe<Gender>;
  id: Scalars["String"];
  isFreeWorkout: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  nmbUserVotes: Maybe<Scalars["Int"]>;
  /** specific fields */
  passes: WorkoutPasses;
  recommended: Maybe<Scalars["Boolean"]>;
  recommendedPositions: Maybe<Array<Position>>;
  /** @deprecated Works not for free area workouts, coach workouts and b42 gamechanger workouts, use workoutReminders instead */
  reminders: Array<WorkoutReminder>;
  source: WorkoutSource;
  subType: Maybe<WorkoutSubType>;
  tags: Array<WorkoutTag>;
  time: WorkoutTime;
  type: WorkoutType;
  workoutReminders: Array<WorkoutReminderUnion>;
};

export type BoughtPremiumActivity = Activity & {
  __typename: "BoughtPremiumActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export enum ButtonStyle {
  DARK = "DARK",
  DARK_OUTLINE = "DARK_OUTLINE",
  GRAY_OUTLINE = "GRAY_OUTLINE",
  GRAY_TEXT = "GRAY_TEXT",
  ORANGE = "ORANGE",
  ORANGE_OPACITY = "ORANGE_OPACITY",
  ORANGE_OUTLINE = "ORANGE_OUTLINE",
  ORANGE_TEXT = "ORANGE_TEXT",
  PRIMARY = "PRIMARY",
  PRIMARY_OUTLINE = "PRIMARY_OUTLINE",
  PRIMARY_OUTLINE_ACTIVE = "PRIMARY_OUTLINE_ACTIVE",
  PRIMARY_TEXT = "PRIMARY_TEXT",
  WHITE = "WHITE",
  WHITE_OUTLINE = "WHITE_OUTLINE",
  WHITE_TEXT = "WHITE_TEXT"
}

export enum CacheControlScope {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC"
}

export type Challenge = {
  __typename: "Challenge";
  category: Maybe<ChallengeCategory>;
  challengePrizes: Array<ChallengePrize>;
  closed: Maybe<Scalars["Boolean"]>;
  /** Markdown description */
  description: Scalars["String"];
  end: Maybe<Scalars["Date"]>;
  goal: Scalars["String"];
  headerImage: Scalars["String"];
  id: Scalars["ID"];
  instruction: Scalars["String"];
  joined: Scalars["Boolean"];
  leaderboard: ChallengeLeaderboard;
  partners: Array<ChallengePartner>;
  prizeDescription: Scalars["String"];
  prizes: Array<Scalars["String"]>;
  recurring: Maybe<Scalars["Boolean"]>;
  /** Some challenges may be related to another challenge (e.g. a user challenge with a corresponding team challenge) */
  relatedChallenge: Maybe<Challenge>;
  shortDescription: Scalars["String"];
  start: Scalars["Date"];
  status: ChallengeStatus;
  termsOfParticipation: Scalars["String"];
  /**  links to terms and conditions of participation  */
  termsUrl: Maybe<Scalars["String"]>;
  thumbnail: Scalars["String"];
  title: Scalars["String"];
  type: ChallengeType;
  userScoreTarget: Maybe<Scalars["Int"]>;
};

export type ChallengeLeaderboardArgs = {
  paginationInput?: InputMaybe<ChallengeLeaderboardPaginationInput>;
};

export enum ChallengeCategory {
  BALL = "BALL",
  BALLCONTROL = "BALLCONTROL",
  BALLSKILLS = "BALLSKILLS",
  DRIBBLING = "DRIBBLING",
  FASCIA_ROLLER = "FASCIA_ROLLER",
  FITNESS = "FITNESS",
  MOBILITY = "MOBILITY",
  PASSING = "PASSING",
  PLYO = "PLYO",
  PREVENTION = "PREVENTION",
  PREVENTION_AND_RECOVERY = "PREVENTION_AND_RECOVERY",
  RECOVERY = "RECOVERY",
  REHA = "REHA",
  RUNNING = "RUNNING",
  SPEED = "SPEED",
  STABILITY = "STABILITY",
  STAMINA = "STAMINA",
  STRENGTH = "STRENGTH"
}

export type ChallengeLeaderboard = ChallengeTeamLeaderboard | ChallengeUserLeaderboard;

export type ChallengeLeaderboardPaginationInput = {
  cursorEnd?: InputMaybe<Scalars["ID"]>;
  cursorStart?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export enum ChallengeLeaderboardScoreType {
  AVG_NMB_RUN_WORKOUTS = "AVG_NMB_RUN_WORKOUTS",
  AVG_NMB_WORKOUTS = "AVG_NMB_WORKOUTS",
  AVG_NMB_WORKOUTS_PLUS_NMB_RUN_WORKOUTS = "AVG_NMB_WORKOUTS_PLUS_NMB_RUN_WORKOUTS",
  B42_SCORE = "B42_SCORE",
  B42_SCORE_PLUS_WORKOUTS = "B42_SCORE_PLUS_WORKOUTS",
  NMB_RUN_WORKOUTS = "NMB_RUN_WORKOUTS",
  NMB_WORKOUTS = "NMB_WORKOUTS",
  NMB_WORKOUTS_PLUS_NMB_RUN_WORKOUTS = "NMB_WORKOUTS_PLUS_NMB_RUN_WORKOUTS",
  RUN_WORKOUT_BEST_TIME = "RUN_WORKOUT_BEST_TIME",
  RUN_WORKOUT_KILOMETERS_RUN = "RUN_WORKOUT_KILOMETERS_RUN"
}

export type ChallengePartner = {
  __typename: "ChallengePartner";
  name: Scalars["String"];
  thumbnail: Scalars["String"];
  url: Maybe<Scalars["String"]>;
};

export type ChallengePrize = {
  __typename: "ChallengePrize";
  caption: Maybe<Scalars["String"]>;
  image: Scalars["String"];
  subTitle: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export enum ChallengeStatus {
  ACTIVE = "ACTIVE",
  FINISHED = "FINISHED",
  UPCOMING = "UPCOMING"
}

export type ChallengeTeamLeaderboard = {
  __typename: "ChallengeTeamLeaderboard";
  currentTeamEdge: Maybe<ChallengeTeamLeaderboardEdge>;
  edges: Array<ChallengeTeamLeaderboardEdge>;
  id: Scalars["ID"];
  scoreType: ChallengeLeaderboardScoreType;
  totalCount: Scalars["Int"];
};

export type ChallengeTeamLeaderboardEdge = {
  __typename: "ChallengeTeamLeaderboardEdge";
  cursor: Scalars["ID"];
  node: Maybe<ChallengeTeamLeaderboardNode>;
};

export type ChallengeTeamLeaderboardNode = {
  __typename: "ChallengeTeamLeaderboardNode";
  rank: Scalars["Int"];
  team: Team;
  /**  value containing result for team  */
  value: LeaderboardValue;
};

export enum ChallengeType {
  SINGLE_PLAYERS = "SINGLE_PLAYERS",
  TEAMS = "TEAMS",
  TEAMS_AND_SPORTS_CLUB_TEAMS = "TEAMS_AND_SPORTS_CLUB_TEAMS"
}

export type ChallengeUserLeaderboard = {
  __typename: "ChallengeUserLeaderboard";
  currentUserEdge: Maybe<ChallengeUserLeaderboardEdge>;
  edges: Array<ChallengeUserLeaderboardEdge>;
  id: Scalars["ID"];
  scoreType: ChallengeLeaderboardScoreType;
  totalCount: Scalars["Int"];
};

export type ChallengeUserLeaderboardEdge = {
  __typename: "ChallengeUserLeaderboardEdge";
  cursor: Scalars["ID"];
  node: Maybe<ChallengeUserLeaderboardNode>;
};

export type ChallengeUserLeaderboardNode = {
  __typename: "ChallengeUserLeaderboardNode";
  rank: Scalars["Int"];
  user: User;
  /**  value containing result for user  */
  value: LeaderboardValue;
};

/**  DEPRECATED  */
export type ChampionWorkout = {
  __typename: "ChampionWorkout";
  workoutIndex: Scalars["Int"];
  workouts: Maybe<Array<Maybe<WorkoutUnion>>>;
};

export type ChampionWorkoutData = {
  __typename: "ChampionWorkoutData";
  elapsedTime: Maybe<Scalars["Int"]>;
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type ChampionWorkoutDataInput = {
  elapsedTime: Scalars["Int"];
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type CheckupWorkoutReminder = Reminder & {
  __typename: "CheckupWorkoutReminder";
  id: Scalars["ID"];
  part: CheckupWorkoutReminderPart;
  reminderDate: Scalars["Date"];
  type: Scalars["String"];
};

export enum CheckupWorkoutReminderPart {
  PART_1 = "PART_1",
  PART_2 = "PART_2"
}

export type Club = {
  __typename: "Club";
  _id: Scalars["ID"];
  /** @deprecated Ambition is no longer used */
  ambition: Maybe<Scalars["String"]>;
  b42Score: Maybe<B42ClubScore>;
  /** Number of workouts done by all members in this week (Mon 0:01 - Sun 23:59) */
  currWeekNmbWorkoutsDone: Scalars["Int"];
  /** Sum of all kilometers run by all members in this week (Mon 0:01 - Sun 23:59) */
  currWeekTotalKMRun: Scalars["Int"];
  hasCoach: Scalars["Boolean"];
  logo: Maybe<File>;
  members: Array<User>;
  name: Scalars["String"];
  /** Number of player with status=Fit */
  nmbFitPlayers: Scalars["Int"];
  /** Number of player with status=Injured */
  nmbInjuredPlayers: Scalars["Int"];
  /** @deprecated No programs in app anymore */
  nmbPlayersWithActiveProgram: Scalars["Int"];
  /** Number of members who have done at least one performance test per category */
  nmbPlayersWithAllPerformanceTestsDone: Scalars["Int"];
  ranking: Maybe<ClubRanking>;
};

/** - DEPRECATED - */
export type ClubActivitiesConnection = {
  __typename: "ClubActivitiesConnection";
  edges: Array<ClubActivityEdge>;
  totalCount: Scalars["Int"];
};

/** - DEPRECATED - */
export type ClubActivityEdge = {
  __typename: "ClubActivityEdge";
  cursor: Scalars["ID"];
  node: ClubActivityNode;
};

/** - DEPRECATED - */
export type ClubActivityFilter = {
  types: Array<ActivityType>;
};

/** - DEPRECATED - */
export type ClubActivityNode = {
  __typename: "ClubActivityNode";
  activity: ActivityUnion;
  user: User;
};

export type ClubRanking = {
  __typename: "ClubRanking";
  global: Maybe<Scalars["Int"]>;
};

/** - DEPRECATED - */
export type ClubRankingChallengePayload = {
  endDate: Scalars["Date"];
  filter?: InputMaybe<ClubRankingFilterOptions>;
  name: Scalars["String"];
  startDate: Scalars["Date"];
};

/** - DEPRECATED - */
export type ClubRankingConnection = {
  __typename: "ClubRankingConnection";
  currentClubEdge: Maybe<ClubRankingEdge>;
  edges: Array<ClubRankingEdge>;
  totalCount: Scalars["Int"];
};

/** - DEPRECATED - */
export type ClubRankingEdge = {
  __typename: "ClubRankingEdge";
  cursor: Scalars["ID"];
  node: ClubRankingNode;
};

/** - DEPRECATED - */
export type ClubRankingFilterInput = {
  challengePayload?: InputMaybe<ClubRankingChallengePayload>;
  filter: RankingFilter;
};

/** - DEPRECATED - */
export type ClubRankingFilterOptions = {
  excluded?: InputMaybe<Array<Scalars["ID"]>>;
  maxNmbWorkoutsPerMemberPerDay?: InputMaybe<Scalars["Int"]>;
  minMembers?: InputMaybe<Scalars["Int"]>;
};

/** - DEPRECATED - */
export type ClubRankingNode = {
  __typename: "ClubRankingNode";
  club: Club;
  /**
   * Number of workouts for each category.
   * Use this instead of calculating it in the frontend,
   * because the backend filters the workouts and than it can happen, that the rank from the backend doens't match the number of workouts displayed in the frontend.
   */
  nmbOfWorkouts: LeaderboardRankingNodeNmbOfWorkouts;
  rank: Scalars["Int"];
};

export type CoachActivitiesOccurrenceFilter = {
  from?: InputMaybe<Scalars["Date"]>;
  types?: InputMaybe<Array<CoachActivityType>>;
};

export type CoachActivitiesScheduleFilter = {
  from: Scalars["Date"];
  to: Scalars["Date"];
  types?: InputMaybe<Array<CoachActivityType>>;
};

export type CoachActivity = {
  __typename: "CoachActivity";
  _id: Scalars["String"];
  /** @deprecated Use participants instead */
  attendees: Array<AttendeeUnion>;
  /** @deprecated Use team instead */
  club: Club;
  coach: User;
  createdAt: Scalars["Date"];
  info: CoachActivityInfoUnion;
  /** @deprecated Use occurrences instead */
  occurences: Array<Occurrence>;
  occurrences: Array<Occurrence>;
  participants: Array<User>;
  participantsFilters: Maybe<ParticipantsFilters>;
  recurringPattern: Maybe<RecurringPattern>;
  team: Team;
  /** Do not use this to display when an event will happen. For this use `occurrences` instead. */
  timeFrame: TimeFrame;
  type: CoachActivityType;
};

export type CoachActivityEventFeedbackInteractionResult = {
  __typename: "CoachActivityEventFeedbackInteractionResult";
  date: Scalars["Date"];
  result: Maybe<CoachActivityEventFeedbackResult>;
  user: User;
};

export enum CoachActivityEventFeedbackPattern {
  IMMEDIATELY_AFTER = "IMMEDIATELY_AFTER",
  NEXT_DAY = "NEXT_DAY",
  THIS_EVENING = "THIS_EVENING"
}

export type CoachActivityEventFeedbackResult = {
  __typename: "CoachActivityEventFeedbackResult";
  /** Open field for feedback */
  openField: Maybe<Scalars["String"]>;
  /** 1 - 10 */
  performance: Scalars["Int"];
  /** 1 - 10 */
  trainingLoad: Scalars["Int"];
};

export type CoachActivityEventFeedbackResultInput = {
  /** Open field for feedback */
  openField?: InputMaybe<Scalars["String"]>;
  /** 1 - 10 */
  performance: Scalars["Int"];
  /** 1 - 10 */
  trainingLoad: Scalars["Int"];
};

export type CoachActivityFeedbackInteractionResult = {
  __typename: "CoachActivityFeedbackInteractionResult";
  date: Maybe<Scalars["Date"]>;
  result: CoachActivityFeedbackResult;
  user: User;
};

export type CoachActivityFeedbackPain = {
  __typename: "CoachActivityFeedbackPain";
  description: Maybe<Scalars["String"]>;
  value: Scalars["Boolean"];
};

export type CoachActivityFeedbackPainInput = {
  description?: InputMaybe<Scalars["String"]>;
  value: Scalars["Boolean"];
};

export type CoachActivityFeedbackResult = {
  __typename: "CoachActivityFeedbackResult";
  /**
   * 0 - 10
   * @deprecated Use trainingLoad instead
   */
  generalLoad: Maybe<Scalars["Int"]>;
  openField: Maybe<Scalars["String"]>;
  pains: Maybe<CoachActivityFeedbackPain>;
  /** 1 - 5 */
  satisfaction: Maybe<Scalars["Int"]>;
  /** 1 - 100 */
  soreness: Maybe<Scalars["Int"]>;
  /** 1 - 10 */
  trainingLoad: Maybe<Scalars["Int"]>;
  /** 1 - 5 */
  wellbeing: Maybe<Scalars["Int"]>;
};

export type CoachActivityFeedbackResultInput = {
  openField?: InputMaybe<Scalars["String"]>;
  pains?: InputMaybe<CoachActivityFeedbackPainInput>;
  /** 1 - 5 */
  satisfaction?: InputMaybe<Scalars["Int"]>;
  /** 1 - 100 */
  soreness?: InputMaybe<Scalars["Int"]>;
  /** 1 - 5 */
  trainingLoad?: InputMaybe<Scalars["Int"]>;
  /** 1 - 5 */
  wellbeing?: InputMaybe<Scalars["Int"]>;
};

export type CoachActivityInfoEventFeedback = {
  __typename: "CoachActivityInfoEventFeedback";
  connectedCoachActivity: CoachActivity;
  connectedOccurrenceId: Scalars["ID"];
  interactions: Array<CoachActivityEventFeedbackInteractionResult>;
};

export type CoachActivityInfoEventGame = {
  __typename: "CoachActivityInfoEventGame";
  /** allows displaying number of votes */
  attendanceVisible: Maybe<Scalars["Boolean"]>;
  /** votes for all participants will be set automatically to accept */
  autoAccept: Maybe<Scalars["Boolean"]>;
  automaticEventFeedback: Maybe<CoachActivityEventFeedbackPattern>;
  connectedEventFeedback: Maybe<CoachActivity>;
  description: Maybe<Scalars["String"]>;
  endTime: Maybe<Scalars["Date"]>;
  gameTime: Scalars["Date"];
  gameType: Maybe<GameType>;
  interactions: Array<CoachActivityInfoEventInteraction>;
  isHomeGame: Scalars["Boolean"];
  lineup: Maybe<Lineup>;
  lineupVisibility: Maybe<LineupVisibility>;
  location: Maybe<Scalars["String"]>;
  locationUrl: Maybe<Scalars["String"]>;
  meetingPointLocation: Maybe<Scalars["String"]>;
  meetingPointTime: Maybe<Scalars["Date"]>;
  /** @deprecated Use opponentTeamName instead */
  opponentClubName: Scalars["String"];
  opponentTeamName: Scalars["String"];
  responseDeadline: Maybe<Scalars["Date"]>;
};

export type CoachActivityInfoEventGameInput = {
  /** allows displaying number of votes */
  attendanceVisible?: InputMaybe<Scalars["Boolean"]>;
  /** votes for all participants will be set automatically to accept */
  autoAccept?: InputMaybe<Scalars["Boolean"]>;
  automaticEventFeedback?: InputMaybe<CoachActivityEventFeedbackPattern>;
  description?: InputMaybe<Scalars["String"]>;
  endTime?: InputMaybe<Scalars["Date"]>;
  /** game time - its time of the game, meeting time is time of the event */
  gameTime: Scalars["Date"];
  gameType?: InputMaybe<GameType>;
  isHomeGame: Scalars["Boolean"];
  lineup?: InputMaybe<LineupInput>;
  lineupVisibility?: InputMaybe<LineupVisibility>;
  location?: InputMaybe<Scalars["String"]>;
  locationUrl?: InputMaybe<Scalars["String"]>;
  meetingPointLocation?: InputMaybe<Scalars["String"]>;
  meetingPointTime?: InputMaybe<Scalars["Date"]>;
  /** opponentClubName or opponentTeamName should be passed */
  opponentClubName?: InputMaybe<Scalars["String"]>;
  opponentTeamName?: InputMaybe<Scalars["String"]>;
  responseDeadline?: InputMaybe<Scalars["Date"]>;
};

export type CoachActivityInfoEventInput = {
  game?: InputMaybe<CoachActivityInfoEventGameInput>;
  meeting?: InputMaybe<CoachActivityInfoEventMeetingInput>;
  teamTraining?: InputMaybe<CoachActivityInfoEventTeamTrainingInput>;
};

export type CoachActivityInfoEventInteraction = {
  __typename: "CoachActivityInfoEventInteraction";
  date: Scalars["Date"];
  occurrenceId: Scalars["ID"];
  votes: Array<CoachActivityVote>;
};

export type CoachActivityInfoEventMeeting = {
  __typename: "CoachActivityInfoEventMeeting";
  /** allows displaying number of votes */
  attendanceVisible: Maybe<Scalars["Boolean"]>;
  /** votes for all participants will be set automatically to accept */
  autoAccept: Maybe<Scalars["Boolean"]>;
  description: Maybe<Scalars["String"]>;
  endTime: Maybe<Scalars["Date"]>;
  interactions: Array<CoachActivityInfoEventInteraction>;
  location: Maybe<Scalars["String"]>;
  locationUrl: Maybe<Scalars["String"]>;
  meetingPointLocation: Maybe<Scalars["String"]>;
  meetingPointTime: Maybe<Scalars["Date"]>;
  responseDeadline: Maybe<Scalars["Date"]>;
  title: Scalars["String"];
};

export type CoachActivityInfoEventMeetingInput = {
  /** allows displaying number of votes */
  attendanceVisible?: InputMaybe<Scalars["Boolean"]>;
  /** votes for all participants will be set automatically to accept */
  autoAccept?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  endTime?: InputMaybe<Scalars["Date"]>;
  location?: InputMaybe<Scalars["String"]>;
  locationUrl?: InputMaybe<Scalars["String"]>;
  meetingPointLocation?: InputMaybe<Scalars["String"]>;
  meetingPointTime?: InputMaybe<Scalars["Date"]>;
  responseDeadline?: InputMaybe<Scalars["Date"]>;
  title: Scalars["String"];
};

export type CoachActivityInfoEventTeamTraining = {
  __typename: "CoachActivityInfoEventTeamTraining";
  /** allows displaying number of votes */
  attendanceVisible: Maybe<Scalars["Boolean"]>;
  /** votes for all participants will be set automatically to accept */
  autoAccept: Maybe<Scalars["Boolean"]>;
  coolDown: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  endTime: Maybe<Scalars["Date"]>;
  focus: Maybe<Scalars["String"]>;
  intensity: Maybe<Scalars["Int"]>;
  interactions: Array<CoachActivityInfoEventInteraction>;
  location: Maybe<Scalars["String"]>;
  locationUrl: Maybe<Scalars["String"]>;
  mainPart: Maybe<Scalars["String"]>;
  meetingPointLocation: Maybe<Scalars["String"]>;
  meetingPointTime: Maybe<Scalars["Date"]>;
  occurrenceInfo: Maybe<Array<CoachActivityInfoEventTrainingOccurrenceInfo>>;
  responseDeadline: Maybe<Scalars["Date"]>;
  /** indicates time gap for responseDeadline in series */
  responseDeadlineTimeGap: Maybe<Scalars["Int"]>;
  title: Maybe<Scalars["String"]>;
  warmup: Maybe<Scalars["String"]>;
};

export type CoachActivityInfoEventTeamTrainingInput = {
  /** allows displaying number of votes */
  attendanceVisible?: InputMaybe<Scalars["Boolean"]>;
  /** votes for all participants will be set automatically to accept */
  autoAccept?: InputMaybe<Scalars["Boolean"]>;
  automaticEventFeedback?: InputMaybe<CoachActivityEventFeedbackPattern>;
  coolDown?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  endTime?: InputMaybe<Scalars["Date"]>;
  focus?: InputMaybe<Scalars["String"]>;
  intensity?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["String"]>;
  locationUrl?: InputMaybe<Scalars["String"]>;
  mainPart?: InputMaybe<Scalars["String"]>;
  meetingPointLocation?: InputMaybe<Scalars["String"]>;
  meetingPointTime?: InputMaybe<Scalars["Date"]>;
  responseDeadline?: InputMaybe<Scalars["Date"]>;
  responseDeadlineTimeGap?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
  warmup?: InputMaybe<Scalars["String"]>;
};

export type CoachActivityInfoEventTrainingOccurrenceInfo = {
  __typename: "CoachActivityInfoEventTrainingOccurrenceInfo";
  info: CoachActivityInfoEventTrainingOccurrenceInfoDetail;
  occurrenceId: Scalars["ID"];
};

export type CoachActivityInfoEventTrainingOccurrenceInfoDetail = {
  __typename: "CoachActivityInfoEventTrainingOccurrenceInfoDetail";
  /** allows displaying number of votes */
  attendanceVisible: Maybe<Scalars["Boolean"]>;
  /** votes for all participants will be set automatically to accept */
  autoAccept: Maybe<Scalars["Boolean"]>;
  automaticEventFeedback: Maybe<CoachActivityEventFeedbackPattern>;
  connectedEventFeedback: Maybe<CoachActivity>;
  coolDown: Maybe<Scalars["String"]>;
  coolDownFiles: Maybe<Array<Maybe<File>>>;
  description: Maybe<Scalars["String"]>;
  endTime: Maybe<Scalars["Date"]>;
  focus: Maybe<Scalars["String"]>;
  intensity: Maybe<Scalars["Int"]>;
  location: Maybe<Scalars["String"]>;
  locationUrl: Maybe<Scalars["String"]>;
  mainPart: Maybe<Scalars["String"]>;
  mainPartFiles: Maybe<Array<Maybe<File>>>;
  meetingPointLocation: Maybe<Scalars["String"]>;
  meetingPointTime: Maybe<Scalars["Date"]>;
  responseDeadline: Maybe<Scalars["Date"]>;
  title: Maybe<Scalars["String"]>;
  warmup: Maybe<Scalars["String"]>;
  warmupFiles: Maybe<Array<Maybe<File>>>;
};

export type CoachActivityInfoFeedback = {
  __typename: "CoachActivityInfoFeedback";
  anonymous: Scalars["Boolean"];
  description: Maybe<Scalars["String"]>;
  /** @deprecated Use trainingLoad instead */
  generalLoad: Maybe<Scalars["Boolean"]>;
  interactions: Array<CoachActivityInfoFeedbackInteraction>;
  isQuickFeedback: Scalars["Boolean"];
  openField: Maybe<Scalars["Boolean"]>;
  pains: Maybe<Scalars["Boolean"]>;
  satisfaction: Maybe<Scalars["Boolean"]>;
  soreness: Maybe<Scalars["Boolean"]>;
  trainingLoad: Maybe<Scalars["Boolean"]>;
  wellbeing: Maybe<Scalars["Boolean"]>;
};

export type CoachActivityInfoFeedbackInput = {
  anonymous: Scalars["Boolean"];
  description?: InputMaybe<Scalars["String"]>;
  openField?: InputMaybe<Scalars["Boolean"]>;
  pains?: InputMaybe<Scalars["Boolean"]>;
  satisfaction?: InputMaybe<Scalars["Boolean"]>;
  soreness?: InputMaybe<Scalars["Boolean"]>;
  /** 0 - 10 */
  trainingLoad?: InputMaybe<Scalars["Boolean"]>;
  wellbeing?: InputMaybe<Scalars["Boolean"]>;
};

export type CoachActivityInfoFeedbackInteraction = {
  __typename: "CoachActivityInfoFeedbackInteraction";
  date: Scalars["Date"];
  results: Array<CoachActivityFeedbackInteractionResult>;
};

export type CoachActivityInfoInput = {
  event?: InputMaybe<CoachActivityInfoEventInput>;
  feedback?: InputMaybe<CoachActivityInfoFeedbackInput>;
  task?: InputMaybe<CoachActivityInfoTaskInput>;
};

export type CoachActivityInfoTaskInput = {
  performanceTest?: InputMaybe<CoachActivityInfoTaskPerformanceTestInput>;
  runWorkout?: InputMaybe<CoachActivityInfoTaskRunWorkoutInput>;
  workout?: InputMaybe<CoachActivityInfoTaskWorkoutInput>;
};

export type CoachActivityInfoTaskPerformanceTest = {
  __typename: "CoachActivityInfoTaskPerformanceTest";
  description: Maybe<Scalars["String"]>;
  interactions: Array<CoachActivityInfoTaskPerformanceTestInteraction>;
  performanceTest: PerformanceTest;
};

export type CoachActivityInfoTaskPerformanceTestInput = {
  description?: InputMaybe<Scalars["String"]>;
  performanceTestId: Scalars["String"];
};

export type CoachActivityInfoTaskPerformanceTestInteraction = {
  __typename: "CoachActivityInfoTaskPerformanceTestInteraction";
  date: Scalars["Date"];
  occurrenceId: Scalars["ID"];
  results: Array<CoachActivityInfoTaskPerformanceTestInteractionResult>;
};

export type CoachActivityInfoTaskPerformanceTestInteractionResult = {
  __typename: "CoachActivityInfoTaskPerformanceTestInteractionResult";
  result: Maybe<SinglePerformanceTestResult>;
  user: User;
};

export type CoachActivityInfoTaskRunWorkout = {
  __typename: "CoachActivityInfoTaskRunWorkout";
  description: Maybe<Scalars["String"]>;
  interactions: Array<CoachActivityInfoTaskRunWorkoutInteraction>;
  runWorkout: RunWorkoutUnion;
};

export type CoachActivityInfoTaskRunWorkoutInput = {
  description?: InputMaybe<Scalars["String"]>;
  runWorkoutId: Scalars["String"];
};

export type CoachActivityInfoTaskRunWorkoutInteraction = {
  __typename: "CoachActivityInfoTaskRunWorkoutInteraction";
  date: Scalars["Date"];
  occurrenceId: Scalars["ID"];
  results: Array<CoachActivityInfoTaskRunWorkoutInteractionResult>;
};

export type CoachActivityInfoTaskRunWorkoutInteractionResult = {
  __typename: "CoachActivityInfoTaskRunWorkoutInteractionResult";
  manualEnteredResult: Maybe<CoachWorkoutManualEnteredResult>;
  result: Maybe<RunWorkoutResult>;
  user: User;
};

export type CoachActivityInfoTaskWorkout = {
  __typename: "CoachActivityInfoTaskWorkout";
  description: Maybe<Scalars["String"]>;
  interactions: Array<CoachActivityInfoTaskWorkoutInteraction>;
  workout: WorkoutUnion;
  workoutDifficulty: Maybe<WorkoutDifficulty>;
};

export type CoachActivityInfoTaskWorkoutInput = {
  description?: InputMaybe<Scalars["String"]>;
  workoutDifficulty?: InputMaybe<WorkoutDifficulty>;
  workoutId: Scalars["String"];
};

export type CoachActivityInfoTaskWorkoutInteraction = {
  __typename: "CoachActivityInfoTaskWorkoutInteraction";
  date: Scalars["Date"];
  occurrenceId: Scalars["ID"];
  results: Array<CoachActivityInfoTaskWorkoutInteractionResult>;
};

export type CoachActivityInfoTaskWorkoutInteractionResult = {
  __typename: "CoachActivityInfoTaskWorkoutInteractionResult";
  manualEnteredResult: Maybe<CoachWorkoutManualEnteredResult>;
  result: Maybe<CoachWorkoutDataRecord>;
  user: User;
};

export type CoachActivityInfoUnion =
  | CoachActivityInfoEventFeedback
  | CoachActivityInfoEventGame
  | CoachActivityInfoEventMeeting
  | CoachActivityInfoEventTeamTraining
  | CoachActivityInfoFeedback
  | CoachActivityInfoTaskPerformanceTest
  | CoachActivityInfoTaskRunWorkout
  | CoachActivityInfoTaskWorkout;

export type CoachActivityInput = {
  info: CoachActivityInfoInput;
  participantsFilters?: InputMaybe<ParticipantsFiltersInput>;
  recurringPattern?: InputMaybe<RecurringPatternInput>;
  timeFrame: TimeFrameInput;
  type: CoachActivityType;
};

export type CoachActivityNextOccurrence = {
  __typename: "CoachActivityNextOccurrence";
  coachActivity: CoachActivity;
  occurrence: Occurrence;
};

export type CoachActivityOccurrenceEdge = {
  __typename: "CoachActivityOccurrenceEdge";
  cursor: Scalars["ID"];
  occurence: Occurrence;
};

export type CoachActivityParticipationRate = {
  __typename: "CoachActivityParticipationRate";
  participation: Scalars["Float"];
  unanswered: Scalars["Float"];
};

export type CoachActivitySchedule = {
  __typename: "CoachActivitySchedule";
  coachActivity: CoachActivity;
  occurrences: Array<Occurrence>;
};

export enum CoachActivityType {
  /** Automatically created feedback after Game/Training */
  EVENT_FEEDBACK = "EVENT_FEEDBACK",
  /** Detailed or Quick Feedback */
  FEEDBACK = "FEEDBACK",
  GAME = "GAME",
  MEETING = "MEETING",
  PERFORMANCE_TEST = "PERFORMANCE_TEST",
  RUN_WORKOUT = "RUN_WORKOUT",
  TEAM_TRAINING = "TEAM_TRAINING",
  WORKOUT = "WORKOUT"
}

export type CoachActivityVote = {
  __typename: "CoachActivityVote";
  description: Maybe<Scalars["String"]>;
  /** grade for participation, set by coach */
  performanceGrade: Maybe<Scalars["Int"]>;
  user: User;
  voteAnswer: VoteAnswer;
};

export type CoachAssignedGamechangerNotification = Notification & {
  __typename: "CoachAssignedGamechangerNotification";
  date: Scalars["Date"];
  id: Scalars["String"];
};

export type CoachAssignedWorkoutNotification = Notification & {
  __typename: "CoachAssignedWorkoutNotification";
  coachActivityId: Scalars["String"];
  date: Scalars["Date"];
  id: Scalars["String"];
};

export type CoachAttendeeInput = {
  clubId: Scalars["String"];
};

export type CoachCancelledGamechangerNotification = Notification & {
  __typename: "CoachCancelledGamechangerNotification";
  date: Scalars["Date"];
  id: Scalars["String"];
};

export type CoachCreatedFeedbackNotification = Notification & {
  __typename: "CoachCreatedFeedbackNotification";
  coachActivityId: Scalars["String"];
  date: Scalars["Date"];
  id: Scalars["String"];
};

export type CoachCreatedTeamChallengeNotification = Notification & {
  __typename: "CoachCreatedTeamChallengeNotification";
  date: Scalars["Date"];
  id: Scalars["String"];
  teamChallengeId: Scalars["ID"];
};

export type CoachFeedActivityCanceled = CoachFeedItem & {
  __typename: "CoachFeedActivityCanceled";
  _id: Scalars["String"];
  canceledDate: Scalars["Date"];
  coach: User;
  coachActivity: CoachActivity;
  date: Scalars["Date"];
  multi: Scalars["Boolean"];
  /** @deprecated No need in this prop */
  recipients: Array<CoachFeedRecipientUnion>;
};

export type CoachFeedActivityCreated = CoachFeedItem & {
  __typename: "CoachFeedActivityCreated";
  _id: Scalars["String"];
  coach: User;
  coachActivity: CoachActivity;
  date: Scalars["Date"];
  /** @deprecated No need in this prop */
  recipients: Array<CoachFeedRecipientUnion>;
};

export type CoachFeedActivityInfoChanged = CoachFeedItem & {
  __typename: "CoachFeedActivityInfoChanged";
  _id: Scalars["String"];
  coach: User;
  coachActivity: CoachActivity;
  date: Scalars["Date"];
  description: Maybe<CoachFeedActivityInfoChangedFieldString>;
  gameTime: Maybe<CoachFeedActivityInfoChangedFieldDate>;
  isHomeGame: Maybe<CoachFeedActivityInfoChangedFieldBoolean>;
  location: Maybe<CoachFeedActivityInfoChangedFieldString>;
  /** @deprecated Use opponentTeamName instead */
  opponentClubName: Maybe<CoachFeedActivityInfoChangedFieldString>;
  opponentTeamName: Maybe<CoachFeedActivityInfoChangedFieldString>;
  /** @deprecated No need in this prop */
  recipients: Array<CoachFeedRecipientUnion>;
  title: Maybe<CoachFeedActivityInfoChangedFieldString>;
};

export type CoachFeedActivityInfoChangedFieldBoolean = {
  __typename: "CoachFeedActivityInfoChangedFieldBoolean";
  new: Maybe<Scalars["Boolean"]>;
  old: Maybe<Scalars["Boolean"]>;
};

export type CoachFeedActivityInfoChangedFieldDate = {
  __typename: "CoachFeedActivityInfoChangedFieldDate";
  new: Maybe<Scalars["Date"]>;
  old: Maybe<Scalars["Date"]>;
};

export type CoachFeedActivityInfoChangedFieldString = {
  __typename: "CoachFeedActivityInfoChangedFieldString";
  new: Maybe<Scalars["String"]>;
  old: Maybe<Scalars["String"]>;
};

export type CoachFeedActivityReminder = CoachFeedItem & {
  __typename: "CoachFeedActivityReminder";
  _id: Scalars["String"];
  coach: User;
  coachActivity: CoachActivity;
  date: Scalars["Date"];
  occurrenceDate: Scalars["Date"];
  /** @deprecated No need in this prop */
  recipients: Array<CoachFeedRecipientUnion>;
};

export type CoachFeedActivityRescheduled = CoachFeedItem & {
  __typename: "CoachFeedActivityRescheduled";
  _id: Scalars["String"];
  coach: User;
  coachActivity: CoachActivity;
  date: Scalars["Date"];
  multi: Scalars["Boolean"];
  newDate: Scalars["Date"];
  newDuration: Scalars["Int"];
  oldDate: Scalars["Date"];
  /** @deprecated No need in this prop */
  recipients: Array<CoachFeedRecipientUnion>;
};

export type CoachFeedAnnouncement = CoachFeedItem & {
  __typename: "CoachFeedAnnouncement";
  _id: Scalars["String"];
  coach: User;
  date: Scalars["Date"];
  message: Scalars["String"];
  reactions: Array<CoachFeedItemReaction>;
  /** @deprecated No need in this prop */
  recipients: Array<CoachFeedRecipientUnion>;
};

export type CoachFeedAnnouncementInput = {
  message: Scalars["String"];
};

export enum CoachFeedCoachActivityNotificationType {
  CREATED = "CREATED",
  INFO_CHANGE = "INFO_CHANGE",
  MULTIPLE_CANCEL = "MULTIPLE_CANCEL",
  MULTIPLE_RESCHEDULE = "MULTIPLE_RESCHEDULE",
  SINGLE_CANCEL = "SINGLE_CANCEL",
  SINGLE_RESCHEDULE = "SINGLE_RESCHEDULE"
}

export type CoachFeedFilter = {
  types?: InputMaybe<Array<CoachFeedItemType>>;
};

export type CoachFeedItem = {
  _id: Scalars["String"];
  coach: User;
  date: Scalars["Date"];
  /** @deprecated No need in this prop */
  recipients: Array<CoachFeedRecipientUnion>;
};

export type CoachFeedItemEdge = {
  __typename: "CoachFeedItemEdge";
  cursor: Scalars["String"];
  node: CoachFeedItemUnion;
};

export type CoachFeedItemReaction = {
  __typename: "CoachFeedItemReaction";
  reaction: Reaction;
  user: User;
};

export enum CoachFeedItemType {
  ACTIVITY_CANCELED = "ACTIVITY_CANCELED",
  ACTIVITY_CREATED = "ACTIVITY_CREATED",
  ACTIVITY_INFO_CHANGED = "ACTIVITY_INFO_CHANGED",
  ACTIVITY_REMINDER = "ACTIVITY_REMINDER",
  ACTIVITY_RESCHEDULED = "ACTIVITY_RESCHEDULED",
  ANNOUNCEMENT = "ANNOUNCEMENT"
}

export type CoachFeedItemUnion =
  | CoachFeedActivityCanceled
  | CoachFeedActivityCreated
  | CoachFeedActivityInfoChanged
  | CoachFeedActivityReminder
  | CoachFeedActivityRescheduled
  | CoachFeedAnnouncement;

export type CoachFeedItemsConnection = {
  __typename: "CoachFeedItemsConnection";
  edges: Array<CoachFeedItemEdge>;
  totalCount: Scalars["Int"];
};

/** - DEPRECATED - */
export type CoachFeedRecipientUnion = Club | User;

export enum CoachType {
  /** @deprecated Please use Position instead */
  ATHLETIC_COACH = "ATHLETIC_COACH",
  /** @deprecated Please use Position instead */
  CO_COACH = "CO_COACH",
  /** @deprecated Please use Position instead */
  HEADCOACH = "HEADCOACH",
  /** @deprecated Please use Position instead */
  PHYSIO = "PHYSIO",
  /** @deprecated Please use Position instead */
  PLAYER_COACH = "PLAYER_COACH"
}

export type CoachWorkoutDataRecord = {
  __typename: "CoachWorkoutDataRecord";
  _id: Scalars["String"];
  date: Scalars["Date"];
  elapsedTime: Maybe<Scalars["Int"]>;
  elapsedTimePerExercise: Maybe<Array<ElapsedTimeForExercise>>;
  /** @deprecated No longer supported */
  userScore: Maybe<Scalars["Int"]>;
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type CoachWorkoutDataRecordInput = {
  date: Scalars["Date"];
  elapsedTime?: InputMaybe<Scalars["Int"]>;
  elapsedTimePerExercise?: InputMaybe<Array<ElapsedTimeForExerciseInput>>;
  source?: InputMaybe<WorkoutSource>;
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type CoachWorkoutManualEnteredResult = {
  __typename: "CoachWorkoutManualEnteredResult";
  elapsedMillis: Scalars["Int"];
};

export type CoachWorkoutReminder = Reminder & {
  __typename: "CoachWorkoutReminder";
  activityId: Scalars["String"];
  difficulty: WorkoutDifficulty;
  id: Scalars["ID"];
  reminderDate: Scalars["Date"];
  type: Scalars["String"];
  workoutId: Scalars["ID"];
  workoutType: WorkoutType;
};

export type CommunityActivitiesConnection = {
  __typename: "CommunityActivitiesConnection";
  edges: Array<ActivityEdge>;
};

export type CommunityActivityFilter = {
  types: Array<ActivityType>;
};

export type CompleteCoachActivityTaskInput = {
  elapsedMillis: Scalars["Int"];
};

export type CompletedChallengeActivity = Activity & {
  __typename: "CompletedChallengeActivity";
  _id: Scalars["String"];
  challenge: Maybe<Challenge>;
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export type CompletedTeamChallengeActivity = Activity & {
  __typename: "CompletedTeamChallengeActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
  teamChallenge: Maybe<TeamChallenge>;
};

export type ConnectFitbitInput = {
  /** access token from fitbit */
  accessToken: Scalars["String"];
  /** fitbit user id */
  fitbitUserId: Scalars["String"];
  /** refresh token from fitbit */
  refreshToken: Scalars["String"];
};

export type CooldownDataRecord = {
  __typename: "CooldownDataRecord";
  _id: Scalars["ID"];
  cooldownId: Scalars["String"];
  /** @deprecated No longer supported */
  date: Maybe<Scalars["Date"]>;
};

export type CooldownDataRecordInput = {
  cooldownId: Scalars["String"];
};

export type CreateB42GamechangerWorkoutReminderInput = {
  b42GamechangerId: Scalars["String"];
  difficulty: WorkoutDifficulty;
  /** If set, workout reminder will be rescheduled */
  id?: InputMaybe<Scalars["ID"]>;
  reminderDate: Scalars["Date"];
  trainingDayId: Scalars["String"];
  workoutId: Scalars["ID"];
  workoutType: WorkoutType;
};

export type CreateCoachWorkoutReminderInput = {
  activityId: Scalars["String"];
  difficulty: WorkoutDifficulty;
  /** If set, workout reminder will be rescheduled */
  id?: InputMaybe<Scalars["ID"]>;
  reminderDate: Scalars["Date"];
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type CreateFreeAreaWorkoutReminderInput = {
  difficulty: WorkoutDifficulty;
  /** If set, workout reminder will be rescheduled */
  id?: InputMaybe<Scalars["ID"]>;
  reminderDate: Scalars["Date"];
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type CreateTeamChallengeInput = {
  categories: Array<ChallengeCategory>;
  description: Scalars["String"];
  end: Scalars["Date"];
  goal: Scalars["String"];
  mode: TeamChallengeMode;
  participants: Array<Scalars["ID"]>;
  start: Scalars["Date"];
  teamScoreTarget?: InputMaybe<Scalars["Int"]>;
  title: Scalars["String"];
  userScoreTarget?: InputMaybe<Scalars["Int"]>;
};

export enum CriteriaType {
  BLOGPOST_READ = "BLOGPOST_READ",
  /** @deprecated Please use TEAM_JOINED instead */
  CLUB_JOINED = "CLUB_JOINED",
  /** @deprecated Please use TEAM_MEMBERS instead */
  CLUB_MEMBERS = "CLUB_MEMBERS",
  COOLDOWN_FINISHED = "COOLDOWN_FINISHED",
  PROGRAM_FINISHED = "PROGRAM_FINISHED",
  PROGRAM_PROGRESS = "PROGRAM_PROGRESS",
  PROGRAM_STARTED = "PROGRAM_STARTED",
  TEAM_JOINED = "TEAM_JOINED",
  TEAM_MEMBERS = "TEAM_MEMBERS",
  WARMUP_FINISHED = "WARMUP_FINISHED",
  WORKOUT_FINISHED = "WORKOUT_FINISHED",
  WORKOUT_FINISHED_PER_DAY = "WORKOUT_FINISHED_PER_DAY",
  WORKOUT_IMPROVEMENT = "WORKOUT_IMPROVEMENT",
  WORKOUT_TIME = "WORKOUT_TIME"
}

export type Criterias = {
  __typename: "Criterias";
  count: Scalars["Int"];
  difficulty: Maybe<Scalars["String"]>;
  duration: Maybe<Scalars["Int"]>;
  percentage: Maybe<Scalars["Int"]>;
  programType: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type CustomBodyWeightWorkoutInput = {
  description?: InputMaybe<Scalars["String"]>;
  exercises: Array<ExerciseInput>;
  name: Scalars["String"];
  passes: CustomWorkoutPassesInput;
  type: WorkoutType;
};

export type CustomExercise = {
  __typename: "CustomExercise";
  coverImage: Maybe<Asset>;
  createdAt: Scalars["Date"];
  createdBy: User;
  description: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  instruction: Scalars["String"];
  keypoints: Array<Scalars["String"]>;
  name: Scalars["String"];
  type: WorkoutExerciseType;
  videos: Array<Asset>;
};

export type CustomExerciseInput = {
  description?: InputMaybe<Scalars["String"]>;
  instruction: Scalars["String"];
  keypoints: Array<Scalars["String"]>;
  name: Scalars["String"];
  type: WorkoutExerciseType;
};

export type CustomWorkout = {
  __typename: "CustomWorkout";
  coverImage: Asset;
  createdAt: Scalars["Date"];
  createdBy: User;
  description: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  type: WorkoutType;
};

export type CustomWorkoutPassesInput = {
  easy: Scalars["Int"];
  hard: Scalars["Int"];
  medium: Scalars["Int"];
};

export enum DayOfWeek {
  FR = "FR",
  MO = "MO",
  SA = "SA",
  SU = "SU",
  TH = "TH",
  TU = "TU",
  WE = "WE"
}

export type DefaultExercise = BaseExercise & {
  __typename: "DefaultExercise";
  coverImage: Maybe<Asset>;
  equipments: Array<Equipment>;
  id: Scalars["String"];
  /** @deprecated Gender specific Image in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  image: Maybe<Asset>;
  /** @deprecated Gender specific Image in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  imageGirls: Maybe<Asset>;
  instruction: Scalars["String"];
  keypoints: Array<Scalars["String"]>;
  name: Scalars["String"];
  type: ExerciseType;
  /** @deprecated Gender specific video in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  video: Maybe<Asset>;
  /** @deprecated Gender specific video in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  videoGirls: Maybe<Asset>;
  videos: Maybe<Array<Asset>>;
};

export type DistanceBasedExercise = BaseExercise & {
  __typename: "DistanceBasedExercise";
  coverImage: Maybe<Asset>;
  distance: ExerciseDistance;
  equipments: Array<Equipment>;
  id: Scalars["String"];
  image: Maybe<Asset>;
  imageGirls: Maybe<Asset>;
  instruction: Scalars["String"];
  keypoints: Array<Scalars["String"]>;
  name: Scalars["String"];
  type: ExerciseType;
  video: Maybe<Asset>;
  videoGirls: Maybe<Asset>;
  videos: Maybe<Array<Asset>>;
};

export type ElapsedMillisForExercise = {
  __typename: "ElapsedMillisForExercise";
  elapsedMillis: Scalars["Int"];
  exerciseId: Scalars["String"];
};

export type ElapsedMillisForExerciseInput = {
  elapsedMillis: Scalars["Int"];
  exerciseId: Scalars["String"];
};

export type ElapsedMillisPerExercise = {
  __typename: "ElapsedMillisPerExercise";
  elapsedMillis: Scalars["Int"];
  exerciseId: Scalars["String"];
};

export type ElapsedTimeForExercise = {
  __typename: "ElapsedTimeForExercise";
  elapsedTime: Scalars["Int"];
  exerciseId: Scalars["String"];
};

/** DEPRECATED - Use `FinishWorkoutInput` instead as parent type! */
export type ElapsedTimeForExerciseInput = {
  elapsedTime: Scalars["Int"];
  exerciseId: Scalars["String"];
  /** Only necessary for OutdoorSpeedWorkout. This is the index of the part that contains the exercise */
  outdoorSpeedWorkoutPartIndex?: InputMaybe<Scalars["Int"]>;
};

export type ElapsedTimePerExercise = {
  __typename: "ElapsedTimePerExercise";
  elapsedTime: Scalars["Int"];
  exerciseId: Scalars["String"];
};

export type EndOfYearStoryStats = {
  __typename: "EndOfYearStoryStats";
  mostFinishedWorkout: Maybe<WorkoutCount>;
  nmbOfFinishedWorkouts: Scalars["Int"];
  threeMostTrainedWorkoutTypes: Array<WorkoutType>;
  threeMostTrainedWorkouts: Array<Workout>;
  totalTrainedMinutes: Scalars["Int"];
  /** Array with 7 elements. Index 0 is monday. Values are the workout count per weekday */
  trainingDayHistogram: Array<Scalars["Int"]>;
};

export type EnduranceRunWorkout = BaseRunWorkout & {
  __typename: "EnduranceRunWorkout";
  _id: Scalars["String"];
  averageDuration: Maybe<Scalars["Int"]>;
  distance: Scalars["Int"];
  previewImage: Asset;
  type: RunType;
};

export type Equipment = {
  __typename: "Equipment";
  count: Scalars["Int"];
  description: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
};

export type EventAddToCartDataInput = {
  subscription_billing_cycle: Scalars["String"];
};

export type EventAppInfoInput = {
  buildNumber?: InputMaybe<Scalars["String"]>;
  bundleId?: InputMaybe<Scalars["String"]>;
  version?: InputMaybe<Scalars["String"]>;
};

export type EventBlogPostReadDataInput = {
  id: Scalars["Int"];
  title?: InputMaybe<Scalars["String"]>;
};

export type EventDeviceInput = {
  apiLevel?: InputMaybe<Scalars["Int"]>;
  brand?: InputMaybe<Scalars["String"]>;
  deviceId?: InputMaybe<Scalars["String"]>;
  deviceType?: InputMaybe<Scalars["String"]>;
  manufacturer?: InputMaybe<Scalars["String"]>;
  systemName?: InputMaybe<Scalars["String"]>;
  systemVersion?: InputMaybe<Scalars["String"]>;
};

export type EventExerciseFinishedDataInput = {
  elapsed_time: Scalars["Int"];
  exercise_name: Scalars["String"];
};

export type EventInput = {
  data: Scalars["JSONObject"];
  /**
   * Date when event happened.
   * If not set current date will be used.
   */
  timestamp?: InputMaybe<Scalars["Date"]>;
  type: Scalars["String"];
  /**  If not set current user will be used  */
  userId?: InputMaybe<Scalars["ID"]>;
};

export type EventJoinClubEventDataInput = {
  from?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  params?: InputMaybe<Scalars["JSONObject"]>;
};

export type EventLogInput = {
  /**
   * Date when event happened.
   * If not set current date will be used.
   */
  timestamp?: InputMaybe<Scalars["Date"]>;
  /**  If not set current user will be used  */
  userId?: InputMaybe<Scalars["ID"]>;
};

export type EventMetaInfoInput = {
  appInfo?: InputMaybe<EventAppInfoInput>;
  device?: InputMaybe<EventDeviceInput>;
};

export type EventPerformanceTestCancelledDataInput = {
  elapsedTime: Scalars["Int"];
  performanceTestId: PerformanceTestId;
  results: Array<PerformanceTestResultInput>;
  testExerciseId: PerformanceTestExerciseId;
};

export type EventSocialShareDataInput = {
  area: Scalars["String"];
  platform?: InputMaybe<Scalars["String"]>;
  successful: Scalars["Boolean"];
};

export type EventVisitedRouteDataInput = {
  from?: InputMaybe<Scalars["String"]>;
  params?: InputMaybe<Scalars["JSONObject"]>;
  to: Scalars["String"];
};

export type EventWorkoutCancelledDataInput = {
  area: WorkoutDataRecordArea;
  currentExerciseId?: InputMaybe<Scalars["Int"]>;
  elapsedTime: Scalars["Int"];
  programId?: InputMaybe<Scalars["String"]>;
  weekId?: InputMaybe<Scalars["Int"]>;
  workoutDifficulty?: InputMaybe<WorkoutDifficulty>;
  workoutId: Scalars["String"];
};

/** DEPRECATED */
export type Exercise = {
  __typename: "Exercise";
  coverImage: Maybe<Asset>;
  /** @deprecated Not translated. Use `durationSeconds` in combination with `durationSecondsPerSide` flag instead */
  duration: Maybe<Scalars["String"]>;
  durationSeconds: Maybe<ExerciseDurationsSeconds>;
  durationSecondsPerSide: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  /** @deprecated Gender specific Image in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  image: Maybe<Asset>;
  /** @deprecated Gender specific Image in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  imageGirls: Maybe<Asset>;
  instruction: Scalars["String"];
  keypoints: Array<Scalars["String"]>;
  name: Scalars["String"];
  repetitions: Maybe<ExerciseRepetitions>;
  repetitionsPerSide: Maybe<Scalars["Boolean"]>;
  /** @deprecated Gender specific video in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  video: Maybe<Asset>;
  /** @deprecated Gender specific video in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  videoGirls: Maybe<Asset>;
  videos: Maybe<Array<Asset>>;
};

export type ExerciseDistance = {
  __typename: "ExerciseDistance";
  easy: Scalars["Int"];
  hard: Scalars["Int"];
  medium: Scalars["Int"];
};

export type ExerciseDurationsSeconds = {
  __typename: "ExerciseDurationsSeconds";
  easy: Scalars["Int"];
  hard: Scalars["Int"];
  medium: Scalars["Int"];
};

export type ExerciseInput = {
  exerciseId: Scalars["String"];
  perSide?: InputMaybe<Scalars["Boolean"]>;
  type: ExerciseType;
  value: ExerciseInputValue;
};

export type ExerciseInputValue = {
  easy: Scalars["Int"];
  hard: Scalars["Int"];
  medium: Scalars["Int"];
};

export type ExerciseRepetitions = {
  __typename: "ExerciseRepetitions";
  easy: Scalars["Int"];
  hard: Scalars["Int"];
  medium: Scalars["Int"];
};

export enum ExerciseSource {
  B42 = "B42",
  /** Exercise was created by coach in coach zone */
  CUSTOM = "CUSTOM"
}

export enum ExerciseType {
  DEFAULT = "DEFAULT",
  DISTANCE_BASED = "DISTANCE_BASED",
  REP_BASED = "REP_BASED",
  STAMINA_ENDURANCE = "STAMINA_ENDURANCE",
  STAMINA_INTERVAL = "STAMINA_INTERVAL",
  STAMINA_RECOVERY = "STAMINA_RECOVERY",
  STAMINA_TARGETTIME = "STAMINA_TARGETTIME",
  TIME_BASED = "TIME_BASED"
}

export type ExerciseUnion =
  | DefaultExercise
  | DistanceBasedExercise
  | RepBasedExercise
  | StaminaEnduranceExercise
  | StaminaIntervalExercise
  | StaminaRecoveryExercise
  | StaminaTargetTimeExercise
  | TimeBasedExercise;

export enum ExpensePurpose {
  EQUIPMENT = "EQUIPMENT",
  GROCERCIES = "GROCERCIES",
  LOGISTICS = "LOGISTICS",
  OTHER = "OTHER"
}

export type ExpenseTransactionInfoInput = {
  description?: InputMaybe<Scalars["String"]>;
  purpose: ExpensePurpose;
};

export enum ExternalSport {
  AEROBICS = "AEROBICS",
  BADMINTON = "BADMINTON",
  BASKETBALL = "BASKETBALL",
  BEACH_VOLLEYBALL = "BEACH_VOLLEYBALL",
  BOXING = "BOXING",
  CLIMBING = "CLIMBING",
  CROSSFIT = "CROSSFIT",
  CYCLING = "CYCLING",
  DANCING = "DANCING",
  HIKING = "HIKING",
  HORSE_RIDING = "HORSE_RIDING",
  OTHER = "OTHER",
  RUNNING = "RUNNING",
  SKIING = "SKIING",
  SKI_TOUR = "SKI_TOUR",
  SNOWBOARDING = "SNOWBOARDING",
  SOCCER = "SOCCER",
  SWIMMING = "SWIMMING",
  TENNIS = "TENNIS",
  VOLLEYBALL = "VOLLEYBALL",
  YOGA = "YOGA"
}

export type ExternalSportsMeasurement = {
  __typename: "ExternalSportsMeasurement";
  /** The radius of uncertainty for the ExternalSports, measured in meters. */
  accuracy: Scalars["Float"];
  /** Altitude measured in meters */
  altitude: Scalars["Float"];
  /** The direction in which the device is traveling, measured in degrees and relative to due north. */
  bearing: Scalars["Float"];
  /** The accuracy of the bearing value, measured in degrees. (0 if not supported) */
  bearingAccuracy: Scalars["Float"];
  /** Heart rate - only provided by tracking devices */
  heartRate: Maybe<Scalars["Int"]>;
  /** The latitude in degrees */
  latitude: Scalars["Float"];
  /** The longitude in degrees */
  longitude: Scalars["Float"];
  /** Speed measured in meters per second. */
  speed: Scalars["Float"];
  /** Accuracy of the speed value measured in meters per second (0 if not supported) */
  speedAccuracy: Scalars["Float"];
  /** Steps per minute - only provided by tracking devices */
  steps: Maybe<Scalars["Int"]>;
  time: Scalars["Float"];
};

/**  DEPRECATED  */
export type ExternalSportsResult = {
  __typename: "ExternalSportsResult";
  _id: Scalars["String"];
  elapsedTimeMillis: Scalars["Float"];
  source: TrackingSource;
  sourceId: Scalars["String"];
  sport: Scalars["String"];
  startTimestamp: Scalars["Float"];
};

export type ExternalSportsSegment = {
  __typename: "ExternalSportsSegment";
  distance: Scalars["Float"];
  endTimestamp: Scalars["Float"];
  measurements: Array<ExternalSportsMeasurement>;
  startTimestamp: Scalars["Float"];
};

export type ExternalSportsTrack = {
  __typename: "ExternalSportsTrack";
  endTimestamp: Scalars["Float"];
  segments: Array<ExternalSportsSegment>;
  startTimestamp: Scalars["Float"];
  totalDistance: Scalars["Float"];
};

export type ExternalSportsWorkoutResult = {
  __typename: "ExternalSportsWorkoutResult";
  _id: Scalars["ID"];
  date: Scalars["Date"];
  elapsedTimeMillis: Scalars["Float"];
  externalSport: ExternalSport;
  info: Maybe<Scalars["String"]>;
  /** Data provider of this workout, e.g. Garmin */
  source: TrackingSource;
  /** ID of this workout in the system of the data provider, e.g. Garmin */
  sourceId: Maybe<Scalars["String"]>;
  /** track for locations, e.g. biking, swimming, running */
  track: Maybe<ExternalSportsTrack>;
};

export enum FeaturePermission {
  /** Permission to access absence */
  ABSENCE_READ = "ABSENCE_READ",
  ABSENCE_WRITE = "ABSENCE_WRITE",
  /** Permission to access participation stats of a coach activity event like TEAM_TRAINING, MEETING, GAME */
  COACH_ACTIVITY_EVENT_PARTICIPATION_READ = "COACH_ACTIVITY_EVENT_PARTICIPATION_READ",
  COACH_ACTIVITY_EVENT_PARTICIPATION_WRITE = "COACH_ACTIVITY_EVENT_PARTICIPATION_WRITE",
  /** Permission to access coach activity events like TEAM_TRAINING, MEETING, GAME */
  COACH_ACTIVITY_EVENT_READ = "COACH_ACTIVITY_EVENT_READ",
  COACH_ACTIVITY_EVENT_WRITE = "COACH_ACTIVITY_EVENT_WRITE",
  /** Permission to access coach activity workout */
  COACH_ACTIVITY_WORKOUT_READ = "COACH_ACTIVITY_WORKOUT_READ",
  COACH_ACTIVITY_WORKOUT_WRITE = "COACH_ACTIVITY_WORKOUT_WRITE",
  COACH_FEED_ANNOUNCEMENT_WRITE = "COACH_FEED_ANNOUNCEMENT_WRITE",
  /** Permission to access coach feed */
  COACH_FEED_READ = "COACH_FEED_READ",
  /** Permission to access team settings */
  TEAM_SETTINGS_READ = "TEAM_SETTINGS_READ",
  TEAM_SETTINGS_WRITE = "TEAM_SETTINGS_WRITE",
  TEAM_SQUAD_PLAYER_NOTES_READ = "TEAM_SQUAD_PLAYER_NOTES_READ",
  TEAM_SQUAD_PLAYER_NOTES_WRITE = "TEAM_SQUAD_PLAYER_NOTES_WRITE",
  TEAM_SQUAD_PLAYER_READ = "TEAM_SQUAD_PLAYER_READ",
  /** Permission to access sportsclub team squad */
  TEAM_SQUAD_READ = "TEAM_SQUAD_READ",
  /** Permission to access coach training load */
  TRAINING_LOAD_READ = "TRAINING_LOAD_READ",
  TRAINING_LOAD_REQUEST_WRITE = "TRAINING_LOAD_REQUEST_WRITE",
  TRAINING_LOAD_WRITE = "TRAINING_LOAD_WRITE",
  /** Permission to access treasury */
  TREASURY_READ = "TREASURY_READ",
  TREASURY_WRITE = "TREASURY_WRITE",
  /** Permission for workout builder */
  WORKOUT_BUILDER_READ = "WORKOUT_BUILDER_READ",
  WORKOUT_BUILDER_WRITE = "WORKOUT_BUILDER_WRITE"
}

export type File = {
  __typename: "File";
  filename: Scalars["String"];
  id: Scalars["ID"];
  md5: Scalars["String"];
  mimetype: Scalars["String"];
  size: Scalars["Int"];
  uploadedAt: Scalars["Date"];
  uri: Scalars["String"];
};

export type FinishWorkoutInput = {
  date: Scalars["Date"];
  elapsedMillisTotal: Scalars["Int"];
  perExercise: Array<FinishWorkoutInputExercise>;
  workoutId: Scalars["String"];
};

export type FinishWorkoutInputExercise = {
  elapsedMillis: Scalars["Int"];
  exerciseId: Scalars["String"];
  /** Only necessary for OutdoorSpeedWorkout. This is the index of the part that contains the exercise */
  outdoorSpeedWorkoutPartIndex?: InputMaybe<Scalars["Int"]>;
};

export type FinishedCustomWorkoutActivity = Activity & {
  __typename: "FinishedCustomWorkoutActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  elapsedMillis: Scalars["Int"];
  elapsedMillisPerExercise: Array<ElapsedMillisPerExercise>;
  reactions: Maybe<Array<ActivityReaction>>;
  workout: Maybe<WorkoutUnion>;
  workoutResult: Maybe<WorkoutResult>;
};

export type FinishedExternalSportsWorkoutActivity = Activity & {
  __typename: "FinishedExternalSportsWorkoutActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
  /** track.segements in ExternalSportsWorkoutResult will not be returned in this query, because of performance improvements */
  workoutResult: Maybe<ExternalSportsWorkoutResult>;
};

export type FinishedPerformanceTestActivity = Activity & {
  __typename: "FinishedPerformanceTestActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  newScore: Maybe<Scalars["Int"]>;
  oldScore: Maybe<Scalars["Int"]>;
  performanceTestResult: Maybe<PerformanceTestResult>;
  reactions: Maybe<Array<ActivityReaction>>;
  testId: PerformanceTestId;
};

export type FinishedProgramActivity = Activity & {
  __typename: "FinishedProgramActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  program: Maybe<ProgramUnion>;
  programDifficulty: Maybe<WorkoutDifficulty>;
  /** @deprecated Will no longer work for Road to Recoveries! */
  programStateId: Maybe<Scalars["String"]>;
  reactions: Maybe<Array<ActivityReaction>>;
};

export type FinishedRunWorkoutActivity = Activity & {
  __typename: "FinishedRunWorkoutActivity";
  _id: Scalars["String"];
  /** @deprecated Use team instead */
  clubId: Maybe<Scalars["String"]>;
  date: Scalars["Date"];
  /** @deprecated Use newLeaderboardTeamPosition instead */
  newLeaderboardClubPosition: Maybe<Scalars["Int"]>;
  /** @deprecated Not supported anymore */
  newLeaderboardTeamPosition: Maybe<Scalars["Int"]>;
  /** @deprecated Use oldLeaderboardTeamPosition instead */
  oldLeaderboardClubPosition: Maybe<Scalars["Int"]>;
  /** @deprecated Not supported anymore */
  oldLeaderboardTeamPosition: Maybe<Scalars["Int"]>;
  reactions: Maybe<Array<ActivityReaction>>;
  /** runWorkoutResult only has extended track format when activity is requested by id, see query activityById */
  runWorkoutResult: Maybe<RunWorkoutResultExtended>;
  team: Maybe<Team>;
};

export type FinishedWorkoutActivity = Activity & {
  __typename: "FinishedWorkoutActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  /**
   * Elapsed time in seconds
   * @deprecated Use field `workoutResult`
   */
  elapsedTime: Maybe<Scalars["Int"]>;
  reactions: Maybe<Array<ActivityReaction>>;
  workout: Maybe<WorkoutUnion>;
  /** @deprecated Use field `workoutResult` */
  workoutDataRecordId: Maybe<Scalars["String"]>;
  /** @deprecated Use field `workoutResult` */
  workoutDifficulty: Maybe<WorkoutDifficulty>;
  workoutResult: Maybe<WorkoutResult>;
};

export enum FitnessStatus {
  FIT = "FIT",
  INJURED = "INJURED"
}

export enum Focus {
  COMEBACK = "COMEBACK",
  PERFORMANCE = "PERFORMANCE",
  PREVENTION = "PREVENTION"
}

export type FollowRequestNotification = Notification & {
  __typename: "FollowRequestNotification";
  date: Scalars["Date"];
  id: Scalars["String"];
};

export enum FollowState {
  IS_FOLLOWING = "IS_FOLLOWING",
  NOT_FOLLOWING = "NOT_FOLLOWING",
  REQUESTED = "REQUESTED"
}

export type FollowSuggestion = {
  __typename: "FollowSuggestion";
  id: Scalars["ID"];
  mutualFollowers: MutualFollowers;
  user: User;
};

export type FollowerRequest = {
  __typename: "FollowerRequest";
  id: Scalars["ID"];
  requestedBy: User;
};

export type FollowingAcceptedNotification = Notification & {
  __typename: "FollowingAcceptedNotification";
  date: Scalars["Date"];
  id: Scalars["String"];
};

export type FollowingRequest = {
  __typename: "FollowingRequest";
  id: Scalars["ID"];
  requestedTo: User;
};

export enum Foot {
  BOTH = "BOTH",
  LEFT = "LEFT",
  RIGHT = "RIGHT"
}

export enum ForgotPasswordErrorCodes {
  EMAIL_UNKNOWN = "EMAIL_UNKNOWN"
}

export type ForgotPasswordErrorResponse = {
  __typename: "ForgotPasswordErrorResponse";
  code: Maybe<ForgotPasswordErrorCodes>;
};

export type ForgotPasswordResponse = ForgotPasswordErrorResponse | ForgotPasswordSuccessResponse;

export type ForgotPasswordSuccessResponse = {
  __typename: "ForgotPasswordSuccessResponse";
  /**  Dummy value -> Always true. If something fails, an error response will be send  */
  success: Maybe<Scalars["Boolean"]>;
};

export type FreeAreaExercise = {
  __typename: "FreeAreaExercise";
  baseExercise: Exercise;
  free: Scalars["Boolean"];
  id: Scalars["ID"];
  level: Scalars["Int"];
  men: Scalars["Boolean"];
  previewImage: Asset;
  previewImageWomen: Maybe<Asset>;
  tags: Array<FreeAreaExerciseTags>;
  women: Scalars["Boolean"];
};

export enum FreeAreaExerciseTags {
  ASSESSMENT = "ASSESSMENT",
  MOBILITY = "MOBILITY",
  NEURO = "NEURO",
  SPEED = "SPEED",
  STABILITY = "STABILITY",
  STRENGTH = "STRENGTH"
}

export type FreeAreaWorkout = {
  __typename: "FreeAreaWorkout";
  baseWorkout: Workout;
  free: Scalars["Boolean"];
  gender: Gender;
  id: Scalars["ID"];
  previewImage: Asset;
  rating: FreeAreaWorkoutRating;
  tags: Array<FreeAreaWorkoutTags>;
};

export type FreeAreaWorkoutDataRecord = {
  __typename: "FreeAreaWorkoutDataRecord";
  _id: Scalars["ID"];
  area: WorkoutDataRecordArea;
  date: Scalars["Date"];
  elapsedTime: Maybe<Scalars["Int"]>;
  elapsedTimePerExercise: Maybe<Array<ElapsedTimeForExercise>>;
  /** @deprecated No longer supported */
  userScore: Maybe<Scalars["Int"]>;
  workoutDifficulty: Maybe<WorkoutDifficulty>;
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type FreeAreaWorkoutDataRecordInput = {
  area: WorkoutDataRecordArea;
  date: Scalars["Date"];
  elapsedTime?: InputMaybe<Scalars["Int"]>;
  elapsedTimePerExercise?: InputMaybe<Array<ElapsedTimeForExerciseInput>>;
  source?: InputMaybe<WorkoutSource>;
  workoutDifficulty?: InputMaybe<WorkoutDifficulty>;
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type FreeAreaWorkoutRating = {
  __typename: "FreeAreaWorkoutRating";
  mobility: Scalars["Int"];
  plyo: Scalars["Int"];
  speed: Scalars["Int"];
  stability: Scalars["Int"];
  stamina: Scalars["Int"];
  strength: Scalars["Int"];
};

export type FreeAreaWorkoutReminder = Reminder & {
  __typename: "FreeAreaWorkoutReminder";
  difficulty: WorkoutDifficulty;
  id: Scalars["ID"];
  reminderDate: Scalars["Date"];
  type: Scalars["String"];
  workoutId: Scalars["ID"];
  workoutType: WorkoutType;
};

export enum FreeAreaWorkoutTags {
  MOBILITY = "MOBILITY",
  PLYO = "PLYO",
  SPEED = "SPEED",
  STABILITY = "STABILITY",
  STAMINA = "STAMINA",
  STRENGTH = "STRENGTH"
}

export type FreeRunWorkout = BaseRunWorkout & {
  __typename: "FreeRunWorkout";
  _id: Scalars["String"];
  type: RunType;
};

export enum GameType {
  CHAMPIONSHIP = "CHAMPIONSHIP",
  CUP = "CUP",
  FRIENDLY_MATCH = "FRIENDLY_MATCH",
  FUTSAL = "FUTSAL",
  INDOOR_TOURNAMENT = "INDOOR_TOURNAMENT",
  TOURNAMENT = "TOURNAMENT"
}

export type Gamechanger = {
  __typename: "Gamechanger";
  bloodweek: Maybe<GamechangerWorkoutWeek>;
  chapters: Array<GamechangerChapter>;
  id: GamechangerId;
  name: Scalars["String"];
  workoutWeeks: Array<GamechangerWorkoutWeek>;
};

export type GamechangerChampionWorkout = {
  __typename: "GamechangerChampionWorkout";
  workouts: Array<GamechangerWorkout>;
};

export type GamechangerChampionWorkoutDataRecord = {
  __typename: "GamechangerChampionWorkoutDataRecord";
  _id: Scalars["ID"];
  area: WorkoutDataRecordArea;
  championWorkoutsData: Array<ChampionWorkoutData>;
  date: Scalars["Date"];
  programId: GamechangerId;
  userScore: Maybe<Scalars["Int"]>;
  weekId: Scalars["Int"];
  workoutDifficulty: WorkoutDifficulty;
  workoutIndex: Scalars["Int"];
};

export type GamechangerChampionWorkoutDataRecordInput = {
  area: WorkoutDataRecordArea;
  championWorkoutsData: Array<ChampionWorkoutDataInput>;
  date: Scalars["Date"];
  programId: GamechangerId;
  weekId: Scalars["Int"];
  workoutDifficulty: WorkoutDifficulty;
  workoutIndex: Scalars["Int"];
};

export type GamechangerChapter = {
  __typename: "GamechangerChapter";
  content: Maybe<Scalars["String"]>;
  heading: Scalars["String"];
  subChapters: Maybe<Array<GamechangerChapter>>;
};

export enum GamechangerId {
  GAMECHANGER_BOYS_ID = "GAMECHANGER_BOYS_ID",
  GAMECHANGER_BOYS_PRESEASON_ID = "GAMECHANGER_BOYS_PRESEASON_ID",
  GAMECHANGER_BOYS_SEASON_ID = "GAMECHANGER_BOYS_SEASON_ID",
  GAMECHANGER_WOMEN_OFFSEASON_ID = "GAMECHANGER_WOMEN_OFFSEASON_ID",
  GAMECHANGER_WOMEN_PRESEASON_ID = "GAMECHANGER_WOMEN_PRESEASON_ID",
  GAMECHANGER_WOMEN_SEASON_ID = "GAMECHANGER_WOMEN_SEASON_ID"
}

export type GamechangerRegularWorkoutDataRecord = {
  __typename: "GamechangerRegularWorkoutDataRecord";
  _id: Scalars["ID"];
  area: WorkoutDataRecordArea;
  date: Scalars["Date"];
  elapsedTime: Maybe<Scalars["Int"]>;
  elapsedTimePerExercise: Maybe<Array<ElapsedTimePerExercise>>;
  programId: GamechangerId;
  userScore: Maybe<Scalars["Int"]>;
  weekId: Scalars["Int"];
  workoutDifficulty: WorkoutDifficulty;
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type GamechangerRegularWorkoutDataRecordInput = {
  area: WorkoutDataRecordArea;
  date: Scalars["Date"];
  elapsedTime?: InputMaybe<Scalars["Int"]>;
  elapsedTimePerExercise?: InputMaybe<Array<ElapsedTimeForExerciseInput>>;
  programId: GamechangerId;
  weekId: Scalars["Int"];
  workoutDifficulty: WorkoutDifficulty;
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type GamechangerState = BaseProgramState & {
  __typename: "GamechangerState";
  /** Unique ID for state */
  _id: Scalars["ID"];
  championsworkoutIntroDialogShown: Maybe<Scalars["Boolean"]>;
  completeDate: Maybe<Scalars["Date"]>;
  current: Scalars["Boolean"];
  difficulty: WorkoutDifficulty;
  gamechangerId: GamechangerId;
  /** @deprecated Confusing name (Isn't an unique identifier of this state!) Use `gamechangerId` instead! */
  id: GamechangerId;
  menstruation: Maybe<Scalars["Boolean"]>;
  resetDate: Maybe<Scalars["Date"]>;
  startDate: Scalars["Date"];
  workoutInfoDialogShown: Maybe<Scalars["Boolean"]>;
};

export type GamechangerWorkout = {
  __typename: "GamechangerWorkout";
  baseWorkout: Workout;
  id: Scalars["String"];
  previewImage: Asset;
};

export type GamechangerWorkoutDataRecord = GamechangerChampionWorkoutDataRecord | GamechangerRegularWorkoutDataRecord;

export type GamechangerWorkoutWeek = {
  __typename: "GamechangerWorkoutWeek";
  championWorkouts: Maybe<Array<GamechangerChampionWorkout>>;
  isChampionsweek: Maybe<Scalars["Boolean"]>;
  workouts: Array<GamechangerWorkout>;
};

export enum GarminPermission {
  ACTIVITY_IMPORT = "ACTIVITY_IMPORT",
  COURSE_IMPORT = "COURSE_IMPORT",
  WORKOUT_IMPORT = "WORKOUT_IMPORT"
}

export enum Gender {
  MAN = "MAN",
  WOMAN = "WOMAN"
}

export enum GenderIdentity {
  DIVERS = "DIVERS",
  MAN = "MAN",
  WOMAN = "WOMAN"
}

export type Guide = {
  __typename: "Guide";
  backgroundImage: Asset;
  id: GuideId;
  name: Scalars["String"];
  weeks: Array<GuideWorkoutWeek>;
};

export enum GuideId {
  GOALKEEPER_GUIDE = "GOALKEEPER_GUIDE",
  GOALKEEPER_GUIDE_WOMEN = "GOALKEEPER_GUIDE_WOMEN",
  MOBILTIY_GUIDE = "MOBILTIY_GUIDE",
  MOBILTIY_GUIDE_WOMEN = "MOBILTIY_GUIDE_WOMEN",
  SPEED_GUIDE = "SPEED_GUIDE",
  SPEED_GUIDE_WOMEN = "SPEED_GUIDE_WOMEN",
  STABILITY_GUIDE = "STABILITY_GUIDE",
  STABILITY_GUIDE_WOMEN = "STABILITY_GUIDE_WOMEN",
  STAMINA_GUIDE = "STAMINA_GUIDE",
  STAMINA_GUIDE_WOMEN = "STAMINA_GUIDE_WOMEN"
}

export type GuideState = BaseProgramState & {
  __typename: "GuideState";
  /** Unique ID for state */
  _id: Scalars["ID"];
  completeDate: Maybe<Scalars["Date"]>;
  current: Scalars["Boolean"];
  /**  EASY by default  */
  difficulty: WorkoutDifficulty;
  guideId: GuideId;
  /** @deprecated Confusing name (Isn't an unique identifier of this state!) Use `guideId` instead! */
  id: GuideId;
  resetDate: Maybe<Scalars["Date"]>;
  startDate: Scalars["Date"];
  workoutInfoDialogShown: Maybe<Scalars["Boolean"]>;
};

export type GuideWorkoutDataRecord = {
  __typename: "GuideWorkoutDataRecord";
  _id: Scalars["ID"];
  area: WorkoutDataRecordArea;
  date: Scalars["Date"];
  elapsedTime: Maybe<Scalars["Int"]>;
  elapsedTimePerExercise: Maybe<Array<ElapsedTimeForExercise>>;
  programId: GuideId;
  userScore: Maybe<Scalars["Int"]>;
  weekId: Scalars["Int"];
  workoutDifficulty: WorkoutDifficulty;
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type GuideWorkoutDataRecordInput = {
  area: WorkoutDataRecordArea;
  date: Scalars["Date"];
  elapsedTime?: InputMaybe<Scalars["Int"]>;
  elapsedTimePerExercise?: InputMaybe<Array<InputMaybe<ElapsedTimeForExerciseInput>>>;
  programId: GuideId;
  weekId: Scalars["Int"];
  workoutDifficulty: WorkoutDifficulty;
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type GuideWorkoutWeek = {
  __typename: "GuideWorkoutWeek";
  name: Scalars["String"];
  workouts: Array<Workout>;
  workoutsByDifficulty: Maybe<GuideWorkoutsByDifficulty>;
};

export type GuideWorkoutsByDifficulty = {
  __typename: "GuideWorkoutsByDifficulty";
  easy: Array<Workout>;
  hard: Maybe<Array<Maybe<Workout>>>;
  medium: Maybe<Array<Maybe<Workout>>>;
};

export enum IapProcessErrorCodes {
  CONNECTION_FAILED = "CONNECTION_FAILED",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  INVALID_PAYLOAD = "INVALID_PAYLOAD",
  NEED_MORE_DATA = "NEED_MORE_DATA",
  NOTHING_PURCHASED = "NOTHING_PURCHASED",
  PURCHASE_CONSUMED = "PURCHASE_CONSUMED",
  PURCHASE_EXPIRED = "PURCHASE_EXPIRED"
}

export type IapProcessErrorResponse = {
  __typename: "IapProcessErrorResponse";
  code: IapProcessErrorCodes;
};

export type IapProcessResponse = IapProcessErrorResponse | IapProcessSuccessResponse;

export type IapProcessSuccessResponse = {
  __typename: "IapProcessSuccessResponse";
  success: Maybe<Scalars["Boolean"]>;
};

export enum IapType {
  ANDROID_PLAYSTORE = "ANDROID_PLAYSTORE",
  IOS_APPSTORE = "IOS_APPSTORE"
}

export enum Injury {
  ANKLE = "ANKLE",
  CALF = "CALF",
  GROIN = "GROIN",
  HAMSTRING = "HAMSTRING",
  KNEE = "KNEE"
}

export type InstalledAppActivity = Activity & {
  __typename: "InstalledAppActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export type IntervalRunWorkout = BaseRunWorkout & {
  __typename: "IntervalRunWorkout";
  _id: Scalars["String"];
  averageDuration: Maybe<Scalars["Int"]>;
  difficulty: WorkoutDifficulty;
  previewImage: Asset;
  series: Array<IntervalRunWorkoutSeries>;
  type: RunType;
};

export type IntervalRunWorkoutRounds = {
  __typename: "IntervalRunWorkoutRounds";
  distance: Scalars["Int"];
  pauseTime: Scalars["Int"];
};

export type IntervalRunWorkoutSeries = {
  __typename: "IntervalRunWorkoutSeries";
  rounds: Array<IntervalRunWorkoutRounds>;
};

export type JoinTeamInput = {
  playerCoachPosition?: InputMaybe<Position>;
  position?: InputMaybe<Position>;
  teamId: Scalars["String"];
};

export type JoinedChallengeActivity = Activity & {
  __typename: "JoinedChallengeActivity";
  _id: Scalars["String"];
  challenge: Maybe<Challenge>;
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export type JoinedClubActivity = Activity & {
  __typename: "JoinedClubActivity";
  _id: Scalars["String"];
  club: Maybe<Club>;
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export type LeaderboardRankingNodeNmbOfWorkouts = {
  __typename: "LeaderboardRankingNodeNmbOfWorkouts";
  mobility: Scalars["Int"];
  plyo: Scalars["Int"];
  prevention: Scalars["Int"];
  roadToRecovery: Scalars["Int"];
  speed: Scalars["Int"];
  stability: Scalars["Int"];
  stamina: Scalars["Int"];
  strength: Scalars["Int"];
  total: Scalars["Int"];
};

export type LeaderboardValue = LeaderboardValue_Float | LeaderboardValue_Int;

export type LeaderboardValue_Float = {
  __typename: "LeaderboardValue_Float";
  value: Scalars["Float"];
};

export type LeaderboardValue_Int = {
  __typename: "LeaderboardValue_Int";
  value: Scalars["Int"];
};

export type Lineup = {
  __typename: "Lineup";
  formation: LineupFormation;
  tactic: LineupTacticalFormation;
};

export type LineupFormation = {
  __typename: "LineupFormation";
  bench: Array<Maybe<LineupFormationPosition>>;
  defense: Array<Maybe<LineupFormationPosition>>;
  defensiveMidfield: Array<Maybe<LineupFormationPosition>>;
  goalKeeper: Array<Maybe<LineupFormationPosition>>;
  midfield: Array<Maybe<LineupFormationPosition>>;
  striker: Array<Maybe<LineupFormationPosition>>;
};

export type LineupFormationInput = {
  bench: Array<InputMaybe<LineupFormationPositionInput>>;
  defense: Array<InputMaybe<LineupFormationPositionInput>>;
  defensiveMidfield: Array<InputMaybe<LineupFormationPositionInput>>;
  goalKeeper: Array<InputMaybe<LineupFormationPositionInput>>;
  midfield: Array<InputMaybe<LineupFormationPositionInput>>;
  striker: Array<InputMaybe<LineupFormationPositionInput>>;
};

export type LineupFormationPosition = {
  __typename: "LineupFormationPosition";
  /** Optional external player which is not part of the squad, if user is provided this field will be empty */
  externalPlayerName: Maybe<Scalars["String"]>;
  /** shirt number of the player this specific game, set by LineupInput in CoachActivityInfoEventGameInput */
  shirtNumber: Maybe<Scalars["Int"]>;
  /** user of the player for a specific game, optionally can be replaced by externalPlayerName */
  user: Maybe<User>;
};

export type LineupFormationPositionInput = {
  /** external player which is not part of the squad, if user is provided this field will be ignored */
  externalPlayerName?: InputMaybe<Scalars["String"]>;
  /** shirt number of the player for a specific game */
  shirtNumber?: InputMaybe<Scalars["Int"]>;
  /** user id of the player for a specific game */
  user?: InputMaybe<Scalars["ID"]>;
};

export type LineupInput = {
  formation: LineupFormationInput;
  tactic: LineupTacticalFormation;
};

export enum LineupTacticalFormation {
  FIVE_FOUR_ONE = "FIVE_FOUR_ONE",
  FIVE_ONE_ONE_ONE_ONE = "FIVE_ONE_ONE_ONE_ONE",
  FIVE_ONE_THREE = "FIVE_ONE_THREE",
  FIVE_THREE_ONE = "FIVE_THREE_ONE",
  FIVE_THREE_TWO = "FIVE_THREE_TWO",
  /** Five Players in Lineup */
  FIVE_TWO_TWO = "FIVE_TWO_TWO",
  FOUR_FIVE_ONE = "FOUR_FIVE_ONE",
  FOUR_FOUR_TWO = "FOUR_FOUR_TWO",
  FOUR_ONE_FOUR_ONE = "FOUR_ONE_FOUR_ONE",
  FOUR_THREE_THREE = "FOUR_THREE_THREE",
  FOUR_THREE_TWO_ONE = "FOUR_THREE_TWO_ONE",
  FOUR_TWO_FOUR = "FOUR_TWO_FOUR",
  FOUR_TWO_THREE_ONE = "FOUR_TWO_THREE_ONE",
  FOUR_TWO_TWO_TWO = "FOUR_TWO_TWO_TWO",
  /** Nine Players in Lineup */
  NINE_FOUR_FOUR = "NINE_FOUR_FOUR",
  NINE_FOUR_ONE_TWO_ONE = "NINE_FOUR_ONE_TWO_ONE",
  NINE_FOUR_TWO_ONE_ONE = "NINE_FOUR_TWO_ONE_ONE",
  NINE_THREE_FOUR_ONE = "NINE_THREE_FOUR_ONE",
  NINE_THREE_ONE_THREE_ONE = "NINE_THREE_ONE_THREE_ONE",
  NINE_THREE_ONE_TWO_TWO = "NINE_THREE_ONE_TWO_TWO",
  NINE_THREE_THREE_ONE_ONE = "NINE_THREE_THREE_ONE_ONE",
  NINE_THREE_THREE_TWO = "NINE_THREE_THREE_TWO",
  NINE_THREE_TWO_ONE_TWO = "NINE_THREE_TWO_ONE_TWO",
  NINE_THREE_TWO_THREE = "NINE_THREE_TWO_THREE",
  NINE_THREE_TWO_TWO_ONE = "NINE_THREE_TWO_TWO_ONE",
  NINE_TWO_THREE_THREE = "NINE_TWO_THREE_THREE",
  SEVEN_THREE_ONE_TWO = "SEVEN_THREE_ONE_TWO",
  /** Seven Players in Lineup */
  SEVEN_THREE_THREE = "SEVEN_THREE_THREE",
  SEVEN_THREE_TWO_ONE = "SEVEN_THREE_TWO_ONE",
  SEVEN_TWO_ONE_THREE = "SEVEN_TWO_ONE_THREE",
  SEVEN_TWO_ONE_TWO_ONE = "SEVEN_TWO_ONE_TWO_ONE",
  SEVEN_TWO_THREE_ONE = "SEVEN_TWO_THREE_ONE",
  SEVEN_TWO_TWO_TWO = "SEVEN_TWO_TWO_TWO",
  THREE_FIVE_TWO = "THREE_FIVE_TWO",
  THREE_FOUR_THREE = "THREE_FOUR_THREE",
  THREE_THREE_FOUR = "THREE_THREE_FOUR"
}

export enum LineupVisibility {
  /** @deprecated Not used anymore */
  COACH_ONLY = "COACH_ONLY",
  /** @deprecated Not used anymore */
  EVERYONE = "EVERYONE",
  /** neither squad nor lineup will be visible in app */
  NONE = "NONE",
  /** squad will be visible in app */
  SQUAD = "SQUAD",
  /** squad and lineup will be visible in app */
  SQUAD_AND_LINEUP = "SQUAD_AND_LINEUP"
}

export type LockState = {
  __typename: "LockState";
  isLocked: Scalars["Boolean"];
  unlockedInDays: Maybe<Scalars["Int"]>;
};

export enum ManualWorkoutDuration {
  FIFTEEN_MIN = "FIFTEEN_MIN",
  FOURTYFIVE_MIN = "FOURTYFIVE_MIN",
  FOUR_HOURS = "FOUR_HOURS",
  ONEANDHALF_HOURS = "ONEANDHALF_HOURS",
  ONE_HOUR = "ONE_HOUR",
  THIRTY_MIN = "THIRTY_MIN",
  THREEANDHALF_HOURS = "THREEANDHALF_HOURS",
  THREE_HOURS = "THREE_HOURS",
  TWENTY_MIN = "TWENTY_MIN",
  TWOANDHALF_HOURS = "TWOANDHALF_HOURS",
  TWO_HOURS = "TWO_HOURS"
}

export type ManualWorkoutResultInput = {
  date: Scalars["Date"];
  duration: ManualWorkoutDuration;
  info?: InputMaybe<Scalars["String"]>;
  sport: ExternalSport;
};

export type MarketingPushNotificationInput = {
  deeplink: Scalars["String"];
  languageCode: AvailableLanguage;
  message?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type MetaDataKeyValue = {
  key: Scalars["String"];
  value: Scalars["String"];
};

export type Mutation = {
  __typename: "Mutation";
  acceptFollowRequest: User;
  addAdIDForUser: Array<UserAdId>;
  /** Adds a participant to an event */
  addCoachActivityEventParticipant: CoachActivity;
  /** Adds a participant to a feedback */
  addCoachActivityFeedbackParticipant: CoachActivity;
  /** Adds a participant to a task */
  addCoachActivityTaskParticipant: CoachActivity;
  /** used for entering a manual workout with the b42 app */
  addManualWorkoutResult: ExternalSportsWorkoutResult;
  addTag: User;
  addTeamChallengeParticipant: TeamChallenge;
  /** Adds temporary user to sports club team */
  addTemporaryUserToTeamInSportsClub: SportsClub;
  addTreasuryTransaction: TreasuryTransaction;
  /**
   * Add user to sportsClub. For testing only.
   * @deprecated For testing only
   */
  addUserToSportsClub: SportsClub;
  /** Add user in sports club to team. */
  addUserToTeamInSportsClub: SportsClub;
  addUsersToTeamInSportsClub: SportsClub;
  /** Assign B42 Gamechanger to user */
  assignB42Gamechanger: UserData;
  /** Assign B42 Gamechanger to multiple users */
  assignB42GamechangerToUsers: Scalars["Boolean"];
  /** Assign Road to Recovery to user */
  assignRtR: UserData;
  /** Cancel B42 Gamechanger for user */
  cancelB42Gamechanger: UserData;
  /**
   * Cancels occurrence(s) in the series. Date specifies occurrence which is cancelling (and following ones, if specified)
   * @deprecated Use cancelCoachActivityOccurrenceById instead
   */
  cancelCoachActivityOccurrence: CoachActivity;
  /** Cancels an occurrence in the series. If `followingOnes` is true, all occurrences following the occurrence specified by the passed occurrenceId will be canceled too */
  cancelCoachActivityOccurrenceById: CoachActivity;
  /**
   * Cancels the current B42 coach program.
   * Cancelled B42 coach programs can't be restarted, only paused ones (via pauseCurrentB42CoachProgram) can be resumed!
   */
  cancelCurrentB42CoachProgram: B42CoachProgramState;
  cancelCurrentB42Gamechanger: UserData;
  /**
   * Cancels the current Gamechanger.
   * Returns the state of it with `current=false`.
   * After this mutation the query `currentGamechangerState` will return NULL until the mutation `startGamechanger` gets called.
   * @deprecated No longer supported
   */
  cancelCurrentGamechanger: GamechangerState;
  /**
   * Cancels the current Guide.
   * Returns the state of it with `current=false`.
   * After this mutation the query `currentGuideState` will return NULL until the mutation `startGuide` gets called.
   * @deprecated No longer supported
   */
  cancelCurrentGuide: GuideState;
  /**
   * Cancels the current PreventionProgram.
   * Returns the state of it with `current=false`.
   * After this mutation the query `currentPreventionProgramState` will return NULL until the mutation `startPreventionProgram` gets called.
   * @deprecated No longer supported
   */
  cancelCurrentPreventionProgram: PreventionProgramState;
  /**
   * Cancels the current Road to Recovery.
   * Returns the state of it with `current=false`.
   * After this mutation the query `currentRtRState` will return NULL until the mutation `startRtR` gets called.
   */
  cancelCurrentRtR: RoadToRecoveryState;
  /** Cancel Road to Recovery for user */
  cancelRtr: UserData;
  changeActivityDate: ActivityUnion;
  /**
   * Changes logo of the passed club.
   * Authenticated user has to member of the club he want's to change.
   *
   * Possible error codes: CLUB_NOT_FOUND, USER_NOT_IN_CLUB
   * @deprecated Use team instead
   */
  changeClubAmbition: Club;
  /**
   * Changes logo of the passed club.
   * Authenticated user has to member of the club he want's to change.
   *
   * Possible error codes: CLUB_NOT_FOUND, USER_NOT_IN_CLUB
   * @deprecated Use team instead
   */
  changeClubLogo: Club;
  /**
   * Changes the name of the passed club.
   * Authenticated user has to member of the club he want's to change.
   *
   * Possible error codes: CLUB_NOT_FOUND, USER_NOT_IN_CLUB, NAME_ALREADY_USED
   * @deprecated Use team instead
   */
  changeClubName: Club;
  changePasswordForUser: Maybe<Scalars["Boolean"]>;
  /** Change logo of sports club */
  changeSportsClubLogo: SportsClub;
  /** Change name of sports club */
  changeSportsClubName: SportsClub;
  /**
   * Changes logo of the passed team.
   * Authenticated user has to member of the team he want's to change.
   *
   * Possible error codes: TEAM_NOT_FOUND, USER_NOT_IN_TEAM
   * @deprecated Ambition is no longer used
   */
  changeTeamAmbition: Team;
  /**
   * Changes logo of the passed team.
   * Authenticated user has to member of the team he want's to change.
   *
   * Possible error codes: TEAM_NOT_FOUND, USER_NOT_IN_TEAM
   */
  changeTeamLogo: Team;
  /**
   * Changes the name of the passed team.
   * Authenticated user has to member of the team he want's to change.
   *
   * Possible error codes: TEAM_NOT_FOUND, USER_NOT_IN_TEAM, NAME_ALREADY_USED
   */
  changeTeamName: Team;
  /** Change team name in sports club. */
  changeTeamNameInSportsClub: SportsClub;
  /** Use to manually finish workout or runWorkout in CoachActivity */
  completeCoachActivityTaskWorkoutForUser: CoachActivity;
  /** @deprecated No longer supported */
  completeProfile: ProfileCompletion;
  connectAppleHealth: User;
  connectFitbit: User;
  connectGarmin: User;
  connectPolar: User;
  /** Creates absence for current user, if userId is passed, creates absence for that user */
  createAbsence: Absence;
  /** Creates a new note for an activity & occurrence */
  createActivityNote: Note;
  /** @deprecated Use team instead */
  createClub: Club;
  createCoachActivity: CoachActivity;
  createCoachFeedAnnouncement: CoachFeedAnnouncement;
  createCustomBodyWeightWorkout: CustomWorkout;
  createCustomExercise: CustomExercise;
  createPerformanceTestManualResult: PerformanceTestManualResult;
  createPerformanceTestTemplate: PerformanceTestTemplate;
  /** Creates a new sports club. */
  createSportsClub: SportsClub;
  createStripeCheckoutSession: StripeCheckoutSession;
  createStripeCustomerPortalSession: StripeCustomerPortalSession;
  createTeam: Team;
  createTeamChallenge: TeamChallenge;
  /** Creates a new team in a sports club. */
  createTeamInSportsClub: SportsClub;
  /** Creates a new note for a team member */
  createTeamMemberNote: Note;
  createTrainingLoad: TrainingLoad;
  /** @deprecated Use addUserTrainingLoad instead, new scale from 0-10 */
  createUserTrainingLoad: TrainingLoad;
  /** Deletes absence */
  deleteAbsence: Scalars["Boolean"];
  /** A user can have a most one checkup reminder, so we don't need an ID of it */
  deleteCheckupWorkoutReminder: Scalars["Boolean"];
  deleteCoachActivity: CoachActivity;
  deleteCoachActivityTeamTrainingFile: Maybe<CoachActivity>;
  deleteCustomExercise: Scalars["Boolean"];
  deleteCustomExerciseImage: CustomExercise;
  deleteCustomExerciseVideo: CustomExercise;
  deleteCustomWorkout: Scalars["Boolean"];
  /** @deprecated Use `deleteFinishedExternalSportWorkoutActivity` instead */
  deleteExternalSportsWorkoutResult: Maybe<Scalars["Boolean"]>;
  /** deletes finished workout activity, finished run workout or finished external sports workout without deleting workout result itself */
  deleteFinishedWorkoutActivity: Maybe<Scalars["Boolean"]>;
  /** Deletes an existing note */
  deleteNote: Note;
  deletePerformanceTestManualResult: PerformanceTestManualResult;
  deletePerformanceTestTemplate: PerformanceTestTemplate;
  /** @deprecated Not supported anymore! */
  deleteRunFromHistory: RunWorkoutResult;
  deleteTeamChallenge: Scalars["Boolean"];
  /** Deletes a team in a sports club. */
  deleteTeamInSportsClub: SportsClub;
  deleteTreasuryTransaction: Scalars["Boolean"];
  deleteUser: Scalars["Boolean"];
  deleteWorkoutReminder: Maybe<WorkoutReminder>;
  disconnectFitbit: User;
  disconnectGarmin: User;
  disconnectPolar: User;
  doubleOptOut: User;
  editCoachActivityEvent: Maybe<CoachActivity>;
  /**
   * Changes all future occurrences of coach activity. Occurrences in the past will stay untouched.
   * Changing the start date only has an effect if the series didn't start already.
   */
  editCoachActivitySchedule: CoachActivity;
  finishB42CoachProgramWorkout: B42CoachProgramWorkoutDataRecord;
  finishB42GamechangerPerformanceTest: B42Gamechanger;
  /** Finishes a workout as part of the current B42 Gamechanger */
  finishB42GamechangerWorkout: B42Gamechanger;
  finishCheckupWorkout: Maybe<User>;
  /**
   * Use only to finish Workouts, to finish runWorkout call finishRunWorkout and pass coachActivityId
   * @deprecated Unclear naming. Use `finishCoachActivityTaskWorkout` instead
   */
  finishCoachActivityTask: CoachWorkoutDataRecord;
  finishCoachActivityTaskWorkout: WorkoutResult;
  finishCooldown: CooldownDataRecord;
  finishExternalSportsWorkoutWithApple: ExternalSportsWorkoutResult;
  /** @deprecated use `finishExternalSportsWorkoutWithApple` instead. Not supported anymore. */
  finishExternalWorkoutWithApple: ExternalSportsResult;
  finishFreeAreaWorkout: FreeAreaWorkoutDataRecord;
  /** @deprecated No longer supported */
  finishGamechangerChampionWorkout: GamechangerChampionWorkoutDataRecord;
  /** @deprecated No longer supported */
  finishGamechangerRegularWorkout: GamechangerRegularWorkoutDataRecord;
  /** @deprecated No longer supported */
  finishGuideWorkout: GuideWorkoutDataRecord;
  /** Finish performance test for a single user. If userId is not provided, authenticated user is used. */
  finishPerformanceTest: SinglePerformanceTestResult;
  /** @deprecated Use mutation to finish single performance tests instead! Not supported anymore! */
  finishPerformanceTestDay: PerformanceTestResult;
  /** @deprecated No longer supported */
  finishPreventionProgramWorkout: PreventionProgramWorkoutDataRecord;
  finishRoadToRecoveryWorkout: RoadToRecoveryWorkoutDataRecord;
  finishRtR: RoadToRecoveryState;
  /** @deprecated Uses phases instead of workoutId. Please use finishRoadToRecoveryWorkout instead */
  finishRtRWorkout: RtRWorkoutDataRecord;
  finishRunWorkout: RunWorkoutResult;
  finishRunWorkoutWithApple: RunWorkoutResult;
  finishWarmup: WarmupDataRecord;
  forgotPassword: ForgotPasswordResponse;
  generateAccessToken: AccessTokenGenerateResponse;
  /** Use for example if user switches account on same device. Otherwise he would receive messages from wrong account. */
  invalidateFirebaseCloudMessagingTokenForDevice: Maybe<Scalars["Boolean"]>;
  joinChallenge: Maybe<Challenge>;
  /** Join sports club (plus optionally team) by invite link. */
  joinSportsClubByInvite: User;
  /** Join sports club team by decoded invite link. */
  joinSportsClubTeam: User;
  /**
   * Possible error codes: TEAM_NOT_FOUND, ALREADY_TEAM_MEMBER
   * @deprecated Used to join legacy teams
   */
  joinTeam: User;
  /**
   * Possible error codes: INVALID_LINK, TEAM_NOT_FOUND, ALREADY_TEAM_MEMBER
   * @deprecated Used to join legacy teams
   */
  joinTeamViaInvitationLink: User;
  /** Possible error codes: TEAM_NOT_FOUND, USER_NOT_IN_TEAM */
  kickMembers: Team;
  /** Current user leaves sports club */
  leaveSportsClub: User;
  leaveTeam: User;
  /** @deprecated Use generic `logEvents` mutation instead */
  logAddToCartEvent: Maybe<Scalars["Boolean"]>;
  /** @deprecated Use generic `logEvents` mutation instead */
  logBlogPostReadEvent: Maybe<Scalars["Boolean"]>;
  logEvents: Scalars["Boolean"];
  /** @deprecated Use generic `logEvents` mutation instead */
  logExerciseFinishedEvent: Maybe<Scalars["Boolean"]>;
  /** @deprecated Use generic `logEvents` mutation instead */
  logPerformanceTestCancelledEvent: Maybe<Scalars["Boolean"]>;
  /** @deprecated Use generic `logEvents` mutation instead */
  logSessionEndEvent: Maybe<Scalars["Boolean"]>;
  /** @deprecated Use generic `logEvents` mutation instead */
  logSessionStartEvent: Maybe<Scalars["Boolean"]>;
  /** @deprecated Use generic `logEvents` mutation instead */
  logSocialShareEvent: Maybe<Scalars["Boolean"]>;
  /** @deprecated Use generic `logEvents` mutation instead */
  logVisitedRouteEvent: Maybe<Scalars["Boolean"]>;
  /** @deprecated Use generic `logEvents` mutation instead */
  logWorkoutCancelledEvent: Maybe<Scalars["Boolean"]>;
  /** Marks a team member as principal for the team */
  markSportsClubTeamMemberAsPrincipal: SportsClub;
  /** overwrites the vote from player by coach */
  overwriteVoteAnswerCoachActivityEvent: Maybe<CoachActivity>;
  /**
   * Pauses the current B42 coach program (by setting `current==false`).
   * Paused programs are resumed automatically by `startB42CoachProgram` if the parameters match.
   */
  pauseCurrentB42CoachProgram: B42CoachProgramState;
  /** @deprecated No longer supported */
  processIap: IapProcessResponse;
  ratePlayerInCoachActivityEvent: Maybe<CoachActivity>;
  rateWorkout: WorkoutUnion;
  /** @deprecated Use `rateWorkout` instead */
  rateWorkoutRecord: WorkoutDataRecord;
  redeemVoucher: RedeemVoucherResponse;
  rejectCoachActivityEventFeedback: Maybe<CoachActivity>;
  /** Removes a participant from an event */
  removeCoachActivityEventParticipant: CoachActivity;
  /** Removes a participant from a feedback */
  removeCoachActivityFeedbackParticipant: CoachActivity;
  /** Removes a participant from a series event */
  removeCoachActivitySeriesEventParticipant: CoachActivity;
  /** Removes a participant from a task */
  removeCoachActivityTaskParticipant: CoachActivity;
  removeFollowSuggestion: User;
  /** Remove follower from current user's followers or abort follow request from passed user */
  removeFollower: User;
  /** Removes the sponsor of a sports club */
  removeSportsClubSponsor: SportsClub;
  removeTeamChallengeParticipant: TeamChallenge;
  /** Remove temporary user from sports club team */
  removeTemporaryUserFromTeamInSportsClub: SportsClub;
  /** Removes a user of a sports club. */
  removeUserFromSportsClub: SportsClub;
  /** Remove user in sports club from team. */
  removeUserInSportsClubFromTeam: SportsClub;
  removeUsersFromSportsClub: SportsClub;
  removeUsersInSportsClubFromTeam: SportsClub;
  reportUser: Maybe<Scalars["Boolean"]>;
  /** Requests following the passed user and returns this user */
  requestFollow: User;
  /** @deprecated Use rescheduleCoachACtivityOccurrenceById instead */
  rescheduleCoachActivityOccurrence: CoachActivity;
  rescheduleCoachActivityOccurrenceById: CoachActivity;
  /** @deprecated Use editCoachActivitySchedule instead */
  rescheduleCoachActivityOccurrences: CoachActivity;
  resendDoubleOptIn: ResendDoubleOptInResponse;
  /**
   * Resets the users current B42 coach program.
   * Internally cancels the current state and creates a new one which is returned.
   */
  resetCurrentB42CoachProgram: B42CoachProgramState;
  /**
   *  Resets the current Gamechanger. `currentGamechangerFinishedWorkouts` will be empty after this mutation
   * @deprecated No longer supported
   */
  resetCurrentGamechanger: GamechangerState;
  /** @deprecated No longer supported */
  resetCurrentGuide: GuideState;
  /** @deprecated No longer supported */
  resetCurrentPreventionProgram: PreventionProgramState;
  resetCurrentRtR: RoadToRecoveryState;
  resetPassword: ResetPasswordResponse;
  restartCurrentB42Gamechanger: B42Gamechanger;
  sendCoachActivityEventFeedback: Maybe<CoachActivity>;
  sendCoachActivityFeedback: Maybe<CoachActivity>;
  /** Sends invitation link to several email adresses */
  sendSportsClubTeamInviteLinkByMail: Maybe<SportsClubTeamInviteLinkByMailResponse>;
  setAchievementDialogShown: Array<UserAchievements>;
  /** @deprecated Use `setUserAttribution` instead */
  setAttribution: User;
  setB42GamechangerWorkoutReminder: Maybe<B42GamechangerWorkoutReminder>;
  /** @deprecated No longer supported */
  setChampionsWorkoutIntroDialogShown: GamechangerState;
  setCheckupWorkoutReminder: Maybe<CheckupWorkoutReminder>;
  setCoachWorkoutReminder: Maybe<CoachWorkoutReminder>;
  setCurrentRtRPhase: RoadToRecoveryState;
  /** @deprecated No longer supported */
  setDifficulty: GamechangerState;
  setFreeAreaWorkoutReminder: Maybe<FreeAreaWorkoutReminder>;
  /** @deprecated No longer supported */
  setGuideDifficulty: GuideState;
  /** @deprecated No longer supported */
  setGuideInfoDialogShown: GuideState;
  setLanguageCode: User;
  setOnboardingCompleted: User;
  /** @deprecated No longer supported */
  setPreventionProgramDifficulty: PreventionProgramState;
  /** @deprecated No longer supported */
  setPreventionProgramInfoDialogShown: PreventionProgramState;
  setRtRInfoDialogRead: RoadToRecoveryState;
  /** Assigns data to a sports club team member */
  setSportsClubTeamMemberData: SportsClub;
  /** Possible error codes: TEAM_NOT_FOUND, USER_NOT_IN_TEAM */
  setTeamMemberPosition: User;
  /** Possible error codes: TEAM_NOT_FOUND */
  setTeamMembersRole: SetTeamMembersRoleResponse;
  setUserAttribution: User;
  setUserPrivacyVisibilities: User;
  /**  Merges passed user profile data with existing one. If noMerge=true it overwrites current one  */
  setUserProfileData: User;
  /** @deprecated No longer supported */
  setWorkoutInfoDialogShown: GamechangerState;
  setWorkoutReminder: WorkoutReminder;
  signIn: SignInResponse;
  signInThirdParty: SignInThirdPartyResponse;
  signInWithAccessToken: AccessTokenRetrieveResponse;
  /** @deprecated Use `signInThirdParty` instead */
  signInWithApple: SignInThirdPartyResponse;
  /** @deprecated Use `signInThirdParty` instead */
  signInWithFacebook: SignInThirdPartyResponse;
  signUp: SignUpResponse;
  /**
   * Starts a B42 coach program or resumes one if there is a paused one.
   * Requires gender of user to be set!
   */
  startB42CoachProgram: B42CoachProgramState;
  startB42Gamechanger: UserData;
  /**
   * Starts a Gamechanger.
   * If the user fitness is set the corresponding Gamechanger is started.
   * If not the preset difficulty will be EASY and the frontend is responsible for the further handling of this case!
   * If the user already started the Gamechanger with the given ID he can continue with his old progress
   * @deprecated No longer supported
   */
  startGamechanger: GamechangerState;
  /**
   * Starts a Guide.
   * If the user already started the Guide with the given ID, the old entry will be overwritten!
   * @deprecated No longer supported
   */
  startGuide: GuideState;
  /**
   * Starts a PreventionProgram. If the user already started the PreventionProgram with the given ID, the old entry will be overwritten!
   * @deprecated No longer supported
   */
  startPreventionProgram: PreventionProgramState;
  /**
   * Starts a Road to Recovery.
   * If the user already started the RtR with the given ID, the old entry will be overwritten!
   */
  startRtR: RoadToRecoveryState;
  /** Completes the onboarding of a sports club and sets trial licence */
  startTrialLicence: SportsClub;
  submitUserFeedback: UserFeedbackResponse;
  /** Switches current sports club team for current user */
  switchSportsClubTeam: User;
  /**
   * Possible error codes: NOT_TEAM_MEMBER, USER_NOT_IN_TEAM
   * @deprecated Not supported anymore, team should be transferred to a sportsClub to use this feature
   */
  switchTeam: User;
  toggleActivityReaction: Maybe<ActivityUnion>;
  toggleB42GamechangerMenstruationMode: B42Gamechanger;
  toggleB42GamechangerWorkoutAlternative: B42Gamechanger;
  toggleCoachFeedItemReaction: Maybe<CoachFeedItemUnion>;
  toggleFavoriteWorkout: User;
  /** @deprecated No longer supported */
  toggleMenstruationMode: GamechangerState;
  /** Marks a team member as caption for the team */
  toggleSportsClubTeamMemberAsCaptain: SportsClub;
  toggleVoteAnswerCoachActivityEvent: Maybe<CoachActivity>;
  triggerMarketingPushNotification: Maybe<Scalars["Boolean"]>;
  /** Used for Coaches to trigger training load update notifications on users */
  triggerTrainingLoadUpdateNotification: Maybe<Scalars["Boolean"]>;
  /** Unfollow the passed user or abort follow request for passed user */
  unfollow: User;
  /** Updates absence */
  updateAbsence: Absence;
  /** Update access role (e.g. Owner or Admin) to a user of a sports club. */
  updateAccessRoleForUserInSportsClub: SportsClub;
  /**
   * Assign a user of a sports club as admin.
   * @deprecated Use updateAccessRoleForUserInSportsClub instead
   */
  updateAdminStatusForUserInSportsClub: SportsClub;
  updateCoachActivityFeedbackInfo: CoachActivity;
  updateCoachActivityTaskInfo: CoachActivity;
  updateCustomBodyWeightWorkout: CustomWorkout;
  updateCustomExercise: CustomExercise;
  /** Updates the fitness status of a team member */
  updateFitnessStatusOfSportsClubTeamMember: SportsClub;
  /** Updates an existing note for a team member */
  updateNote: Note;
  updatePerformanceTestManualResult: PerformanceTestManualResult;
  updatePerformanceTestTemplate: PerformanceTestTemplate;
  /** Updates the profile data of a team member */
  updateProfileDataOfSportsClubTeamMember: SportsClub;
  /** Updates the roles of a team member. */
  updateRolesOfTeamMember: SportsClub;
  /** Updates the acquisition data of a sports club */
  updateSportsClubAcquisition: SportsClub;
  /** Update sports club information */
  updateSportsClubInfo: SportsClub;
  /** Updates the sponsor of a sports club */
  updateSportsClubSponsor: SportsClub;
  /** Updates team gender for sports club */
  updateSportsClubTeamGender: SportsClub;
  updateTeamChallenge: TeamChallenge;
  updateTreasuryTransaction: TreasuryTransaction;
  uploadCoachActivityTeamTrainingFile: Maybe<CoachActivity>;
  uploadCustomExerciseImage: CustomExercise;
  uploadCustomExerciseVideo: CustomExercise;
  /** Uploading penalty catalogue as file for team */
  uploadPenaltyCatalogue: SportsClub;
  uploadProfileImage: User;
  /** Updates the sponsor of a sports club */
  uploadSportsClubSponsorBanner: SportsClub;
  /** Overrides token if there is already one for this device id */
  upsertFirebaseCloudMessagingToken: Maybe<Scalars["Boolean"]>;
};

export type MutationAcceptFollowRequestArgs = {
  userId: Scalars["ID"];
};

export type MutationAddAdIdForUserArgs = {
  adID: UserAdIdInput;
};

export type MutationAddCoachActivityEventParticipantArgs = {
  id: Scalars["String"];
  userId: Scalars["ID"];
};

export type MutationAddCoachActivityFeedbackParticipantArgs = {
  occurrenceId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationAddCoachActivityTaskParticipantArgs = {
  occurrenceId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationAddManualWorkoutResultArgs = {
  result: ManualWorkoutResultInput;
};

export type MutationAddTagArgs = {
  tag: Scalars["String"];
};

export type MutationAddTeamChallengeParticipantArgs = {
  challengeId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationAddTemporaryUserToTeamInSportsClubArgs = {
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  roles: Array<SportsClubTeamRole>;
  teamId: Scalars["ID"];
};

export type MutationAddTreasuryTransactionArgs = {
  input: TreasuryTransactionInput;
};

export type MutationAddUserToSportsClubArgs = {
  userId: Scalars["ID"];
};

export type MutationAddUserToTeamInSportsClubArgs = {
  teamId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationAddUsersToTeamInSportsClubArgs = {
  teamId: Scalars["ID"];
  userIds: Array<Scalars["ID"]>;
};

export type MutationAssignB42GamechangerArgs = {
  input: B42GamechangerInput;
  userId: Scalars["ID"];
};

export type MutationAssignB42GamechangerToUsersArgs = {
  input: B42GamechangerInput;
  userIds: Array<Scalars["ID"]>;
};

export type MutationAssignRtRArgs = {
  id: RoadToRecoveryId;
  userId: Scalars["ID"];
};

export type MutationCancelB42GamechangerArgs = {
  userId: Scalars["ID"];
};

export type MutationCancelCoachActivityOccurrenceArgs = {
  date: Scalars["Date"];
  followingOnes: Scalars["Boolean"];
  id: Scalars["ID"];
};

export type MutationCancelCoachActivityOccurrenceByIdArgs = {
  coachActivityId: Scalars["ID"];
  followingOnes: Scalars["Boolean"];
  occurrenceId: Scalars["ID"];
};

export type MutationCancelRtrArgs = {
  userId: Scalars["ID"];
};

export type MutationChangeActivityDateArgs = {
  date: Scalars["Date"];
  id: Scalars["ID"];
};

export type MutationChangeClubAmbitionArgs = {
  ambition: Scalars["String"];
  id: Scalars["ID"];
};

export type MutationChangeClubLogoArgs = {
  id: Scalars["ID"];
  newLogo: Scalars["Upload"];
};

export type MutationChangeClubNameArgs = {
  id: Scalars["ID"];
  newName: Scalars["String"];
};

export type MutationChangePasswordForUserArgs = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type MutationChangeSportsClubLogoArgs = {
  newLogo: Scalars["Upload"];
};

export type MutationChangeSportsClubNameArgs = {
  newName: Scalars["String"];
};

export type MutationChangeTeamAmbitionArgs = {
  ambition: Scalars["String"];
  id: Scalars["ID"];
};

export type MutationChangeTeamLogoArgs = {
  id: Scalars["ID"];
  newLogo: Scalars["Upload"];
};

export type MutationChangeTeamNameArgs = {
  id: Scalars["ID"];
  newName: Scalars["String"];
};

export type MutationChangeTeamNameInSportsClubArgs = {
  name: Scalars["String"];
  teamId: Scalars["ID"];
};

export type MutationCompleteCoachActivityTaskWorkoutForUserArgs = {
  input?: InputMaybe<CompleteCoachActivityTaskInput>;
  occurrenceId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationCompleteProfileArgs = {
  profileCompletion: ProfileCompletionInput;
};

export type MutationConnectFitbitArgs = {
  input: ConnectFitbitInput;
};

export type MutationConnectGarminArgs = {
  accessSecret: Scalars["String"];
  accessToken: Scalars["String"];
  garminUserId: Scalars["String"];
};

export type MutationConnectPolarArgs = {
  accessToken: Scalars["String"];
  polarUserId: Scalars["Int"];
};

export type MutationCreateAbsenceArgs = {
  input: AbsenceInput;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type MutationCreateActivityNoteArgs = {
  activityId: Scalars["ID"];
  note: Scalars["String"];
  occurrenceId: Scalars["ID"];
};

export type MutationCreateClubArgs = {
  name: Scalars["String"];
};

export type MutationCreateCoachActivityArgs = {
  input: CoachActivityInput;
};

export type MutationCreateCoachFeedAnnouncementArgs = {
  input: CoachFeedAnnouncementInput;
};

export type MutationCreateCustomBodyWeightWorkoutArgs = {
  input: CustomBodyWeightWorkoutInput;
};

export type MutationCreateCustomExerciseArgs = {
  input: CustomExerciseInput;
};

export type MutationCreatePerformanceTestManualResultArgs = {
  result: PerformanceTestManualResultInput;
};

export type MutationCreatePerformanceTestTemplateArgs = {
  template: PerformanceTestTemplateInput;
};

export type MutationCreateSportsClubArgs = {
  attribution?: InputMaybe<SportsClubAttributionInput>;
  name: Scalars["String"];
};

export type MutationCreateStripeCheckoutSessionArgs = {
  cancelUrl: Scalars["String"];
  priceId: Scalars["String"];
  subscriptionMetadata?: InputMaybe<Array<MetaDataKeyValue>>;
  successUrl: Scalars["String"];
  teamId?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateStripeCustomerPortalSessionArgs = {
  returnUrl?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateTeamArgs = {
  name: Scalars["String"];
};

export type MutationCreateTeamChallengeArgs = {
  input: CreateTeamChallengeInput;
};

export type MutationCreateTeamInSportsClubArgs = {
  name: Scalars["String"];
};

export type MutationCreateTeamMemberNoteArgs = {
  note: Scalars["String"];
  userId: Scalars["ID"];
};

export type MutationCreateTrainingLoadArgs = {
  input: TrainingLoadInput;
};

export type MutationCreateUserTrainingLoadArgs = {
  input: TrainingLoadInput;
};

export type MutationDeleteAbsenceArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteCoachActivityArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteCoachActivityTeamTrainingFileArgs = {
  fileId: Scalars["String"];
  id: Scalars["String"];
  occurrenceId: Scalars["String"];
};

export type MutationDeleteCustomExerciseArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteCustomExerciseImageArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteCustomExerciseVideoArgs = {
  id: Scalars["ID"];
  videoUrl: Scalars["String"];
};

export type MutationDeleteCustomWorkoutArgs = {
  id: Scalars["String"];
};

export type MutationDeleteExternalSportsWorkoutResultArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteFinishedWorkoutActivityArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteNoteArgs = {
  id: Scalars["ID"];
};

export type MutationDeletePerformanceTestManualResultArgs = {
  resultId: Scalars["ID"];
};

export type MutationDeletePerformanceTestTemplateArgs = {
  templateId: Scalars["ID"];
};

export type MutationDeleteRunFromHistoryArgs = {
  runWorkoutResultId: Scalars["ID"];
};

export type MutationDeleteTeamChallengeArgs = {
  challengeId: Scalars["ID"];
};

export type MutationDeleteTeamInSportsClubArgs = {
  teamId: Scalars["ID"];
};

export type MutationDeleteTreasuryTransactionArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteWorkoutReminderArgs = {
  id: Scalars["ID"];
};

export type MutationEditCoachActivityEventArgs = {
  id: Scalars["String"];
  input: CoachActivityInfoEventInput;
  occurrenceId?: InputMaybe<Scalars["String"]>;
};

export type MutationEditCoachActivityScheduleArgs = {
  coachActivityId: Scalars["ID"];
  recurringPattern?: InputMaybe<RecurringPatternInput>;
  timeFrame: TimeFrameInput;
};

export type MutationFinishB42CoachProgramWorkoutArgs = {
  record: B42CoachProgramWorkoutDataRecordInput;
};

export type MutationFinishB42GamechangerPerformanceTestArgs = {
  input: B42GamechangerPerformanceTestInput;
};

export type MutationFinishB42GamechangerWorkoutArgs = {
  input: B42GamechangerWorkoutInput;
};

export type MutationFinishCheckupWorkoutArgs = {
  input: FinishWorkoutInput;
};

export type MutationFinishCoachActivityTaskArgs = {
  id: Scalars["String"];
  input: CoachWorkoutDataRecordInput;
};

export type MutationFinishCoachActivityTaskWorkoutArgs = {
  activityId: Scalars["String"];
  input: CoachWorkoutDataRecordInput;
};

export type MutationFinishCooldownArgs = {
  data: CooldownDataRecordInput;
};

export type MutationFinishExternalSportsWorkoutWithAppleArgs = {
  result: AppleWorkoutResultInput;
};

export type MutationFinishExternalWorkoutWithAppleArgs = {
  result: AppleWorkoutResultInput;
};

export type MutationFinishFreeAreaWorkoutArgs = {
  data: FreeAreaWorkoutDataRecordInput;
};

export type MutationFinishGamechangerChampionWorkoutArgs = {
  data: GamechangerChampionWorkoutDataRecordInput;
};

export type MutationFinishGamechangerRegularWorkoutArgs = {
  data: GamechangerRegularWorkoutDataRecordInput;
};

export type MutationFinishGuideWorkoutArgs = {
  data: GuideWorkoutDataRecordInput;
};

export type MutationFinishPerformanceTestArgs = {
  result: PerformanceTestResultInput;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type MutationFinishPerformanceTestDayArgs = {
  result: Array<PerformanceTestResultInput>;
};

export type MutationFinishPreventionProgramWorkoutArgs = {
  data: PreventionProgramWorkoutDataRecordInput;
};

export type MutationFinishRoadToRecoveryWorkoutArgs = {
  data: RoadToRecoveryWorkoutDataRecordInput;
};

export type MutationFinishRtRArgs = {
  id: RoadToRecoveryId;
};

export type MutationFinishRtRWorkoutArgs = {
  data: RtRWorkoutDataRecordInput;
};

export type MutationFinishRunWorkoutArgs = {
  result: RunWorkoutResultInput;
};

export type MutationFinishRunWorkoutWithAppleArgs = {
  result: AppleRunWorkoutResultInput;
};

export type MutationFinishWarmupArgs = {
  data: WarmupDataRecordInput;
};

export type MutationForgotPasswordArgs = {
  email: Scalars["String"];
};

export type MutationInvalidateFirebaseCloudMessagingTokenForDeviceArgs = {
  deviceId: Scalars["String"];
};

export type MutationJoinChallengeArgs = {
  challengeId: Scalars["ID"];
};

export type MutationJoinSportsClubByInviteArgs = {
  inviteLink: Scalars["String"];
};

export type MutationJoinSportsClubTeamArgs = {
  role?: InputMaybe<SportsClubTeamRole>;
  sportsClubId: Scalars["ID"];
  sportsClubTeamId: Scalars["ID"];
};

export type MutationJoinTeamArgs = {
  input: JoinTeamInput;
};

export type MutationJoinTeamViaInvitationLinkArgs = {
  teamInvitationLink: Scalars["String"];
};

export type MutationKickMembersArgs = {
  id: Scalars["String"];
  userIds: Array<Scalars["String"]>;
};

export type MutationLeaveTeamArgs = {
  id: Scalars["String"];
};

export type MutationLogAddToCartEventArgs = {
  data: EventAddToCartDataInput;
  event?: InputMaybe<EventLogInput>;
};

export type MutationLogBlogPostReadEventArgs = {
  data: EventBlogPostReadDataInput;
  event?: InputMaybe<EventLogInput>;
};

export type MutationLogEventsArgs = {
  events: Array<EventInput>;
  metaInfo?: InputMaybe<EventMetaInfoInput>;
};

export type MutationLogExerciseFinishedEventArgs = {
  data: EventExerciseFinishedDataInput;
  event?: InputMaybe<EventLogInput>;
};

export type MutationLogPerformanceTestCancelledEventArgs = {
  data: EventPerformanceTestCancelledDataInput;
  event?: InputMaybe<EventLogInput>;
};

export type MutationLogSessionEndEventArgs = {
  event?: InputMaybe<EventLogInput>;
};

export type MutationLogSessionStartEventArgs = {
  event?: InputMaybe<EventLogInput>;
};

export type MutationLogSocialShareEventArgs = {
  data: EventSocialShareDataInput;
  event?: InputMaybe<EventLogInput>;
};

export type MutationLogVisitedRouteEventArgs = {
  data: EventVisitedRouteDataInput;
  event?: InputMaybe<EventLogInput>;
};

export type MutationLogWorkoutCancelledEventArgs = {
  data: EventWorkoutCancelledDataInput;
  event?: InputMaybe<EventLogInput>;
};

export type MutationMarkSportsClubTeamMemberAsPrincipalArgs = {
  teamId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationOverwriteVoteAnswerCoachActivityEventArgs = {
  input: OverwriteVoteAnswerCoachActivityEventInput;
};

export type MutationProcessIapArgs = {
  receipt: Scalars["String"];
  type: IapType;
};

export type MutationRatePlayerInCoachActivityEventArgs = {
  input: RatePlayerInCoachActivityEventInput;
};

export type MutationRateWorkoutArgs = {
  reason: Scalars["String"];
  score: Scalars["Int"];
  workoutId: Scalars["String"];
};

export type MutationRateWorkoutRecordArgs = {
  area: WorkoutDataRecordArea;
  id: Scalars["ID"];
  reason?: InputMaybe<Scalars["String"]>;
  score?: InputMaybe<Scalars["Int"]>;
};

export type MutationRedeemVoucherArgs = {
  code: Scalars["String"];
};

export type MutationRejectCoachActivityEventFeedbackArgs = {
  id: Scalars["String"];
};

export type MutationRemoveCoachActivityEventParticipantArgs = {
  id: Scalars["String"];
  userId: Scalars["ID"];
};

export type MutationRemoveCoachActivityFeedbackParticipantArgs = {
  occurrenceId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationRemoveCoachActivitySeriesEventParticipantArgs = {
  id: Scalars["String"];
  userId: Scalars["ID"];
};

export type MutationRemoveCoachActivityTaskParticipantArgs = {
  occurrenceId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationRemoveFollowSuggestionArgs = {
  followSuggestionId: Scalars["ID"];
};

export type MutationRemoveFollowerArgs = {
  userId: Scalars["ID"];
};

export type MutationRemoveTeamChallengeParticipantArgs = {
  challengeId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationRemoveTemporaryUserFromTeamInSportsClubArgs = {
  teamId: Scalars["ID"];
  tmpId: Scalars["String"];
};

export type MutationRemoveUserFromSportsClubArgs = {
  userId: Scalars["ID"];
};

export type MutationRemoveUserInSportsClubFromTeamArgs = {
  teamId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationRemoveUsersFromSportsClubArgs = {
  userIds: Array<Scalars["ID"]>;
};

export type MutationRemoveUsersInSportsClubFromTeamArgs = {
  teamId: Scalars["ID"];
  userIds: Array<Scalars["ID"]>;
};

export type MutationReportUserArgs = {
  reportReason: ReportReason;
  userID: Scalars["ID"];
};

export type MutationRequestFollowArgs = {
  userId: Scalars["ID"];
};

export type MutationRescheduleCoachActivityOccurrenceArgs = {
  id: Scalars["ID"];
  input: RescheduleSingleOccurrenceInput;
};

export type MutationRescheduleCoachActivityOccurrenceByIdArgs = {
  coachActivityId: Scalars["ID"];
  durationMs?: InputMaybe<Scalars["Int"]>;
  keepInteractions?: InputMaybe<Scalars["Boolean"]>;
  occurrenceId: Scalars["ID"];
  startDate: Scalars["Date"];
};

export type MutationRescheduleCoachActivityOccurrencesArgs = {
  id: Scalars["ID"];
  input: RescheduleMultipleOccurrencesInput;
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars["String"];
  token: Scalars["String"];
};

export type MutationSendCoachActivityEventFeedbackArgs = {
  id: Scalars["String"];
  result: CoachActivityEventFeedbackResultInput;
};

export type MutationSendCoachActivityFeedbackArgs = {
  id: Scalars["String"];
  result: CoachActivityFeedbackResultInput;
};

export type MutationSendSportsClubTeamInviteLinkByMailArgs = {
  input: SportsClubTeamInviteLinkByMailInput;
};

export type MutationSetAttributionArgs = {
  campaign?: InputMaybe<Scalars["String"]>;
  costAmount?: InputMaybe<Scalars["Int"]>;
  costCurrency?: InputMaybe<Scalars["String"]>;
  costType?: InputMaybe<Scalars["String"]>;
  medium?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
};

export type MutationSetB42GamechangerWorkoutReminderArgs = {
  input: CreateB42GamechangerWorkoutReminderInput;
};

export type MutationSetChampionsWorkoutIntroDialogShownArgs = {
  id: GamechangerId;
};

export type MutationSetCheckupWorkoutReminderArgs = {
  part: CheckupWorkoutReminderPart;
  reminderDate: Scalars["Date"];
};

export type MutationSetCoachWorkoutReminderArgs = {
  input: CreateCoachWorkoutReminderInput;
};

export type MutationSetCurrentRtRPhaseArgs = {
  id: RoadToRecoveryId;
  phase: RtRPhase;
};

export type MutationSetDifficultyArgs = {
  difficulty: WorkoutDifficulty;
  id: GamechangerId;
};

export type MutationSetFreeAreaWorkoutReminderArgs = {
  input: CreateFreeAreaWorkoutReminderInput;
};

export type MutationSetGuideDifficultyArgs = {
  difficulty: WorkoutDifficulty;
  id: GuideId;
};

export type MutationSetGuideInfoDialogShownArgs = {
  id: GuideId;
};

export type MutationSetLanguageCodeArgs = {
  code: Scalars["String"];
};

export type MutationSetPreventionProgramDifficultyArgs = {
  difficulty: WorkoutDifficulty;
  id: PreventionProgramId;
};

export type MutationSetPreventionProgramInfoDialogShownArgs = {
  id: PreventionProgramId;
};

export type MutationSetRtRInfoDialogReadArgs = {
  id: RoadToRecoveryId;
  phase: RtRPhase;
};

export type MutationSetSportsClubTeamMemberDataArgs = {
  data: SportsClubTeamMemberDataInput;
  userId: Scalars["ID"];
};

export type MutationSetTeamMemberPositionArgs = {
  input: SetTeamMemberPositionInput;
};

export type MutationSetTeamMembersRoleArgs = {
  input: SetTeamMembersRoleInput;
};

export type MutationSetUserAttributionArgs = {
  input: UserAttributionInput;
};

export type MutationSetUserPrivacyVisibilitiesArgs = {
  visibilities: UserPrivacyVisibilityInput;
};

export type MutationSetUserProfileDataArgs = {
  noMerge?: InputMaybe<Scalars["Boolean"]>;
  userProfileData: UserProfileDataInput;
};

export type MutationSetWorkoutInfoDialogShownArgs = {
  id: GamechangerId;
};

export type MutationSetWorkoutReminderArgs = {
  input: SetWorkoutReminderInput;
};

export type MutationSignInArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationSignInThirdPartyArgs = {
  lang: Scalars["String"];
  token: Scalars["String"];
  type: ThirdPartySignInProviders;
};

export type MutationSignInWithAccessTokenArgs = {
  token: Scalars["String"];
};

export type MutationSignInWithAppleArgs = {
  idToken: Scalars["String"];
  lang: Scalars["String"];
};

export type MutationSignInWithFacebookArgs = {
  accessToken: Scalars["String"];
  lang: Scalars["String"];
};

export type MutationSignUpArgs = {
  email: Scalars["String"];
  lang: Scalars["String"];
  password: Scalars["String"];
};

export type MutationStartB42CoachProgramArgs = {
  difficulty: WorkoutDifficulty;
  nmbOfTrainingDays: Scalars["Int"];
  type: B42CoachProgramType;
};

export type MutationStartB42GamechangerArgs = {
  input: B42GamechangerInput;
};

export type MutationStartGamechangerArgs = {
  id: GamechangerId;
};

export type MutationStartGuideArgs = {
  id: GuideId;
};

export type MutationStartPreventionProgramArgs = {
  id: PreventionProgramId;
};

export type MutationStartRtRArgs = {
  id: RoadToRecoveryId;
};

export type MutationSubmitUserFeedbackArgs = {
  input: UserFeedback;
};

export type MutationSwitchSportsClubTeamArgs = {
  teamId: Scalars["ID"];
};

export type MutationSwitchTeamArgs = {
  id: Scalars["String"];
};

export type MutationToggleActivityReactionArgs = {
  id: Scalars["ID"];
  reaction: Reaction;
};

export type MutationToggleB42GamechangerMenstruationModeArgs = {
  b42GamechangerId: Scalars["ID"];
};

export type MutationToggleB42GamechangerWorkoutAlternativeArgs = {
  b42GamechangerId: Scalars["ID"];
  trainingDayId: Scalars["ID"];
};

export type MutationToggleCoachFeedItemReactionArgs = {
  id: Scalars["ID"];
  reaction: Reaction;
};

export type MutationToggleFavoriteWorkoutArgs = {
  workoutId: Scalars["String"];
};

export type MutationToggleMenstruationModeArgs = {
  id: GamechangerId;
};

export type MutationToggleSportsClubTeamMemberAsCaptainArgs = {
  teamId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationToggleVoteAnswerCoachActivityEventArgs = {
  date: Scalars["Date"];
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  voteAnswer: VoteAnswer;
};

export type MutationTriggerMarketingPushNotificationArgs = {
  input: MarketingPushNotificationInput;
};

export type MutationTriggerTrainingLoadUpdateNotificationArgs = {
  userIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type MutationUnfollowArgs = {
  userId: Scalars["ID"];
};

export type MutationUpdateAbsenceArgs = {
  id: Scalars["ID"];
  input: AbsenceInput;
};

export type MutationUpdateAccessRoleForUserInSportsClubArgs = {
  accessRole: SportsClubRole;
  userId: Scalars["ID"];
};

export type MutationUpdateAdminStatusForUserInSportsClubArgs = {
  admin?: InputMaybe<Scalars["Boolean"]>;
  userId: Scalars["ID"];
};

export type MutationUpdateCoachActivityFeedbackInfoArgs = {
  activityId: Scalars["String"];
  input: UpdateCoachActivityFeedbackInfoInput;
};

export type MutationUpdateCoachActivityTaskInfoArgs = {
  activityId: Scalars["String"];
  input: UpdateCoachActivityTaskInfoInput;
};

export type MutationUpdateCustomBodyWeightWorkoutArgs = {
  id: Scalars["String"];
  input: CustomBodyWeightWorkoutInput;
};

export type MutationUpdateCustomExerciseArgs = {
  id: Scalars["ID"];
  input: CustomExerciseInput;
};

export type MutationUpdateFitnessStatusOfSportsClubTeamMemberArgs = {
  fitnessStatus: FitnessStatus;
  userId: Scalars["ID"];
};

export type MutationUpdateNoteArgs = {
  id: Scalars["ID"];
  note: Scalars["String"];
};

export type MutationUpdatePerformanceTestManualResultArgs = {
  date: Scalars["Date"];
  resultId: Scalars["ID"];
  results: Array<PerformanceTestManualResultResultInput>;
};

export type MutationUpdatePerformanceTestTemplateArgs = {
  template: PerformanceTestTemplateInput;
  templateId: Scalars["ID"];
};

export type MutationUpdateProfileDataOfSportsClubTeamMemberArgs = {
  data: SportsClubTeamMemberProfileDataInput;
  teamId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationUpdateRolesOfTeamMemberArgs = {
  roles: Array<SportsClubTeamRole>;
  teamId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationUpdateSportsClubAcquisitionArgs = {
  input: SportsClubAcquisitionInput;
};

export type MutationUpdateSportsClubInfoArgs = {
  input: SportsClubInfoInput;
};

export type MutationUpdateSportsClubSponsorArgs = {
  input: SportsClubSponsorInput;
};

export type MutationUpdateSportsClubTeamGenderArgs = {
  gender: TeamGender;
  teamId: Scalars["ID"];
};

export type MutationUpdateTeamChallengeArgs = {
  challengeId: Scalars["ID"];
  input: UpdateTeamChallengeInput;
};

export type MutationUpdateTreasuryTransactionArgs = {
  id: Scalars["ID"];
  input: TreasuryTransactionInput;
};

export type MutationUploadCoachActivityTeamTrainingFileArgs = {
  file: Scalars["Upload"];
  id: Scalars["String"];
  occurrenceId: Scalars["String"];
  type: TeamTrainingPart;
};

export type MutationUploadCustomExerciseImageArgs = {
  file: Scalars["Upload"];
  id: Scalars["ID"];
};

export type MutationUploadCustomExerciseVideoArgs = {
  file: Scalars["Upload"];
  id: Scalars["ID"];
};

export type MutationUploadPenaltyCatalogueArgs = {
  newPenaltyCatalogue: Scalars["Upload"];
  teamId: Scalars["ID"];
};

export type MutationUploadProfileImageArgs = {
  file: Scalars["Upload"];
};

export type MutationUploadSportsClubSponsorBannerArgs = {
  banner: Scalars["Upload"];
  size: SportsClubSponsorBannerSize;
};

export type MutationUpsertFirebaseCloudMessagingTokenArgs = {
  deviceId: Scalars["String"];
  token: Scalars["String"];
};

export type MutualFollowers = {
  __typename: "MutualFollowers";
  /**
   * An excerpt of the mutual followers. The number of users returned is defined by the `limit` parameter.
   * This is done to optimize this query as resolving many users without acutally displaying them is very inefficient.
   */
  mutualFollowersExcerpt: Array<User>;
  totalNmbMutualFollowers: Scalars["Int"];
};

export type MutualFollowersMutualFollowersExcerptArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type Note = {
  __typename: "Note";
  _id: Scalars["ID"];
  activityId: Maybe<Scalars["ID"]>;
  createdAt: Scalars["Date"];
  createdBy: Scalars["String"];
  note: Scalars["String"];
  occurrenceId: Maybe<Scalars["ID"]>;
  teamId: Maybe<Scalars["ID"]>;
  updatedAt: Scalars["Date"];
  userId: Maybe<Scalars["ID"]>;
};

export type Notification = {
  date: Scalars["Date"];
  id: Scalars["String"];
};

export type NotificationEdge = {
  __typename: "NotificationEdge";
  cursor: Scalars["ID"];
  node: NotificationNode;
};

export type NotificationNode = {
  __typename: "NotificationNode";
  notification: NotificationUnion;
  user: User;
};

export type NotificationUnion =
  | AbsenceUpdatedNotification
  | ActivityReactionNotification
  | CoachAssignedGamechangerNotification
  | CoachAssignedWorkoutNotification
  | CoachCancelledGamechangerNotification
  | CoachCreatedFeedbackNotification
  | CoachCreatedTeamChallengeNotification
  | FollowRequestNotification
  | FollowingAcceptedNotification
  | TrainingLoadUpdateNotification
  | UserAnsweredEventFeedbackNotification
  | UserAnsweredFeedbackNotification
  | UserCreatedAbsenceNotification
  | UserFinishedCoachAssignedPerformanceTestNotification
  | UserFinishedCoachAssignedRunWorkoutNotification
  | UserFinishedCoachAssignedWorkoutNotification
  | UserFinishedTeamChallengeNotification
  | UserJoinedSportsClubTeamNotification
  | UserSubmittedEventParticipationNotification
  | WorkoutReminderNotification;

export type NotificationsConnection = {
  __typename: "NotificationsConnection";
  edges: Array<NotificationEdge>;
};

export type NumberOfWorkouts = {
  __typename: "NumberOfWorkouts";
  ball: Scalars["Int"];
  cooldown: Scalars["Int"];
  external: Scalars["Int"];
  fasciaRoller: Scalars["Int"];
  mobility: Scalars["Int"];
  plyo: Scalars["Int"];
  prevention: Scalars["Int"];
  roadToRecovery: Scalars["Int"];
  running: Scalars["Int"];
  speed: Scalars["Int"];
  stability: Scalars["Int"];
  stamina: Scalars["Int"];
  strength: Scalars["Int"];
  total: Scalars["Int"];
  warmup: Scalars["Int"];
};

export type Occurrence = {
  __typename: "Occurrence";
  coachActivity: CoachActivity;
  /** Duration in millis */
  duration: Scalars["Float"];
  id: Scalars["ID"];
  notes: Maybe<Array<Note>>;
  /** Time in UTC 0 */
  startDate: Scalars["Date"];
};

export enum OnboardingGoal {
  BECOME_REGULAR_PLAYER = "BECOME_REGULAR_PLAYER",
  INJURY_FREE_IN_STARTING_ELEVEN = "INJURY_FREE_IN_STARTING_ELEVEN",
  JUST_BEING_THERE = "JUST_BEING_THERE",
  REMAIN_LEADER = "REMAIN_LEADER"
}

export type OpenGraphData = {
  __typename: "OpenGraphData";
  description: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
};

export type OverwriteVoteAnswerCoachActivityEventInput = {
  /** allows displaying number of votes */
  attendanceVisible?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  occurrenceId: Scalars["ID"];
  userId: Scalars["ID"];
  voteAnswer: VoteAnswer;
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortField?: InputMaybe<Scalars["String"]>;
  sortOrder?: InputMaybe<SortOrder>;
};

export enum ParticipantType {
  ONLY_COACHES = "ONLY_COACHES",
  WHOLE_TEAM = "WHOLE_TEAM"
}

export type ParticipantsFilterValues = {
  __typename: "ParticipantsFilterValues";
  fitnessStatus: Maybe<FitnessStatus>;
  positionGroups: Maybe<Array<PositionGroup>>;
  teamRoles: Maybe<Array<SportsClubTeamRole>>;
  users: Maybe<Array<User>>;
};

export type ParticipantsFilterValuesInput = {
  fitnessStatus?: InputMaybe<FitnessStatus>;
  positionGroups?: InputMaybe<Array<PositionGroup>>;
  teamRoles?: InputMaybe<Array<SportsClubTeamRole>>;
  userIds?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ParticipantsFilters = {
  __typename: "ParticipantsFilters";
  exclude: Maybe<ParticipantsFilterValues>;
  include: Maybe<ParticipantsFilterValues>;
};

export type ParticipantsFiltersInput = {
  exclude?: InputMaybe<ParticipantsFilterValuesInput>;
  include?: InputMaybe<ParticipantsFilterValuesInput>;
};

export enum PerformanceFocus {
  MOBILITY = "MOBILITY",
  SPEED = "SPEED",
  STABILITY = "STABILITY",
  STAMINA = "STAMINA",
  STRENGTH = "STRENGTH"
}

export type PerformanceTest = PerformanceTestCommon | PerformanceTestStamina | PerformanceTestStaminaNew;

export type PerformanceTestCommon = BasePerformanceTest & {
  __typename: "PerformanceTestCommon";
  _id: PerformanceTestId;
  averageDuration: Scalars["Int"];
  heading: Scalars["String"];
  introduction: Scalars["String"];
  name: Scalars["String"];
  testExercises: Array<PerformanceTestExercise>;
  warmup: Maybe<Warmup>;
};

export type PerformanceTestComparison = {
  __typename: "PerformanceTestComparison";
  avgLower: Scalars["Int"];
  avgUpper: Scalars["Int"];
};

export type PerformanceTestComparisons = {
  __typename: "PerformanceTestComparisons";
  club: Maybe<PerformanceTestComparison>;
  gender: Maybe<PerformanceTestComparison>;
  positionGroup: Maybe<PerformanceTestComparison>;
  team: Maybe<PerformanceTestComparison>;
};

export type PerformanceTestExercise =
  | PerformanceTestExercise_BestOfThreeInput
  | PerformanceTestExercise_BestOfThreeMeasurement
  | PerformanceTestExercise_DurationMeasurement
  | PerformanceTestExercise_SelectResult
  | PerformanceTestExercise_TimeBasedRepetitions;

export type PerformanceTestExerciseAntiFraudBorder = {
  __typename: "PerformanceTestExerciseAntiFraudBorder";
  man: Scalars["Int"];
  manFloat: Maybe<Scalars["Float"]>;
  woman: Scalars["Int"];
  womanFloat: Maybe<Scalars["Float"]>;
};

export type PerformanceTestExerciseGradeBasedResultInput = {
  exerciseId: PerformanceTestExerciseId;
  grade: PerformanceTestResultGrade;
};

export type PerformanceTestExerciseGradeResult = {
  __typename: "PerformanceTestExerciseGradeResult";
  exerciseId: PerformanceTestExerciseId;
  grade: PerformanceTestResultGrade;
};

export enum PerformanceTestExerciseId {
  BROAD_JUMP = "BROAD_JUMP",
  CRUYFF_FINESS = "CRUYFF_FINESS",
  DEEP_SQUAT = "DEEP_SQUAT",
  ENDURANCE_RUN_3KM = "ENDURANCE_RUN_3KM",
  HAMSTRING_STRETCH = "HAMSTRING_STRETCH",
  /** @deprecated No longer supported */
  HIP_ROTATION = "HIP_ROTATION",
  INSIDE_OUTSIDE_TWO_LEGGED = "INSIDE_OUTSIDE_TWO_LEGGED",
  JUGGLING_INSIDE = "JUGGLING_INSIDE",
  JUGGLING_LOWER_BODY = "JUGGLING_LOWER_BODY",
  KNEE_TO_WALL = "KNEE_TO_WALL",
  LUNGES = "LUNGES",
  ONETOUCH_PASS_TWO_LEGGED = "ONETOUCH_PASS_TWO_LEGGED",
  ONE_TOUCH_PASS_SHORT = "ONE_TOUCH_PASS_SHORT",
  PASS_AND_TRIVELA_TOUCH = "PASS_AND_TRIVELA_TOUCH",
  PUSH_UPS = "PUSH_UPS",
  QUAD_STRETCH = "QUAD_STRETCH",
  REVERSE_NORDIC = "REVERSE_NORDIC",
  ROLL_RECEPTION = "ROLL_RECEPTION",
  ROTARY_MOBILITY = "ROTARY_MOBILITY",
  SIT_UPS = "SIT_UPS",
  SLIDE_ROLE_TOUCH = "SLIDE_ROLE_TOUCH",
  SOLE_DRAG_90S_BACK = "SOLE_DRAG_90S_BACK",
  SOLE_DRAG_AND_INSIDE_ACROSS = "SOLE_DRAG_AND_INSIDE_ACROSS",
  SOLE_DRAG_INSIDE = "SOLE_DRAG_INSIDE",
  SOLE_DRAG_OUTSIDE = "SOLE_DRAG_OUTSIDE",
  SOLE_DRAG_OUTSIDE_ACROSS = "SOLE_DRAG_OUTSIDE_ACROSS",
  SOLE_ROLE_INSIDE_INSIDE = "SOLE_ROLE_INSIDE_INSIDE",
  SOLE_ROLE_RONALDO = "SOLE_ROLE_RONALDO",
  SPRINT_40_METER = "SPRINT_40_METER",
  WALL_SIT = "WALL_SIT",
  WALL_SQUAT = "WALL_SQUAT",
  /** @deprecated Part of deprecated stamina test */
  YOYO_TEST = "YOYO_TEST"
}

export type PerformanceTestExerciseResult = PerformanceTestExerciseGradeResult | PerformanceTestExerciseValueResult;

export enum PerformanceTestExerciseType {
  BALLCONTROL_TEST = "BALLCONTROL_TEST",
  BALLSKILLS_TEST = "BALLSKILLS_TEST",
  CORE_STRENGTH_TEST = "CORE_STRENGTH_TEST",
  DRIBBLING_TEST = "DRIBBLING_TEST",
  LOWER_BODY_STRENGTH_TEST = "LOWER_BODY_STRENGTH_TEST",
  MOBILITY_TEST = "MOBILITY_TEST",
  PASSING_TEST = "PASSING_TEST",
  SPEED_TEST = "SPEED_TEST",
  STAMINA_TEST = "STAMINA_TEST",
  UPPER_BODY_STRENGTH_TEST = "UPPER_BODY_STRENGTH_TEST"
}

export type PerformanceTestExerciseValueBasedResultInput = {
  exerciseId: PerformanceTestExerciseId;
  value: Scalars["Int"];
  /** If value requires higher accuracy then a integer can represent, send it additionally as float in this field */
  valueFloat?: InputMaybe<Scalars["Float"]>;
};

export type PerformanceTestExerciseValueResult = {
  __typename: "PerformanceTestExerciseValueResult";
  exerciseId: PerformanceTestExerciseId;
  value: Scalars["Float"];
};

/** Used for performance test exercises in which the user has three attempts for an exercise and must enter them (Example: Broad jump) */
export type PerformanceTestExercise_BestOfThreeInput = BasePerformanceTestExercise & {
  __typename: "PerformanceTestExercise_BestOfThreeInput";
  _id: PerformanceTestExerciseId;
  antiFraudBorder: PerformanceTestExerciseAntiFraudBorder;
  /** Keypoint what user shouldn't do */
  donts: Array<Scalars["String"]>;
  /** Keypoints what user should do */
  dos: Array<Scalars["String"]>;
  equipment: Array<Scalars["String"]>;
  equipmentShort: Array<Scalars["String"]>;
  goal: Scalars["String"];
  /** Performance test specific instruction */
  instruction: Scalars["String"];
  /** Performance test specific insctruction keypoints */
  instructionKeypoints: Array<Maybe<Scalars["String"]>>;
  name: Scalars["String"];
  /** If yes, the test has to be done once per side */
  perSide: Maybe<Scalars["Boolean"]>;
  previewImage: Asset;
  scoreCategory: Array<Scalars["String"]>;
  scoreCategoryShort: Array<Scalars["String"]>;
  shortIntroduction: Scalars["String"];
  video: Asset;
};

/** Used for performance test exercises in which the user has three attempts for an exercise and these are measured in each case (usually by stopwatch) (Example: Sprint 40 meter) */
export type PerformanceTestExercise_BestOfThreeMeasurement = BasePerformanceTestExercise & {
  __typename: "PerformanceTestExercise_BestOfThreeMeasurement";
  _id: PerformanceTestExerciseId;
  antiFraudBorder: PerformanceTestExerciseAntiFraudBorder;
  /** Keypoint what user shouldn't do */
  donts: Array<Scalars["String"]>;
  /** Keypoints what user should do */
  dos: Array<Scalars["String"]>;
  equipment: Array<Scalars["String"]>;
  equipmentShort: Array<Scalars["String"]>;
  goal: Scalars["String"];
  /** Performance test specific instruction */
  instruction: Scalars["String"];
  /** Performance test specific insctruction keypoints */
  instructionKeypoints: Array<Maybe<Scalars["String"]>>;
  name: Scalars["String"];
  /** If yes, the test has to be done once per side */
  perSide: Maybe<Scalars["Boolean"]>;
  previewImage: Asset;
  scoreCategory: Array<Scalars["String"]>;
  scoreCategoryShort: Array<Scalars["String"]>;
  shortIntroduction: Scalars["String"];
  video: Asset;
};

/** Used for performance test exercises where the user has to hold an exercise as long as possible and this time has to be measured (Example: Wall Sit) */
export type PerformanceTestExercise_DurationMeasurement = BasePerformanceTestExercise & {
  __typename: "PerformanceTestExercise_DurationMeasurement";
  _id: PerformanceTestExerciseId;
  antiFraudBorder: PerformanceTestExerciseAntiFraudBorder;
  /** Keypoint what user shouldn't do */
  donts: Array<Scalars["String"]>;
  /** Keypoints what user should do */
  dos: Array<Scalars["String"]>;
  equipment: Array<Scalars["String"]>;
  equipmentShort: Array<Scalars["String"]>;
  /** Performance test specific instruction */
  instruction: Scalars["String"];
  /** Performance test specific insctruction keypoints */
  instructionKeypoints: Array<Maybe<Scalars["String"]>>;
  name: Scalars["String"];
  /** If yes, the test has to be done once per side */
  perSide: Maybe<Scalars["Boolean"]>;
  previewImage: Asset;
  scoreCategory: Array<Scalars["String"]>;
  scoreCategoryShort: Array<Scalars["String"]>;
  shortIntroduction: Scalars["String"];
  video: Asset;
};

/** Used for performance test exercises where the user has a choice between predefined values for the result (Example: Wall Squat) */
export type PerformanceTestExercise_SelectResult = BasePerformanceTestExercise & {
  __typename: "PerformanceTestExercise_SelectResult";
  _id: PerformanceTestExerciseId;
  /** Keypoint what user shouldn't do */
  donts: Array<Scalars["String"]>;
  /** Keypoints what user should do */
  dos: Array<Scalars["String"]>;
  equipment: Array<Scalars["String"]>;
  equipmentShort: Array<Scalars["String"]>;
  goal: Scalars["String"];
  /** Performance test specific instruction */
  instruction: Scalars["String"];
  /** Performance test specific insctruction keypoints */
  instructionKeypoints: Array<Maybe<Scalars["String"]>>;
  name: Scalars["String"];
  /** If yes, the test has to be done once per side */
  perSide: Maybe<Scalars["Boolean"]>;
  previewImage: Asset;
  results: Array<PerformanceTestExercise_SelectResult_Result>;
  scoreCategory: Array<Scalars["String"]>;
  scoreCategoryShort: Array<Scalars["String"]>;
  shortIntroduction: Scalars["String"];
  video: Asset;
};

export type PerformanceTestExercise_SelectResult_Result = {
  __typename: "PerformanceTestExercise_SelectResult_Result";
  backgroundImage: Asset;
  description: Scalars["String"];
  grade: PerformanceTestResultGrade;
};

/** Used for performance test exercises that specify a certain duration and the user must do as many repetitions as possible (Example: Push ups) */
export type PerformanceTestExercise_TimeBasedRepetitions = BasePerformanceTestExercise & {
  __typename: "PerformanceTestExercise_TimeBasedRepetitions";
  _id: PerformanceTestExerciseId;
  antiFraudBorder: PerformanceTestExerciseAntiFraudBorder;
  /** Keypoint what user shouldn't do */
  donts: Array<Scalars["String"]>;
  /** Keypoints what user should do */
  dos: Array<Scalars["String"]>;
  duration: Scalars["Int"];
  equipment: Array<Scalars["String"]>;
  equipmentShort: Array<Scalars["String"]>;
  /** Performance test specific instruction */
  instruction: Scalars["String"];
  /** Performance test specific insctruction keypoints */
  instructionKeypoints: Array<Maybe<Scalars["String"]>>;
  name: Scalars["String"];
  /** If yes, the test has to be done once per side */
  perSide: Maybe<Scalars["Boolean"]>;
  previewImage: Asset;
  scoreCategory: Array<Scalars["String"]>;
  scoreCategoryShort: Array<Scalars["String"]>;
  shortIntroduction: Scalars["String"];
  video: Asset;
};

/** Used for stamina test (YoYo Test) */
export type PerformanceTestExercise_YoYo = BasePerformanceTestExercise & {
  __typename: "PerformanceTestExercise_YoYo";
  _id: PerformanceTestExerciseId;
  antiFraudBorder: PerformanceTestExerciseAntiFraudBorder;
  distance: Scalars["Int"];
  /** Keypoint what user shouldn't do */
  donts: Array<Scalars["String"]>;
  /** Keypoints what user should do */
  dos: Array<Scalars["String"]>;
  equipment: Array<Scalars["String"]>;
  equipmentShort: Array<Scalars["String"]>;
  goal: Scalars["String"];
  /** Performance test specific instruction */
  instruction: Scalars["String"];
  /** Performance test specific insctruction keypoints */
  instructionKeypoints: Array<Maybe<Scalars["String"]>>;
  name: Scalars["String"];
  /** If yes, the test has to be done once per side */
  perSide: Maybe<Scalars["Boolean"]>;
  previewImage: Asset;
  scoreCategory: Array<Scalars["String"]>;
  scoreCategoryShort: Array<Scalars["String"]>;
  secondsForRounds: Array<Scalars["Float"]>;
  shortIntroduction: Scalars["String"];
  video: Asset;
};

export enum PerformanceTestId {
  BALLCONTROL = "BALLCONTROL",
  BALLSKILLS = "BALLSKILLS",
  DRIBBLING = "DRIBBLING",
  MOBILITY = "MOBILITY",
  PASSING = "PASSING",
  SPEED = "SPEED",
  /** @deprecated Replaced with STAMINA_NEW */
  STAMINA = "STAMINA",
  STAMINA_NEW = "STAMINA_NEW",
  STRENGTH = "STRENGTH"
}

export type PerformanceTestManualResult = {
  __typename: "PerformanceTestManualResult";
  _id: Scalars["ID"];
  category: PerformanceTestTemplateCategory;
  createdAt: Scalars["Date"];
  createdBy: Scalars["String"];
  date: Scalars["Date"];
  description: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  results: Array<PerformanceTestManualResultResult>;
  teamId: Scalars["ID"];
  unit: PerformanceTestTemplateUnit;
};

export type PerformanceTestManualResultInput = {
  date: Scalars["Date"];
  results: Array<PerformanceTestManualResultResultInput>;
  templateId: Scalars["ID"];
};

export type PerformanceTestManualResultResult = {
  __typename: "PerformanceTestManualResultResult";
  result: Scalars["String"];
  user: User;
  userId: Scalars["String"];
};

export type PerformanceTestManualResultResultInput = {
  result: Scalars["String"];
  userId: Scalars["String"];
};

export type PerformanceTestResult = {
  __typename: "PerformanceTestResult";
  /** @deprecated Used in deprecated mutations and queries! */
  dateDay1: Scalars["Date"];
  /** @deprecated Used in deprecated mutations and queries! */
  dateDay2: Maybe<Scalars["Date"]>;
  /** @deprecated Used in deprecated mutations and queries! */
  score: B42Score;
};

export enum PerformanceTestResultGrade {
  ABOVE_AVERAGE = "ABOVE_AVERAGE",
  AVERAGE = "AVERAGE",
  BAD = "BAD",
  BELOW_AVERAGE = "BELOW_AVERAGE",
  GOOD = "GOOD",
  VERY_BAD = "VERY_BAD",
  VERY_GOOD = "VERY_GOOD"
}

export type PerformanceTestResultInput = {
  /** If performance test was done as part of a B42 coach program, set this to link program state to result */
  b42CoachProgramStateId?: InputMaybe<Scalars["ID"]>;
  /** If performance test was done as coach assignment, set this to link coach activity to result */
  coachActivityId?: InputMaybe<Scalars["String"]>;
  gradeBasedExercisesResults: Array<PerformanceTestExerciseGradeBasedResultInput>;
  performanceTestId: PerformanceTestId;
  valueBasedExercisesResults: Array<PerformanceTestExerciseValueBasedResultInput>;
};

export type PerformanceTestStamina = BasePerformanceTest & {
  __typename: "PerformanceTestStamina";
  _id: PerformanceTestId;
  averageDuration: Scalars["Int"];
  heading: Scalars["String"];
  introduction: Scalars["String"];
  name: Scalars["String"];
  testExercise: PerformanceTestExercise_YoYo;
};

export type PerformanceTestStaminaNew = BasePerformanceTest & {
  __typename: "PerformanceTestStaminaNew";
  _id: PerformanceTestId;
  antiFraudBorder: PerformanceTestExerciseAntiFraudBorder;
  averageDuration: Scalars["Int"];
  heading: Scalars["String"];
  introduction: Scalars["String"];
  name: Scalars["String"];
  run: EnduranceRunWorkout;
  /** Necessary for sending results. They are always based on test exercises even if the test doesn't contain a real exercise. */
  testExerciseId: PerformanceTestExerciseId;
};

export type PerformanceTestTemplate = {
  __typename: "PerformanceTestTemplate";
  _id: Scalars["ID"];
  category: PerformanceTestTemplateCategory;
  createdAt: Scalars["Date"];
  createdBy: Scalars["String"];
  description: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  unit: PerformanceTestTemplateUnit;
  userId: Scalars["ID"];
};

export enum PerformanceTestTemplateCategory {
  BALL = "BALL",
  MOBILITY = "MOBILITY",
  OTHER = "OTHER",
  SPEED = "SPEED",
  STAMINA = "STAMINA",
  STRENGTH = "STRENGTH"
}

export type PerformanceTestTemplateInput = {
  category: PerformanceTestTemplateCategory;
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  unit: PerformanceTestTemplateUnit;
};

export enum PerformanceTestTemplateUnit {
  CENTIMETERS = "CENTIMETERS",
  HOURS = "HOURS",
  KILOGRAMS = "KILOGRAMS",
  KILOMETRES = "KILOMETRES",
  KILOMETRES_PER_HOUR = "KILOMETRES_PER_HOUR",
  /** Runden */
  LANES = "LANES",
  /** Bahnen */
  LAPS = "LAPS",
  METRES = "METRES",
  MINUTES = "MINUTES",
  PERCENTAGE = "PERCENTAGE",
  POINTS = "POINTS",
  REPETITIONS = "REPETITIONS",
  SECONDS = "SECONDS",
  TRACKS = "TRACKS"
}

export enum PersonalGoal {
  BENCH_PLAYER = "BENCH_PLAYER",
  LEADER = "LEADER",
  REGULAR_PLAYER = "REGULAR_PLAYER"
}

export type PlannedProgramWorkoutActivity = Activity & {
  __typename: "PlannedProgramWorkoutActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  program: Maybe<ProgramUnion>;
  reactions: Maybe<Array<ActivityReaction>>;
  weekId: Scalars["Int"];
  workout: Maybe<ActivityWorkoutUnion>;
};

export enum Position {
  ATHLETIC_COACH = "ATHLETIC_COACH",
  CAM = "CAM",
  CB = "CB",
  CDM = "CDM",
  CM = "CM",
  COACH = "COACH",
  CO_COACH = "CO_COACH",
  GK = "GK",
  LB = "LB",
  LM = "LM",
  LW = "LW",
  PHYSIO = "PHYSIO",
  PLAYER_COACH = "PLAYER_COACH",
  RB = "RB",
  RM = "RM",
  RW = "RW",
  ST = "ST"
}

export enum PositionGroup {
  COACH = "COACH",
  DEFENSE = "DEFENSE",
  GOALKEEPER = "GOALKEEPER",
  MIDFIELD = "MIDFIELD",
  STRIKER = "STRIKER"
}

export enum Prevention {
  ANKLE = "ANKLE",
  GROIN = "GROIN",
  HAMSTRING = "HAMSTRING",
  KNEE = "KNEE"
}

export type PreventionProgram = {
  __typename: "PreventionProgram";
  backgroundImage: Asset;
  id: PreventionProgramId;
  name: Scalars["String"];
  weeks: Array<PreventionProgramWorkoutWeek>;
};

export enum PreventionProgramId {
  PREVENTION_PROGRAM_ANKLE = "PREVENTION_PROGRAM_ANKLE",
  PREVENTION_PROGRAM_ANKLE_WOMEN = "PREVENTION_PROGRAM_ANKLE_WOMEN",
  PREVENTION_PROGRAM_GROIN = "PREVENTION_PROGRAM_GROIN",
  PREVENTION_PROGRAM_GROIN_WOMEN = "PREVENTION_PROGRAM_GROIN_WOMEN",
  PREVENTION_PROGRAM_HAMSTRING = "PREVENTION_PROGRAM_HAMSTRING",
  PREVENTION_PROGRAM_HAMSTRING_WOMEN = "PREVENTION_PROGRAM_HAMSTRING_WOMEN",
  PREVENTION_PROGRAM_KNEE = "PREVENTION_PROGRAM_KNEE",
  PREVENTION_PROGRAM_KNEE_WOMEN = "PREVENTION_PROGRAM_KNEE_WOMEN"
}

export type PreventionProgramState = BaseProgramState & {
  __typename: "PreventionProgramState";
  /** Unique ID for state */
  _id: Scalars["ID"];
  completeDate: Maybe<Scalars["Date"]>;
  current: Scalars["Boolean"];
  /**  EASY by default  */
  difficulty: WorkoutDifficulty;
  /** @deprecated Confusing name (Isn't an unique identifier of this state!) Use `preventionProgramId` instead! */
  id: PreventionProgramId;
  preventionProgramId: PreventionProgramId;
  resetDate: Maybe<Scalars["Date"]>;
  startDate: Scalars["Date"];
  workoutInfoDialogShown: Maybe<Scalars["Boolean"]>;
};

export type PreventionProgramWorkoutDataRecord = {
  __typename: "PreventionProgramWorkoutDataRecord";
  _id: Scalars["ID"];
  area: WorkoutDataRecordArea;
  date: Scalars["Date"];
  elapsedTime: Maybe<Scalars["Int"]>;
  elapsedTimePerExercise: Maybe<Array<ElapsedTimeForExercise>>;
  programId: PreventionProgramId;
  userScore: Maybe<Scalars["Int"]>;
  weekId: Scalars["Int"];
  workoutDifficulty: WorkoutDifficulty;
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type PreventionProgramWorkoutDataRecordInput = {
  area: WorkoutDataRecordArea;
  date: Scalars["Date"];
  elapsedTime?: InputMaybe<Scalars["Int"]>;
  elapsedTimePerExercise?: InputMaybe<Array<InputMaybe<ElapsedTimeForExerciseInput>>>;
  programId: PreventionProgramId;
  weekId: Scalars["Int"];
  workoutDifficulty: WorkoutDifficulty;
  workoutId: Scalars["String"];
  workoutType: WorkoutType;
};

export type PreventionProgramWorkoutWeek = {
  __typename: "PreventionProgramWorkoutWeek";
  name: Scalars["String"];
  workoutsByDifficulty: PreventionProgramWorkoutsByDifficulty;
};

export type PreventionProgramWorkoutsByDifficulty = {
  __typename: "PreventionProgramWorkoutsByDifficulty";
  easy: Array<Workout>;
  hard: Maybe<Array<Maybe<Workout>>>;
  medium: Maybe<Array<Maybe<Workout>>>;
};

export type ProfileCompletion = {
  __typename: "ProfileCompletion";
  finishWorkout: Maybe<Scalars["Boolean"]>;
  inviteFellowPlayer: Maybe<Scalars["Boolean"]>;
  readBlogPost: Maybe<Scalars["Boolean"]>;
  startProgram: Maybe<Scalars["Boolean"]>;
};

export type ProfileCompletionInput = {
  finishWorkout?: InputMaybe<Scalars["Boolean"]>;
  inviteFellowPlayer?: InputMaybe<Scalars["Boolean"]>;
  readBlogPost?: InputMaybe<Scalars["Boolean"]>;
  startProgram?: InputMaybe<Scalars["Boolean"]>;
};

export enum ProgramType {
  ALL = "ALL",
  GAMECHANGER = "GAMECHANGER",
  GUIDE = "GUIDE",
  OTHER = "OTHER",
  PREVENTION_PROGRAM = "PREVENTION_PROGRAM",
  ROAD_TO_RECOVERY = "ROAD_TO_RECOVERY"
}

export type ProgramUnion = B42CoachProgram | Gamechanger | Guide | PreventionProgram | RoadToRecovery;

export enum PushNotificationChannels {
  /** Channel for absence updates */
  ABSENCE = "ABSENCE",
  /**
   * Deprecated
   * @deprecated Feature not available anymore
   */
  BLOG = "BLOG",
  /** Channel for coach activities like teamtraining, games, events */
  COACH_FEED = "COACH_FEED",
  /** Channel for coach feedback */
  COACH_FEEDBACK = "COACH_FEEDBACK",
  /** Channel for interaction of coach with gamechanger */
  COACH_GAMECHANGER = "COACH_GAMECHANGER",
  /** Channel for coach messages */
  COACH_MESSAGE = "COACH_MESSAGE",
  /** Channel for team related activities like new members, absences by players */
  COACH_TEAM = "COACH_TEAM",
  /** Channel for user interaction with events of coach */
  COACH_USER_EVENT_INTERACTION = "COACH_USER_EVENT_INTERACTION",
  /** Channel for user interaction with feedback of coach */
  COACH_USER_FEEDBACK_INTERACTION = "COACH_USER_FEEDBACK_INTERACTION",
  /** Channel for finished workouts of players that were assigned by coach */
  COACH_USER_FINISHED_WORKOUT = "COACH_USER_FINISHED_WORKOUT",
  /** Channel for coach workout assignments */
  COACH_WORKOUT_ASSIGNMENT = "COACH_WORKOUT_ASSIGNMENT",
  /** Channel for notifications from followers' activities like finished workouts */
  FOLLOWER_ACTIVITY = "FOLLOWER_ACTIVITY",
  /** Channel for notifications from followers' reactions like comments and likes */
  FOLLOWER_REACTION = "FOLLOWER_REACTION",
  /** Channel for notifications for follower requests and accepted requests */
  FOLLOWER_REQUEST = "FOLLOWER_REQUEST",
  /** Channel for marketing notifications */
  MARKETING = "Marketing",
  /**
   * Deprecated
   * @deprecated Feature not available anymore
   */
  NEWS_FEED = "NEWS_FEED",
  /** Channel for workout reminder notifications */
  REMINDER = "REMINDER",
  /** Channel for activities from teammates like finished workouts */
  TEAM_FEED = "TEAM_FEED",
  /** Channel for training load updates */
  TRAINING_LOAD = "TRAINING_LOAD"
}

export type Query = {
  __typename: "Query";
  /** Returns absences for current user, if userId is passed, returns absences for that user */
  absences: Array<Absence>;
  /** Returns absences for current team of user */
  absencesForSportsClubTeam: Array<Absence>;
  /** Returns absent team members for current team of user and the passed time range */
  absentSportsClubTeamMembers: Array<User>;
  /**
   * Returns an URL to the B42 Academy that allows the user to access it without signing in or creating an account
   * @deprecated No longer supported
   */
  academySSOUrl: Scalars["String"];
  activeChallenges: Array<Challenge>;
  /** Returns activities satisfying passed filter from user with passed userID (or current user if ommited) */
  activities: Array<ActivityUnion>;
  /** Returns activity for given id */
  activityById: ActivityUnion;
  adsEnabled: Scalars["Boolean"];
  /** Use this only if necessary! In the most cases `currentB42Gamechanger` should be sufficient. */
  b42GamechangerById: Maybe<B42Gamechanger>;
  /** @deprecated Use b42ScoreLeaderboardTeamRanking instead */
  b42ScoreLeaderboardClubRanking: B42ScoreBasedClubRankingConnection;
  /** @deprecated Not supported anymore */
  b42ScoreLeaderboardTeamRanking: B42ScoreBasedTeamRankingConnection;
  /** @deprecated Not supported anymore */
  b42ScoreLeaderboardUserRanking: B42ScoreBasedUserRankingConnection;
  /**
   * If testId is not set then it the total score is calculated
   * If userId is not set then the results will be calculated for the current user
   */
  b42ScoreResultsByTestId: Array<B42ScoreSingleResult>;
  /**
   * Returns the b42 score for testIds
   * If userId is not set then the results will be calculated for the current user
   */
  b42ScoreResultsByTestIds: Array<B42ScoreSingleResult>;
  bannerAds: Array<BannerAd>;
  /** Returns basic information about a sports club team */
  basicSportsClubTeam: BasicSportsClubTeam;
  /** @deprecated No longer supported */
  blogPostById: Maybe<BlogPost>;
  blogPostBySlug: Maybe<BlogPost>;
  blogPostBy_Id: Maybe<BlogPost>;
  blogPosts: Array<BlogPost>;
  challengeById: Maybe<Challenge>;
  challengeLeaderboardById: Maybe<ChallengeLeaderboard>;
  /** There can be at most one checkup workout reminder per user. It doesn't make sense that a user is reminded of the first AND second part simultanously */
  checkupWorkoutReminder: Maybe<CheckupWorkoutReminder>;
  /** @deprecated Use teamActivities instead */
  clubActivitiesList: ClubActivitiesConnection;
  /** @deprecated Use team instead */
  clubById: Maybe<Club>;
  /**
   * Returns a club with matching name (Case-Insensitive!)
   * @deprecated Use team instead
   */
  clubByName: Maybe<Club>;
  /**
   * Returns a branded link which is a deferred deeplink that preselects the club the current user is in for the invited user
   * @deprecated Use team instead
   */
  clubInviteLink: Scalars["String"];
  /**
   * - DEPRECATED -
   * Returns list of ranked clubs.
   * If cursors are omitted, it will return place in list where club of requesting user is in the middle.
   * If both cursors are given only `cursorStart` will be considered!
   * @deprecated No typed payload by filter possible! Please use leaderboardRanking instead
   */
  clubRanking: ClubRankingConnection;
  /** @deprecated Use team instead */
  clubs: Array<Club>;
  /**
   *  Returns all clubs which contain the name fragment
   * @deprecated Use team instead
   */
  clubsWithNameContain: Array<Club>;
  /** Returns all activities with type created by the coach with passed ID between startDate and endDate or now if not specified */
  coachActivitiesCreatedInTimeframe: Array<CoachActivity>;
  coachActivitiesSchedule: Array<CoachActivitySchedule>;
  coachActivityById: Maybe<CoachActivity>;
  coachActivityByOccurrenceId: Maybe<CoachActivity>;
  /** Returns game participation rate and unanswered rate */
  coachActivityGameParticipationRate: CoachActivityParticipationRate;
  /**
   * Returns all coach activity occurrences in chronological order starting from `cursor`.
   * If `cursorStart` is omitted, starts from current date.
   * If direction is ASC, returns occurences going into the future.
   * If direction is DESC, returns occurrence going into the past.
   */
  coachActivityOccurrences: Array<CoachActivityOccurrenceEdge>;
  coachActivityOccurrencesByTimeRange: Array<CoachActivityOccurrenceEdge>;
  /** Returns training participation rate and unanswered rate */
  coachActivityTrainingParticipationRate: CoachActivityParticipationRate;
  coachFeedItems: CoachFeedItemsConnection;
  coachFeedItemsCountSinceDate: Scalars["Int"];
  /** Get all notifications that are related to the coach */
  coachNotifications: NotificationsConnection;
  /** Returns all release notes for the coach zone. */
  coachZoneReleaseNotes: Array<ReleaseNote>;
  /** Returns activities satisfying passed filter from followed users */
  communityActivities: CommunityActivitiesConnection;
  /** Returns the state of the current B42 coach program if there is one */
  currentB42CoachProgramState: Maybe<B42CoachProgramState>;
  /**
   *  Returns all finished workouts for the currently active Gamechanger
   * @deprecated No longer supported
   */
  currentGamechangerFinishedWorkouts: Array<GamechangerWorkoutDataRecord>;
  /** @deprecated No longer supported */
  currentGamechangerState: Maybe<GamechangerState>;
  /**
   *  Returns all finished workouts for the currently active Guide
   * @deprecated No longer supported
   */
  currentGuideFinishedWorkouts: Array<GuideWorkoutDataRecord>;
  /** @deprecated No longer supported */
  currentGuideState: Maybe<GuideState>;
  /**
   *  Returns all finished workouts for the currently active PreventionProgram
   * @deprecated No longer supported
   */
  currentPreventionProgramFinishedWorkouts: Array<PreventionProgramWorkoutDataRecord>;
  /** @deprecated No longer supported */
  currentPreventionProgramState: Maybe<PreventionProgramState>;
  /**  Returns all finished workouts for the currently active Road to Recovery  */
  currentRtRFinishedWorkouts: Array<RtRWorkoutDataRecord>;
  currentRtRState: Maybe<RoadToRecoveryState>;
  customExercisesForCurrentSportsClubTeam: Array<CustomExercise>;
  /** Returns basic custom workout information */
  customWorkoutsForCurrentSportsClubTeam: Array<CustomWorkout>;
  /** @deprecated Non-typed exercises. Please use exercisesTyped query instead */
  exercises: Array<Exercise>;
  /** @deprecated Non-typed exercises. Please use exercisesTypedByIds instead */
  exercisesByIds: Array<Exercise>;
  /** @deprecated Exercises always depend on a workout! */
  exercisesTyped: Array<ExerciseUnion>;
  /** @deprecated Exercises always depend on a workout! */
  exercisesTypedByIds: Array<ExerciseUnion>;
  /** @deprecated No longer supported */
  freeAreaExerciseById: Maybe<FreeAreaExercise>;
  /** @deprecated No longer supported */
  freeAreaExercises: Array<FreeAreaExercise>;
  freeAreaFinishedWorkouts: Array<FreeAreaWorkoutDataRecord>;
  freeAreaWorkoutById: Maybe<FreeAreaWorkout>;
  freeAreaWorkouts: Array<FreeAreaWorkout>;
  freeAreaWorkoutsByIds: Array<FreeAreaWorkout>;
  /** @deprecated No longer supported */
  gamechangerById: Gamechanger;
  /** @deprecated No longer supported */
  guideById: Guide;
  joinedChallenges: Array<Challenge>;
  joinedTeamChallenges: Array<TeamChallenge>;
  /** Returns the last feedback answered by the user */
  lastCoachActivityFeedbackAnswered: Maybe<CoachActivity>;
  /** Returns the last game participation of the user */
  lastCoachActivityGameParticipation: Maybe<Occurrence>;
  /** Returns the last training participation of the user */
  lastCoachActivityTrainingParticipation: Maybe<Occurrence>;
  /** @deprecated Use leaderboardTeamRanking instead */
  leaderboardClubRanking: ClubRankingConnection;
  /** @deprecated No longer supported */
  leaderboardTeamRanking: TeamRankingConnection;
  /**
   * Returns list of ranked competitior (users or clubs).
   * If cursors are omitted, it will return place in list where competitior of requesting user is in the middle.
   * If both cursors are given only `cursorStart` will be considered!
   * @deprecated No longer supported
   */
  leaderboardUserRanking: UserRankingConnection;
  nextCoachActivitiesOccurrences: Array<CoachActivityNextOccurrence>;
  nextCoachActivityOccurrence: Maybe<Occurrence>;
  /** Get all notifications that are related to the player */
  notifications: NotificationsConnection;
  performanceTest: PerformanceTest;
  /**
   * deprecated
   * @deprecated Comparison for B42 scores is not used anymore!
   */
  performanceTestComparison: PerformanceTestComparisons;
  performanceTestManualResults: Array<PerformanceTestManualResult>;
  /** Specific for requesting user */
  performanceTestResults: Array<SinglePerformanceTestResult>;
  /** Performance test templates for team */
  performanceTestTemplates: Array<PerformanceTestTemplate>;
  performanceTests: Array<PerformanceTest>;
  /** @deprecated No longer supported */
  preventionProgramById: PreventionProgram;
  /** Returns all release notes for the performance app. */
  releaseNotes: Array<ReleaseNote>;
  roadToRecoveryById: RoadToRecovery;
  runLeaderboardUserRanking: TimeBasedUserRankingConnection;
  runWorkoutById: Maybe<RunWorkoutUnion>;
  runWorkoutResultById: Maybe<RunWorkoutResultExtended>;
  runWorkoutResults: Array<RunWorkoutResult>;
  runWorkouts: Array<RunWorkoutUnion>;
  searchWorkoutsByName: Array<WorkoutMeta>;
  /** @deprecated Use team instead */
  sendClubInvitationMail: SendClubInvitationMailResponse;
  sendTeamInvitationMail: SendTeamInvitationMailResponse;
  /** Returns invitation link for a sports club optionally with team parameter */
  sportsClubInviteLink: Scalars["String"];
  /** Streak statistics for user or current user if no user is provided */
  streakStatsByUser: StreakStats;
  teamActivities: TeamActivitiesConnection;
  /** Returns activities satisfying passed filter and team since date (or current team if ommited) */
  teamActivitiesForTeamSinceDate: TeamActivitiesConnection;
  /** @deprecated Teams list no longer available with new sportsClub logic. Use search queries instead. */
  teamById: Maybe<Team>;
  /** Returns a tea, with matching name (Case-Insensitive!) */
  teamByName: Maybe<Team>;
  teamChallengeById: Maybe<TeamChallenge>;
  teamChallengeLeaderboardById: Maybe<ChallengeLeaderboard>;
  /** Shows a leaderboard for one specific team inside a team challenge */
  teamChallengeLeaderboardTeamInternal: Maybe<TeamChallengeLeaderboardTeamInternal>;
  /**
   * Shows a leaderboard for one specific team inside a team challenge
   * @deprecated Use `challengeLeaderboardForTeam` instead
   */
  teamChallengeUserLeaderboard: Maybe<ChallengeUserLeaderboard>;
  teamChallenges: Array<TeamChallenge>;
  /** Returns a branded link which is a deferred deeplink that preselects the team the current user is in for the invited user */
  teamInviteLink: Scalars["String"];
  teamWorkoutStats: Maybe<TeamWorkoutStats>;
  /** @deprecated Teams list no longer available with new sportsClub logic. Use search queries instead. */
  teams: Array<Team>;
  /**  Returns all teams which contain the name fragment  */
  teamsWithNameContain: Array<Team>;
  totalTreasuryBalance: Maybe<Scalars["Float"]>;
  treasuryTransactions: TreasuryTransactionsConnection;
  /**  Returns user from authEntity from JWT if possible  */
  user: Maybe<User>;
  userByEmail: Maybe<User>;
  userById: Maybe<User>;
  /**
   * - DEPRECATED -
   * Returns list of ranked users.
   * If cursors are omitted, it will return place in list where requesting user is in the middle.
   * If both cursors are given only `cursorStart` will be considered!
   * @deprecated No typed payload by filter possible! Please use leaderboardRanking instead
   */
  userRanking: UserRankingConnection;
  /** @deprecated Use trainingLoads on type User */
  userTrainingLoadsForUserId: Maybe<Array<TrainingLoad>>;
  /** @deprecated All users can't be loaded */
  users: Array<User>;
  usersByName: Array<User>;
  videoAds: Array<VideoAd>;
  workoutEditorExercises: Array<WorkoutExercise>;
  workoutResultById: Maybe<WorkoutResult>;
  /** @deprecated Non-typed workouts. Please use workoutsTyped instead */
  workouts: Array<Workout>;
  workoutsByCategory: Array<WorkoutUnion>;
  /** @deprecated Non-typed workouts. Please use workoutsTypedByIds instead */
  workoutsByIds: Array<Workout>;
  /** Returns workouts for current sports club team */
  workoutsForCurrentSportsClubTeam: Array<WorkoutUnion>;
  workoutsTyped: Array<WorkoutUnion>;
  workoutsTypedByIds: Array<WorkoutUnion>;
};

export type QueryAbsencesArgs = {
  cursor?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type QueryAbsencesForSportsClubTeamArgs = {
  cursor?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryAbsentSportsClubTeamMembersArgs = {
  end: Scalars["Date"];
  start: Scalars["Date"];
};

export type QueryAcademySsoUrlArgs = {
  redirectUrl?: InputMaybe<Scalars["String"]>;
};

export type QueryActivitiesArgs = {
  filter: ActivityFilter;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type QueryActivityByIdArgs = {
  id: Scalars["ID"];
};

export type QueryB42GamechangerByIdArgs = {
  id: Scalars["ID"];
};

export type QueryB42ScoreLeaderboardClubRankingArgs = {
  cursorEnd?: InputMaybe<Scalars["ID"]>;
  cursorStart?: InputMaybe<Scalars["ID"]>;
  filter: B42ClubRankingFilterInput;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryB42ScoreLeaderboardTeamRankingArgs = {
  cursorEnd?: InputMaybe<Scalars["ID"]>;
  cursorStart?: InputMaybe<Scalars["ID"]>;
  filter: B42TeamRankingFilterInput;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryB42ScoreLeaderboardUserRankingArgs = {
  cursorEnd?: InputMaybe<Scalars["ID"]>;
  cursorStart?: InputMaybe<Scalars["ID"]>;
  filter: B42UserRankingFilterInput;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryB42ScoreResultsByTestIdArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  testId?: InputMaybe<PerformanceTestId>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryB42ScoreResultsByTestIdsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  testIds: Array<PerformanceTestId>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryBasicSportsClubTeamArgs = {
  sportsClubId: Scalars["ID"];
  sportsClubTeamId: Scalars["ID"];
};

export type QueryBlogPostByIdArgs = {
  id: Scalars["Int"];
};

export type QueryBlogPostBySlugArgs = {
  slug: Scalars["String"];
};

export type QueryBlogPostBy_IdArgs = {
  id: Scalars["String"];
};

export type QueryBlogPostsArgs = {
  filter?: InputMaybe<BlogPostFilter>;
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  sortField?: InputMaybe<Scalars["String"]>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QueryChallengeByIdArgs = {
  challengeId: Scalars["ID"];
};

export type QueryChallengeLeaderboardByIdArgs = {
  challengeId: Scalars["ID"];
  paginationInput?: InputMaybe<ChallengeLeaderboardPaginationInput>;
};

export type QueryClubActivitiesListArgs = {
  cursorStart?: InputMaybe<Scalars["ID"]>;
  filter: ClubActivityFilter;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryClubByIdArgs = {
  id: Scalars["ID"];
};

export type QueryClubByNameArgs = {
  name: Scalars["String"];
};

export type QueryClubRankingArgs = {
  cursorEnd?: InputMaybe<Scalars["ID"]>;
  cursorStart?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryClubsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type QueryClubsWithNameContainArgs = {
  nameFragment: Scalars["String"];
};

export type QueryCoachActivitiesCreatedInTimeframeArgs = {
  coachId: Scalars["ID"];
  endDate?: InputMaybe<Scalars["Date"]>;
  startDate: Scalars["Date"];
  types: Array<CoachActivityType>;
};

export type QueryCoachActivitiesScheduleArgs = {
  filter: CoachActivitiesScheduleFilter;
};

export type QueryCoachActivityByIdArgs = {
  id: Scalars["ID"];
};

export type QueryCoachActivityByOccurrenceIdArgs = {
  occurrenceId: Scalars["ID"];
};

export type QueryCoachActivityGameParticipationRateArgs = {
  endDate?: InputMaybe<Scalars["Date"]>;
  startDate: Scalars["Date"];
  userId: Scalars["ID"];
};

export type QueryCoachActivityOccurrencesArgs = {
  cursor?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<SortOrder>;
  types?: InputMaybe<Array<CoachActivityType>>;
};

export type QueryCoachActivityOccurrencesByTimeRangeArgs = {
  endDate: Scalars["Date"];
  startDate: Scalars["Date"];
  types?: InputMaybe<Array<CoachActivityType>>;
};

export type QueryCoachActivityTrainingParticipationRateArgs = {
  endDate?: InputMaybe<Scalars["Date"]>;
  startDate: Scalars["Date"];
  userId: Scalars["ID"];
};

export type QueryCoachFeedItemsArgs = {
  cursorStart?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<CoachFeedFilter>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryCoachFeedItemsCountSinceDateArgs = {
  filter?: InputMaybe<CoachFeedFilter>;
  startDate: Scalars["Date"];
};

export type QueryCoachNotificationsArgs = {
  cursor?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryCommunityActivitiesArgs = {
  cursorStart?: InputMaybe<Scalars["ID"]>;
  filter: CommunityActivityFilter;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryExercisesByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryExercisesTypedByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryFreeAreaExerciseByIdArgs = {
  id: Scalars["ID"];
};

export type QueryFreeAreaWorkoutByIdArgs = {
  id: Scalars["ID"];
};

export type QueryFreeAreaWorkoutsByIdsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type QueryGamechangerByIdArgs = {
  id: GamechangerId;
};

export type QueryGuideByIdArgs = {
  id: GuideId;
};

export type QueryLastCoachActivityFeedbackAnsweredArgs = {
  userId: Scalars["ID"];
};

export type QueryLastCoachActivityGameParticipationArgs = {
  userId: Scalars["ID"];
};

export type QueryLastCoachActivityTrainingParticipationArgs = {
  userId: Scalars["ID"];
};

export type QueryLeaderboardClubRankingArgs = {
  cursorEnd?: InputMaybe<Scalars["ID"]>;
  cursorStart?: InputMaybe<Scalars["ID"]>;
  filter: ClubRankingFilterInput;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryLeaderboardTeamRankingArgs = {
  cursorEnd?: InputMaybe<Scalars["ID"]>;
  cursorStart?: InputMaybe<Scalars["ID"]>;
  filter: TeamRankingFilterInput;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryLeaderboardUserRankingArgs = {
  cursorEnd?: InputMaybe<Scalars["ID"]>;
  cursorStart?: InputMaybe<Scalars["ID"]>;
  filter: UserRankingFilterInput;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryNextCoachActivitiesOccurrencesArgs = {
  filter: CoachActivitiesOccurrenceFilter;
  maxOccurrencesCount?: InputMaybe<Scalars["Int"]>;
};

export type QueryNextCoachActivityOccurrenceArgs = {
  from?: InputMaybe<Scalars["Date"]>;
  id: Scalars["ID"];
};

export type QueryNotificationsArgs = {
  cursor?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryPerformanceTestArgs = {
  id: PerformanceTestId;
};

export type QueryPreventionProgramByIdArgs = {
  id: PreventionProgramId;
};

export type QueryRoadToRecoveryByIdArgs = {
  id: RoadToRecoveryId;
};

export type QueryRunLeaderboardUserRankingArgs = {
  cursorEnd?: InputMaybe<Scalars["ID"]>;
  cursorStart?: InputMaybe<Scalars["ID"]>;
  filter: WorkoutRankingFilterInput;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryRunWorkoutByIdArgs = {
  id: Scalars["String"];
};

export type QueryRunWorkoutResultByIdArgs = {
  id: Scalars["ID"];
};

export type QueryRunWorkoutResultsArgs = {
  filter?: InputMaybe<RunWorkoutResultsFilter>;
};

export type QueryRunWorkoutsArgs = {
  filter?: InputMaybe<RunWorkoutsFilter>;
};

export type QuerySearchWorkoutsByNameArgs = {
  query: Scalars["String"];
};

export type QuerySendClubInvitationMailArgs = {
  input: SendClubInvitationMailInput;
};

export type QuerySendTeamInvitationMailArgs = {
  input: SendTeamInvitationMailInput;
};

export type QuerySportsClubInviteLinkArgs = {
  params?: InputMaybe<SportsClubInviteLinkParams>;
};

export type QueryStreakStatsByUserArgs = {
  userId?: InputMaybe<Scalars["ID"]>;
};

export type QueryTeamActivitiesArgs = {
  cursorStart?: InputMaybe<Scalars["ID"]>;
  filter: TeamActivityFilter;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryTeamActivitiesForTeamSinceDateArgs = {
  date: Scalars["Date"];
  filter: TeamActivityFilter;
  teamId?: InputMaybe<Scalars["ID"]>;
};

export type QueryTeamByIdArgs = {
  id: Scalars["ID"];
};

export type QueryTeamByNameArgs = {
  name: Scalars["String"];
};

export type QueryTeamChallengeByIdArgs = {
  id: Scalars["ID"];
};

export type QueryTeamChallengeLeaderboardByIdArgs = {
  challengeId: Scalars["ID"];
  paginationInput?: InputMaybe<ChallengeLeaderboardPaginationInput>;
};

export type QueryTeamChallengeLeaderboardTeamInternalArgs = {
  challengeId: Scalars["ID"];
  paginationInput?: InputMaybe<ChallengeLeaderboardPaginationInput>;
  teamId: Scalars["ID"];
};

export type QueryTeamChallengeUserLeaderboardArgs = {
  challengeId: Scalars["ID"];
  paginationInput?: InputMaybe<ChallengeLeaderboardPaginationInput>;
  teamId: Scalars["ID"];
};

export type QueryTeamWorkoutStatsArgs = {
  filter: TeamWorkoutStatsFilter;
};

export type QueryTeamsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type QueryTeamsWithNameContainArgs = {
  nameFragment: Scalars["String"];
};

export type QueryTreasuryTransactionsArgs = {
  cursor?: InputMaybe<Scalars["ID"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryUserByEmailArgs = {
  email: Scalars["String"];
};

export type QueryUserByIdArgs = {
  id: Scalars["ID"];
};

export type QueryUserRankingArgs = {
  cursorEnd?: InputMaybe<Scalars["ID"]>;
  cursorStart?: InputMaybe<Scalars["ID"]>;
  filter: UserRankingFilter;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryUserTrainingLoadsForUserIdArgs = {
  userId: Scalars["ID"];
};

export type QueryUsersByNameArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryWorkoutResultByIdArgs = {
  id: Scalars["ID"];
};

export type QueryWorkoutsByCategoryArgs = {
  workoutCategory: WorkoutCategory;
};

export type QueryWorkoutsByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryWorkoutsTypedArgs = {
  filter?: InputMaybe<WorkoutsTypedFilter>;
};

export type QueryWorkoutsTypedByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum RankingFilter {
  CHALLENGE = "CHALLENGE",
  /** @deprecated Please use TEAM instead */
  CLUB = "CLUB",
  /** @deprecated Please use TEAM_TOP10 instead */
  CLUB_TOP10 = "CLUB_TOP10",
  COUNTRY = "COUNTRY",
  COUNTRY_TOP10 = "COUNTRY_TOP10",
  GLOBAL = "GLOBAL",
  TEAM = "TEAM",
  TEAM_TOP10 = "TEAM_TOP10",
  TOP10 = "TOP10"
}

export type RatePlayerInCoachActivityEventInput = {
  id: Scalars["ID"];
  occurrenceId: Scalars["ID"];
  /** grade between 1 and 6, similar to german school system, assigned from coach to player after event finish */
  performanceGrade?: InputMaybe<Scalars["Int"]>;
  userId: Scalars["ID"];
};

export enum Reaction {
  FIRE = "FIRE",
  POWER = "POWER",
  WOW = "WOW",
  YES = "YES"
}

export type ReadBlogPostActivity = Activity & {
  __typename: "ReadBlogPostActivity";
  _id: Scalars["String"];
  blogPost: Maybe<BlogPost>;
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export type RecurringPattern = {
  __typename: "RecurringPattern";
  daysOfMonth: Maybe<Array<Scalars["Int"]>>;
  daysOfWeek: Maybe<Array<DayOfWeek>>;
  monthsOfYear: Maybe<Array<Scalars["Int"]>>;
  recurringType: RecurringType;
  separationCount: Maybe<Scalars["Int"]>;
};

export type RecurringPatternInput = {
  daysOfMonth?: InputMaybe<Array<Scalars["Int"]>>;
  daysOfWeek?: InputMaybe<Array<DayOfWeek>>;
  monthsOfYear?: InputMaybe<Array<Scalars["Int"]>>;
  recurringType: RecurringType;
  separationCount?: InputMaybe<Scalars["Int"]>;
};

export enum RecurringType {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  YEARLY = "YEARLY"
}

export enum RedeemVoucherErrorCodes {
  ALREADY_USED = "ALREADY_USED",
  /**  The main reason for this error is if the voucher is a subscription voucher and the payment was canceled and the paid time ended.  */
  DISABLED = "DISABLED",
  USAGES_EXCEEDED = "USAGES_EXCEEDED",
  VOUCHER_NOT_FOUND = "VOUCHER_NOT_FOUND"
}

export type RedeemVoucherErrorResponse = {
  __typename: "RedeemVoucherErrorResponse";
  code: RedeemVoucherErrorCodes;
};

export type RedeemVoucherResponse = RedeemVoucherErrorResponse | RedeemVoucherSuccessResponse;

export type RedeemVoucherSuccessResponse = {
  __typename: "RedeemVoucherSuccessResponse";
  success: Scalars["Boolean"];
  voucher: Voucher;
};

export type ReleaseNote = {
  __typename: "ReleaseNote";
  changes: Array<Scalars["String"]>;
  date: Scalars["Date"];
  version: Scalars["String"];
};

export type Reminder = {
  id: Scalars["ID"];
  reminderDate: Scalars["Date"];
  type: Scalars["String"];
};

export type RepBasedExercise = BaseExercise & {
  __typename: "RepBasedExercise";
  coverImage: Maybe<Asset>;
  equipments: Array<Equipment>;
  id: Scalars["String"];
  /** @deprecated Gender specific Image in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  image: Maybe<Asset>;
  /** @deprecated Gender specific Image in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  imageGirls: Maybe<Asset>;
  instruction: Scalars["String"];
  keypoints: Array<Scalars["String"]>;
  name: Scalars["String"];
  repetitions: Maybe<ExerciseRepetitions>;
  repetitionsPerSide: Maybe<Scalars["Boolean"]>;
  type: ExerciseType;
  /** @deprecated Gender specific video in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  video: Maybe<Asset>;
  /** @deprecated Gender specific video in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  videoGirls: Maybe<Asset>;
  videos: Maybe<Array<Asset>>;
};

export enum ReportReason {
  OFFENSIVE_IMAGE = "OFFENSIVE_IMAGE",
  WRONG_TEAM = "WRONG_TEAM"
}

/** Deprecated */
export type RescheduleMultipleOccurrencesInput = {
  recurringPattern?: InputMaybe<RecurringPatternInput>;
  timeFrame: RescheduleMultipleOccurrencesTimeFrameInput;
};

/** Deprecated */
export type RescheduleMultipleOccurrencesTimeFrameInput = {
  /** Duration in millis, default is one hour */
  duration?: InputMaybe<Scalars["Float"]>;
  /** Time in UTC 0, it is a new end time of a new event series */
  endDate: Scalars["Date"];
  /** Time in UTC 0, it is a new start time of a new event series */
  newStartDate: Scalars["Date"];
  /** Time in UTC 0, it is a date of occurrence from which reschedule this and following occurrences */
  startDate: Scalars["Date"];
};

/** Deprecated */
export type RescheduleSingleOccurrenceInput = {
  timeFrame: RescheduleSingleOccurrenceTimeFrameInput;
};

/** Deprecated */
export type RescheduleSingleOccurrenceTimeFrameInput = {
  /** Duration in millis, default is one hour */
  duration?: InputMaybe<Scalars["Float"]>;
  /** Time in UTC 0, it is a new date for occurrence */
  newStartDate: Scalars["Date"];
  /** Time in UTC 0, it is a date of occurrence that is rescheduling */
  startDate: Scalars["Date"];
};

export enum ResendDoubleOptInErrorCodes {
  GENERAL_ERROR = "GENERAL_ERROR"
}

export type ResendDoubleOptInErrorResponse = {
  __typename: "ResendDoubleOptInErrorResponse";
  code: Maybe<ResendDoubleOptInErrorCodes>;
};

export type ResendDoubleOptInResponse = ResendDoubleOptInErrorResponse | ResendDoubleOptInSuccessResponse;

export type ResendDoubleOptInSuccessResponse = {
  __typename: "ResendDoubleOptInSuccessResponse";
  success: Maybe<Scalars["Boolean"]>;
};

export enum ResetPasswordErrorCodes {
  TOKEN_INVALID = "TOKEN_INVALID"
}

export type ResetPasswordErrorResponse = {
  __typename: "ResetPasswordErrorResponse";
  code: Maybe<ResetPasswordErrorCodes>;
};

export type ResetPasswordResponse = ResetPasswordErrorResponse | ResetPasswordSuccessResponse;

export type ResetPasswordSuccessResponse = {
  __typename: "ResetPasswordSuccessResponse";
  success: Maybe<Scalars["Boolean"]>;
};

export enum RevenuePurpose {
  DONATION = "DONATION",
  MEMBER = "MEMBER",
  OTHER = "OTHER",
  PENALTY = "PENALTY",
  SPONSOR = "SPONSOR"
}

export type RevenueTransactionInfoInput = {
  description?: InputMaybe<Scalars["String"]>;
  purpose: RevenuePurpose;
};

export type RoadToRecovery = {
  __typename: "RoadToRecovery";
  chapters: Array<RoadToRecoveryChapter>;
  id: RoadToRecoveryId;
  name: Scalars["String"];
  phases: RoadToRecoveryPhases;
};

export type RoadToRecoveryChapter = {
  __typename: "RoadToRecoveryChapter";
  heading: Maybe<Scalars["String"]>;
  headingLower: Maybe<Scalars["String"]>;
  headingUpper: Maybe<Scalars["String"]>;
  html: Maybe<Scalars["String"]>;
  theme: Maybe<RoadToRecoveryChapterThemes>;
};

export enum RoadToRecoveryChapterThemes {
  DARK = "DARK",
  LIGHT = "LIGHT"
}

export type RoadToRecoveryExercise = {
  __typename: "RoadToRecoveryExercise";
  baseExercise: Exercise;
  durationSeconds: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  onlyInjuredSide: Maybe<Scalars["Boolean"]>;
  sets: Scalars["String"];
  /** @deprecated Concated string for workload. Please use baseExercise.repetitions or baseExercise.durationSeconds and onlyInjuredSide instead */
  work: Scalars["String"];
};

export enum RoadToRecoveryId {
  ROAD_TO_RECOVERY_ANKLE = "ROAD_TO_RECOVERY_ANKLE",
  ROAD_TO_RECOVERY_CALF = "ROAD_TO_RECOVERY_CALF",
  ROAD_TO_RECOVERY_GROIN = "ROAD_TO_RECOVERY_GROIN",
  ROAD_TO_RECOVERY_HAMSTRING = "ROAD_TO_RECOVERY_HAMSTRING",
  ROAD_TO_RECOVERY_KNEE = "ROAD_TO_RECOVERY_KNEE",
  ROAD_TO_RECOVERY_LOWER_BACK = "ROAD_TO_RECOVERY_LOWER_BACK",
  ROAD_TO_RECOVERY_SHOULDER = "ROAD_TO_RECOVERY_SHOULDER",
  ROAD_TO_RECOVERY_UPPER_BACK = "ROAD_TO_RECOVERY_UPPER_BACK"
}

export type RoadToRecoveryPhase = {
  __typename: "RoadToRecoveryPhase";
  backgroundImage: Asset;
  exerciseSchedule: Array<Maybe<RoadToRecoveryWorkout>>;
  finalTest: Exercise;
  finalTestInfo: Scalars["String"];
  phaseInfo: Scalars["String"];
};

export type RoadToRecoveryPhaseData = {
  __typename: "RoadToRecoveryPhaseData";
  infoDialogRead: Scalars["Boolean"];
  phase: RtRPhase;
  phaseStartDate: Scalars["Date"];
};

export type RoadToRecoveryPhases = {
  __typename: "RoadToRecoveryPhases";
  healing: RoadToRecoveryPhase;
  rebuilding: RoadToRecoveryPhase;
  returnToCompetition: RoadToRecoveryPhase;
};

export type RoadToRecoveryState = BaseProgramState & {
  __typename: "RoadToRecoveryState";
  /** Unique ID for state */
  _id: Scalars["ID"];
  assignedBy: Maybe<User>;
  completeDate: Maybe<Scalars["Date"]>;
  current: Scalars["Boolean"];
  currentPhase: RtRPhase;
  /** @deprecated Confusing name (Isn't an unique identifier of this state!) Use `rtrId` instead! */
  id: RoadToRecoveryId;
  phaseData: Array<RoadToRecoveryPhaseData>;
  progress: Scalars["Int"];
  resetDate: Maybe<Scalars["Date"]>;
  rtrId: RoadToRecoveryId;
  startDate: Scalars["Date"];
};

export type RoadToRecoveryWorkout = {
  __typename: "RoadToRecoveryWorkout";
  _id: Scalars["String"];
  exercises: Array<RoadToRecoveryExercise>;
  name: Scalars["String"];
};

export type RoadToRecoveryWorkoutDataRecord = {
  __typename: "RoadToRecoveryWorkoutDataRecord";
  area: WorkoutDataRecordArea;
  date: Scalars["Date"];
  elapsedTime: Maybe<Scalars["Int"]>;
  elapsedTimePerExercise: Maybe<Array<Maybe<ElapsedTimePerExercise>>>;
  programId: Scalars["String"];
  workoutId: Scalars["String"];
};

export type RoadToRecoveryWorkoutDataRecordInput = {
  date: Scalars["Date"];
  elapsedTime: Scalars["Int"];
  elapsedTimePerExercise: Array<ElapsedTimeForExerciseInput>;
  programId: RoadToRecoveryId;
  workoutId: Scalars["String"];
};

export enum RtRPhase {
  HEALING = "HEALING",
  REBUILDING = "REBUILDING",
  RETURN_TO_COMPETITION = "RETURN_TO_COMPETITION"
}

export type RtRWorkoutDataRecord = {
  __typename: "RtRWorkoutDataRecord";
  area: WorkoutDataRecordArea;
  date: Scalars["Date"];
  elapsedTime: Maybe<Scalars["Int"]>;
  phase: Maybe<RtRPhase>;
  programId: Scalars["String"];
  workoutId: Maybe<Scalars["String"]>;
};

export type RtRWorkoutDataRecordInput = {
  date: Scalars["Date"];
  elapsedTime?: InputMaybe<Scalars["Int"]>;
  phase: RtRPhase;
  programId: RoadToRecoveryId;
};

export type RunTrack = {
  __typename: "RunTrack";
  endTimestamp: Scalars["Float"];
  startTimestamp: Scalars["Float"];
  totalDistance: Scalars["Float"];
};

/** Extended version of `RunTrack` with track segments and measurements */
export type RunTrackExtended = {
  __typename: "RunTrackExtended";
  endTimestamp: Scalars["Float"];
  segments: Array<RunTrackSegment>;
  startTimestamp: Scalars["Float"];
  totalDistance: Scalars["Float"];
};

export type RunTrackInput = {
  endTimestamp: Scalars["Float"];
  segments: Array<RunTrackSegmentInput>;
  startTimestamp: Scalars["Float"];
  totalDistance: Scalars["Float"];
};

export type RunTrackMeasurement = {
  __typename: "RunTrackMeasurement";
  /** The radius of uncertainty for the location, measured in meters. */
  accuracy: Scalars["Float"];
  /** Altitude measured in meters */
  altitude: Scalars["Float"];
  /** The direction in which the device is traveling, measured in degrees and relative to due north. */
  bearing: Scalars["Float"];
  /** The accuracy of the bearing value, measured in degrees. (0 if not supported) */
  bearingAccuracy: Scalars["Float"];
  /** Heart rate - only provided by tracking devices */
  heartRate: Maybe<Scalars["Int"]>;
  /** The latitude in degrees */
  latitude: Scalars["Float"];
  /** The longitude in degrees */
  longitude: Scalars["Float"];
  /** Speed measured in meters per second. */
  speed: Scalars["Float"];
  /** Accuracy of the speed value measured in meters per second (0 if not supported) */
  speedAccuracy: Scalars["Float"];
  /** Steps per minute - only provided by tracking devices */
  steps: Maybe<Scalars["Int"]>;
  time: Scalars["Float"];
};

export type RunTrackMeasurementInput = {
  /** The radius of uncertainty for the location, measured in meters. */
  accuracy: Scalars["Float"];
  /** Altitude measured in meters */
  altitude: Scalars["Float"];
  /** The direction in which the device is traveling, measured in degrees and relative to due north. */
  bearing: Scalars["Float"];
  /** The accuracy of the bearing value, measured in degrees. (0 if not supported) */
  bearingAccuracy: Scalars["Float"];
  /** The latitude in degrees */
  latitude: Scalars["Float"];
  /** The longitude in degrees */
  longitude: Scalars["Float"];
  /** Speed measured in meters per second. */
  speed: Scalars["Float"];
  /** Accuracy of the speed value measured in meters per second (0 if not supported) */
  speedAccuracy: Scalars["Float"];
  time: Scalars["Float"];
};

export type RunTrackSegment = {
  __typename: "RunTrackSegment";
  distance: Scalars["Float"];
  endTimestamp: Scalars["Float"];
  measurements: Array<RunTrackMeasurement>;
  startTimestamp: Scalars["Float"];
};

export type RunTrackSegmentInput = {
  distance: Scalars["Float"];
  endTimestamp: Scalars["Float"];
  measurements: Array<RunTrackMeasurementInput>;
  startTimestamp: Scalars["Float"];
};

export enum RunType {
  ENDURANCE = "ENDURANCE",
  FREE = "FREE",
  INTERVAL = "INTERVAL"
}

export type RunWorkoutResult = {
  __typename: "RunWorkoutResult";
  _id: Scalars["String"];
  date: Scalars["Date"];
  /** Elapsed time in milliseconds WITHOUT pauses. */
  elapsedTimeMillis: Scalars["Float"];
  /** Data provider of this workout, e.g. Garmin */
  source: Maybe<TrackingSource>;
  /** ID of this workout in the system of the data provider, e.g. Garmin */
  sourceId: Maybe<Scalars["String"]>;
  track: RunTrack;
  type: RunType;
  workoutId: Scalars["String"];
};

/** Extended version of `RunWorkoutResult` with track segments and measurements */
export type RunWorkoutResultExtended = {
  __typename: "RunWorkoutResultExtended";
  _id: Scalars["String"];
  date: Scalars["Date"];
  /** Elapsed time in milliseconds WITHOUT pauses. */
  elapsedTimeMillis: Scalars["Float"];
  /** Data provider of this workout, e.g. Garmin */
  source: Maybe<TrackingSource>;
  /** ID of this workout in the system of the data provider, e.g. Garmin */
  sourceId: Maybe<Scalars["String"]>;
  track: RunTrackExtended;
  type: RunType;
  workoutId: Scalars["String"];
};

export type RunWorkoutResultInput = {
  coachActivityId?: InputMaybe<Scalars["String"]>;
  date: Scalars["Date"];
  /** Elapsed time in milliseconds WITHOUT pauses. */
  elapsedTimeMillis: Scalars["Float"];
  track: RunTrackInput;
  type: RunType;
  workoutId: Scalars["String"];
};

export type RunWorkoutResultsFilter = {
  from: Scalars["Date"];
  to: Scalars["Date"];
};

export type RunWorkoutUnion = EnduranceRunWorkout | FreeRunWorkout | IntervalRunWorkout;

export type RunWorkoutsFilter = {
  types?: InputMaybe<Array<RunType>>;
};

export type SeoData = {
  __typename: "SEOData";
  description: Maybe<Scalars["String"]>;
  title: Maybe<Scalars["String"]>;
};

export enum SeasonPhase {
  OFFSEASON = "OFFSEASON",
  PRESEASON = "PRESEASON",
  SEASON = "SEASON"
}

export enum SendClubInvitationMailErrorCodes {
  GENERAL_ERROR = "GENERAL_ERROR"
}

export type SendClubInvitationMailErrorResponse = {
  __typename: "SendClubInvitationMailErrorResponse";
  code: Maybe<SendClubInvitationMailErrorCodes>;
};

export type SendClubInvitationMailInput = {
  club: Scalars["ID"];
  email: Scalars["String"];
  language: Scalars["String"];
};

export type SendClubInvitationMailResponse = SendClubInvitationMailErrorResponse | SendClubInvitationMailSuccessResponse;

export type SendClubInvitationMailSuccessResponse = {
  __typename: "SendClubInvitationMailSuccessResponse";
  inviteLink: Scalars["String"];
};

export enum SendTeamInvitationMailErrorCodes {
  GENERAL_ERROR = "GENERAL_ERROR"
}

export type SendTeamInvitationMailErrorResponse = {
  __typename: "SendTeamInvitationMailErrorResponse";
  code: Maybe<SendTeamInvitationMailErrorCodes>;
};

export type SendTeamInvitationMailInput = {
  email: Scalars["String"];
  language: Scalars["String"];
  teamId: Scalars["ID"];
};

export type SendTeamInvitationMailResponse = SendTeamInvitationMailErrorResponse | SendTeamInvitationMailSuccessResponse;

export type SendTeamInvitationMailSuccessResponse = {
  __typename: "SendTeamInvitationMailSuccessResponse";
  inviteLink: Scalars["String"];
};

export type SetTeamMemberPositionInput = {
  playerCoachPosition?: InputMaybe<Position>;
  position: Position;
  teamId: Scalars["String"];
};

export enum SetTeamMembersRoleErrorCodes {
  TEAM_SHOULD_HAVE_ADMIN = "TEAM_SHOULD_HAVE_ADMIN"
}

export type SetTeamMembersRoleErrorResponse = {
  __typename: "SetTeamMembersRoleErrorResponse";
  code: SetTeamMembersRoleErrorCodes;
};

export type SetTeamMembersRoleInput = {
  role: TeamRole;
  teamId: Scalars["String"];
  userIds: Array<Scalars["String"]>;
};

export type SetTeamMembersRoleResponse = SetTeamMembersRoleErrorResponse | Team;

export enum SignInErrorCodes {
  EMAIL_UNKNOWN = "EMAIL_UNKNOWN",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS"
}

export type SignInErrorResponse = {
  __typename: "SignInErrorResponse";
  code: SignInErrorCodes;
};

export type SignInResponse = SignInErrorResponse | SignInSignUpSuccessResponse;

export type SignInSignUpSuccessResponse = {
  __typename: "SignInSignUpSuccessResponse";
  /**  True if user was signed up with this request  */
  signedUp: Maybe<Scalars["Boolean"]>;
  token: Scalars["String"];
  user: User;
  userId: Scalars["String"];
};

export enum SignInThirdPartyErrorCodes {
  UNKNOWN = "UNKNOWN"
}

export type SignInThirdPartyErrorResponse = {
  __typename: "SignInThirdPartyErrorResponse";
  code: SignInThirdPartyErrorCodes;
};

export type SignInThirdPartyResponse = SignInSignUpSuccessResponse | SignInThirdPartyErrorResponse;

export enum SignUpErrorCodes {
  EMAIL_TAKEN_ALREADY = "EMAIL_TAKEN_ALREADY"
}

export type SignUpErrorResponse = {
  __typename: "SignUpErrorResponse";
  code: SignUpErrorCodes;
};

export type SignUpResponse = SignInSignUpSuccessResponse | SignUpErrorResponse;

export type SinglePerformanceTestResult = {
  __typename: "SinglePerformanceTestResult";
  _id: Scalars["ID"];
  /**
   * B42 score resulting from this test result.
   * All subtypes which aren't targeted by the performance test will be 0, so total will also be biased!
   *
   * Use only if necessary, resolving this for many results is quite expensive!
   */
  b42Score: B42Score;
  /** @deprecated Not supported anymore! */
  previousPerformanceTestResult: Maybe<SinglePerformanceTestResult>;
  results: Array<PerformanceTestExerciseResult>;
  takenAt: Scalars["Date"];
  testId: PerformanceTestId;
};

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC"
}

export type SpeedOutdoorWorkout = BaseWorkout & {
  __typename: "SpeedOutdoorWorkout";
  avgUserScore: Maybe<Scalars["Float"]>;
  categories: Array<WorkoutCategory>;
  coverImage: Asset;
  /** Concats all exercises from all parts */
  exercises: Array<ExerciseUnion>;
  finalExercises: Maybe<Array<ExerciseUnion>>;
  gender: Maybe<Gender>;
  id: Scalars["String"];
  isFreeWorkout: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  nmbUserVotes: Maybe<Scalars["Int"]>;
  /** If workout is divided into parts, this field contains the indivual parts */
  parts: Array<WorkoutPart>;
  recommended: Maybe<Scalars["Boolean"]>;
  recommendedPositions: Maybe<Array<Position>>;
  /** @deprecated Works not for free area workouts, coach workouts and b42 gamechanger workouts, use workoutReminders instead */
  reminders: Array<WorkoutReminder>;
  source: WorkoutSource;
  tags: Array<WorkoutTag>;
  time: WorkoutTime;
  type: WorkoutType;
  workoutReminders: Array<WorkoutReminderUnion>;
};

export type SportsClub = {
  __typename: "SportsClub";
  _id: Scalars["ID"];
  attribution: SportsClubAttribution;
  info: SportsClubInfo;
  logo: Maybe<File>;
  name: Scalars["String"];
  onboardingStatus: SportsClubOnboardingStatus;
  sponsor: Maybe<SportsClubSponsor>;
  teams: Array<SportsClubTeam>;
  users: Array<SportsClubUser>;
};

export type SportsClubAcquisitionInput = {
  description?: InputMaybe<Scalars["String"]>;
  source: SportsClubAcquisitionSource;
};

export enum SportsClubAcquisitionSource {
  B42_APP = "B42_APP",
  EM_SPECIAL = "EM_SPECIAL",
  GOOGLE_SEARCH = "GOOGLE_SEARCH",
  ITK = "ITK",
  OTHER = "OTHER",
  RECOMMENDATION = "RECOMMENDATION",
  SOCIAL_MEDIA = "SOCIAL_MEDIA"
}

export type SportsClubAttribution = {
  __typename: "SportsClubAttribution";
  refByAffiliateId: Maybe<Scalars["String"]>;
  referredBy: Maybe<Scalars["String"]>;
};

export type SportsClubAttributionInput = {
  refByAffiliateId?: InputMaybe<Scalars["String"]>;
  referredBy?: InputMaybe<Scalars["String"]>;
};

export type SportsClubInfo = {
  __typename: "SportsClubInfo";
  location: Maybe<Scalars["String"]>;
  phone: Maybe<Scalars["String"]>;
  postcode: Maybe<Scalars["String"]>;
  street: Maybe<Scalars["String"]>;
  website: Maybe<Scalars["String"]>;
};

export type SportsClubInfoInput = {
  location?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  postcode?: InputMaybe<Scalars["String"]>;
  street?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type SportsClubInviteLinkParams = {
  role?: InputMaybe<SportsClubTeamRole>;
  /** teamId can be passed, if not passed, the current team of the user is used */
  teamId?: InputMaybe<Scalars["ID"]>;
};

export type SportsClubMembership = {
  __typename: "SportsClubMembership";
  currentSportsClubTeam: Maybe<SportsClubTeam>;
  sportsClub: SportsClub;
  /** Teams of the sports club the user is member of. */
  teamMemberships: Array<SportsClubTeam>;
};

export enum SportsClubOnboardingStatus {
  ABORTED = "ABORTED",
  CLUB_SIGNUP_COMPLETED = "CLUB_SIGNUP_COMPLETED",
  /** Coach Zone Onboarding Completed - when team was created */
  ONBOARDING_COMPLETED = "ONBOARDING_COMPLETED"
}

export type SportsClubPremiumLicence = {
  __typename: "SportsClubPremiumLicence";
  expiryDate: Scalars["Date"];
  premiumType: SportsClubPremiumType;
  /** type: team or sports-club */
  type: Scalars["String"];
};

export enum SportsClubPremiumType {
  BASIC = "BASIC",
  PRO = "PRO",
  SPECIAL = "SPECIAL",
  TRIAL = "TRIAL"
}

export enum SportsClubRole {
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
  OWNER = "OWNER"
}

export enum SportsClubSource {
  MANUAL = "MANUAL",
  WEBSITE = "WEBSITE"
}

export type SportsClubSponsor = {
  __typename: "SportsClubSponsor";
  largeBanner: Maybe<File>;
  /** Url to the sponsor website */
  link: Maybe<Scalars["String"]>;
  mediumBanner: Maybe<File>;
  smallBanner: Maybe<File>;
};

export enum SportsClubSponsorBannerSize {
  LARGE = "LARGE",
  MEDIUM = "MEDIUM",
  SMALL = "SMALL"
}

export type SportsClubSponsorInput = {
  /** Url to the sponsor website */
  link?: InputMaybe<Scalars["String"]>;
};

export type SportsClubTeam = {
  __typename: "SportsClubTeam";
  _id: Scalars["ID"];
  gender: Maybe<TeamGender>;
  licence: Maybe<SportsClubPremiumLicence>;
  members: Array<SportsClubTeamMember>;
  /** Members which are temporary and not referencing real user objects */
  membersTemporary: Maybe<Array<SportsClubTeamMemberTemporary>>;
  name: Scalars["String"];
  /** Number of player with status=Fit */
  nmbFitPlayers: Scalars["Int"];
  /** Number of player with status=Injured */
  nmbInjuredPlayers: Scalars["Int"];
  penaltyCatalogue: Maybe<File>;
  /** Permissions for the team of current user */
  permissions: Array<FeaturePermission>;
};

export enum SportsClubTeamInviteLinkByMailErrorCodes {
  GENERAL_ERROR = "GENERAL_ERROR"
}

export type SportsClubTeamInviteLinkByMailErrorResponse = {
  __typename: "SportsClubTeamInviteLinkByMailErrorResponse";
  code: Maybe<SportsClubTeamInviteLinkByMailErrorCodes>;
};

export type SportsClubTeamInviteLinkByMailInput = {
  emails: Array<Scalars["String"]>;
  role?: InputMaybe<SportsClubTeamRole>;
  /** teamId can be passed, if not passed, the current team of the user is used */
  teamId?: InputMaybe<Scalars["ID"]>;
};

export type SportsClubTeamInviteLinkByMailResponse = SportsClubTeamInviteLinkByMailErrorResponse | SportsClubTeamInviteLinkByMailSuccessResponse;

export type SportsClubTeamInviteLinkByMailSuccessResponse = {
  __typename: "SportsClubTeamInviteLinkByMailSuccessResponse";
  inviteLink: Scalars["String"];
};

export type SportsClubTeamMember = {
  __typename: "SportsClubTeamMember";
  data: Maybe<SportsClubTeamMemberData>;
  isCaptain: Scalars["Boolean"];
  isPrincipal: Scalars["Boolean"];
  notes: Maybe<Array<Note>>;
  position: Maybe<Position>;
  positionGroup: Maybe<PositionGroup>;
  roles: Maybe<Array<SportsClubTeamRole>>;
  user: User;
};

export type SportsClubTeamMemberData = {
  __typename: "SportsClubTeamMemberData";
  address: SportsClubTeamMemberDataAddress;
  customFields: Array<SportsClubTeamMemberDataCustomField>;
  jerseySize: Scalars["String"];
  notes: Scalars["String"];
  phone: SportsClubTeamMemberDataPhone;
  playerPass: SportsClubTeamMemberDataPlayerPass;
};

export type SportsClubTeamMemberDataAddress = {
  __typename: "SportsClubTeamMemberDataAddress";
  city: Scalars["String"];
  line1: Scalars["String"];
  line2: Scalars["String"];
  zip: Scalars["String"];
};

export type SportsClubTeamMemberDataAddressInput = {
  city: Scalars["String"];
  line1: Scalars["String"];
  line2: Scalars["String"];
  zip: Scalars["String"];
};

export type SportsClubTeamMemberDataCustomField = {
  __typename: "SportsClubTeamMemberDataCustomField";
  name: Scalars["String"];
  value: Scalars["String"];
};

export type SportsClubTeamMemberDataCustomFieldInput = {
  name: Scalars["String"];
  value: Scalars["String"];
};

export type SportsClubTeamMemberDataInput = {
  address: SportsClubTeamMemberDataAddressInput;
  customFields: Array<SportsClubTeamMemberDataCustomFieldInput>;
  jerseySize: Scalars["String"];
  notes: Scalars["String"];
  phone: SportsClubTeamMemberDataPhoneInput;
  playerPass: SportsClubTeamMemberDataPlayerPassInput;
};

export type SportsClubTeamMemberDataPhone = {
  __typename: "SportsClubTeamMemberDataPhone";
  emergency: Scalars["String"];
  emergencyName: Maybe<Scalars["String"]>;
  player: Scalars["String"];
};

export type SportsClubTeamMemberDataPhoneInput = {
  emergency: Scalars["String"];
  emergencyName: Scalars["String"];
  player: Scalars["String"];
};

export type SportsClubTeamMemberDataPlayerPass = {
  __typename: "SportsClubTeamMemberDataPlayerPass";
  number: Scalars["String"];
  validity: Scalars["String"];
};

export type SportsClubTeamMemberDataPlayerPassInput = {
  number: Scalars["String"];
  validity: Scalars["String"];
};

export type SportsClubTeamMemberProfileDataInput = {
  birthday?: InputMaybe<Scalars["Date"]>;
  firstname: Scalars["String"];
  lastname?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Position>;
  shirtNumber?: InputMaybe<Scalars["Int"]>;
};

export type SportsClubTeamMemberTemporary = {
  __typename: "SportsClubTeamMemberTemporary";
  firstname: Scalars["String"];
  lastname: Scalars["String"];
  roles: Array<SportsClubTeamRole>;
  /** tmpId is a simple string id, so we can remove the user from the team by this id */
  tmpId: Scalars["String"];
};

export enum SportsClubTeamRole {
  ATHLETIC_COACH = "ATHLETIC_COACH",
  COACH = "COACH",
  CO_COACH = "CO_COACH",
  PHYSIO = "PHYSIO",
  PLAYER = "PLAYER",
  SUPERVISOR = "SUPERVISOR",
  TREASURER = "TREASURER"
}

export type SportsClubUser = {
  __typename: "SportsClubUser";
  clubRole: SportsClubRole;
  user: User;
};

export type StaminaEnduranceExercise = BaseExercise & {
  __typename: "StaminaEnduranceExercise";
  coverImage: Maybe<Asset>;
  distance: Scalars["Int"];
  duration: Scalars["Int"];
  equipments: Array<Equipment>;
  id: Scalars["String"];
  image: Maybe<Asset>;
  imageGirls: Maybe<Asset>;
  instruction: Scalars["String"];
  keypoints: Array<Scalars["String"]>;
  name: Scalars["String"];
  type: ExerciseType;
  video: Maybe<Asset>;
  videoGirls: Maybe<Asset>;
  videos: Maybe<Array<Asset>>;
};

export type StaminaIntervalExercise = BaseExercise & {
  __typename: "StaminaIntervalExercise";
  coverImage: Maybe<Asset>;
  distance: Scalars["Int"];
  equipments: Array<Equipment>;
  id: Scalars["String"];
  image: Maybe<Asset>;
  imageGirls: Maybe<Asset>;
  instruction: Scalars["String"];
  intervals: Scalars["Int"];
  keypoints: Array<Scalars["String"]>;
  name: Scalars["String"];
  type: ExerciseType;
  video: Maybe<Asset>;
  videoGirls: Maybe<Asset>;
  videos: Maybe<Array<Asset>>;
};

export type StaminaRecoveryExercise = BaseExercise & {
  __typename: "StaminaRecoveryExercise";
  coverImage: Maybe<Asset>;
  duration: Scalars["Int"];
  equipments: Array<Equipment>;
  id: Scalars["String"];
  image: Maybe<Asset>;
  imageGirls: Maybe<Asset>;
  instruction: Scalars["String"];
  keypoints: Array<Scalars["String"]>;
  name: Scalars["String"];
  type: ExerciseType;
  video: Maybe<Asset>;
  videoGirls: Maybe<Asset>;
  videos: Maybe<Array<Asset>>;
};

export type StaminaTargetTimeExercise = BaseExercise & {
  __typename: "StaminaTargetTimeExercise";
  coverImage: Maybe<Asset>;
  duration: Scalars["Int"];
  equipments: Array<Equipment>;
  id: Scalars["String"];
  image: Maybe<Asset>;
  imageGirls: Maybe<Asset>;
  instruction: Scalars["String"];
  keypoints: Array<Scalars["String"]>;
  name: Scalars["String"];
  type: ExerciseType;
  video: Maybe<Asset>;
  videoGirls: Maybe<Asset>;
  videos: Maybe<Array<Asset>>;
};

export type StaminaWorkout = BaseWorkout & {
  __typename: "StaminaWorkout";
  avgUserScore: Maybe<Scalars["Float"]>;
  categories: Array<WorkoutCategory>;
  coverImage: Asset;
  exercises: Array<ExerciseUnion>;
  finalExercises: Maybe<Array<ExerciseUnion>>;
  gender: Maybe<Gender>;
  id: Scalars["String"];
  isFreeWorkout: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  nmbUserVotes: Maybe<Scalars["Int"]>;
  recommended: Maybe<Scalars["Boolean"]>;
  recommendedPositions: Maybe<Array<Position>>;
  /** @deprecated Works not for free area workouts, coach workouts and b42 gamechanger workouts, use workoutReminders instead */
  reminders: Array<WorkoutReminder>;
  /** specific fields */
  rounds: Scalars["Int"];
  source: WorkoutSource;
  tags: Array<WorkoutTag>;
  time: WorkoutTime;
  type: WorkoutType;
  workoutReminders: Array<WorkoutReminderUnion>;
};

/** Deprecated! No longer used and won't be returned by backend. Exists just for compatibility */
export type StartedAppActivity = Activity & {
  __typename: "StartedAppActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export type StartedProgramActivity = Activity & {
  __typename: "StartedProgramActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  program: Maybe<ProgramUnion>;
  reactions: Maybe<Array<ActivityReaction>>;
};

export type StreakStats = {
  __typename: "StreakStats";
  currentStreak: WeeklyStreakProgress;
  currentWeek: WorkoutStats;
  lastWeek: WorkoutStats;
  longestStreak: Scalars["Int"];
};

export type StripeCheckoutSession = {
  __typename: "StripeCheckoutSession";
  url: Scalars["String"];
};

export type StripeCustomerPortalSession = {
  __typename: "StripeCustomerPortalSession";
  url: Scalars["String"];
};

export type Team = {
  __typename: "Team";
  _id: Scalars["ID"];
  /** @deprecated Ambition is no longer used */
  ambition: Maybe<Scalars["String"]>;
  b42Score: Maybe<B42TeamScore>;
  /** Number of workouts done by all members in this week (Mon 0:01 - Sun 23:59) */
  currWeekNmbWorkoutsDone: Scalars["Int"];
  /** Sum of all kilometers run by all members in this week (Mon 0:01 - Sun 23:59) */
  currWeekTotalKMRun: Scalars["Int"];
  gender: Maybe<TeamGender>;
  hasCoach: Scalars["Boolean"];
  logo: Maybe<File>;
  members: Array<TeamMember>;
  name: Scalars["String"];
  /** Number of player with status=Fit */
  nmbFitPlayers: Scalars["Int"];
  /** Number of player with status=Injured */
  nmbInjuredPlayers: Scalars["Int"];
  /** @deprecated No longer supported */
  nmbPlayersWithActiveProgram: Scalars["Int"];
  /** Number of members who have done at least one performance test per category */
  nmbPlayersWithAllPerformanceTestsDone: Scalars["Int"];
  premium: Maybe<TeamPremium>;
  /** @deprecated Ranking is no longer used */
  ranking: Maybe<TeamRanking>;
};

export type TeamActivitiesConnection = {
  __typename: "TeamActivitiesConnection";
  edges: Array<TeamActivityEdge>;
  totalCount: Scalars["Int"];
};

export type TeamActivityEdge = {
  __typename: "TeamActivityEdge";
  cursor: Scalars["ID"];
  node: TeamActivityNode;
};

export type TeamActivityFilter = {
  types: Array<ActivityType>;
};

export type TeamActivityNode = {
  __typename: "TeamActivityNode";
  activity: ActivityUnion;
  user: User;
};

export type TeamAdminAssignedActivity = Activity & {
  __typename: "TeamAdminAssignedActivity";
  _id: Scalars["String"];
  assignedAdmin: User;
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export type TeamAmbitionChangedActivity = Activity & {
  __typename: "TeamAmbitionChangedActivity";
  _id: Scalars["String"];
  ambition: Scalars["String"];
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export type TeamChallenge = {
  __typename: "TeamChallenge";
  categories: Array<ChallengeCategory>;
  createdAt: Scalars["Date"];
  createdBy: User;
  description: Scalars["String"];
  end: Scalars["Date"];
  goal: Scalars["String"];
  id: Scalars["ID"];
  mode: TeamChallengeMode;
  participants: Array<User>;
  start: Scalars["Date"];
  status: ChallengeStatus;
  teamScoreTarget: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
  userScoreTarget: Maybe<Scalars["Int"]>;
};

/** Contains a user leaderboard for one team inside a team challenge */
export type TeamChallengeLeaderboardTeamInternal = {
  __typename: "TeamChallengeLeaderboardTeamInternal";
  leaderboard: ChallengeUserLeaderboard;
  /** ID of this leaderboards team. Useful as cache key */
  teamId: Scalars["ID"];
  /** Optional score the team has to reach internally to win additional things */
  teamScoreTarget: Maybe<TeamChallengeTeamScoreTarget>;
};

export enum TeamChallengeMode {
  INDIVIDUAL = "INDIVIDUAL",
  TEAM = "TEAM"
}

/**
 * Optional score the team has to reach internally to win additional things
 * This score can be different from the score shown in the all teams leaderboard
 */
export type TeamChallengeTeamScoreTarget = {
  __typename: "TeamChallengeTeamScoreTarget";
  /** What the team currently reached */
  reached: Scalars["Int"];
  /** The target a team has to reach */
  target: Scalars["Int"];
};

export enum TeamGender {
  MAN = "MAN",
  MIXED = "MIXED",
  WOMAN = "WOMAN"
}

export type TeamLogoChangedActivity = Activity & {
  __typename: "TeamLogoChangedActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  logo: Maybe<File>;
  reactions: Maybe<Array<ActivityReaction>>;
};

export type TeamMember = {
  __typename: "TeamMember";
  /** If position is coach and user also plays on a pitch, field makes possible to set position as a player */
  playerCoachPosition: Maybe<Position>;
  position: Maybe<Position>;
  positionGroup: Maybe<PositionGroup>;
  role: TeamRole;
  user: User;
};

export type TeamMemberJoinedActivity = Activity & {
  __typename: "TeamMemberJoinedActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export type TeamMemberKickedActivity = Activity & {
  __typename: "TeamMemberKickedActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  kickedMember: User;
  reactions: Maybe<Array<ActivityReaction>>;
};

export type TeamMemberLeftActivity = Activity & {
  __typename: "TeamMemberLeftActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export type TeamMembership = {
  __typename: "TeamMembership";
  playerCoachPosition: Maybe<Position>;
  position: Maybe<Position>;
  positionGroup: Maybe<PositionGroup>;
  role: TeamRole;
  team: Team;
};

export type TeamNameChangedActivity = Activity & {
  __typename: "TeamNameChangedActivity";
  _id: Scalars["String"];
  date: Scalars["Date"];
  name: Scalars["String"];
  reactions: Maybe<Array<ActivityReaction>>;
};

export type TeamPremium = {
  __typename: "TeamPremium";
  expiryDate: Maybe<Scalars["Date"]>;
};

export type TeamRanking = {
  __typename: "TeamRanking";
  global: Maybe<Scalars["Int"]>;
};

export type TeamRankingChallengePayload = {
  endDate: Scalars["Date"];
  filter?: InputMaybe<TeamRankingFilterOptions>;
  name: Scalars["String"];
  startDate: Scalars["Date"];
};

export type TeamRankingConnection = {
  __typename: "TeamRankingConnection";
  currentTeamEdge: Maybe<TeamRankingEdge>;
  edges: Array<TeamRankingEdge>;
  totalCount: Scalars["Int"];
};

export type TeamRankingEdge = {
  __typename: "TeamRankingEdge";
  cursor: Scalars["ID"];
  node: TeamRankingNode;
};

export type TeamRankingFilterInput = {
  challengePayload?: InputMaybe<TeamRankingChallengePayload>;
  filter: RankingFilter;
};

export type TeamRankingFilterOptions = {
  excluded?: InputMaybe<Array<Scalars["ID"]>>;
  maxNmbWorkoutsPerMemberPerDay?: InputMaybe<Scalars["Int"]>;
  minMembers?: InputMaybe<Scalars["Int"]>;
};

export type TeamRankingNode = {
  __typename: "TeamRankingNode";
  /**
   * Number of workouts for each category.
   * Use this instead of calculating it in the frontend,
   * because the backend filters the workouts and than it can happen, that the rank from the backend doens't match the number of workouts displayed in the frontend.
   */
  nmbOfWorkouts: LeaderboardRankingNodeNmbOfWorkouts;
  rank: Scalars["Int"];
  team: Team;
};

export enum TeamRole {
  ADMIN = "ADMIN",
  MEMBER = "MEMBER"
}

export enum TeamTrainingPart {
  COOL_DOWN = "COOL_DOWN",
  MAIN_PART = "MAIN_PART",
  WARMUP = "WARMUP"
}

export type TeamWorkoutStats = {
  __typename: "TeamWorkoutStats";
  nmbCustomWorkouts: Scalars["Int"];
  nmbExternalWorkouts: Scalars["Int"];
  nmbPerformanceTests: Scalars["Int"];
  nmbRunWorkouts: Scalars["Int"];
  nmbWorkouts: Scalars["Int"];
};

export type TeamWorkoutStatsFilter = {
  from: Scalars["Date"];
  to: Scalars["Date"];
};

export enum ThirdPartySignInProviders {
  APPLE = "APPLE",
  FACEBOOK = "FACEBOOK",
  /** Uses facebook graph api */
  FACEBOOK_GRAPH = "FACEBOOK_GRAPH",
  GOOGLE = "GOOGLE"
}

export type TimeBasedExercise = BaseExercise & {
  __typename: "TimeBasedExercise";
  coverImage: Maybe<Asset>;
  durationSeconds: ExerciseDurationsSeconds;
  durationSecondsPerSide: Maybe<Scalars["Boolean"]>;
  equipments: Array<Equipment>;
  id: Scalars["String"];
  /** @deprecated Gender specific Image in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  image: Maybe<Asset>;
  /** @deprecated Gender specific Image in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  imageGirls: Maybe<Asset>;
  instruction: Scalars["String"];
  keypoints: Array<Scalars["String"]>;
  name: Scalars["String"];
  type: ExerciseType;
  /** @deprecated Gender specific video in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  video: Maybe<Asset>;
  /** @deprecated Gender specific video in 16:9 resolution. Is not used anymore since App Version >4.3.0 */
  videoGirls: Maybe<Asset>;
  videos: Maybe<Array<Asset>>;
};

export type TimeBasedUserRankingConnection = {
  __typename: "TimeBasedUserRankingConnection";
  currentUserEdge: Maybe<TimeBasedUserRankingEdge>;
  edges: Array<TimeBasedUserRankingEdge>;
  totalCount: Scalars["Int"];
};

export type TimeBasedUserRankingEdge = {
  __typename: "TimeBasedUserRankingEdge";
  cursor: Scalars["ID"];
  node: TimeBasedUserRankingNode;
};

export type TimeBasedUserRankingNode = {
  __typename: "TimeBasedUserRankingNode";
  bestTimeMillis: Scalars["Float"];
  rank: Scalars["Int"];
  user: User;
};

export type TimeFrame = {
  __typename: "TimeFrame";
  /**
   * For events its the duration of a specific event.
   * E.g. a team training has a duration of 90 mins -> duration = 90 mins in milliseconds
   *
   * For tasks and feedbacks its the duration in which the user can complete the task or submit the feedback.
   * E.g. a coach assigns a workout and the user has from monday till wednesday to complete it. The duration would be 3 days in milliseconds
   * @deprecated Use `duration` on an individual occurrence to get its duration. On the TimeFrame of a complete coach activity this doesn't make sense!
   */
  duration: Scalars["Int"];
  /** Time in UTC 0 */
  endDate: Scalars["Date"];
  /**
   * Time in UTC 0
   *
   * Be aware that the startDate isn't necessarily the date when the event is taking place!
   * For this use `occurrences` field
   */
  startDate: Scalars["Date"];
};

export type TimeFrameInput = {
  /** Duration in millis, default is one hour */
  duration?: InputMaybe<Scalars["Float"]>;
  /** Time in UTC 0 */
  endDate?: InputMaybe<Scalars["Date"]>;
  /** Time in UTC 0 */
  startDate: Scalars["Date"];
  /** IANA time zone name */
  timezone?: InputMaybe<Scalars["String"]>;
};

/** Source of run workout that was created using tracking device */
export enum TrackingSource {
  /** Run was tracked with apple health */
  APPLE = "APPLE",
  /** Run was tracked with fitbit device */
  FITBIT = "FITBIT",
  /** Run was tracked with garmin device */
  GARMIN = "GARMIN",
  /** Workout was entered manual by B42 App */
  MANUAL = "MANUAL",
  /** Run was tracked with polar device */
  POLAR = "POLAR"
}

export type TrainingLoad = {
  __typename: "TrainingLoad";
  _id: Scalars["ID"];
  createdAt: Scalars["Date"];
  load: Scalars["Int"];
  /** @deprecated Use load instead, this outputs from 1-5, new scale from 0-10 */
  trainingLoad: Scalars["Int"];
  userId: Scalars["String"];
};

export type TrainingLoadInput = {
  trainingLoad: Scalars["Int"];
  userId?: InputMaybe<Scalars["ID"]>;
};

export type TrainingLoadUpdateNotification = Notification & {
  __typename: "TrainingLoadUpdateNotification";
  date: Scalars["Date"];
  id: Scalars["String"];
};

export type TreasuryTransaction = {
  __typename: "TreasuryTransaction";
  amount: Scalars["Float"];
  date: Scalars["Date"];
  id: Scalars["ID"];
  info: TreasuryTransactionInfoUnion;
  type: TreasuryTransactionType;
  users: Array<User>;
};

export type TreasuryTransactionEdge = {
  __typename: "TreasuryTransactionEdge";
  cursor: Scalars["ID"];
  node: TreasuryTransactionNode;
};

export type TreasuryTransactionExpenseInfo = {
  __typename: "TreasuryTransactionExpenseInfo";
  description: Maybe<Scalars["String"]>;
  purpose: ExpensePurpose;
};

export type TreasuryTransactionInfoUnion = TreasuryTransactionExpenseInfo | TreasuryTransactionRevenueInfo;

export type TreasuryTransactionInput = {
  amount: Scalars["Float"];
  expense?: InputMaybe<ExpenseTransactionInfoInput>;
  revenue?: InputMaybe<RevenueTransactionInfoInput>;
  type: TreasuryTransactionType;
  users: Array<Scalars["ID"]>;
};

export type TreasuryTransactionNode = {
  __typename: "TreasuryTransactionNode";
  transaction: TreasuryTransaction;
};

export type TreasuryTransactionRevenueInfo = {
  __typename: "TreasuryTransactionRevenueInfo";
  description: Maybe<Scalars["String"]>;
  purpose: RevenuePurpose;
};

export enum TreasuryTransactionType {
  EXPENSE = "EXPENSE",
  REVENUE = "REVENUE"
}

export type TreasuryTransactionsConnection = {
  __typename: "TreasuryTransactionsConnection";
  edges: Array<TreasuryTransactionEdge>;
};

export type UpdateCoachActivityFeedbackInfoInput = {
  description?: InputMaybe<Scalars["String"]>;
};

export type UpdateCoachActivityTaskInfoInput = {
  description?: InputMaybe<Scalars["String"]>;
};

export type UpdateTeamChallengeInput = {
  description: Scalars["String"];
  goal: Scalars["String"];
  title: Scalars["String"];
};

export type User = {
  __typename: "User";
  _id: Scalars["ID"];
  /**  Attribution is where user comes from  */
  attribution: Maybe<UserAttribution>;
  b42Score: B42Score;
  createdAt: Scalars["Date"];
  doubleOptIn: Scalars["Boolean"];
  email: Scalars["String"];
  enabled: Scalars["Boolean"];
  /** @deprecated Use `yearStoryStats` with year parameter instead */
  endOfYearStoryStats: EndOfYearStoryStats;
  /** Following state related to the current user. */
  followState: FollowState;
  followSuggestions: Maybe<Array<FollowSuggestion>>;
  /** Use this field with care. If you resolve it for many users it can have a high performance impact! */
  followers: Array<User>;
  /** Use this field with care. If you resolve it for many users it can have a high performance impact! */
  following: Array<User>;
  hasAppleHealthConnected: Scalars["Boolean"];
  hasFitbitAccount: Scalars["Boolean"];
  hasGarminAccount: Scalars["Boolean"];
  hasPolarAccount: Scalars["Boolean"];
  languageCode: Maybe<Scalars["String"]>;
  lastSession: Maybe<Scalars["Date"]>;
  numberOfWorkouts: NumberOfWorkouts;
  /**
   * This field returns requests from potential followers for current user.
   * Personal data - Only available for current user.
   */
  pendingFollowerRequests: Array<FollowerRequest>;
  /**
   * This field returns following requests for current user.
   * Personal data - Only available for current user.
   */
  pendingFollowingRequests: Array<FollowingRequest>;
  premium: UserPremium;
  ranking: Maybe<UserRanking>;
  signupProvider: Maybe<ThirdPartySignInProviders>;
  tags: Array<Scalars["String"]>;
  /** @deprecated Use trainingLoadsbyRange with new scale */
  trainingLoads: Maybe<Array<TrainingLoad>>;
  trainingLoadsByRange: Array<TrainingLoad>;
  updatedAt: Scalars["Date"];
  /** @deprecated No longer supported */
  usedVouchers: Array<Scalars["String"]>;
  /**  Contains all data that is synced between backend and frontend  */
  userData: UserData;
  userPrivacy: Maybe<UserPrivacy>;
  userStats: UserStats;
  yearStoryStats: EndOfYearStoryStats;
};

export type UserFollowersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserFollowingArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTrainingLoadsByRangeArgs = {
  from: Scalars["Date"];
  to?: InputMaybe<Scalars["Date"]>;
};

export type UserYearStoryStatsArgs = {
  year: Scalars["Int"];
};

export type UserAdIdInput = {
  id: Scalars["String"];
  type: UserAdIdTypes;
};

export type UserAchievements = {
  __typename: "UserAchievements";
  achievedAt: Scalars["Date"];
  achievementId: Scalars["ID"];
  dialogShown: Maybe<Scalars["Boolean"]>;
};

export type UserAdId = {
  __typename: "UserAdID";
  id: Scalars["String"];
  type: UserAdIdTypes;
};

export enum UserAdIdTypes {
  /** @deprecated Adjust isn't used any longer */
  ADJUST_ADID = "ADJUST_ADID",
  GPS_ADID = "GPS_ADID",
  IOS_IDFA = "IOS_IDFA"
}

export type UserAnsweredEventFeedbackNotification = Notification & {
  __typename: "UserAnsweredEventFeedbackNotification";
  coachActivityId: Scalars["String"];
  connectedEventOccurrenceId: Scalars["String"];
  date: Scalars["Date"];
  id: Scalars["String"];
  occurrenceId: Scalars["String"];
};

export type UserAnsweredFeedbackNotification = Notification & {
  __typename: "UserAnsweredFeedbackNotification";
  coachActivityId: Scalars["String"];
  date: Scalars["Date"];
  id: Scalars["String"];
  occurrenceId: Scalars["String"];
};

export type UserAttendeeInput = {
  userId: Scalars["String"];
};

export type UserAttribution = {
  __typename: "UserAttribution";
  campaign: Maybe<Scalars["String"]>;
  costAmount: Maybe<Scalars["Int"]>;
  costCurrency: Maybe<Scalars["String"]>;
  costType: Maybe<Scalars["String"]>;
  medium: Maybe<Scalars["String"]>;
  refByAffiliateId: Maybe<Scalars["String"]>;
  referralId: Scalars["String"];
  referralInviteLink: Scalars["String"];
  referredBy: Maybe<Scalars["String"]>;
  source: Maybe<Scalars["String"]>;
};

export type UserAttributionInput = {
  refByAffiliateId?: InputMaybe<Scalars["String"]>;
  referredBy?: InputMaybe<Scalars["String"]>;
};

export type UserCreatedAbsenceNotification = Notification & {
  __typename: "UserCreatedAbsenceNotification";
  absenceId: Scalars["String"];
  date: Scalars["Date"];
  id: Scalars["String"];
};

export type UserData = {
  __typename: "UserData";
  /** @deprecated No longer supported */
  achievements: Array<Achievement>;
  /** @deprecated No longer supported */
  b42CoachProgramStates: Array<B42CoachProgramState>;
  /** @deprecated Use b42Sore that is in User, not in UserData! */
  b42Score: B42Score;
  cooldownDataRecords: Array<CooldownDataRecord>;
  currentB42Gamechanger: Maybe<B42Gamechanger>;
  currentRtRState: Maybe<RoadToRecoveryState>;
  currentTeamMembership: Maybe<TeamMembership>;
  finishedB42Gamechangers: Array<B42Gamechanger>;
  /** @deprecated No longer supported */
  gamechangerStates: Array<GamechangerState>;
  /** @deprecated No longer supported */
  guideStates: Array<GuideState>;
  onboardingCompleted: Scalars["Boolean"];
  /** @deprecated There is no more test results, so returns only last result */
  performanceTestResults: Array<PerformanceTestResult>;
  /** @deprecated No longer supported */
  preventionProgramStates: Array<PreventionProgramState>;
  /**
   * Returns all workout data records of this user.
   * Use field in program state where possible for better performance (E.g. B42CoachProgramState.finishedWorkouts)
   * @deprecated No longer supported
   */
  profileCompletion: ProfileCompletion;
  profileData: UserProfileData;
  /** @deprecated No need to return all states. Use currentRtRState instead */
  roadToRecoveryStates: Array<RoadToRecoveryState>;
  /** @deprecated Not supported anymore! */
  runWorkoutResults: Array<RunWorkoutResult>;
  /** @deprecated Not supported anymore! Use queries directly */
  singlePerformanceTestResults: Array<SinglePerformanceTestResult>;
  sportsClubMembership: Maybe<SportsClubMembership>;
  teamMemberships: Array<TeamMembership>;
  updatedAt: Scalars["Date"];
  warmupDataRecords: Array<WarmupDataRecord>;
  /** @deprecated No longer supported */
  workoutDataRecordById: Maybe<WorkoutDataRecord>;
  /** @deprecated No longer supported */
  workoutDataRecords: Array<WorkoutDataRecord>;
};

export type UserDataWorkoutDataRecordByIdArgs = {
  id: Scalars["ID"];
};

export type UserFeedback = {
  identifier: Scalars["String"];
  message: Scalars["String"];
};

export type UserFeedbackResponse = {
  __typename: "UserFeedbackResponse";
  success: Scalars["Boolean"];
};

export type UserFinishedCoachAssignedPerformanceTestNotification = Notification & {
  __typename: "UserFinishedCoachAssignedPerformanceTestNotification";
  coachActivityId: Scalars["String"];
  date: Scalars["Date"];
  id: Scalars["String"];
  occurrenceId: Scalars["String"];
  testId: PerformanceTestId;
};

export type UserFinishedCoachAssignedRunWorkoutNotification = Notification & {
  __typename: "UserFinishedCoachAssignedRunWorkoutNotification";
  coachActivityId: Scalars["String"];
  date: Scalars["Date"];
  id: Scalars["String"];
  occurrenceId: Scalars["String"];
  runType: RunType;
};

export type UserFinishedCoachAssignedWorkoutNotification = Notification & {
  __typename: "UserFinishedCoachAssignedWorkoutNotification";
  coachActivityId: Scalars["String"];
  date: Scalars["Date"];
  id: Scalars["String"];
  occurrenceId: Scalars["String"];
  workoutId: Scalars["String"];
  workoutName: Scalars["String"];
};

export type UserFinishedTeamChallengeNotification = Notification & {
  __typename: "UserFinishedTeamChallengeNotification";
  date: Scalars["Date"];
  id: Scalars["String"];
  teamChallengeId: Scalars["ID"];
};

export type UserJoinedSportsClubTeamNotification = Notification & {
  __typename: "UserJoinedSportsClubTeamNotification";
  date: Scalars["Date"];
  id: Scalars["String"];
  sportsClubTeamId: Scalars["ID"];
};

export type UserPremium = {
  __typename: "UserPremium";
  enabled: Maybe<Scalars["Boolean"]>;
  /** @deprecated Rely only on enabled prop */
  expiryDate: Maybe<Scalars["Date"]>;
  hasGamechangerAccess: Maybe<Scalars["Boolean"]>;
  /** @deprecated Rely only on enabled prop */
  type: Maybe<Scalars["String"]>;
};

export type UserPrivacy = {
  __typename: "UserPrivacy";
  visibility: UserPrivacyVisibility;
};

export type UserPrivacyVisibility = {
  __typename: "UserPrivacyVisibility";
  gpsLocation: UserPrivacyVisibilityType;
  profile: UserPrivacyVisibilityType;
  /** @deprecated Not needed anymore */
  workoutActivity: UserPrivacyVisibilityType;
};

export type UserPrivacyVisibilityInput = {
  gpsLocation?: InputMaybe<UserPrivacyVisibilityType>;
  profile?: InputMaybe<UserPrivacyVisibilityType>;
  workoutActivity?: InputMaybe<UserPrivacyVisibilityType>;
};

export enum UserPrivacyVisibilityType {
  ALL = "ALL",
  FOLLOWER = "FOLLOWER",
  PRIVATE = "PRIVATE"
}

export type UserProfileData = {
  __typename: "UserProfileData";
  advancedMode: Scalars["Boolean"];
  ageGroup: Maybe<AgeGroup>;
  ambition: Maybe<Scalars["String"]>;
  birthday: Maybe<Scalars["Date"]>;
  /** @deprecated Use UserData.currentTeamMembership instead */
  club: Maybe<Club>;
  /**
   * If position=='COACH' it's possible to distinguish between different types of coaches
   * @deprecated Use position instead
   */
  coachType: Maybe<CoachType>;
  countryCode: Maybe<Scalars["String"]>;
  /** Used as default difficulty for program recommendation */
  defaultDifficulty: Maybe<WorkoutDifficulty>;
  /** number of training days selected in the onboarding */
  defaultNmbOfTrainingDays: Maybe<Scalars["Int"]>;
  favoriteWorkouts: Array<Scalars["String"]>;
  firstname: Maybe<Scalars["String"]>;
  fitness: Maybe<Scalars["Int"]>;
  fitnessStatus: Maybe<FitnessStatus>;
  focus: Maybe<Focus>;
  foot: Maybe<Foot>;
  /** used for internal handling if we handle user as man or woman */
  gender: Maybe<Gender>;
  /**
   * used for external and visual handling including divers, e.g. in onboarding and editing
   * users with genderidentity divers need to select if they want to be handled as woman or man (gender property)
   */
  genderIdentity: Maybe<GenderIdentity>;
  height: Maybe<Scalars["Int"]>;
  injury: Maybe<Injury>;
  lastname: Maybe<Scalars["String"]>;
  onboardingGoal: Maybe<OnboardingGoal>;
  performanceFocus: Maybe<Array<PerformanceFocus>>;
  personalGoal: Maybe<PersonalGoal>;
  picture: Maybe<File>;
  /** only used for users where main position is player coach */
  playerCoachPosition: Maybe<Position>;
  position: Maybe<Position>;
  /** More general group for users position */
  positionGroup: Maybe<PositionGroup>;
  prevention: Maybe<Prevention>;
  pushNotificationChannels: Maybe<Array<PushNotificationChannels>>;
  seasonPhase: Maybe<SeasonPhase>;
  shirtNumber: Maybe<Scalars["Int"]>;
  weight: Maybe<Scalars["Int"]>;
};

export type UserProfileDataInput = {
  advancedMode?: InputMaybe<Scalars["Boolean"]>;
  ambition?: InputMaybe<Scalars["String"]>;
  birthday?: InputMaybe<Scalars["Date"]>;
  club?: InputMaybe<Scalars["ID"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  defaultDifficulty?: InputMaybe<WorkoutDifficulty>;
  defaultNmbOfTrainingDays?: InputMaybe<Scalars["Int"]>;
  firstname?: InputMaybe<Scalars["String"]>;
  fitness?: InputMaybe<Scalars["Int"]>;
  fitnessStatus?: InputMaybe<FitnessStatus>;
  focus?: InputMaybe<Focus>;
  foot?: InputMaybe<Foot>;
  /**  set this for gender identity DIVERS to man or woman  */
  gender?: InputMaybe<Gender>;
  genderIdentity?: InputMaybe<GenderIdentity>;
  height?: InputMaybe<Scalars["Int"]>;
  injury?: InputMaybe<Injury>;
  lastname?: InputMaybe<Scalars["String"]>;
  onboardingGoal?: InputMaybe<OnboardingGoal>;
  performanceFocus?: InputMaybe<Array<PerformanceFocus>>;
  personalGoal?: InputMaybe<PersonalGoal>;
  /**  ID of profile picture file  */
  picture?: InputMaybe<Scalars["ID"]>;
  /**  position on field for player coaches  */
  playerCoachPosition?: InputMaybe<Position>;
  position?: InputMaybe<Position>;
  prevention?: InputMaybe<Prevention>;
  pushNotificationChannels?: InputMaybe<Array<PushNotificationChannels>>;
  seasonPhase?: InputMaybe<SeasonPhase>;
  shirtNumber?: InputMaybe<Scalars["Int"]>;
  weight?: InputMaybe<Scalars["Int"]>;
};

export type UserRanking = {
  __typename: "UserRanking";
  /** @deprecated Use team instead */
  club: Maybe<Scalars["Int"]>;
  global: Maybe<Scalars["Int"]>;
  team: Maybe<Scalars["Int"]>;
};

export type UserRankingChallengePayload = {
  endDate: Scalars["Date"];
  filter?: InputMaybe<UserRankingFilterOptions>;
  name: Scalars["String"];
  startDate: Scalars["Date"];
};

export type UserRankingConnection = {
  __typename: "UserRankingConnection";
  currentUserEdge: Maybe<UserRankingEdge>;
  edges: Array<UserRankingEdge>;
  totalCount: Scalars["Int"];
};

export type UserRankingEdge = {
  __typename: "UserRankingEdge";
  cursor: Scalars["ID"];
  node: UserRankingNode;
};

export enum UserRankingFilter {
  /** @deprecated Please use universal RankingFilter instead */
  CHALLENGE = "CHALLENGE",
  /** @deprecated Please use universal RankingFilter instead */
  CLUB = "CLUB",
  /** @deprecated Please use universal RankingFilter instead */
  COUNTRY = "COUNTRY",
  /** @deprecated Please use universal RankingFilter instead */
  GLOBAL = "GLOBAL",
  /** @deprecated Please use universal RankingFilter instead */
  TOP10 = "TOP10"
}

export type UserRankingFilterInput = {
  challengePayload?: InputMaybe<UserRankingChallengePayload>;
  filter: RankingFilter;
};

export type UserRankingFilterOptions = {
  languageCode?: InputMaybe<Scalars["String"]>;
  maxNmbWorkoutsPerDay?: InputMaybe<Scalars["Int"]>;
};

export type UserRankingNode = {
  __typename: "UserRankingNode";
  /**
   * Number of workouts for each category.
   * Use this instead of calculating it in the frontend,
   * because the backend filters the workouts and than it can happen, that the rank from the backend doens't match the number of workouts displayed in the frontend.
   */
  nmbOfWorkouts: LeaderboardRankingNodeNmbOfWorkouts;
  rank: Scalars["Int"];
  user: User;
};

export type UserStats = {
  __typename: "UserStats";
  /** @deprecated Not supported anymore */
  nmbAssignedWorkouts: Scalars["Int"];
  /** @deprecated Not supported anymore */
  nmbAssignedWorkoutsDone: Scalars["Int"];
  nmbFollowers: Scalars["Int"];
  nmbFollowings: Scalars["Int"];
  /** Nmb of workouts in last seven days */
  nmbWorkoutsFinishedLastWeek: Scalars["Int"];
  nmbWorkoutsFinishedThisMonth: Scalars["Int"];
  nmbWorkoutsFinishedThisWeek: Scalars["Int"];
  nmbWorkoutsFinishedThisYear: Scalars["Int"];
  /**
   * 0 - 100
   * @deprecated Not supported anymore
   */
  teamTrainingParticipationRate: Scalars["Int"];
};

export type UserSubmittedEventParticipationNotification = Notification & {
  __typename: "UserSubmittedEventParticipationNotification";
  coachActivityId: Scalars["String"];
  date: Scalars["Date"];
  id: Scalars["String"];
  occurrenceId: Scalars["String"];
  voteAnswer: VoteAnswer;
};

export type VideoAd = {
  __typename: "VideoAd";
  VideoAdId: Scalars["ID"];
  callToAction: Maybe<VideoAdCallToAction>;
  order: Maybe<VideoAdOrder>;
  video: Asset;
};

export type VideoAdCallToAction = {
  __typename: "VideoAdCallToAction";
  buttonStyle: ButtonStyle;
  callToActionUrl: Scalars["String"];
  text: Scalars["String"];
};

export enum VideoAdOrder {
  AFTER_WORKOUT = "AFTER_WORKOUT",
  BEFORE_WORKOUT = "BEFORE_WORKOUT"
}

export enum VoteAnswer {
  ACCEPT = "ACCEPT",
  REJECT = "REJECT",
  UNSURE = "UNSURE"
}

export type Voucher = {
  __typename: "Voucher";
  code: Scalars["String"];
  id: Scalars["ID"];
};

export type Warmup = {
  __typename: "Warmup";
  _id: Scalars["String"];
  description: Maybe<Scalars["String"]>;
  duration: Scalars["Int"];
  name: Scalars["String"];
  passes: Scalars["Int"];
  warmupExercises: Array<WarmupExercise>;
};

export type WarmupDataRecord = {
  __typename: "WarmupDataRecord";
  _id: Scalars["ID"];
  date: Scalars["Date"];
  warmupId: Scalars["String"];
};

export type WarmupDataRecordInput = {
  warmupId: Scalars["String"];
};

export type WarmupExercise = {
  __typename: "WarmupExercise";
  duration: Scalars["Int"];
  exercise: ExerciseUnion;
  repetitions: Scalars["Int"];
};

export type WeeklyStreakProgress = {
  __typename: "WeeklyStreakProgress";
  nmbOfWeeks: Scalars["Int"];
  totalThisWeek: Scalars["Int"];
  totalToAchieve: Scalars["Int"];
};

/**  DEPRECATED  */
export type Workout = {
  __typename: "Workout";
  avgUserScore: Maybe<Scalars["Float"]>;
  coverImage: Asset;
  exercises: Array<Exercise>;
  finalExercises: Maybe<Array<Exercise>>;
  id: Scalars["String"];
  name: Scalars["String"];
  nmbUserVotes: Maybe<Scalars["Int"]>;
  passes: WorkoutPasses;
  subType: Maybe<WorkoutSubType>;
  type: WorkoutType;
};

export type WorkoutAverageTimePerDifficulty = {
  __typename: "WorkoutAverageTimePerDifficulty";
  easy: Maybe<Scalars["Int"]>;
  hard: Maybe<Scalars["Int"]>;
  medium: Maybe<Scalars["Int"]>;
};

export enum WorkoutCategory {
  BALL_CONTROL = "BALL_CONTROL",
  BALL_DRIBBLING = "BALL_DRIBBLING",
  BALL_PASSING = "BALL_PASSING",
  BALL_SKILLS = "BALL_SKILLS",
  FITNESS_ENDURANCE = "FITNESS_ENDURANCE",
  FITNESS_GOALKEEPER = "FITNESS_GOALKEEPER",
  FITNESS_PLYO = "FITNESS_PLYO",
  FITNESS_SPEED = "FITNESS_SPEED",
  FITNESS_STRENGTH = "FITNESS_STRENGTH",
  PREVENTION_COOLDOWN = "PREVENTION_COOLDOWN",
  PREVENTION_FASCIA_ROLLER = "PREVENTION_FASCIA_ROLLER",
  PREVENTION_MOBILITY = "PREVENTION_MOBILITY",
  PREVENTION_PREVENTION = "PREVENTION_PREVENTION",
  PREVENTION_STABILITY = "PREVENTION_STABILITY",
  PREVENTION_WARMUP = "PREVENTION_WARMUP",
  REHA_ANKLE = "REHA_ANKLE",
  REHA_BACK = "REHA_BACK",
  REHA_CALF = "REHA_CALF",
  REHA_GROIN = "REHA_GROIN",
  REHA_HAMSTRING = "REHA_HAMSTRING",
  REHA_KNEE = "REHA_KNEE",
  REHA_LOWER_BACK = "REHA_LOWER_BACK",
  REHA_SHOULDER = "REHA_SHOULDER",
  REHA_UPPER_BACK = "REHA_UPPER_BACK",
  RUNNING_ENDURANCE = "RUNNING_ENDURANCE",
  RUNNING_FREE = "RUNNING_FREE",
  RUNNING_INTERVAL = "RUNNING_INTERVAL",
  UNDER_X_MIN = "UNDER_X_MIN"
}

export type WorkoutCount = {
  __typename: "WorkoutCount";
  count: Scalars["Int"];
  workout: Workout;
};

export type WorkoutDataRecord =
  | B42CoachProgramWorkoutDataRecord
  | CoachWorkoutDataRecord
  | FreeAreaWorkoutDataRecord
  | GamechangerChampionWorkoutDataRecord
  | GamechangerRegularWorkoutDataRecord
  | GuideWorkoutDataRecord
  | PreventionProgramWorkoutDataRecord
  | RoadToRecoveryWorkoutDataRecord
  | RtRWorkoutDataRecord;

export enum WorkoutDataRecordArea {
  B42_COACH_PROGRAM = "B42_COACH_PROGRAM",
  COACH_AREA = "COACH_AREA",
  FREE_AREA = "FREE_AREA",
  GAMECHANGER = "GAMECHANGER",
  GUIDE = "GUIDE",
  PREVENTION_PROGRAM = "PREVENTION_PROGRAM",
  ROAD_TO_RECOVERY = "ROAD_TO_RECOVERY"
}

export enum WorkoutDifficulty {
  EASY = "EASY",
  HARD = "HARD",
  MEDIUM = "MEDIUM"
}

export type WorkoutExercise = {
  __typename: "WorkoutExercise";
  coverImage: Maybe<Asset>;
  id: Scalars["String"];
  instruction: Scalars["String"];
  keypoints: Array<Scalars["String"]>;
  name: Scalars["String"];
  type: WorkoutExerciseType;
  videos: Maybe<Array<Asset>>;
};

export enum WorkoutExerciseType {
  BALLCONTROL = "BALLCONTROL",
  BALLSKILLS = "BALLSKILLS",
  /** @deprecated Duplicate of RECOVERY */
  COMEBACK = "COMEBACK",
  COOLDOWN = "COOLDOWN",
  CORE = "CORE",
  DRIBBLING = "DRIBBLING",
  FASCIA_ROLLER = "FASCIA_ROLLER",
  JUMPINGS_LANDINGS_REBOUNDINGS = "JUMPINGS_LANDINGS_REBOUNDINGS",
  KIDS_WARMUP = "KIDS_WARMUP",
  LOWER_BODY_BILITERAL = "LOWER_BODY_BILITERAL",
  LOWER_BODY_UNILITERAL = "LOWER_BODY_UNILITERAL",
  MOBILITY = "MOBILITY",
  PASSING = "PASSING",
  PLYO = "PLYO",
  PRESSURE_HORIZONTAL = "PRESSURE_HORIZONTAL",
  PRESSURE_VERTICAL = "PRESSURE_VERTICAL",
  PREVENTION = "PREVENTION",
  PULL_HORIZONTAL = "PULL_HORIZONTAL",
  PULL_VERTICAL = "PULL_VERTICAL",
  RECOVERY = "RECOVERY",
  SPEED = "SPEED",
  SPEED_OUTDOOR = "SPEED_OUTDOOR",
  STABILITY = "STABILITY",
  STAMINA = "STAMINA",
  STRENGTH = "STRENGTH",
  STRETCHING = "STRETCHING",
  WARMUP = "WARMUP"
}

export type WorkoutMeta = {
  __typename: "WorkoutMeta";
  coverImage: Asset;
  id: Scalars["String"];
  name: Scalars["String"];
  source: WorkoutSource;
  tags: Maybe<Array<WorkoutTag>>;
  type: WorkoutType;
};

export type WorkoutPart = {
  __typename: "WorkoutPart";
  exercises: Array<ExerciseUnion>;
  partName: Maybe<Scalars["String"]>;
  passes: WorkoutPasses;
};

export type WorkoutPasses = {
  __typename: "WorkoutPasses";
  easy: Scalars["Int"];
  hard: Scalars["Int"];
  medium: Scalars["Int"];
};

export enum WorkoutRankingFilter {
  AGE_GROUP = "AGE_GROUP",
  /** @deprecated Please use TEAM instead */
  CLUB = "CLUB",
  GENDER = "GENDER",
  GLOBAL = "GLOBAL",
  TEAM = "TEAM"
}

export type WorkoutRankingFilterInput = {
  filter: WorkoutRankingFilter;
  workoutId: Scalars["String"];
};

export type WorkoutReminder = Reminder & {
  __typename: "WorkoutReminder";
  id: Scalars["ID"];
  reminderDate: Scalars["Date"];
  type: Scalars["String"];
  workoutId: Scalars["ID"];
};

export type WorkoutReminderNotification = Notification & {
  __typename: "WorkoutReminderNotification";
  date: Scalars["Date"];
  id: Scalars["String"];
  reminderId: Scalars["String"];
};

export type WorkoutReminderUnion = B42GamechangerWorkoutReminder | CoachWorkoutReminder | FreeAreaWorkoutReminder;

export type WorkoutResult = {
  __typename: "WorkoutResult";
  /** @deprecated No longer used and very intensive regarding performance. */
  benchmarks: WorkoutResultBenchmarks;
  date: Scalars["Date"];
  /**
   * Elapsed time in milliseconds
   * @deprecated Can't represent large values. Use `elapsedMillisF` instead
   */
  elapsedMillis: Scalars["Int"];
  elapsedMillisF: Scalars["Float"];
  elapsedMillisPerExercise: Maybe<Array<ElapsedMillisForExercise>>;
  id: Scalars["ID"];
  relatedWorkout: WorkoutUnion;
  /** @deprecated Old workout type. Use `relatedWorkout` instead */
  workout: Workout;
  workoutDifficulty: WorkoutDifficulty;
};

export type WorkoutResultBenchmark = {
  __typename: "WorkoutResultBenchmark";
  /** Percentage of users this result is bettern than */
  betterThanXPercent: Scalars["Int"];
  /** @deprecated No longer supported */
  charts: WorkoutResultBenchmarkChart;
  /**
   * Percentile the workout result is in
   * @deprecated Has to be inverted in the frontend and is very confusing to use.
   */
  percentile: Scalars["Int"];
};

/** DEPRECATED */
export type WorkoutResultBenchmarkChart = {
  __typename: "WorkoutResultBenchmarkChart";
  /** @deprecated No longer supported */
  buckets: Array<WorkoutResultBenchmarkChartBucket>;
};

export type WorkoutResultBenchmarkChartBucket = {
  __typename: "WorkoutResultBenchmarkChartBucket";
  lowerTimeBoundary: Scalars["Int"];
  nmbUsers: Scalars["Int"];
  upperTimeBoundary: Scalars["Int"];
};

export type WorkoutResultBenchmarks = {
  __typename: "WorkoutResultBenchmarks";
  /** Benchmark against all users */
  all: Maybe<WorkoutResultBenchmark>;
  /** Benchmark against all users with the same position */
  position: Maybe<WorkoutResultBenchmark>;
  /** Benchmark against team members */
  team: Maybe<WorkoutResultBenchmark>;
};

export enum WorkoutSource {
  B42 = "B42",
  /** Workout was created by coach in coach zone */
  CUSTOM = "CUSTOM"
}

export type WorkoutStats = {
  __typename: "WorkoutStats";
  nmbPerformanceTests: Scalars["Int"];
  nmbWorkouts: Scalars["Int"];
  nmbWorkoutsBall: Scalars["Int"];
  nmbWorkoutsExternal: Scalars["Int"];
  nmbWorkoutsFitness: Scalars["Int"];
  nmbWorkoutsPrevention: Scalars["Int"];
  nmbWorkoutsRunning: Scalars["Int"];
  runningKM: Scalars["Float"];
  trainingSeconds: Scalars["Int"];
};

export enum WorkoutSubType {
  CORE = "CORE",
  FULL_BODY = "FULL_BODY",
  GOALKEEPER = "GOALKEEPER",
  LOWER_BODY = "LOWER_BODY",
  UPPER_BODY = "UPPER_BODY"
}

export enum WorkoutTag {
  ACTIVATION = "ACTIVATION",
  ANKLE = "ANKLE",
  BACK = "BACK",
  BACK_SPINE = "BACK_SPINE",
  CALF = "CALF",
  CHANGE_OF_DIRECTION = "CHANGE_OF_DIRECTION",
  COOLDOWN = "COOLDOWN",
  CORE = "CORE",
  ENDURANCE = "ENDURANCE",
  FRONT_MUSCLE_CHAIN = "FRONT_MUSCLE_CHAIN",
  FULL_BODY = "FULL_BODY",
  GOAL_KICK = "GOAL_KICK",
  GROIN = "GROIN",
  HAMSTRING = "HAMSTRING",
  HIP = "HIP",
  INDOOR = "INDOOR",
  INTERVAL = "INTERVAL",
  KIDS_READY = "KIDS_READY",
  KNEE = "KNEE",
  LEG_AXIS = "LEG_AXIS",
  LOWER_BODY = "LOWER_BODY",
  MOBILITY = "MOBILITY",
  NARROW_SPACE = "NARROW_SPACE",
  ONE_VS_ONE = "ONE_VS_ONE",
  OUTDOOR = "OUTDOOR",
  PLYO = "PLYO",
  POSTERIOR_MUSCLE_CHAIN = "POSTERIOR_MUSCLE_CHAIN",
  PREVENTION = "PREVENTION",
  REGENERATION = "REGENERATION",
  SHOULDER = "SHOULDER",
  SPEED = "SPEED",
  STABILITY = "STABILITY",
  STAMINA = "STAMINA",
  STRENGTH = "STRENGTH",
  TEMPO_RUN = "TEMPO_RUN",
  UPPER_BODY = "UPPER_BODY",
  WARMUP = "WARMUP"
}

export type WorkoutTime = {
  __typename: "WorkoutTime";
  antiFraud: Maybe<Scalars["Int"]>;
  average: Maybe<Scalars["Int"]>;
  averagePerDifficulty: Maybe<WorkoutAverageTimePerDifficulty>;
};

export enum WorkoutType {
  BALLCONTROL = "BALLCONTROL",
  BALLSKILLS = "BALLSKILLS",
  /** @deprecated Duplicate of RECOVERY */
  COMEBACK = "COMEBACK",
  COOLDOWN = "COOLDOWN",
  DRIBBLING = "DRIBBLING",
  FASCIA_ROLLER = "FASCIA_ROLLER",
  MOBILITY = "MOBILITY",
  PASSING = "PASSING",
  PLYO = "PLYO",
  PREVENTION = "PREVENTION",
  RECOVERY = "RECOVERY",
  SPEED = "SPEED",
  SPEED_OUTDOOR = "SPEED_OUTDOOR",
  STABILITY = "STABILITY",
  STAMINA = "STAMINA",
  STRENGTH = "STRENGTH",
  WARMUP = "WARMUP"
}

export type WorkoutUnion = BodyWeightWorkout | SpeedOutdoorWorkout | StaminaWorkout;

export type WorkoutsTypedFilter = {
  types: Array<WorkoutType>;
};

export type SetWorkoutReminderInput = {
  /** If set, workout reminder will be rescheduled */
  id?: InputMaybe<Scalars["ID"]>;
  reminderDate: Scalars["Date"];
  workoutId: Scalars["String"];
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename: "Query";
  user: {
    __typename: "User";
    _id: string;
    userData: { __typename: "UserData"; profileData: { __typename: "UserProfileData"; firstname: string | null; picture: { __typename: "File"; uri: string } | null } };
  } | null;
};

export type GenerateAccessTokenMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateAccessTokenMutation = {
  __typename: "Mutation";
  generateAccessToken: { __typename: "AccessTokenErrorResponse"; code: AccessTokenErrorCode } | { __typename: "AccessTokenGenerateSuccessResponse"; token: string };
};

export type SignInWithAccessTokenMutationVariables = Exact<{
  token: Scalars["String"];
}>;

export type SignInWithAccessTokenMutation = {
  __typename: "Mutation";
  signInWithAccessToken:
    | { __typename: "AccessTokenErrorResponse"; code: AccessTokenErrorCode }
    | { __typename: "AccessTokenRetrieveSuccessResponse"; token: string; userId: string; user: { __typename: "User"; email: string } };
};

export type SignInMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type SignInMutation = {
  __typename: "Mutation";
  signIn:
    | { __typename: "SignInErrorResponse"; code: SignInErrorCodes }
    | {
        __typename: "SignInSignUpSuccessResponse";
        token: string;
        userId: string;
        user: {
          __typename: "User";
          _id: string;
          email: string;
          userData: {
            __typename: "UserData";
            sportsClubMembership: {
              __typename: "SportsClubMembership";
              sportsClub: { __typename: "SportsClub"; _id: string; name: string; logo: { __typename: "File"; uri: string } | null };
            } | null;
          };
        };
      };
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type ForgotPasswordMutation = {
  __typename: "Mutation";
  forgotPassword:
    | { __typename: "ForgotPasswordErrorResponse"; code: ForgotPasswordErrorCodes | null }
    | { __typename: "ForgotPasswordSuccessResponse"; success: boolean | null };
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type ResetPasswordMutation = {
  __typename: "Mutation";
  resetPassword:
    | { __typename: "ResetPasswordErrorResponse"; code: ResetPasswordErrorCodes | null }
    | { __typename: "ResetPasswordSuccessResponse"; success: boolean | null };
};

export type CreateTeamMutationVariables = Exact<{
  name: Scalars["String"];
}>;

export type CreateTeamMutation = { __typename: "Mutation"; createTeam: { __typename: "Team"; _id: string; name: string } };

export type SetProfileDataMutationVariables = Exact<{
  profileData: UserProfileDataInput;
}>;

export type SetProfileDataMutation = {
  __typename: "Mutation";
  setUserProfileData: {
    __typename: "User";
    _id: string;
    userData: {
      __typename: "UserData";
      profileData: {
        __typename: "UserProfileData";
        firstname: string | null;
        fitness: number | null;
        fitnessStatus: FitnessStatus | null;
        injury: Injury | null;
        prevention: Prevention | null;
        positionGroup: PositionGroup | null;
        position: Position | null;
        playerCoachPosition: Position | null;
        picture: { __typename: "File"; id: string; uri: string } | null;
      };
    };
  };
};

export type SignUpMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
  lang: Scalars["String"];
}>;

export type SignUpMutation = {
  __typename: "Mutation";
  signUp:
    | { __typename: "SignInSignUpSuccessResponse"; token: string; userId: string; user: { __typename: "User"; email: string } }
    | { __typename: "SignUpErrorResponse"; code: SignUpErrorCodes };
};

export type CreateStripeCheckoutSessionMutationVariables = Exact<{
  priceId: Scalars["String"];
  successUrl: Scalars["String"];
  cancelUrl: Scalars["String"];
  teamId?: InputMaybe<Scalars["String"]>;
  subscriptionMetadata?: InputMaybe<Array<MetaDataKeyValue> | MetaDataKeyValue>;
}>;

export type CreateStripeCheckoutSessionMutation = { __typename: "Mutation"; createStripeCheckoutSession: { __typename: "StripeCheckoutSession"; url: string } };

export type SignInThirdPartyMutationVariables = Exact<{
  type: ThirdPartySignInProviders;
  token: Scalars["String"];
  lang: Scalars["String"];
}>;

export type SignInThirdPartyMutation = {
  __typename: "Mutation";
  signInThirdParty:
    | {
        __typename: "SignInSignUpSuccessResponse";
        token: string;
        userId: string;
        user: {
          __typename: "User";
          _id: string;
          email: string;
          userData: {
            __typename: "UserData";
            sportsClubMembership: {
              __typename: "SportsClubMembership";
              sportsClub: { __typename: "SportsClub"; _id: string; name: string; logo: { __typename: "File"; uri: string } | null };
            } | null;
          };
        };
      }
    | { __typename: "SignInThirdPartyErrorResponse"; code: SignInThirdPartyErrorCodes };
};

export type InviteUserQueryVariables = Exact<{ [key: string]: never }>;

export type InviteUserQuery = {
  __typename: "Query";
  user: { __typename: "User"; _id: string; userData: { __typename: "UserData"; onboardingCompleted: boolean } } | null;
};

export type BasicSportsClubTeamQueryVariables = Exact<{
  sportsClubId: Scalars["ID"];
  sportsClubTeamId: Scalars["ID"];
}>;

export type BasicSportsClubTeamQuery = {
  __typename: "Query";
  basicSportsClubTeam: {
    __typename: "BasicSportsClubTeam";
    sportsClubId: string;
    sportsClubName: string;
    teamId: string;
    teamName: string;
    logo: { __typename: "File"; uri: string } | null;
  };
  user: {
    __typename: "User";
    _id: string;
    userData: {
      __typename: "UserData";
      onboardingCompleted: boolean;
      sportsClubMembership: {
        __typename: "SportsClubMembership";
        currentSportsClubTeam: { __typename: "SportsClubTeam"; _id: string; name: string } | null;
        sportsClub: { __typename: "SportsClub"; _id: string; name: string; logo: { __typename: "File"; uri: string } | null };
      } | null;
    };
  } | null;
};

export type LeaveSportsClubMutationVariables = Exact<{ [key: string]: never }>;

export type LeaveSportsClubMutation = {
  __typename: "Mutation";
  leaveSportsClub: {
    __typename: "User";
    _id: string;
    userData: {
      __typename: "UserData";
      sportsClubMembership: { __typename: "SportsClubMembership"; currentSportsClubTeam: { __typename: "SportsClubTeam"; _id: string; name: string } | null } | null;
    };
  };
};

export type AccountUserQueryVariables = Exact<{ [key: string]: never }>;

export type AccountUserQuery = { __typename: "Query"; user: { __typename: "User"; _id: string; email: string; languageCode: string | null } | null };

export type UpdateLanguageCodeMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type UpdateLanguageCodeMutation = { __typename: "Mutation"; setLanguageCode: { __typename: "User"; _id: string; languageCode: string | null } };

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type ChangePasswordMutation = { __typename: "Mutation"; changePasswordForUser: boolean | null };

export type MembershipUserDataQueryVariables = Exact<{ [key: string]: never }>;

export type MembershipUserDataQuery = {
  __typename: "Query";
  user: {
    __typename: "User";
    _id: string;
    premium: { __typename: "UserPremium"; enabled: boolean | null };
    userData: {
      __typename: "UserData";
      profileData: { __typename: "UserProfileData"; firstname: string | null; picture: { __typename: "File"; uri: string } | null };
      singlePerformanceTestResults: Array<{ __typename: "SinglePerformanceTestResult"; _id: string }>;
      roadToRecoveryStates: Array<{ __typename: "RoadToRecoveryState"; _id: string; completeDate: number | null }>;
      sportsClubMembership: {
        __typename: "SportsClubMembership";
        currentSportsClubTeam: { __typename: "SportsClubTeam"; _id: string; permissions: Array<FeaturePermission> } | null;
      } | null;
    };
    numberOfWorkouts: { __typename: "NumberOfWorkouts"; total: number };
    b42Score: { __typename: "B42Score"; total: number };
  } | null;
};

export type ProfileUserQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileUserQuery = {
  __typename: "Query";
  user: {
    __typename: "User";
    _id: string;
    email: string;
    userData: {
      __typename: "UserData";
      profileData: {
        __typename: "UserProfileData";
        firstname: string | null;
        lastname: string | null;
        gender: Gender | null;
        genderIdentity: GenderIdentity | null;
        position: Position | null;
        weight: number | null;
        height: number | null;
        foot: Foot | null;
        shirtNumber: number | null;
        birthday: number | null;
        picture: { __typename: "File"; uri: string } | null;
      };
    };
  } | null;
};

export type SaveUserProfileMutationVariables = Exact<{
  userProfileData: UserProfileDataInput;
}>;

export type SaveUserProfileMutation = {
  __typename: "Mutation";
  setUserProfileData: {
    __typename: "User";
    _id: string;
    userData: {
      __typename: "UserData";
      profileData: {
        __typename: "UserProfileData";
        firstname: string | null;
        lastname: string | null;
        gender: Gender | null;
        genderIdentity: GenderIdentity | null;
        position: Position | null;
        weight: number | null;
        height: number | null;
        foot: Foot | null;
        shirtNumber: number | null;
        birthday: number | null;
      };
    };
  };
};

export type UploadProfileImageMutationVariables = Exact<{
  newLogo: Scalars["Upload"];
}>;

export type UploadProfileImageMutation = { __typename: "Mutation"; uploadProfileImage: { __typename: "User"; _id: string } };

export type CurrentUserPremiumQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserPremiumQuery = {
  __typename: "Query";
  user: {
    __typename: "User";
    _id: string;
    signupProvider: ThirdPartySignInProviders | null;
    premium: { __typename: "UserPremium"; enabled: boolean | null; expiryDate: number | null };
  } | null;
};

export type CreateSportsClubMutationVariables = Exact<{
  name: Scalars["String"];
  attribution?: InputMaybe<SportsClubAttributionInput>;
}>;

export type CreateSportsClubMutation = { __typename: "Mutation"; createSportsClub: { __typename: "SportsClub"; _id: string; name: string } };

export type UploadSportsClubLogoMutationVariables = Exact<{
  newLogo: Scalars["Upload"];
}>;

export type UploadSportsClubLogoMutation = {
  __typename: "Mutation";
  changeSportsClubLogo: { __typename: "SportsClub"; _id: string; name: string; logo: { __typename: "File"; uri: string } | null };
};

export type UpdateSportsClubDataMutationVariables = Exact<{
  input: SportsClubInfoInput;
}>;

export type UpdateSportsClubDataMutation = {
  __typename: "Mutation";
  updateSportsClubInfo: { __typename: "SportsClub"; _id: string; name: string; logo: { __typename: "File"; uri: string } | null };
};

export type CreateSportsClubTeamMutationVariables = Exact<{
  name: Scalars["String"];
}>;

export type CreateSportsClubTeamMutation = {
  __typename: "Mutation";
  createTeamInSportsClub: { __typename: "SportsClub"; _id: string; name: string; teams: Array<{ __typename: "SportsClubTeam"; _id: string; name: string }> };
};

export type UpdateSportsClubAcquisitionMutationVariables = Exact<{
  input: SportsClubAcquisitionInput;
}>;

export type UpdateSportsClubAcquisitionMutation = { __typename: "Mutation"; updateSportsClubAcquisition: { __typename: "SportsClub"; _id: string; name: string } };

export type SportsClubTeamInviteLinkQueryVariables = Exact<{
  params?: InputMaybe<SportsClubInviteLinkParams>;
}>;

export type SportsClubTeamInviteLinkQuery = { __typename: "Query"; sportsClubInviteLink: string };

export type JoinSportsClubTeamMutationVariables = Exact<{
  sportsClubId: Scalars["ID"];
  sportsClubTeamId: Scalars["ID"];
  role?: InputMaybe<SportsClubTeamRole>;
}>;

export type JoinSportsClubTeamMutation = {
  __typename: "Mutation";
  joinSportsClubTeam: {
    __typename: "User";
    _id: string;
    userData: {
      __typename: "UserData";
      sportsClubMembership: {
        __typename: "SportsClubMembership";
        currentSportsClubTeam: {
          __typename: "SportsClubTeam";
          _id: string;
          name: string;
          membersTemporary: Array<{
            __typename: "SportsClubTeamMemberTemporary";
            tmpId: string;
            firstname: string;
            lastname: string;
            roles: Array<SportsClubTeamRole>;
          }> | null;
        } | null;
      } | null;
    };
  };
};

export type SendTeamInviteByMailMutationVariables = Exact<{
  input: SportsClubTeamInviteLinkByMailInput;
}>;

export type SendTeamInviteByMailMutation = {
  __typename: "Mutation";
  sendSportsClubTeamInviteLinkByMail:
    | { __typename: "SportsClubTeamInviteLinkByMailErrorResponse"; code: SportsClubTeamInviteLinkByMailErrorCodes | null }
    | { __typename: "SportsClubTeamInviteLinkByMailSuccessResponse"; inviteLink: string }
    | null;
};

export type CompleteOnboardingMutationVariables = Exact<{ [key: string]: never }>;

export type CompleteOnboardingMutation = {
  __typename: "Mutation";
  setOnboardingCompleted: { __typename: "User"; _id: string; userData: { __typename: "UserData"; onboardingCompleted: boolean } };
};

export type UpdateUserLanguageMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type UpdateUserLanguageMutation = { __typename: "Mutation"; setLanguageCode: { __typename: "User"; _id: string; languageCode: string | null } };

export type OnboardingUserQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardingUserQuery = {
  __typename: "Query";
  user: { __typename: "User"; _id: string; premium: { __typename: "UserPremium"; enabled: boolean | null } } | null;
};

export type SetAttributionMutationVariables = Exact<{
  input: UserAttributionInput;
}>;

export type SetAttributionMutation = { __typename: "Mutation"; setUserAttribution: { __typename: "User"; _id: string } };

export type SportsClubTeamTemporaryUsersQueryVariables = Exact<{ [key: string]: never }>;

export type SportsClubTeamTemporaryUsersQuery = {
  __typename: "Query";
  user: {
    __typename: "User";
    _id: string;
    userData: {
      __typename: "UserData";
      sportsClubMembership: {
        __typename: "SportsClubMembership";
        currentSportsClubTeam: {
          __typename: "SportsClubTeam";
          _id: string;
          membersTemporary: Array<{
            __typename: "SportsClubTeamMemberTemporary";
            tmpId: string;
            firstname: string;
            lastname: string;
            roles: Array<SportsClubTeamRole>;
          }> | null;
        } | null;
      } | null;
    };
  } | null;
};

export type RemoveSportsClubTeamTemporaryUserMutationVariables = Exact<{
  teamId: Scalars["ID"];
  tmpId: Scalars["String"];
}>;

export type RemoveSportsClubTeamTemporaryUserMutation = { __typename: "Mutation"; removeTemporaryUserFromTeamInSportsClub: { __typename: "SportsClub"; _id: string } };

export type TeamOnboardingUserQueryVariables = Exact<{ [key: string]: never }>;

export type TeamOnboardingUserQuery = {
  __typename: "Query";
  user: {
    __typename: "User";
    _id: string;
    userData: {
      __typename: "UserData";
      sportsClubMembership: {
        __typename: "SportsClubMembership";
        sportsClub: { __typename: "SportsClub"; _id: string; name: string; logo: { __typename: "File"; uri: string } | null };
      } | null;
    };
  } | null;
};

export const CurrentUserDocument = gql`
  query CurrentUser {
    user {
      _id
      userData {
        profileData {
          firstname
          picture {
            uri
          }
        }
      }
    }
  }
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const GenerateAccessTokenDocument = gql`
  mutation GenerateAccessToken {
    generateAccessToken {
      ... on AccessTokenGenerateSuccessResponse {
        token
      }
      ... on AccessTokenErrorResponse {
        code
      }
    }
  }
`;
export type GenerateAccessTokenMutationFn = Apollo.MutationFunction<GenerateAccessTokenMutation, GenerateAccessTokenMutationVariables>;

/**
 * __useGenerateAccessTokenMutation__
 *
 * To run a mutation, you first call `useGenerateAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAccessTokenMutation, { data, loading, error }] = useGenerateAccessTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateAccessTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateAccessTokenMutation, GenerateAccessTokenMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<GenerateAccessTokenMutation, GenerateAccessTokenMutationVariables>(GenerateAccessTokenDocument, options);
}
export type GenerateAccessTokenMutationHookResult = ReturnType<typeof useGenerateAccessTokenMutation>;
export type GenerateAccessTokenMutationResult = Apollo.MutationResult<GenerateAccessTokenMutation>;
export type GenerateAccessTokenMutationOptions = Apollo.BaseMutationOptions<GenerateAccessTokenMutation, GenerateAccessTokenMutationVariables>;
export const SignInWithAccessTokenDocument = gql`
  mutation SignInWithAccessToken($token: String!) {
    signInWithAccessToken(token: $token) {
      ... on AccessTokenRetrieveSuccessResponse {
        token
        userId
        user {
          email
        }
      }
      ... on AccessTokenErrorResponse {
        code
      }
    }
  }
`;
export type SignInWithAccessTokenMutationFn = Apollo.MutationFunction<SignInWithAccessTokenMutation, SignInWithAccessTokenMutationVariables>;

/**
 * __useSignInWithAccessTokenMutation__
 *
 * To run a mutation, you first call `useSignInWithAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInWithAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInWithAccessTokenMutation, { data, loading, error }] = useSignInWithAccessTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSignInWithAccessTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SignInWithAccessTokenMutation, SignInWithAccessTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SignInWithAccessTokenMutation, SignInWithAccessTokenMutationVariables>(SignInWithAccessTokenDocument, options);
}
export type SignInWithAccessTokenMutationHookResult = ReturnType<typeof useSignInWithAccessTokenMutation>;
export type SignInWithAccessTokenMutationResult = Apollo.MutationResult<SignInWithAccessTokenMutation>;
export type SignInWithAccessTokenMutationOptions = Apollo.BaseMutationOptions<SignInWithAccessTokenMutation, SignInWithAccessTokenMutationVariables>;
export const SignInDocument = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      ... on SignInSignUpSuccessResponse {
        token
        userId
        user {
          _id
          email
          userData {
            sportsClubMembership {
              sportsClub {
                _id
                name
                logo {
                  uri
                }
              }
            }
          }
        }
      }
      ... on SignInErrorResponse {
        code
      }
    }
  }
`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ... on ForgotPasswordSuccessResponse {
        success
      }
      ... on ForgotPasswordErrorResponse {
        code
      }
    }
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword) {
      ... on ResetPasswordSuccessResponse {
        success
      }
      ... on ResetPasswordErrorResponse {
        code
      }
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const CreateTeamDocument = gql`
  mutation CreateTeam($name: String!) {
    createTeam(name: $name) {
      _id
      name
    }
  }
`;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, options);
}
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const SetProfileDataDocument = gql`
  mutation setProfileData($profileData: UserProfileDataInput!) {
    setUserProfileData(userProfileData: $profileData) {
      _id
      userData {
        profileData {
          firstname
          fitness
          fitnessStatus
          injury
          prevention
          picture {
            id
            uri
          }
          positionGroup
          position
          playerCoachPosition
        }
      }
    }
  }
`;
export type SetProfileDataMutationFn = Apollo.MutationFunction<SetProfileDataMutation, SetProfileDataMutationVariables>;

/**
 * __useSetProfileDataMutation__
 *
 * To run a mutation, you first call `useSetProfileDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProfileDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProfileDataMutation, { data, loading, error }] = useSetProfileDataMutation({
 *   variables: {
 *      profileData: // value for 'profileData'
 *   },
 * });
 */
export function useSetProfileDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetProfileDataMutation, SetProfileDataMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SetProfileDataMutation, SetProfileDataMutationVariables>(SetProfileDataDocument, options);
}
export type SetProfileDataMutationHookResult = ReturnType<typeof useSetProfileDataMutation>;
export type SetProfileDataMutationResult = Apollo.MutationResult<SetProfileDataMutation>;
export type SetProfileDataMutationOptions = Apollo.BaseMutationOptions<SetProfileDataMutation, SetProfileDataMutationVariables>;
export const SignUpDocument = gql`
  mutation signUp($email: String!, $password: String!, $lang: String!) {
    signUp(email: $email, password: $password, lang: $lang) {
      ... on SignInSignUpSuccessResponse {
        token
        userId
        user {
          email
        }
      }
      ... on SignUpErrorResponse {
        code
      }
    }
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const CreateStripeCheckoutSessionDocument = gql`
  mutation createStripeCheckoutSession($priceId: String!, $successUrl: String!, $cancelUrl: String!, $teamId: String, $subscriptionMetadata: [MetaDataKeyValue!]) {
    createStripeCheckoutSession(priceId: $priceId, successUrl: $successUrl, cancelUrl: $cancelUrl, teamId: $teamId, subscriptionMetadata: $subscriptionMetadata) {
      url
    }
  }
`;
export type CreateStripeCheckoutSessionMutationFn = Apollo.MutationFunction<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;

/**
 * __useCreateStripeCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCheckoutSessionMutation, { data, loading, error }] = useCreateStripeCheckoutSessionMutation({
 *   variables: {
 *      priceId: // value for 'priceId'
 *      successUrl: // value for 'successUrl'
 *      cancelUrl: // value for 'cancelUrl'
 *      teamId: // value for 'teamId'
 *      subscriptionMetadata: // value for 'subscriptionMetadata'
 *   },
 * });
 */
export function useCreateStripeCheckoutSessionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>(CreateStripeCheckoutSessionDocument, options);
}
export type CreateStripeCheckoutSessionMutationHookResult = ReturnType<typeof useCreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationResult = Apollo.MutationResult<CreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;
export const SignInThirdPartyDocument = gql`
  mutation signInThirdParty($type: ThirdPartySignInProviders!, $token: String!, $lang: String!) {
    signInThirdParty(type: $type, token: $token, lang: $lang) {
      __typename
      ... on SignInSignUpSuccessResponse {
        token
        userId
        user {
          _id
          email
          userData {
            sportsClubMembership {
              sportsClub {
                _id
                name
                logo {
                  uri
                }
              }
            }
          }
        }
      }
      ... on SignInThirdPartyErrorResponse {
        code
      }
    }
  }
`;
export type SignInThirdPartyMutationFn = Apollo.MutationFunction<SignInThirdPartyMutation, SignInThirdPartyMutationVariables>;

/**
 * __useSignInThirdPartyMutation__
 *
 * To run a mutation, you first call `useSignInThirdPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInThirdPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInThirdPartyMutation, { data, loading, error }] = useSignInThirdPartyMutation({
 *   variables: {
 *      type: // value for 'type'
 *      token: // value for 'token'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSignInThirdPartyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignInThirdPartyMutation, SignInThirdPartyMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SignInThirdPartyMutation, SignInThirdPartyMutationVariables>(SignInThirdPartyDocument, options);
}
export type SignInThirdPartyMutationHookResult = ReturnType<typeof useSignInThirdPartyMutation>;
export type SignInThirdPartyMutationResult = Apollo.MutationResult<SignInThirdPartyMutation>;
export type SignInThirdPartyMutationOptions = Apollo.BaseMutationOptions<SignInThirdPartyMutation, SignInThirdPartyMutationVariables>;
export const InviteUserDocument = gql`
  query InviteUser {
    user {
      _id
      userData {
        onboardingCompleted
      }
    }
  }
`;

/**
 * __useInviteUserQuery__
 *
 * To run a query within a React component, call `useInviteUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useInviteUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InviteUserQuery, InviteUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<InviteUserQuery, InviteUserQueryVariables>(InviteUserDocument, options);
}
export function useInviteUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InviteUserQuery, InviteUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<InviteUserQuery, InviteUserQueryVariables>(InviteUserDocument, options);
}
export type InviteUserQueryHookResult = ReturnType<typeof useInviteUserQuery>;
export type InviteUserLazyQueryHookResult = ReturnType<typeof useInviteUserLazyQuery>;
export type InviteUserQueryResult = Apollo.QueryResult<InviteUserQuery, InviteUserQueryVariables>;
export const BasicSportsClubTeamDocument = gql`
  query BasicSportsClubTeam($sportsClubId: ID!, $sportsClubTeamId: ID!) {
    basicSportsClubTeam(sportsClubId: $sportsClubId, sportsClubTeamId: $sportsClubTeamId) {
      sportsClubId
      sportsClubName
      teamId
      teamName
      logo {
        uri
      }
    }
    user {
      _id
      userData {
        sportsClubMembership {
          currentSportsClubTeam {
            _id
            name
          }
          sportsClub {
            _id
            name
            logo {
              uri
            }
          }
        }
        onboardingCompleted
      }
    }
  }
`;

/**
 * __useBasicSportsClubTeamQuery__
 *
 * To run a query within a React component, call `useBasicSportsClubTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicSportsClubTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicSportsClubTeamQuery({
 *   variables: {
 *      sportsClubId: // value for 'sportsClubId'
 *      sportsClubTeamId: // value for 'sportsClubTeamId'
 *   },
 * });
 */
export function useBasicSportsClubTeamQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BasicSportsClubTeamQuery, BasicSportsClubTeamQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BasicSportsClubTeamQuery, BasicSportsClubTeamQueryVariables>(BasicSportsClubTeamDocument, options);
}
export function useBasicSportsClubTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BasicSportsClubTeamQuery, BasicSportsClubTeamQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BasicSportsClubTeamQuery, BasicSportsClubTeamQueryVariables>(BasicSportsClubTeamDocument, options);
}
export type BasicSportsClubTeamQueryHookResult = ReturnType<typeof useBasicSportsClubTeamQuery>;
export type BasicSportsClubTeamLazyQueryHookResult = ReturnType<typeof useBasicSportsClubTeamLazyQuery>;
export type BasicSportsClubTeamQueryResult = Apollo.QueryResult<BasicSportsClubTeamQuery, BasicSportsClubTeamQueryVariables>;
export const LeaveSportsClubDocument = gql`
  mutation LeaveSportsClub {
    leaveSportsClub {
      _id
      userData {
        sportsClubMembership {
          currentSportsClubTeam {
            _id
            name
          }
        }
      }
    }
  }
`;
export type LeaveSportsClubMutationFn = Apollo.MutationFunction<LeaveSportsClubMutation, LeaveSportsClubMutationVariables>;

/**
 * __useLeaveSportsClubMutation__
 *
 * To run a mutation, you first call `useLeaveSportsClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveSportsClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveSportsClubMutation, { data, loading, error }] = useLeaveSportsClubMutation({
 *   variables: {
 *   },
 * });
 */
export function useLeaveSportsClubMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveSportsClubMutation, LeaveSportsClubMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LeaveSportsClubMutation, LeaveSportsClubMutationVariables>(LeaveSportsClubDocument, options);
}
export type LeaveSportsClubMutationHookResult = ReturnType<typeof useLeaveSportsClubMutation>;
export type LeaveSportsClubMutationResult = Apollo.MutationResult<LeaveSportsClubMutation>;
export type LeaveSportsClubMutationOptions = Apollo.BaseMutationOptions<LeaveSportsClubMutation, LeaveSportsClubMutationVariables>;
export const AccountUserDocument = gql`
  query AccountUser {
    user {
      _id
      email
      languageCode
    }
  }
`;

/**
 * __useAccountUserQuery__
 *
 * To run a query within a React component, call `useAccountUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountUserQuery, AccountUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AccountUserQuery, AccountUserQueryVariables>(AccountUserDocument, options);
}
export function useAccountUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountUserQuery, AccountUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AccountUserQuery, AccountUserQueryVariables>(AccountUserDocument, options);
}
export type AccountUserQueryHookResult = ReturnType<typeof useAccountUserQuery>;
export type AccountUserLazyQueryHookResult = ReturnType<typeof useAccountUserLazyQuery>;
export type AccountUserQueryResult = Apollo.QueryResult<AccountUserQuery, AccountUserQueryVariables>;
export const UpdateLanguageCodeDocument = gql`
  mutation UpdateLanguageCode($code: String!) {
    setLanguageCode(code: $code) {
      _id
      languageCode
    }
  }
`;
export type UpdateLanguageCodeMutationFn = Apollo.MutationFunction<UpdateLanguageCodeMutation, UpdateLanguageCodeMutationVariables>;

/**
 * __useUpdateLanguageCodeMutation__
 *
 * To run a mutation, you first call `useUpdateLanguageCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLanguageCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLanguageCodeMutation, { data, loading, error }] = useUpdateLanguageCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useUpdateLanguageCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLanguageCodeMutation, UpdateLanguageCodeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateLanguageCodeMutation, UpdateLanguageCodeMutationVariables>(UpdateLanguageCodeDocument, options);
}
export type UpdateLanguageCodeMutationHookResult = ReturnType<typeof useUpdateLanguageCodeMutation>;
export type UpdateLanguageCodeMutationResult = Apollo.MutationResult<UpdateLanguageCodeMutation>;
export type UpdateLanguageCodeMutationOptions = Apollo.BaseMutationOptions<UpdateLanguageCodeMutation, UpdateLanguageCodeMutationVariables>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePasswordForUser(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const MembershipUserDataDocument = gql`
  query MembershipUserData {
    user {
      _id
      premium {
        enabled
      }
      userData {
        profileData {
          firstname
          picture {
            uri
          }
        }
        singlePerformanceTestResults {
          _id
        }
        roadToRecoveryStates {
          _id
          completeDate
        }
        sportsClubMembership {
          currentSportsClubTeam {
            _id
            permissions
          }
        }
      }
      numberOfWorkouts {
        total
      }
      b42Score {
        total
      }
    }
  }
`;

/**
 * __useMembershipUserDataQuery__
 *
 * To run a query within a React component, call `useMembershipUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipUserDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MembershipUserDataQuery, MembershipUserDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MembershipUserDataQuery, MembershipUserDataQueryVariables>(MembershipUserDataDocument, options);
}
export function useMembershipUserDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MembershipUserDataQuery, MembershipUserDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MembershipUserDataQuery, MembershipUserDataQueryVariables>(MembershipUserDataDocument, options);
}
export type MembershipUserDataQueryHookResult = ReturnType<typeof useMembershipUserDataQuery>;
export type MembershipUserDataLazyQueryHookResult = ReturnType<typeof useMembershipUserDataLazyQuery>;
export type MembershipUserDataQueryResult = Apollo.QueryResult<MembershipUserDataQuery, MembershipUserDataQueryVariables>;
export const ProfileUserDocument = gql`
  query ProfileUser {
    user {
      _id
      email
      userData {
        profileData {
          firstname
          lastname
          gender
          genderIdentity
          position
          weight
          height
          foot
          picture {
            uri
          }
          shirtNumber
          birthday
        }
      }
    }
  }
`;

/**
 * __useProfileUserQuery__
 *
 * To run a query within a React component, call `useProfileUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileUserQuery, ProfileUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProfileUserQuery, ProfileUserQueryVariables>(ProfileUserDocument, options);
}
export function useProfileUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileUserQuery, ProfileUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProfileUserQuery, ProfileUserQueryVariables>(ProfileUserDocument, options);
}
export type ProfileUserQueryHookResult = ReturnType<typeof useProfileUserQuery>;
export type ProfileUserLazyQueryHookResult = ReturnType<typeof useProfileUserLazyQuery>;
export type ProfileUserQueryResult = Apollo.QueryResult<ProfileUserQuery, ProfileUserQueryVariables>;
export const SaveUserProfileDocument = gql`
  mutation SaveUserProfile($userProfileData: UserProfileDataInput!) {
    setUserProfileData(userProfileData: $userProfileData) {
      _id
      userData {
        profileData {
          firstname
          lastname
          gender
          genderIdentity
          position
          weight
          height
          foot
          shirtNumber
          birthday
        }
      }
    }
  }
`;
export type SaveUserProfileMutationFn = Apollo.MutationFunction<SaveUserProfileMutation, SaveUserProfileMutationVariables>;

/**
 * __useSaveUserProfileMutation__
 *
 * To run a mutation, you first call `useSaveUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserProfileMutation, { data, loading, error }] = useSaveUserProfileMutation({
 *   variables: {
 *      userProfileData: // value for 'userProfileData'
 *   },
 * });
 */
export function useSaveUserProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveUserProfileMutation, SaveUserProfileMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveUserProfileMutation, SaveUserProfileMutationVariables>(SaveUserProfileDocument, options);
}
export type SaveUserProfileMutationHookResult = ReturnType<typeof useSaveUserProfileMutation>;
export type SaveUserProfileMutationResult = Apollo.MutationResult<SaveUserProfileMutation>;
export type SaveUserProfileMutationOptions = Apollo.BaseMutationOptions<SaveUserProfileMutation, SaveUserProfileMutationVariables>;
export const UploadProfileImageDocument = gql`
  mutation UploadProfileImage($newLogo: Upload!) {
    uploadProfileImage(file: $newLogo) {
      _id
    }
  }
`;
export type UploadProfileImageMutationFn = Apollo.MutationFunction<UploadProfileImageMutation, UploadProfileImageMutationVariables>;

/**
 * __useUploadProfileImageMutation__
 *
 * To run a mutation, you first call `useUploadProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfileImageMutation, { data, loading, error }] = useUploadProfileImageMutation({
 *   variables: {
 *      newLogo: // value for 'newLogo'
 *   },
 * });
 */
export function useUploadProfileImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadProfileImageMutation, UploadProfileImageMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UploadProfileImageMutation, UploadProfileImageMutationVariables>(UploadProfileImageDocument, options);
}
export type UploadProfileImageMutationHookResult = ReturnType<typeof useUploadProfileImageMutation>;
export type UploadProfileImageMutationResult = Apollo.MutationResult<UploadProfileImageMutation>;
export type UploadProfileImageMutationOptions = Apollo.BaseMutationOptions<UploadProfileImageMutation, UploadProfileImageMutationVariables>;
export const CurrentUserPremiumDocument = gql`
  query CurrentUserPremium {
    user {
      _id
      premium {
        enabled
        expiryDate
      }
      signupProvider
    }
  }
`;

/**
 * __useCurrentUserPremiumQuery__
 *
 * To run a query within a React component, call `useCurrentUserPremiumQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserPremiumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserPremiumQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserPremiumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserPremiumQuery, CurrentUserPremiumQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CurrentUserPremiumQuery, CurrentUserPremiumQueryVariables>(CurrentUserPremiumDocument, options);
}
export function useCurrentUserPremiumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserPremiumQuery, CurrentUserPremiumQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CurrentUserPremiumQuery, CurrentUserPremiumQueryVariables>(CurrentUserPremiumDocument, options);
}
export type CurrentUserPremiumQueryHookResult = ReturnType<typeof useCurrentUserPremiumQuery>;
export type CurrentUserPremiumLazyQueryHookResult = ReturnType<typeof useCurrentUserPremiumLazyQuery>;
export type CurrentUserPremiumQueryResult = Apollo.QueryResult<CurrentUserPremiumQuery, CurrentUserPremiumQueryVariables>;
export const CreateSportsClubDocument = gql`
  mutation CreateSportsClub($name: String!, $attribution: SportsClubAttributionInput) {
    createSportsClub(name: $name, attribution: $attribution) {
      _id
      name
    }
  }
`;
export type CreateSportsClubMutationFn = Apollo.MutationFunction<CreateSportsClubMutation, CreateSportsClubMutationVariables>;

/**
 * __useCreateSportsClubMutation__
 *
 * To run a mutation, you first call `useCreateSportsClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSportsClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSportsClubMutation, { data, loading, error }] = useCreateSportsClubMutation({
 *   variables: {
 *      name: // value for 'name'
 *      attribution: // value for 'attribution'
 *   },
 * });
 */
export function useCreateSportsClubMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSportsClubMutation, CreateSportsClubMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateSportsClubMutation, CreateSportsClubMutationVariables>(CreateSportsClubDocument, options);
}
export type CreateSportsClubMutationHookResult = ReturnType<typeof useCreateSportsClubMutation>;
export type CreateSportsClubMutationResult = Apollo.MutationResult<CreateSportsClubMutation>;
export type CreateSportsClubMutationOptions = Apollo.BaseMutationOptions<CreateSportsClubMutation, CreateSportsClubMutationVariables>;
export const UploadSportsClubLogoDocument = gql`
  mutation UploadSportsClubLogo($newLogo: Upload!) {
    changeSportsClubLogo(newLogo: $newLogo) {
      _id
      name
      logo {
        uri
      }
    }
  }
`;
export type UploadSportsClubLogoMutationFn = Apollo.MutationFunction<UploadSportsClubLogoMutation, UploadSportsClubLogoMutationVariables>;

/**
 * __useUploadSportsClubLogoMutation__
 *
 * To run a mutation, you first call `useUploadSportsClubLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadSportsClubLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadSportsClubLogoMutation, { data, loading, error }] = useUploadSportsClubLogoMutation({
 *   variables: {
 *      newLogo: // value for 'newLogo'
 *   },
 * });
 */
export function useUploadSportsClubLogoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadSportsClubLogoMutation, UploadSportsClubLogoMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UploadSportsClubLogoMutation, UploadSportsClubLogoMutationVariables>(UploadSportsClubLogoDocument, options);
}
export type UploadSportsClubLogoMutationHookResult = ReturnType<typeof useUploadSportsClubLogoMutation>;
export type UploadSportsClubLogoMutationResult = Apollo.MutationResult<UploadSportsClubLogoMutation>;
export type UploadSportsClubLogoMutationOptions = Apollo.BaseMutationOptions<UploadSportsClubLogoMutation, UploadSportsClubLogoMutationVariables>;
export const UpdateSportsClubDataDocument = gql`
  mutation UpdateSportsClubData($input: SportsClubInfoInput!) {
    updateSportsClubInfo(input: $input) {
      _id
      name
      logo {
        uri
      }
    }
  }
`;
export type UpdateSportsClubDataMutationFn = Apollo.MutationFunction<UpdateSportsClubDataMutation, UpdateSportsClubDataMutationVariables>;

/**
 * __useUpdateSportsClubDataMutation__
 *
 * To run a mutation, you first call `useUpdateSportsClubDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSportsClubDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSportsClubDataMutation, { data, loading, error }] = useUpdateSportsClubDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSportsClubDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSportsClubDataMutation, UpdateSportsClubDataMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateSportsClubDataMutation, UpdateSportsClubDataMutationVariables>(UpdateSportsClubDataDocument, options);
}
export type UpdateSportsClubDataMutationHookResult = ReturnType<typeof useUpdateSportsClubDataMutation>;
export type UpdateSportsClubDataMutationResult = Apollo.MutationResult<UpdateSportsClubDataMutation>;
export type UpdateSportsClubDataMutationOptions = Apollo.BaseMutationOptions<UpdateSportsClubDataMutation, UpdateSportsClubDataMutationVariables>;
export const CreateSportsClubTeamDocument = gql`
  mutation CreateSportsClubTeam($name: String!) {
    createTeamInSportsClub(name: $name) {
      _id
      name
      teams {
        _id
        name
      }
    }
  }
`;
export type CreateSportsClubTeamMutationFn = Apollo.MutationFunction<CreateSportsClubTeamMutation, CreateSportsClubTeamMutationVariables>;

/**
 * __useCreateSportsClubTeamMutation__
 *
 * To run a mutation, you first call `useCreateSportsClubTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSportsClubTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSportsClubTeamMutation, { data, loading, error }] = useCreateSportsClubTeamMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateSportsClubTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSportsClubTeamMutation, CreateSportsClubTeamMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateSportsClubTeamMutation, CreateSportsClubTeamMutationVariables>(CreateSportsClubTeamDocument, options);
}
export type CreateSportsClubTeamMutationHookResult = ReturnType<typeof useCreateSportsClubTeamMutation>;
export type CreateSportsClubTeamMutationResult = Apollo.MutationResult<CreateSportsClubTeamMutation>;
export type CreateSportsClubTeamMutationOptions = Apollo.BaseMutationOptions<CreateSportsClubTeamMutation, CreateSportsClubTeamMutationVariables>;
export const UpdateSportsClubAcquisitionDocument = gql`
  mutation UpdateSportsClubAcquisition($input: SportsClubAcquisitionInput!) {
    updateSportsClubAcquisition(input: $input) {
      _id
      name
    }
  }
`;
export type UpdateSportsClubAcquisitionMutationFn = Apollo.MutationFunction<UpdateSportsClubAcquisitionMutation, UpdateSportsClubAcquisitionMutationVariables>;

/**
 * __useUpdateSportsClubAcquisitionMutation__
 *
 * To run a mutation, you first call `useUpdateSportsClubAcquisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSportsClubAcquisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSportsClubAcquisitionMutation, { data, loading, error }] = useUpdateSportsClubAcquisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSportsClubAcquisitionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSportsClubAcquisitionMutation, UpdateSportsClubAcquisitionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateSportsClubAcquisitionMutation, UpdateSportsClubAcquisitionMutationVariables>(UpdateSportsClubAcquisitionDocument, options);
}
export type UpdateSportsClubAcquisitionMutationHookResult = ReturnType<typeof useUpdateSportsClubAcquisitionMutation>;
export type UpdateSportsClubAcquisitionMutationResult = Apollo.MutationResult<UpdateSportsClubAcquisitionMutation>;
export type UpdateSportsClubAcquisitionMutationOptions = Apollo.BaseMutationOptions<UpdateSportsClubAcquisitionMutation, UpdateSportsClubAcquisitionMutationVariables>;
export const SportsClubTeamInviteLinkDocument = gql`
  query SportsClubTeamInviteLink($params: SportsClubInviteLinkParams) {
    sportsClubInviteLink(params: $params)
  }
`;

/**
 * __useSportsClubTeamInviteLinkQuery__
 *
 * To run a query within a React component, call `useSportsClubTeamInviteLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportsClubTeamInviteLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsClubTeamInviteLinkQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSportsClubTeamInviteLinkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportsClubTeamInviteLinkQuery, SportsClubTeamInviteLinkQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SportsClubTeamInviteLinkQuery, SportsClubTeamInviteLinkQueryVariables>(SportsClubTeamInviteLinkDocument, options);
}
export function useSportsClubTeamInviteLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportsClubTeamInviteLinkQuery, SportsClubTeamInviteLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SportsClubTeamInviteLinkQuery, SportsClubTeamInviteLinkQueryVariables>(SportsClubTeamInviteLinkDocument, options);
}
export type SportsClubTeamInviteLinkQueryHookResult = ReturnType<typeof useSportsClubTeamInviteLinkQuery>;
export type SportsClubTeamInviteLinkLazyQueryHookResult = ReturnType<typeof useSportsClubTeamInviteLinkLazyQuery>;
export type SportsClubTeamInviteLinkQueryResult = Apollo.QueryResult<SportsClubTeamInviteLinkQuery, SportsClubTeamInviteLinkQueryVariables>;
export const JoinSportsClubTeamDocument = gql`
  mutation JoinSportsClubTeam($sportsClubId: ID!, $sportsClubTeamId: ID!, $role: SportsClubTeamRole) {
    joinSportsClubTeam(sportsClubId: $sportsClubId, sportsClubTeamId: $sportsClubTeamId, role: $role) {
      _id
      userData {
        sportsClubMembership {
          currentSportsClubTeam {
            _id
            name
            membersTemporary {
              tmpId
              firstname
              lastname
              roles
            }
          }
        }
      }
    }
  }
`;
export type JoinSportsClubTeamMutationFn = Apollo.MutationFunction<JoinSportsClubTeamMutation, JoinSportsClubTeamMutationVariables>;

/**
 * __useJoinSportsClubTeamMutation__
 *
 * To run a mutation, you first call `useJoinSportsClubTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinSportsClubTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinSportsClubTeamMutation, { data, loading, error }] = useJoinSportsClubTeamMutation({
 *   variables: {
 *      sportsClubId: // value for 'sportsClubId'
 *      sportsClubTeamId: // value for 'sportsClubTeamId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useJoinSportsClubTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JoinSportsClubTeamMutation, JoinSportsClubTeamMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<JoinSportsClubTeamMutation, JoinSportsClubTeamMutationVariables>(JoinSportsClubTeamDocument, options);
}
export type JoinSportsClubTeamMutationHookResult = ReturnType<typeof useJoinSportsClubTeamMutation>;
export type JoinSportsClubTeamMutationResult = Apollo.MutationResult<JoinSportsClubTeamMutation>;
export type JoinSportsClubTeamMutationOptions = Apollo.BaseMutationOptions<JoinSportsClubTeamMutation, JoinSportsClubTeamMutationVariables>;
export const SendTeamInviteByMailDocument = gql`
  mutation SendTeamInviteByMail($input: SportsClubTeamInviteLinkByMailInput!) {
    sendSportsClubTeamInviteLinkByMail(input: $input) {
      ... on SportsClubTeamInviteLinkByMailSuccessResponse {
        inviteLink
      }
      ... on SportsClubTeamInviteLinkByMailErrorResponse {
        code
      }
    }
  }
`;
export type SendTeamInviteByMailMutationFn = Apollo.MutationFunction<SendTeamInviteByMailMutation, SendTeamInviteByMailMutationVariables>;

/**
 * __useSendTeamInviteByMailMutation__
 *
 * To run a mutation, you first call `useSendTeamInviteByMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTeamInviteByMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTeamInviteByMailMutation, { data, loading, error }] = useSendTeamInviteByMailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendTeamInviteByMailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendTeamInviteByMailMutation, SendTeamInviteByMailMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SendTeamInviteByMailMutation, SendTeamInviteByMailMutationVariables>(SendTeamInviteByMailDocument, options);
}
export type SendTeamInviteByMailMutationHookResult = ReturnType<typeof useSendTeamInviteByMailMutation>;
export type SendTeamInviteByMailMutationResult = Apollo.MutationResult<SendTeamInviteByMailMutation>;
export type SendTeamInviteByMailMutationOptions = Apollo.BaseMutationOptions<SendTeamInviteByMailMutation, SendTeamInviteByMailMutationVariables>;
export const CompleteOnboardingDocument = gql`
  mutation CompleteOnboarding {
    setOnboardingCompleted {
      _id
      userData {
        onboardingCompleted
      }
    }
  }
`;
export type CompleteOnboardingMutationFn = Apollo.MutationFunction<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>;

/**
 * __useCompleteOnboardingMutation__
 *
 * To run a mutation, you first call `useCompleteOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOnboardingMutation, { data, loading, error }] = useCompleteOnboardingMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompleteOnboardingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>(CompleteOnboardingDocument, options);
}
export type CompleteOnboardingMutationHookResult = ReturnType<typeof useCompleteOnboardingMutation>;
export type CompleteOnboardingMutationResult = Apollo.MutationResult<CompleteOnboardingMutation>;
export type CompleteOnboardingMutationOptions = Apollo.BaseMutationOptions<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>;
export const UpdateUserLanguageDocument = gql`
  mutation UpdateUserLanguage($code: String!) {
    setLanguageCode(code: $code) {
      _id
      languageCode
    }
  }
`;
export type UpdateUserLanguageMutationFn = Apollo.MutationFunction<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>;

/**
 * __useUpdateUserLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateUserLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLanguageMutation, { data, loading, error }] = useUpdateUserLanguageMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useUpdateUserLanguageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>(UpdateUserLanguageDocument, options);
}
export type UpdateUserLanguageMutationHookResult = ReturnType<typeof useUpdateUserLanguageMutation>;
export type UpdateUserLanguageMutationResult = Apollo.MutationResult<UpdateUserLanguageMutation>;
export type UpdateUserLanguageMutationOptions = Apollo.BaseMutationOptions<UpdateUserLanguageMutation, UpdateUserLanguageMutationVariables>;
export const OnboardingUserDocument = gql`
  query OnboardingUser {
    user {
      _id
      premium {
        enabled
      }
    }
  }
`;

/**
 * __useOnboardingUserQuery__
 *
 * To run a query within a React component, call `useOnboardingUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OnboardingUserQuery, OnboardingUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OnboardingUserQuery, OnboardingUserQueryVariables>(OnboardingUserDocument, options);
}
export function useOnboardingUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OnboardingUserQuery, OnboardingUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OnboardingUserQuery, OnboardingUserQueryVariables>(OnboardingUserDocument, options);
}
export type OnboardingUserQueryHookResult = ReturnType<typeof useOnboardingUserQuery>;
export type OnboardingUserLazyQueryHookResult = ReturnType<typeof useOnboardingUserLazyQuery>;
export type OnboardingUserQueryResult = Apollo.QueryResult<OnboardingUserQuery, OnboardingUserQueryVariables>;
export const SetAttributionDocument = gql`
  mutation SetAttribution($input: UserAttributionInput!) {
    setUserAttribution(input: $input) {
      _id
    }
  }
`;
export type SetAttributionMutationFn = Apollo.MutationFunction<SetAttributionMutation, SetAttributionMutationVariables>;

/**
 * __useSetAttributionMutation__
 *
 * To run a mutation, you first call `useSetAttributionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAttributionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAttributionMutation, { data, loading, error }] = useSetAttributionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAttributionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetAttributionMutation, SetAttributionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SetAttributionMutation, SetAttributionMutationVariables>(SetAttributionDocument, options);
}
export type SetAttributionMutationHookResult = ReturnType<typeof useSetAttributionMutation>;
export type SetAttributionMutationResult = Apollo.MutationResult<SetAttributionMutation>;
export type SetAttributionMutationOptions = Apollo.BaseMutationOptions<SetAttributionMutation, SetAttributionMutationVariables>;
export const SportsClubTeamTemporaryUsersDocument = gql`
  query SportsClubTeamTemporaryUsers {
    user {
      _id
      userData {
        sportsClubMembership {
          currentSportsClubTeam {
            _id
            membersTemporary {
              tmpId
              firstname
              lastname
              roles
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSportsClubTeamTemporaryUsersQuery__
 *
 * To run a query within a React component, call `useSportsClubTeamTemporaryUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportsClubTeamTemporaryUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsClubTeamTemporaryUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSportsClubTeamTemporaryUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SportsClubTeamTemporaryUsersQuery, SportsClubTeamTemporaryUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SportsClubTeamTemporaryUsersQuery, SportsClubTeamTemporaryUsersQueryVariables>(SportsClubTeamTemporaryUsersDocument, options);
}
export function useSportsClubTeamTemporaryUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportsClubTeamTemporaryUsersQuery, SportsClubTeamTemporaryUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SportsClubTeamTemporaryUsersQuery, SportsClubTeamTemporaryUsersQueryVariables>(SportsClubTeamTemporaryUsersDocument, options);
}
export type SportsClubTeamTemporaryUsersQueryHookResult = ReturnType<typeof useSportsClubTeamTemporaryUsersQuery>;
export type SportsClubTeamTemporaryUsersLazyQueryHookResult = ReturnType<typeof useSportsClubTeamTemporaryUsersLazyQuery>;
export type SportsClubTeamTemporaryUsersQueryResult = Apollo.QueryResult<SportsClubTeamTemporaryUsersQuery, SportsClubTeamTemporaryUsersQueryVariables>;
export const RemoveSportsClubTeamTemporaryUserDocument = gql`
  mutation RemoveSportsClubTeamTemporaryUser($teamId: ID!, $tmpId: String!) {
    removeTemporaryUserFromTeamInSportsClub(teamId: $teamId, tmpId: $tmpId) {
      _id
    }
  }
`;
export type RemoveSportsClubTeamTemporaryUserMutationFn = Apollo.MutationFunction<
  RemoveSportsClubTeamTemporaryUserMutation,
  RemoveSportsClubTeamTemporaryUserMutationVariables
>;

/**
 * __useRemoveSportsClubTeamTemporaryUserMutation__
 *
 * To run a mutation, you first call `useRemoveSportsClubTeamTemporaryUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSportsClubTeamTemporaryUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSportsClubTeamTemporaryUserMutation, { data, loading, error }] = useRemoveSportsClubTeamTemporaryUserMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      tmpId: // value for 'tmpId'
 *   },
 * });
 */
export function useRemoveSportsClubTeamTemporaryUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveSportsClubTeamTemporaryUserMutation, RemoveSportsClubTeamTemporaryUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RemoveSportsClubTeamTemporaryUserMutation, RemoveSportsClubTeamTemporaryUserMutationVariables>(
    RemoveSportsClubTeamTemporaryUserDocument,
    options
  );
}
export type RemoveSportsClubTeamTemporaryUserMutationHookResult = ReturnType<typeof useRemoveSportsClubTeamTemporaryUserMutation>;
export type RemoveSportsClubTeamTemporaryUserMutationResult = Apollo.MutationResult<RemoveSportsClubTeamTemporaryUserMutation>;
export type RemoveSportsClubTeamTemporaryUserMutationOptions = Apollo.BaseMutationOptions<
  RemoveSportsClubTeamTemporaryUserMutation,
  RemoveSportsClubTeamTemporaryUserMutationVariables
>;
export const TeamOnboardingUserDocument = gql`
  query TeamOnboardingUser {
    user {
      _id
      userData {
        sportsClubMembership {
          sportsClub {
            _id
            name
            logo {
              uri
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTeamOnboardingUserQuery__
 *
 * To run a query within a React component, call `useTeamOnboardingUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamOnboardingUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamOnboardingUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamOnboardingUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamOnboardingUserQuery, TeamOnboardingUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeamOnboardingUserQuery, TeamOnboardingUserQueryVariables>(TeamOnboardingUserDocument, options);
}
export function useTeamOnboardingUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamOnboardingUserQuery, TeamOnboardingUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeamOnboardingUserQuery, TeamOnboardingUserQueryVariables>(TeamOnboardingUserDocument, options);
}
export type TeamOnboardingUserQueryHookResult = ReturnType<typeof useTeamOnboardingUserQuery>;
export type TeamOnboardingUserLazyQueryHookResult = ReturnType<typeof useTeamOnboardingUserLazyQuery>;
export type TeamOnboardingUserQueryResult = Apollo.QueryResult<TeamOnboardingUserQuery, TeamOnboardingUserQueryVariables>;
