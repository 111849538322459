import { Grid, useMediaQuery } from "@geist-ui/core";
import { TeamPaymentBg } from "assets/backgroundImages";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { ProgressBar } from "components/atoms/ProgressBar";
import { Select, SelectOption } from "components/atoms/Select";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { SportsClubAcquisitionSource, useUpdateSportsClubAcquisitionMutation } from "generated/graphql";
import { useToast } from "hooks/useToast";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";

export const SportsClubAcquisition: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const { clear, role } = useOnboardingStore();

  const [description, setDescription] = useState("");
  const [source, setSource] = useState<SportsClubAcquisitionSource>();

  const [mutate, { loading }] = useUpdateSportsClubAcquisitionMutation();

  const goToNextStep = () => {
    if (!source) {
      toast({ text: t("pages.teamOnboarding.acquisition.error"), type: "error" });
      return;
    }

    if (source === SportsClubAcquisitionSource.OTHER && !description) {
      toast({ text: t("pages.teamOnboarding.acquisition.error"), type: "error" });
      return;
    }

    mutate({ variables: { input: { source, description } } });

    clear();

    if (role === "PLAYER") {
      navigate({ pathname: "../../onboarding/finish" });
    } else {
      navigate("../finish");
    }
  };

  const isValid = source && (source !== SportsClubAcquisitionSource.OTHER || (source === SportsClubAcquisitionSource.OTHER && description));
  const sources = [
    SportsClubAcquisitionSource.GOOGLE_SEARCH,
    SportsClubAcquisitionSource.SOCIAL_MEDIA,
    SportsClubAcquisitionSource.RECOMMENDATION,
    SportsClubAcquisitionSource.B42_APP,
    SportsClubAcquisitionSource.OTHER
  ];

  return (
    <>
      <PageContainer background={<BackgroundImage src={TeamPaymentBg} />}>
        {isXS ? <ProgressBar currentStep={13} totalSteps={14} /> : null}
        <OnboardingStep title={t("pages.teamOnboarding.acquisition.title")}>
          <Grid.Container gap={2} direction={isXS ? "column" : "row"} justify="center">
            <Grid sm={24} xs={24} width="100%">
              <Select placeholder={t("pages.teamOnboarding.acquisition.select")} value={source} onChange={(value) => setSource(value as SportsClubAcquisitionSource)}>
                {sources.map((p) => (
                  <SelectOption key={p} value={p}>
                    {t(`pages.teamOnboarding.acquisition.${p}`)}
                  </SelectOption>
                ))}
              </Select>
            </Grid>
          </Grid.Container>
          {source === SportsClubAcquisitionSource.OTHER ? <Grid.Container justify="center" marginTop={2} marginBottom={1}>
            <Input placeholder={t("pages.teamOnboarding.acquisition.more")} value={description} onChange={(e) => setDescription(e.target.value)} />
          </Grid.Container> : null}
          <Grid.Container justify="center" marginTop={2} marginBottom={1}>
            <Grid sm={24} xs={24}>
              <Button type="button" variant={isValid ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
                {t("common.continue")}
              </Button>
            </Grid>
          </Grid.Container>
        </OnboardingStep>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={13} totalSteps={14} /> : null}
      {loading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
};
