import styled from "@emotion/styled";
import { Grid, Spinner } from "@geist-ui/core";
import { GenderSelectionWoman } from "assets";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { SportsClubTeamRole, useBasicSportsClubTeamLazyQuery, useJoinSportsClubTeamMutation, useSetAttributionMutation } from "generated/graphql";
import { useSearchParam } from "hooks/useSearchParam";
import { useToast } from "hooks/useToast";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useInviteStore } from "stores/invite.store";
import { useOnboardingStore } from "stores/onboarding.store";

export const InviteRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showToast = useToast();
  const { bundle, attribution } = useOnboardingStore();
  const searchParams = new URLSearchParams(window.location.search);
  const { setInviteData } = useInviteStore();
  const [mutateAttribution] = useSetAttributionMutation();

  const [query, { data, loading, error }] = useBasicSportsClubTeamLazyQuery({
    onCompleted: (res) => {
      if (res.user?.userData.sportsClubMembership && res.user?.userData.sportsClubMembership?.sportsClub._id !== sportsClubId) {
        mutateAttribution({ variables: { input: { referredBy: attribution?.referredBy, refByAffiliateId: attribution?.refByAffiliateId } } });
        navigate({ pathname: "../change", search: searchParams.toString() });
      }
    }
  });

  const [mutate] = useJoinSportsClubTeamMutation({
    onCompleted: (res) => handleJoinFinished(res.joinSportsClubTeam.userData.sportsClubMembership?.currentSportsClubTeam?.membersTemporary),
    onError: () => {
      showToast({ text: t("pages.invite.error.toast"), type: "error" });
      handleJoinFinished();
    }
  });

  const sportsClubId = useSearchParam<string>("sportsClub", {
    defaultValue: ""
  });
  const sportsClubTeamId = useSearchParam<string>("team", {
    defaultValue: ""
  });
  const role = useSearchParam<SportsClubTeamRole>("role", {
    defaultValue: SportsClubTeamRole.PLAYER,
    allowedValues: Object.values(SportsClubTeamRole)
  });

  const handleJoinFinished = (temporaryMembers?: { tmpId: string }[] | null) => {
    setInviteData(sportsClubId, sportsClubTeamId, role);

    if ((temporaryMembers?.length ?? 0) > 0) {
      // select player and go to accept afterwards
      navigate({ pathname: "../../onboarding/team-player-selection", search: location.search });
    } else if (role === SportsClubTeamRole.COACH || role === SportsClubTeamRole.CO_COACH) {
      // enter user data and go to accept afterwards
      navigate({ pathname: "../../onboarding/user-data-selection-lite" });
    } else {
      // enter user data and go to accept afterwards
      navigate({ pathname: "../../onboarding/team-player-data" });
    }
  };

  useEffect(() => {
    if (sportsClubId && sportsClubTeamId) {
      query({ variables: { sportsClubId, sportsClubTeamId } });
    }
  }, [query, sportsClubId, sportsClubTeamId]);

  const handleConfirmClick = () => {
    mutate({ variables: { sportsClubId, sportsClubTeamId, role } });
  };

  const handleDeclineClick = () => {
    navigate({ pathname: "../decline" });
  };

  const handleErrorClick = () => {
    if (bundle === "TEAM") {
      navigate({ pathname: "../../sports-club-onboarding", search: location.search });
    } else if (bundle === "PLAYER") {
      navigate({ pathname: "../../onboarding", search: location.search });
    } else {
      navigate({ pathname: "../../membership-area", search: location.search });
    }
  };

  if (!sportsClubId || !sportsClubTeamId || loading) {
    return (
      <Grid.Container direction="column" justify="center">
        <Grid sm={24} direction="column" justify="center">
          <Spinner mt={3} mb={3} />
        </Grid>
      </Grid.Container>
    );
  }

  if (!sportsClubId || !sportsClubTeamId || !data?.basicSportsClubTeam || error) {
    return (
      <Grid.Container>
        <Grid sm={24} direction="column" justify="center">
          <ErrorText>{t("pages.invite.error.message")}</ErrorText>
          <Button type="button" variant="primary" fullWidth onClick={handleErrorClick}>
            {t("common.continue")}
          </Button>
        </Grid>
      </Grid.Container>
    );
  }

  const team = data.basicSportsClubTeam.sportsClubName;

  return (
    <>
      <PageContainer background={<BackgroundImage src={GenderSelectionWoman} />}>
        <OnboardingStep title={t("pages.invite.request.title")} subtitle={t("pages.invite.request.subTitle")}></OnboardingStep>
        <Grid.Container direction="column">
          <Grid sm={24}>
            <TeamContainer>
              <LogoContainer>{data.basicSportsClubTeam.logo ? <LogoImage src={data.basicSportsClubTeam.logo?.uri} alt={"Logo - " + team} /> : null}</LogoContainer>
              <TeamName>
                {role === SportsClubTeamRole.PLAYER ? t("pages.invite.request.playerMessage", { team }) : t("pages.invite.request.coachMessage", { team })}
              </TeamName>
            </TeamContainer>
          </Grid>
          <Grid sm={24} direction="column">
            <ContinueButton type="button" variant="primary" fullWidth onClick={handleConfirmClick}>
              {t("pages.invite.request.confirm")}
            </ContinueButton>
            <ContinueButton type="button" variant="primary-outline" fullWidth onClick={handleDeclineClick}>
              {t("pages.invite.request.decline")}
            </ContinueButton>
          </Grid>
        </Grid.Container>
      </PageContainer>
    </>
  );
};

const TeamContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LogoContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.light};
  margin-right: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1.25)};
  justify-content: center;
  align-items: center;
  display: flex;
  width: 90px;
  height: 90px;
`;

const LogoImage = styled.img`
  height: 52px;
  width: 52px;
`;

const TeamName = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 120% */
`;

const ContinueButton = styled(Button)`
  margin: 0 auto;
  max-width: 400px;
`;

const ErrorText = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 120% */
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
