import styled from "@emotion/styled";
import { Grid } from "@geist-ui/core";
import { CheckCircle, GenderSelectionWoman } from "assets";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";

export const InviteDecline = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { bundle } = useOnboardingStore();

  const handleNextClick = () => {
    if (bundle === "TEAM") {
      navigate({ pathname: "../../sports-club-onboarding", search: location.search });
    } else if (bundle === "PLAYER") {
      navigate({ pathname: "../../onboarding", search: location.search });
    } else {
      navigate({ pathname: "../../membership-area", search: location.search });
    }
  }

  return (
    <>
      <PageContainer background={<BackgroundImage src={GenderSelectionWoman} />}>
        <OnboardingStep title={t("pages.invite.decline.title")} subtitle={t("pages.invite.decline.subTitle")}></OnboardingStep>
        <Grid.Container direction="column">
          <Grid sm={24} direction="column">
            <CheckCircleImage src={CheckCircle} />
            <ContinueButton type="button" variant="primary" fullWidth onClick={handleNextClick}>
              {t("common.continue")}
            </ContinueButton>
          </Grid>
        </Grid.Container>
      </PageContainer>
    </>
  );
};

const CheckCircleImage = styled.img`
  height: 106px;
  width: 106px;
  margin: 0 auto 40px;
`;

const ContinueButton = styled(Button)`
  margin: 0 auto;
  max-width: 360px;
`;

