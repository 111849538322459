import { Theme } from "@emotion/react";
import { colors } from "./colors";
import { B42_THEME } from "./geist-ui";

export const theme: Theme = {
  colors,
  box: {
    primary: "#D0FF23"
  },
  breakpoints: B42_THEME.breakpoints,
  spacing: (multiplier?: number) => `${(multiplier || 1) * 8}px`,
  spacingN: (multiplier?: number) => (multiplier || 1) * 8,
  borderRadius: (multiplier?: number) => `${(multiplier || 1) * 8}px`
};
