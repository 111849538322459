import { Grid, useMediaQuery } from "@geist-ui/core";
import { RoleSelectionDiversBg } from "assets/backgroundImages";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { ProgressBar } from "components/atoms/ProgressBar";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { useCreateSportsClubTeamMutation } from "generated/graphql";
import { useToast } from "hooks/useToast";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";

export const SportsClubTeamCreation: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { sportsClubTeamName, setSportsClubTeamName, setSportsClubTeamId } = useOnboardingStore();

  const [mutate, { loading }] = useCreateSportsClubTeamMutation({
    onCompleted: (data) => {
      if (data.createTeamInSportsClub.teams.length) {
        setSportsClubTeamId(data.createTeamInSportsClub.teams[0]._id);
        navigate({ pathname: "../../onboarding/role-selection" });
      }
    },
    onError: () => toast({ text: t("pages.teamOnboarding.sportsClubTeamCreation.error"), type: "error" })
  });

  const goToNextStep = () => {
    if (!sportsClubTeamName) {
      return;
    }

    mutate({ variables: { name: sportsClubTeamName } });
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={RoleSelectionDiversBg} />}>
        {isXS ? <ProgressBar currentStep={4} totalSteps={14} /> : null}
        <OnboardingStep title={t("pages.teamOnboarding.sportsClubTeamCreation.title")} subtitle={t("pages.teamOnboarding.sportsClubTeamCreation.subTitle")}>
          <Grid.Container gap={2} direction={isXS ? "column" : "row"} justify="center">
            <Grid sm={24} xs={24} width="100%">
              <Input
                placeholder={t("pages.teamOnboarding.sportsClubTeamCreation.team")}
                value={sportsClubTeamName}
                onChange={(e) => setSportsClubTeamName(e.target.value)}
              />
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={2} marginBottom={4}>
            <Grid sm={24} xs={24}>
              <Button type="button" variant={sportsClubTeamName ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
                {t("common.continue")}
              </Button>
            </Grid>
          </Grid.Container>
        </OnboardingStep>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={4} totalSteps={14} /> : null}
      {loading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
};
