import { SportsClubTeamRole } from "generated/graphql";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type InviteState = {
  sportsClubId?: string;
  teamId?: string;
  role?: SportsClubTeamRole;
  setInviteData: (sportsClubId: string, teamId: string, role: SportsClubTeamRole) => void;
  clear: () => void;
};

export const useInviteStore = create<InviteState>()(
  persist(
    (set) => ({
      sportsClubId: undefined,
      teamId: undefined,
      role: undefined,
      setInviteData: (sportsClubId, teamId, role) => set(() => ({ sportsClubId, teamId, role })),
      clear: () => set(() => ({ sportsClubId: undefined, teamId: undefined, role: undefined }))
    }),
    {
      name: "invite",
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
