import styled from "@emotion/styled";
import { SignUpBg } from "assets/backgroundImages";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { ResetPasswordErrorCodes, useResetPasswordMutation } from "generated/graphql";
import { useSearchParam } from "hooks/useSearchParam";
import { useToast } from "hooks/useToast";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { logger } from "util/logger";

export const ResetPassword: FunctionComponent = () => {
  const { t } = useTranslation();
  const showToast = useToast();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getValues
  } = useForm<{ password: string; confirmPassword: string }>();

  const resetToken = useSearchParam<string>("resetToken", {
    defaultValue: ""
  });

  const [resetPassword] = useResetPasswordMutation({
    onCompleted: (data) => {
      if (data.resetPassword.__typename === "ResetPasswordSuccessResponse") {
        showToast({ text: t("pages.resetPassword.success"), type: "success", delay: 7000 });
        reset();
        navigate({ pathname: "../sign-up" });
      } else if (data.resetPassword.__typename === "ResetPasswordErrorResponse" && data.resetPassword.code === ResetPasswordErrorCodes.TOKEN_INVALID) {
        showToast({ text: t("pages.resetPassword.tokenExpired"), type: "error" });
      } else {
        showToast({ text: t("pages.resetPassword.error"), type: "error" });
      }
    }
  });

  return (
    <>
      <PageContainer background={<BackgroundImage src={SignUpBg} />}>
        <OnboardingStep title={t("pages.resetPassword.title")} subtitle={t("pages.resetPassword.subtitle")}>
          <ResetPasswordForm
            onSubmit={handleSubmit(
              ({ password }) => resetPassword({ variables: { newPassword: password, token: resetToken } }),
              (e) => logger.error("Submit error", e)
            )}
          >
            <Input
              error={errors.password?.message}
              type="password"
              placeholder={t("pages.resetPassword.password")}
              {...register("password", {
                required: { value: true, message: t("form.error.emailRequired") }
              })}
            />
            <Input
              error={errors.confirmPassword?.message}
              type="password"
              placeholder={t("pages.resetPassword.passwordConfirm")}
              {...register("confirmPassword", {
                required: { value: true, message: t("form.error.emailRequired") },
                validate: (value) => value === getValues("password") || t("pages.resetPassword.passwordWrong")
              })}
            />
            <Button variant="primary" type="submit" fullWidth>
              {t("pages.resetPassword.title")}
            </Button>
          </ResetPasswordForm>
        </OnboardingStep>
      </PageContainer>
    </>
  );
};

const ResetPasswordForm = styled.form``;
