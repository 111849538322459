import React, { Suspense } from "react";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline, GeistProvider, Loading } from "@geist-ui/core";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import { apolloClient } from "./apolloClient";
import App from "./App";
import { AuthContextProvider } from "./contexts/AuthContext";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./theme/emotion";
import { B42_THEME } from "./theme/geist-ui";
import { OAuthContextProvider } from './contexts/OAuthContext';

const tagManagerArgs = {
  gtmId: "GTM-WRSDH4F"
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
        <AuthContextProvider>
          <ApolloProvider client={apolloClient}>
            <ThemeProvider theme={theme}>
              <GeistProvider themeType="male" themes={[B42_THEME]}>
                <CssBaseline />
                <BrowserRouter>
                  <OAuthContextProvider>
                    <App />
                  </OAuthContextProvider>
                </BrowserRouter>
              </GeistProvider>
            </ThemeProvider>
          </ApolloProvider>
        </AuthContextProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
