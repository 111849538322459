import { SignInThirdPartyErrorCodes, ThirdPartySignInProviders } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { logger } from "util/logger";
import { _useSignInThirdParty } from "./use-sign-in-third-party";
import { ISignInSignUpSuccessSuccessResponse } from "hooks/useSignIn";

addEventListener("load", function () {
  logger.log("Initializing AppleID...");
  AppleID.auth.init({
    clientId: "com.b-42.membership",
    usePopup: true,
    scope: "email",
    redirectURI: process.env.REACT_APP_APPLE_SIGN_IN_REDIRECT_URI ?? window.location.origin + "/"
  });
});

export const useAppleSignin = ({
  onSuccess,
  onError
}: { onSuccess?: (data: ISignInSignUpSuccessSuccessResponse) => void; onError?: (e: SignInThirdPartyErrorCodes | Error) => void } = {}) => {
  const { signInThirdParty, loading } = _useSignInThirdParty({ onSuccess, onError });
  const { i18n } = useTranslation();

  const signIn = async () => {
    try {
      const data = await AppleID.auth.signIn();
      await signInThirdParty({
        variables: {
          lang: i18n.language,
          token: data.authorization.id_token,
          type: ThirdPartySignInProviders.APPLE
        }
      });
    } catch (e) {
      logger.error("Sign in with Apple ID failed!", e as Error);
      onError && onError(e as Error);
    }
  };

  return { signInWithApple: signIn, loading };
};
