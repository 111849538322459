import { Theme } from "@emotion/react";

export const colors: Theme["colors"] = {
  primary: "#D0FF23",
  primary10Percent: "#D0FF231A",
  primary50Percent: "#D0FF2380",
  primary70Percent: "#D0FF23b3",
  primaryHover: "#E3FF7B",
  mirage: "#333c42",
  orange: "#f9b10b",
  orange90Percent: "#f9b10bE6",
  black: "#000000",
  black40Percent: "#00000066",
  dark: "#1d1d1d",
  dark10Percent: "#1d1d1d1A",
  dark50Percent: "#1d1d1d80",
  dark70Percent: "#1d1d1db3",
  dark90Percent: "#1d1d1dE6",
  white: "#ffffff",
  white80Percent: "#ffffffcc",
  white50Percent: "#ffffff80",
  white20Percent: "#ffffff33",
  white10Percent: "#ffffff1A",
  whiteHover: "#F3F3F3",
  light: "#F3F3F3",
  light50Percent: "#F3F3F380",
  gray: "#2E2F2F",
  gray2: "#4F4F4F",
  lightGray: "#BDBDBD",
  lightGrey: "#808080",
  darkGray: "#424242",
  darkerGray: "#242424",
  error: "#FF6250",
  correct: "#65D276",
  pink: "#ff6250",
  pink50Percent: "#ff625080",
  purple: "#6D7CFF",
  "sonic-silver": "#707070",
  timberwolf: "#BFBDC6",
  jet: "#303033",
};
