import { useTranslation } from "react-i18next";

/**
 * **Use only if necessary!**
 * In most cases its enough to write `../route` to keep the language part in the route intact.
 *
 * If you have a component outside of the `:locale` Route-element this doesn't work
 * and this helper can be used.
 */
export const useLocalizedRoute = () => {
  const { i18n } = useTranslation();

  return (to: string) => `/${i18n.language}/${to}`;
};
