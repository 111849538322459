import styled from "@emotion/styled/macro";
import React from "react";
import { ReactComponent as Check } from "assets/icons/icon-check.svg";

interface Props extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "defaultValue"> {
  error?: boolean | string;
  checked?: boolean;
  label?: string;
  styledLabel?: React.ReactNode
}

export const Checkbox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <Container>
      <Label>
        <InputStyled {...props} ref={ref} type="checkbox" checked={props.checked} error={!!props.error} onChange={(e) => props.onChange?.(e)} placeholder={undefined} />
        <CheckboxToggler>{props.checked && <CheckIcon />}</CheckboxToggler>
        {props.label && <LabelText>{props.label}</LabelText>}
        {props.styledLabel ? props.styledLabel : null}
      </Label>
      {props.error && typeof props.error == "string" ? <ErrorLabel>{props.error}</ErrorLabel> : null}
    </Container>
  );
});

Checkbox.displayName = "Checkbox";

const CheckIcon = styled(Check)`
  fill: ${({ theme }) => theme.colors.dark};
`;

const Container = styled.div`
  width: 100%;
`;

const Label = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
`;

const LabelText = styled.span`
  color: ${({ theme }) => theme.colors.white80Percent};
  font-size: 14px;
  line-height: 18px;
`;

const CheckboxToggler = styled.div`
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin-right: 12px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
`;

const InputStyled = styled.input<{ error?: boolean }>`
  display: none;

  &:checked + ${CheckboxToggler} {
    background: ${({ theme }) => theme.colors.primary};
  }
`;

const ErrorLabel = styled.label`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  padding: 12px;
`;
