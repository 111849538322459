import styled from "@emotion/styled";

interface Props {
  src: string;
}

export const BackgroundImage = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: ${({ src }) => `url(${src})`};
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.4;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &::after {
    background: ${({ theme }) => `linear-gradient(360deg, ${theme.colors.dark}, ${theme.colors.dark}00)`};
  }

  &::before {
    background: linear-gradient(90deg, #000000 11.82%, rgba(0, 0, 0, 0) 76.97%);
    opacity: 0.6;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
`;
