import { Grid, useMediaQuery } from "@geist-ui/core";
import { TeamPaymentBg } from "assets/backgroundImages";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { ProgressBar } from "components/atoms/ProgressBar";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { SportsClubTeamRole, useCreateSportsClubTeamMutation, useJoinSportsClubTeamMutation } from "generated/graphql";
import { useToast } from "hooks/useToast";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";

export const FurtherTeamCreation: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const { role } = useOnboardingStore();

  const [mutateJoin] = useJoinSportsClubTeamMutation({
    onCompleted: () => goToAcquisition(),
    onError: () => toast({ text: t("pages.teamOnboarding.sportsClubTeamCreation.error"), type: "error" })
  });

  const [mutate, { loading }] = useCreateSportsClubTeamMutation({
    onCompleted: (data) => {
      if (data.createTeamInSportsClub.teams.length > 1) {
        mutateJoin({
          variables: {
            sportsClubId: data.createTeamInSportsClub._id,
            sportsClubTeamId: data.createTeamInSportsClub.teams[1]._id,
            role: role === "COACH" || role === "PLAYER_COACH" || role === "OTHER" ? SportsClubTeamRole.COACH : SportsClubTeamRole.PLAYER
          }
        });
      }
    },
    onError: () => toast({ text: t("pages.teamOnboarding.sportsClubTeamCreation.error"), type: "error" })
  });

  const [name, setName] = useState("");

  const goToNextStep = () => {
    if (!name) {
      return;
    }

    mutate({ variables: { name } });
  };

  const goToAcquisition = () => {
    navigate("../acquisition");
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={TeamPaymentBg} />}>
        {isXS ? <ProgressBar currentStep={12} totalSteps={14} /> : null}
        <OnboardingStep title={t("pages.teamOnboarding.furtherTeamCreation.title")} subtitle={t("pages.teamOnboarding.furtherTeamCreation.subTitle")}>
          <Grid.Container gap={2} direction={isXS ? "column" : "row"} justify="center">
            <Grid sm={24} xs={24} width="100%">
              <Input placeholder={t("pages.teamOnboarding.furtherTeamCreation.team")} value={name} onChange={(e) => setName(e.target.value)} />
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={2} marginBottom={1}>
            <Grid sm={24} xs={24}>
              <Button type="button" variant={name ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
                {t("common.continue")}
              </Button>
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={1} marginBottom={4}>
            <Grid sm={24} xs={24}>
              <Button type="button" variant="primary-outline" onClick={goToAcquisition} fullWidth>
                {t("common.later")}
              </Button>
            </Grid>
          </Grid.Container>
        </OnboardingStep>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={12} totalSteps={14} /> : null}
      {loading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
};
