import { FitnessStatus, Foot, Gender, GenderIdentity, Position, SportsClubAcquisitionSource, SportsClubTeamRole } from "generated/graphql";
import { ParamsBundlePeriod } from "util/constants";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export type Role = "COACH" | "PLAYER" | "PLAYER_COACH" | "OTHER";
export type Bundle = "PLAYER" | "TEAM" | "NONE";

type SportsClubAddress = { website?: string; street?: string; postcode?: string; location?: string; phone?: string };

type Attribution = { referredBy?: string; refByAffiliateId?: string };

type OnboardingState = {
  attribution?: Attribution;
  bundle?: Bundle;
  paymentPeriod?: ParamsBundlePeriod;
  role?: Role;
  gender?: Gender;
  genderIdentity?: GenderIdentity;
  position?: Position;
  fitnessStatus?: FitnessStatus;
  firstname?: string;
  lastname?: string;
  height?: number;
  weight?: number;
  foot?: Foot;
  birthday?: Date;
  sportsClubName?: string;
  sportsClubAddress: SportsClubAddress;
  sportsClubTeamName?: string;
  sportsClubId?: string;
  sportsClubTeamId?: string;
  sportsClubTeamRole: SportsClubTeamRole;
  acquisitionSource?: SportsClubAcquisitionSource;
  acquisitionDescription?: string;
  setAttribution: (attribution: Attribution) => void;
  setBundle: (bundle: Bundle) => void;
  setPaymentPeriod: (paymentPeriod: ParamsBundlePeriod) => void;
  setRole: (role: Role) => void;
  setGenderIdentity: (genderIdentity: GenderIdentity, gender?: Gender) => void;
  setPosition: (position: Position) => void;
  setFitnessStatus: (fitnessStatus: FitnessStatus) => void;
  setFirstname: (firstname: string) => void;
  setLastname: (lastname: string) => void;
  setHeight: (height: number) => void;
  setWeight: (weight: number) => void;
  setFoot: (foot: Foot) => void;
  setBirthday: (birthday: Date) => void;
  setSportsClubName: (sportsClubName: string) => void;
  setSportsClubWebsite: (website: string) => void;
  setSportsClubPhone: (phone: string) => void;
  setSportsClubStreet: (street: string) => void;
  setSportsClubPostcode: (postcode: string) => void;
  setSportsClubLocation: (location: string) => void;
  setSportsClubTeamName: (name: string) => void;
  setSportsClubId: (id: string) => void;
  setSportsClubTeamId: (id: string) => void;
  setSportsClubTeamRole: (role: SportsClubTeamRole) => void;
  setSportsClubAcquisitionSource: (source: SportsClubAcquisitionSource) => void;
  setSportsClubAcquisitionDescription: (description: string) => void;
  clear: () => void;
};

export const useOnboardingStore = create<OnboardingState>()(
  persist(
    (set) => ({
      sportsClubAddress: {},
      sportsClubTeamRole: SportsClubTeamRole.COACH,
      setAttribution: (attribution) => set((state) => ({ ...state, attribution })),
      setBundle: (bundle) => set((state) => ({ ...state, bundle })),
      setPaymentPeriod: (paymentPeriod) => set((state) => ({ ...state, paymentPeriod })),
      setRole: (role) => set((state) => ({ ...state, role })),
      setGenderIdentity: (genderIdentity, gender) => set((state) => ({ ...state, genderIdentity, gender })),
      setPosition: (position) => set((state) => ({ ...state, position })),
      setFitnessStatus: (fitnessStatus) => set((state) => ({ ...state, fitnessStatus })),
      setFirstname: (firstname) => set((state) => ({ ...state, firstname })),
      setLastname: (lastname) => set((state) => ({ ...state, lastname })),
      setHeight: (height) => set((state) => ({ ...state, height })),
      setWeight: (weight) => set((state) => ({ ...state, weight })),
      setFoot: (foot) => set((state) => ({ ...state, foot })),
      setBirthday: (birthday) => set((state) => ({ ...state, birthday })),
      setSportsClubName: (sportsClubName) => set((state) => ({ ...state, sportsClubName })),
      setSportsClubWebsite: (website) => set((state) => ({ ...state, sportsClubAddress: { ...state.sportsClubAddress, website } })),
      setSportsClubPhone: (phone) => set((state) => ({ ...state, sportsClubAddress: { ...state.sportsClubAddress, phone } })),
      setSportsClubStreet: (street) => set((state) => ({ ...state, sportsClubAddress: { ...state.sportsClubAddress, street } })),
      setSportsClubPostcode: (postcode) => set((state) => ({ ...state, sportsClubAddress: { ...state.sportsClubAddress, postcode } })),
      setSportsClubLocation: (location) => set((state) => ({ ...state, sportsClubAddress: { ...state.sportsClubAddress, location } })),
      setSportsClubTeamName: (name) => set((state) => ({ ...state, sportsClubTeamName: name })),
      setSportsClubId: (id) => set((state) => ({ ...state, sportsClubId: id })),
      setSportsClubTeamId: (id) => set((state) => ({ ...state, sportsClubTeamId: id })),
      setSportsClubTeamRole: (role) => set((state) => ({ ...state, sportsClubTeamRole: role })),
      setSportsClubAcquisitionSource: (source) => set((state) => ({ ...state, acquisitionSource: source })),
      setSportsClubAcquisitionDescription: (description) => set((state) => ({ ...state, acquisitionDescription: description })),
      clear: () =>
        set({
          attribution: undefined,
          bundle: undefined,
          paymentPeriod: undefined,
          role: undefined,
          gender: undefined,
          genderIdentity: undefined,
          position: undefined,
          fitnessStatus: undefined,
          firstname: undefined,
          lastname: undefined,
          height: undefined,
          weight: undefined,
          foot: undefined,
          birthday: undefined,
          sportsClubName: undefined,
          sportsClubTeamName: undefined,
          sportsClubAddress: {},
          sportsClubId: undefined,
          sportsClubTeamId: undefined,
          sportsClubTeamRole: undefined,
          acquisitionSource: undefined,
          acquisitionDescription: undefined
        })
    }),
    {
      name: "onboarding",
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
