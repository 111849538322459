/** App environment */
export type Environment = 'development' | 'production';

export const APP_ENV: Environment = process.env.REACT_APP_ENV === 'development' ? 'development' : 'production';

/** Log levels */
type LogLevel = 'log' | 'warn' | 'error';
export const LOG_LEVEL: LogLevel = APP_ENV === 'production' ? 'warn' : 'log';

/** Signature of a logging function */
type OptionalParams = Error | string | object
export interface LogFn {
  (message?: string, ...optionalParams: OptionalParams[]): void;
}

/** Basic logger interface */
export interface Logger {
  log: LogFn;
  warn: LogFn;
  error: LogFn;
}

const NO_OP: LogFn = () => {};

/** 
 * Logger which outputs to the browser console 
 * inspired by https://meticulous.ai/blog/getting-started-with-react-logging/
 */
export class ConsoleLogger implements Logger {
  readonly log: LogFn;
  readonly warn: LogFn;
  readonly error: LogFn;

  constructor(options?: { level? : LogLevel }) {
    const { level } = options || {};

    this.error = console.error.bind(console);

    if (level === 'error') {
      this.warn = NO_OP;
      this.log = NO_OP;

      return;
    }
    
    this.warn = console.warn.bind(console);

    if (level === 'warn') {
      this.log = NO_OP;

      return;
    }

    this.log = console.log.bind(console);
  }
}

export const logger = new ConsoleLogger({ level: LOG_LEVEL });