import { gql } from "@apollo/client";
import { useCreateStripeCheckoutSessionMutation } from "generated/graphql";
import { ParamsBundlePeriod } from "util/constants";
import { logger } from "util/logger";
import { useLocalizedRoute } from "./useLocalizedRoute";
import { useUTMParameters } from "./useUTMParameters";

type PlayerProduct = {
  product: "singleplayer_premium";
  paymentPeriod: keyof typeof ParamsBundlePeriod;
};

type TeamProduct = {
  product: "team_premium";
  teamId: string;
};

export const useStripeCheckout = () => {
  const [createStripeCheckoutSession, { loading }] = useCreateStripeCheckoutSessionMutation();
  const utmParams = useUTMParameters();
  const localizedRoute = useLocalizedRoute();

  const createCheckoutSession = async (opts: (PlayerProduct | TeamProduct) & { metadata?: { [key: string]: string } }) => {
    let priceId: string | undefined;
    let teamId: string | undefined = undefined;

    const isSinglePlayerProduct = opts.product === "singleplayer_premium";

    if (isSinglePlayerProduct) {
      priceId = priceIdMap[opts.product][opts.paymentPeriod];
    } else {
      priceId = priceIdMap[opts.product];
      teamId = opts.teamId;
    }

    if (!priceId) {
      throw new Error(`Couldn't get priceId for product ${opts.product}! Check your configuration!`);
    }
    if (teamId === "") {
      throw new Error("Team ID is set but empty!");
    }

    const successRoute = isSinglePlayerProduct ? localizedRoute("membership-area") : localizedRoute("membership-area");
    const successUrl = `${location.origin}${successRoute}/${location.search}`;

    logger.log('[useStripeCheckout] Create session with', { priceId, teamId });
    const res = await createStripeCheckoutSession({
      variables: {
        priceId,
        successUrl,
        cancelUrl: `${location.origin}/${location.search}`,
        teamId,
        subscriptionMetadata: mapSubscriptionMetadata({
          ...opts.metadata,
          ...mapUTMParamsToMetadata(utmParams)
        })
      }
    });

    if (!res.data) {
      throw new Error("Mutation response is empty!");
    }

    return res.data.createStripeCheckoutSession.url;
  };

  return { loading, createCheckoutSession };
};

function mapSubscriptionMetadata(meta: { [key: string]: string }): { key: string; value: string }[] {
  return Object.entries(meta).map(([key, value]) => ({
    key,
    value
  }));
}

function mapUTMParamsToMetadata(utmParams: ReturnType<typeof useUTMParameters>): { [key: string]: string } {
  return {
    ...(utmParams.source && { utmSource: utmParams.source }),
    ...(utmParams.medium && { utmMedium: utmParams.medium }),
    ...(utmParams.campaign && { utmCampaign: utmParams.campaign })
  };
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_STRIPE_CHECKOUT_SESSION_MUTATION = gql`
  mutation createStripeCheckoutSession($priceId: String!, $successUrl: String!, $cancelUrl: String!, $teamId: String, $subscriptionMetadata: [MetaDataKeyValue!]) {
    createStripeCheckoutSession(priceId: $priceId, successUrl: $successUrl, cancelUrl: $cancelUrl, teamId: $teamId, subscriptionMetadata: $subscriptionMetadata) {
      url
    }
  }
`;

const priceIdMap = {
  singleplayer_premium: {
    "1month": process.env.REACT_APP_STRIPE_SINGLEPLAYER_1_MONTH_PRICE_ID,
    "12months": process.env.REACT_APP_STRIPE_SINGLEPLAYER_12_MONTHS_PRICE_ID
  },
  team_premium: process.env.REACT_APP_STRIPE_TEAMPREMIUM_PRICE_ID
};
