import styled from "@emotion/styled";
import { useMediaQuery } from "@geist-ui/core";
import { SignUpBg } from "assets/backgroundImages";
import { AppleIcon, FacebookIcon } from "assets/icons";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Separator } from "components/atoms/Separator";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { useAppleSignin } from "misc/third-party/apple-signin";
import { useGoogleSignIn } from "misc/third-party/google-signin";
import { FunctionComponent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ParamsBundlePeriod, ParamsBundleType } from "util/constants";
import { useLoginWithFacebook } from "../misc/third-party/facebook-login";
import { H2 } from "components/atoms/Heading";
import { useToast } from "hooks/useToast";
import { AuthButton } from "components/atoms/AuthButton";
import { useSearchParam } from "hooks/useSearchParam";
import { useOnboardingStore } from "stores/onboarding.store";
import { useUpdateUserLanguageMutation } from "generated/graphql";
import { TermsAndConditionsText } from "components/molecules/TermsAndConditionsText";

export const SignUp: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const googleButtonRef = useRef<HTMLDivElement>(null);
  const searchParams = new URLSearchParams(window.location.search);
  const showToast = useToast();
  const { setBundle, setPaymentPeriod, setAttribution } = useOnboardingStore();
  const [mutateLanguage] = useUpdateUserLanguageMutation();

  const bundle = useSearchParam<ParamsBundleType>("bundle", {
    defaultValue: ParamsBundleType.NONE,
    allowedValues: Object.values(ParamsBundleType)
  });

  const paymentPeriod = useSearchParam<ParamsBundlePeriod>("paymentPeriod", {
    defaultValue: ParamsBundlePeriod["1month"],
    allowedValues: Object.values(ParamsBundlePeriod)
  });

  const sportsClubId = useSearchParam<string>("sportsClub", {
    defaultValue: ""
  });
  const teamId = useSearchParam<string>("team", {
    defaultValue: ""
  });

  const referredBy = useSearchParam<string>("ref", {
    defaultValue: ""
  });
  const refByAffiliateId = useSearchParam<string>("affiliate", {
    defaultValue: ""
  });

  const onSuccess = () => {
    // set language code
    const language = getPathLanguage();
    if (language) {
      mutateLanguage({ variables: { code: language } });
    }

    setBundle(bundle);
    setPaymentPeriod(paymentPeriod);
    setAttribution({ referredBy, refByAffiliateId });

    if (sportsClubId && teamId) {
      // invite link was handled
      navigate({ pathname: "../invite", search: location.search });
    } else if (bundle === ParamsBundleType.NONE) {
      navigate({ pathname: "../onboarding/bundle", search: location.search });
    } else if (bundle === ParamsBundleType.TEAM) {
      navigate({ pathname: "../sports-club-onboarding", search: searchParams.toString() });
    } else {
      navigate({ pathname: "../onboarding", search: searchParams.toString() });
    }
  };

  const navigateToEmailSignUp = () => {
    setBundle(bundle);
    setPaymentPeriod(paymentPeriod);
    setAttribution({ referredBy, refByAffiliateId });

    navigate({ pathname: `../sign-up-email`, search: location.search });
  };

  const { loginWithFacebook, loading: loginWithFacebookLoading } = useLoginWithFacebook({
    onSuccess, // TODO: Check if user really signed up. If not send him to sign-in-success
    onError: () => {
      showToast({ text: t("form.error.signUpFailed"), type: "error" });
    }
  });

  const { signInWithApple, loading: signInWithAppleLoading } = useAppleSignin({
    onSuccess, // TODO: Check if user really signed up. If not send him to sign-in-success
    onError: () => {
      showToast({ text: t("form.error.signUpFailed"), type: "error" });
    }
  });

  const { loading: signInWithGoogleLoading } = useGoogleSignIn({
    parent: googleButtonRef.current,
    buttonText: "signup_with",
    buttonWidthPx: isXS ? screen.width - 20 * 2 : 400,
    onSuccess, // TODO: Check if user really signed up. If not send him to sign-in-success
    onError: () => {
      showToast({ text: t("form.error.signUpFailed"), type: "error" });
    }
  });

  const handleSignIn = () => navigate({ pathname: "../sign-in-email", search: location.search });

  return (
    <>
      <PageContainer background={<BackgroundImage src={SignUpBg} />}>
        <OnboardingStep title={t("pages.signUp.heading")} subtitle={t("pages.signUp.subtitle")}>
          <ContentContainer>
            <AuthButton Icon={FacebookIcon} onClick={loginWithFacebook}>
              {t("pages.signUp.signUpWithFacebook")}
            </AuthButton>
            <GoogleButtonParent ref={googleButtonRef} />
            <AuthButton Icon={AppleIcon} onClick={signInWithApple}>
              {t("pages.signUp.signUpWithApple")}
            </AuthButton>
            <Button variant="primary" fullWidth onClick={navigateToEmailSignUp}>
              {t("pages.signUp.signUpWithEmail")}
            </Button>
            <TermsAndConditionsText />
          </ContentContainer>
          <Title>{t("pages.signUpEmail.alreadyHasAccount")}</Title>
          <Separator />
          <Button fullWidth variant="primary-outline" onClick={() => handleSignIn()}>
            {t("pages.signUpEmail.toSignIn")}
          </Button>
        </OnboardingStep>
      </PageContainer>
      {loginWithFacebookLoading || signInWithAppleLoading || signInWithGoogleLoading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
};

const getPathLanguage = () => {
  const pathname = location.pathname.split("/");

  if (pathname.length < 2) {
    return undefined;
  }

  const language = pathname[1];

  if (language.length !== 2) {
    return undefined;
  }

  return language;
};

const ContentContainer = styled.div`
  max-width: 400px;
  width: 100%;
`;

const GoogleButtonParent = styled.div`
  height: 40px;
  display: flex;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(2)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;

const Title = styled(H2)`
  margin-top: ${({ theme }) => theme.spacing(8)};
  margin-bottom: 0;
`;
