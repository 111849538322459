import { FunctionComponent, useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Footer } from "./components/molecules/Footer";
import { Navbar, useNavbarHeight } from "./components/molecules/Navbar";
import { AuthenticatedGuardOutlet } from "./components/AuthenticatedGuardOutlet";
import { UnauthenticatedGuardOutlet } from "./components/UnauthenticatedGuardOutlet";
import { AuthContext } from "./contexts/AuthContext";
import { SignUp } from "./pages/SignUp";
import { SignUpEmail } from "./pages/SignUpEmail";
import { PlayerThankYou } from "./pages/Onboarding/PlayerThankYou";
import { TeamThankYou } from "./pages/TeamOnboarding/TeamThankYou";
import styled from "@emotion/styled";
import {
  GenderSelection,
  PositionSelection,
  SignUpPreCheckout,
  SignInPreCheckout,
  FitnessSelection,
  UserDataSelection,
  RoleSelection,
  UserDataSelectionLite,
  PlayerOnboardingFinish,
  OnboardingBundleSelection,
  SportsClubTeamPlayerSelection,
  SportsClubTeamUserDataSelection
} from "pages/Onboarding";
import {
  CoachInvite,
  ExistingSportsClubSelection,
  FurtherTeamCreation,
  SportsClubAcquisition,
  SportsClubCreation,
  SportsClubData,
  SportsClubTeamCreation,
  TeamMemberInvite,
  TeamOnboardingFinish
} from "pages/TeamOnboarding";
import { MembershipAreaOutlet, Profile, Subscription, Account } from "pages/MembershipArea";
import { OAuth } from "./pages/OAuth";
import { SportsClubLogo } from "pages/TeamOnboarding/SportsClubLogo";
import { BundleSelection } from "pages/Start";
import { InviteAccept, InviteChange, InviteDecline, InviteRequest } from "pages/Invite";
import { ForgotPassword } from "pages/ForgotPassword";
import { ResetPassword } from "pages/ResetPassword";
import { SportsClubName, SportsClubUser, SportsClubAcquisition as NewSportsClubAcqusition } from "pages/SportsClubOnboarding";
import { SignInEmail } from "pages/SignInEmail";

const App: FunctionComponent = () => {
  const { isSignedIn, isInitialized: authContextInitialized } = useContext(AuthContext);
  const navbarHeight = useNavbarHeight();
  const location = useLocation();

  return (
    <>
      <Navbar />
      <Container navbarHeight={navbarHeight}>
        {authContextInitialized ? (
          <Routes>
            <Route path="/" element={<Navigate to={{ pathname: isSignedIn ? "de/membership-area" : "de/sign-up", search: location.search }} replace />} />
            <Route path=":locale">
              <Route index element={<Navigate to={{ pathname: isSignedIn ? "membership-area" : "sign-up", search: location.search }} replace />} />
              <Route path="o-auth" element={<OAuth />} />

              {/* Unauthenticated routes */}
              <Route element={<UnauthenticatedGuardOutlet />}>
                <Route path="sign-up" element={<SignUp />} />
                <Route path="sign-up-email" element={<SignUpEmail />} />
                <Route path="sign-in-email" element={<SignInEmail />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password" element={<ResetPassword />} />

                <Route path="start">
                  <Route index element={<Navigate to={{ pathname: "package-selection", search: location.search }} replace />} />
                  <Route path="package-selection" element={<BundleSelection />} />
                </Route>
              </Route>

              {/* Authenticated routes */}
              <Route element={<AuthenticatedGuardOutlet />}>
                <Route path="onboarding">
                  <Route index element={<Navigate to={{ pathname: "gender-selection", search: location.search }} replace />} />
                  <Route path="gender-selection" element={<GenderSelection />} />
                  <Route path="position-selection" element={<PositionSelection />} />
                  <Route path="fitness-status-selection" element={<FitnessSelection />} />
                  <Route path="user-data-selection" element={<UserDataSelection />} />
                  <Route path="sign-up-pre-checkout" element={<SignUpPreCheckout />} />
                  <Route path="sign-in-pre-checkout" element={<SignInPreCheckout />} />
                  <Route path="thank-you" element={<PlayerThankYou />} />
                  <Route path="role-selection" element={<RoleSelection />} />
                  <Route path="user-data-selection-lite" element={<UserDataSelectionLite />} />
                  <Route path="finish" element={<PlayerOnboardingFinish />} />
                  <Route path="bundle" element={<OnboardingBundleSelection />} />
                  <Route path="team-player-selection" element={<SportsClubTeamPlayerSelection />} />
                  <Route path="team-player-data" element={<SportsClubTeamUserDataSelection />} />
                </Route>
                <Route path="team-onboarding">
                  <Route index element={<Navigate to={{ pathname: "sports-club-creation", search: location.search }} replace />} />
                  <Route path="sports-club-creation" element={<SportsClubCreation />} />
                  <Route path="sports-club-data" element={<SportsClubData />} />
                  <Route path="sports-club-team" element={<SportsClubTeamCreation />} />
                  <Route path="sports-club-logo" element={<SportsClubLogo />} />
                  <Route path="coach-invite" element={<CoachInvite />} />
                  <Route path="team-member-invite" element={<TeamMemberInvite />} />
                  <Route path="further-team-creation" element={<FurtherTeamCreation />} />
                  <Route path="acquisition" element={<SportsClubAcquisition />} />
                  <Route path="thank-you" element={<TeamThankYou />} />
                  <Route path="finish" element={<TeamOnboardingFinish />} />
                  <Route path="existing-sports-club" element={<ExistingSportsClubSelection />} />
                </Route>
                <Route path="sports-club-onboarding">
                  <Route index element={<Navigate to={{ pathname: "name", search: location.search }} replace />} />
                  <Route path="name" element={<SportsClubName />} />
                  <Route path="user" element={<SportsClubUser />} />
                  <Route path="acquisition" element={<NewSportsClubAcqusition />} />
                </Route>
                <Route path="invite">
                  <Route index element={<Navigate to={{ pathname: "request", search: location.search }} replace />} />
                  <Route path="request" element={<InviteRequest />} />
                  <Route path="accept" element={<InviteAccept />} />
                  <Route path="decline" element={<InviteDecline />} />
                  <Route path="change" element={<InviteChange />} />
                </Route>
                <Route path="membership-area" element={<MembershipAreaOutlet />}>
                  <Route index element={<Navigate to={{ pathname: "profile", search: location.search }} replace />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="subscription" element={<Subscription />} />
                  <Route path="account" element={<Account />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        ) : (
          <></>
        )}
        <Footer />
      </Container>
      <div id="modal-parent"></div>
    </>
  );
};

export default App;

const Container = styled.main<{ navbarHeight: number }>`
  min-height: calc(100vh - ${({ navbarHeight }) => navbarHeight}px);
  display: flex;
  flex-direction: column;
`;
