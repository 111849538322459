import { Themes, GeistUIThemes } from "@geist-ui/core";
import { colors } from "./colors";

const palette: Partial<GeistUIThemes["palette"]> = {
  foreground: colors.light,
  background: colors.dark,
  accents_1: colors.darkGray,
  accents_2: colors.darkerGray,
  accents_3: colors.light,
  accents_4: colors.lightGray,
  accents_5: colors.light, // select component
  accents_6: colors.primary,
  accents_7: colors.primary,
  accents_8: colors.primary,
  success: colors.purple,
  successLight: colors.purple,
  secondary: colors.lightGray,
  link: colors.primary,
  border: colors.light,
  selection: colors.darkGray,
  error: colors.error,
  errorDark: colors.error,
  errorLight: colors.error,
  errorLighter: colors.error
};

const font: GeistUIThemes["font"] = {
  mono: "Montserrat",
  sans: "Montserrat",
  prism: "Montserrat"
};

export const B42_THEME = Themes.createFromLight({
  type: "male",
  palette: palette,
  font: font,
  layout: {
    unit: "13px"
  }
});
