import styled from "@emotion/styled";
import { Grid, useMediaQuery } from "@geist-ui/core";
import { RoleSelectionDiversBg } from "assets/backgroundImages";
import { LinkIcon } from "assets/icons";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { ProgressBar } from "components/atoms/ProgressBar";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { useSendTeamInviteByMailMutation, useSportsClubTeamInviteLinkQuery } from "generated/graphql";
import { useToast } from "hooks/useToast";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";
import { EMAIL_REGEX } from "util/constants";

export const TeamMemberInvite: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { sportsClubTeamName, sportsClubTeamId } = useOnboardingStore();
  const { data } = useSportsClubTeamInviteLinkQuery();

  const [mutate, { loading }] = useSendTeamInviteByMailMutation({
    onCompleted: (res) => {
      if (res.sendSportsClubTeamInviteLinkByMail?.__typename === "SportsClubTeamInviteLinkByMailSuccessResponse") {
        setInviteSent(true);
        toast({ text: t("pages.teamOnboarding.teamMemberInvite.sendInvite.success") });
      } else {
        toast({ text: t("pages.teamOnboarding.teamMemberInvite.sendInvite.error"), type: "error" });
      }
    },
    onError: () => toast({ text: t("pages.teamOnboarding.teamMemberInvite.sendInvite.error"), type: "error" })
  });

  const [email, setEmail] = useState("");
  const [inviteLinkCopied, setInviteLinkCopied] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);

  const inviteLink = data?.sportsClubInviteLink;
  const isEmailValid = email.match(EMAIL_REGEX)

  const goToNextStep = () => {
    if ((inviteLinkCopied || inviteSent) === false) {
      return;
    }

    navigate({ pathname: "../further-team-creation" });
  };

  const skip = () => navigate({ pathname: "../further-team-creation" });

  const handleCopyInviteLink = async () => {
    if (navigator.canShare && navigator.canShare({ url: inviteLink })) {
      await navigator.share({
        title: t("pages.teamOnboarding.teamMemberInvite.copyLink.title"),
        text: t("pages.teamOnboarding.teamMemberInvite.copyLink.message", { sportsClubTeamName }),
        url: inviteLink
      });
      setInviteLinkCopied(true);
    } else {
      await navigator.clipboard.writeText(inviteLink ?? "");
      toast({ text: t("pages.teamOnboarding.teamMemberInvite.copyLink.success"), type: "default" });
      setInviteLinkCopied(true);
    }
  };

  const handleEmailInvite = async () => {
    if (!sportsClubTeamId || !email) {
      return;
    }

    mutate({
      variables: {
        input: {
          teamId: sportsClubTeamId,
          emails: [email]
        }
      }
    });
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={RoleSelectionDiversBg} />}>
        {isXS ? <ProgressBar currentStep={11} totalSteps={14} /> : null}
        <OnboardingStep
          title={t("pages.teamOnboarding.teamMemberInvite.title")}
          subtitle={t("pages.teamOnboarding.teamMemberInvite.subTitle", { teamName: sportsClubTeamName })}
        >
          <Grid.Container gap={2} direction={isXS ? "column" : "row"} justify="center">
            <Grid sm={24} xs={24} width="100%">
              <InputContainer>
                <Input placeholder={t("pages.teamOnboarding.teamMemberInvite.email")} value={email} type="email" onChange={(e) => setEmail(e.target.value)} />
                <InputChildContainer>
                  <InviteButton disabled={isEmailValid === null} onClick={handleEmailInvite}>
                    {t("pages.teamOnboarding.teamMemberInvite.sendInvite.title")}
                  </InviteButton>
                </InputChildContainer>
              </InputContainer>
            </Grid>
            <Grid sm={24} xs={24} width="100%">
              <InputContainer>
                <Input placeholder={t("pages.teamOnboarding.teamMemberInvite.inviteLink")} value={inviteLink} disabled />
                <InputIconContainer>
                  <Icon as={LinkIcon} onClick={handleCopyInviteLink} />
                </InputIconContainer>
              </InputContainer>
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={2} marginBottom={1}>
            <Grid sm={24} xs={24}>
              <Button type="button" variant={inviteLinkCopied || inviteSent ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
                {t("common.continue")}
              </Button>
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={1} marginBottom={4}>
            <Grid sm={24} xs={24}>
              <Button type="button" variant="primary-outline" onClick={skip} fullWidth>
                {t("common.later")}
              </Button>
            </Grid>
          </Grid.Container>
        </OnboardingStep>
        {!isXS ? <ProgressBar renderOnBottom currentStep={11} totalSteps={14} /> : null}
        {loading ? <LoadingSpinnerOverlay /> : null}
      </PageContainer>
    </>
  );
};

const InputContainer = styled.div`
  position: relative;
  flex: 1;
`;

const InputIconContainer = styled.div`
  position: absolute;
  top: 22px;
  right: 16px;
  background-color: ${({ theme }) => theme.colors.dark};
`;

const InputChildContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 16px;
  background-color: ${({ theme }) => theme.colors.dark};
`;

const InviteButton = styled.button<{ disabled: boolean }>`
  padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(1.5)};
  border-radius: ${({ theme }) => theme.borderRadius(1.5)};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors["sonic-silver"] : theme.colors.primary)};
  color: ${({ theme }) => theme.colors.dark};
  font-size: 12px;
  line-height: 16px;
  font-weight: 800;
  height: 28px;
  border-width: 0px;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? undefined : "pointer")};
  }
`;

const Icon = styled.svg`
  fill: ${({ color }) => color};

  &:hover {
    cursor: pointer;
  }
`;
