import { FunctionComponent, useContext } from "react";
import styled from "@emotion/styled";
import { Grid, useMediaQuery } from "@geist-ui/core";
import { useTranslation } from "react-i18next";
import { AuthContext } from "contexts/AuthContext";
import { useLocalizedRoute } from "hooks/useLocalizedRoute";
import { NavbarContentDesktop } from "./NavbarContentDesktop";
import { NavbarContentMobile } from "./NavbarContentMobile";
import { useNavbarHeight } from "./useNavbarHeight";
import { gql, useQuery } from "@apollo/client";
import { CurrentUserQuery } from "generated/graphql";
import { Logo } from "assets";
import { useLocation, useNavigate } from "react-router-dom";
import { AvailableLanguage } from "i18n";
import { SUPPORT_DE_LINK, SUPPORT_EN_LINK, WEBSITE_DE_LINK, WEBSITE_EN_LINK } from "util/constants";

export const Navbar: FunctionComponent = () => {
  const { isSignedIn, logout } = useContext(AuthContext);
  const { i18n } = useTranslation();
  const isXS = useMediaQuery("xs");
  const navbarHeight = useNavbarHeight();
  const localizedRoute = useLocalizedRoute();
  const signUpPath = localizedRoute("sign-up");
  const profilePath = localizedRoute("membership-area");
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const { data } = useQuery<CurrentUserQuery>(CURRENT_USER_QUERY, {
    skip: !isSignedIn
  });

  const changeLanguage = (lang: AvailableLanguage) => {
    const locale = `/${i18n.language}/`;
    navigate({ pathname: pathname.replace(locale, `/${lang}/`), search: search }, { replace: true })
    i18n.changeLanguage(lang);
  };
  
  const language = i18n.language;
  const website = language === "de" ? WEBSITE_DE_LINK : WEBSITE_EN_LINK;
  const supportLink = language === "de" ? SUPPORT_DE_LINK : SUPPORT_EN_LINK;

  return (
    <Container>
      <Nav navbarHeight={navbarHeight}>
        <NavBarContainer>
          <Grid.Container gap={1}>
            <Grid xs={4} alignItems="center" justify="flex-start">
              <NavLogoLink href={website}>
                <NavLogoIcon src={Logo} />
              </NavLogoLink>
            </Grid>
            {isXS ? (
              <NavbarContentMobile
                profilePath={profilePath}
                signUpPath={signUpPath}
                isSignedIn={isSignedIn}
                logout={logout}
                changeLanguage={changeLanguage}
                firstname={data?.user?.userData.profileData.firstname}
                pictureUri={data?.user?.userData.profileData.picture?.uri}
                supportLink={supportLink}
              />
            ) : (
              <NavbarContentDesktop
                profilePath={profilePath}
                signUpPath={signUpPath}
                isSignedIn={isSignedIn}
                logout={logout}
                changeLanguage={changeLanguage}
                firstname={data?.user?.userData.profileData.firstname}
                pictureUri={data?.user?.userData.profileData.picture?.uri}
                supportLink={supportLink}
              />
            )}
          </Grid.Container>
        </NavBarContainer>
      </Nav>
    </Container>
  );
};
const Container = styled.header``;

const Nav = styled.nav<{ navbarHeight: number }>`
  background: ${({ theme }) => theme.colors.dark};
  height: ${({ navbarHeight }) => navbarHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
  max-width: 1000px;
  padding: 0 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    padding: 0 20px;
  }
`;

const NavLogoLink = styled.a`
  color: #fff;
  align-items: center;
  display: inline-flex;
`;

const NavLogoIcon = styled.img`
  height: 50px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    height: 18px;
  }
`;

const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    user {
      _id
      userData {
        profileData {
          firstname
          picture {
            uri
          }
        }
      }
    }
  }
`;

export default Navbar;
