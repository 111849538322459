import { FunctionComponent } from "react";
import {  PositionSelectionWomanBg } from "assets/backgroundImages";
import { StripeIcon } from "assets/icons";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { useSearchParam } from "hooks/useSearchParam";
import { useStripeCheckout } from "hooks/useStripeCheckout";
import { useTranslation } from "react-i18next";
import { ParamsBundlePeriod } from "util/constants";

export const SignInPreCheckout: FunctionComponent = () => {
  const { t } = useTranslation();
  const { createCheckoutSession, loading } = useStripeCheckout();

  const paymentPeriod = useSearchParam<ParamsBundlePeriod>("paymentPeriod", {
    defaultValue: ParamsBundlePeriod["1month"],
    allowedValues: Object.values(ParamsBundlePeriod)
  });

  const onContinueWithStripe = async () => {
    const checkoutUrl = await createCheckoutSession({ product: "singleplayer_premium", paymentPeriod });
    window.location.href = checkoutUrl;
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={PositionSelectionWomanBg} />}>
        <OnboardingStep title={t("pages.signInPreCheckout.heading")} subtitle={t("pages.signInPreCheckout.subtitle")}>
          <Button variant="primary" fullWidth onClick={onContinueWithStripe} Icon={StripeIcon} iconSize={76} iconSide="right">
            {t("pages.signInPreCheckout.button")}
          </Button>
        </OnboardingStep>
      </PageContainer>
      {loading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
};
