import { gql, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Spinner, useMediaQuery } from "@geist-ui/core";
import { MembershipBackground } from "assets";
import { Avatar } from "components/atoms/Avatar";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Collapse } from "components/atoms/Collapse";
import { B42Score } from "components/molecules/B42Score";
import { PageContainer } from "components/organisms/PageContainer";
import { AuthContext } from "contexts/AuthContext";
import { OAuthContext } from "contexts/OAuthContext";
import { FeaturePermission, MembershipUserDataQuery } from "generated/graphql";
import { FunctionComponent, useContext, useMemo } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { Outlet, useLocation } from "react-router-dom";
import { logger } from "util/logger";

type Stat = {
  value?: number;
  label: string;
};

export const MembershipAreaOutlet: FunctionComponent = () => {
  const { data, loading } = useQuery<MembershipUserDataQuery>(MEMBERSHIP_USER_DATA_QUERY);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isXS = useMediaQuery("xs");
  const { setOnboardingFinished } = useContext(AuthContext);
  const { redirectOAuth } = useContext(OAuthContext);
  const [, setSearch] = useSearchParams();
  const navigate = useNavigate();

  const finishedRtr = data?.user?.userData.roadToRecoveryStates.filter((r) => r.completeDate).length ?? 0;

  useEffect(() => {
    setOnboardingFinished();
  }, [setOnboardingFinished]);

  const stats: Stat[] = useMemo(
    () => [
      {
        value: data?.user?.userData?.singlePerformanceTestResults?.length ?? undefined,
        label: t("membership.statistics.performanceTests")
      },
      {
        value: finishedRtr,
        label: t("membership.statistics.finishedPrograms")
      },
      {
        value: data?.user?.numberOfWorkouts?.total ?? undefined,
        label: t("membership.statistics.workouts")
      }
    ],
    [data, finishedRtr, t]
  );

  const renderStats = () => (
    <StatisticsContainerRow>
      <>
        {stats.map((stat, index) => (
          <StatisticsItemContainer key={index}>
            <StatisticsItemLabel>{stat.label}</StatisticsItemLabel>
            {loading ? <Spinner /> : <StatisticsItemValue>{stat.value ?? "-"}</StatisticsItemValue>}
          </StatisticsItemContainer>
        ))}
      </>
    </StatisticsContainerRow>
  );

  const handleCoachZonePress = () => {
    setSearch({ redirect_uri: `${process.env.REACT_APP_COACHZONE_OAUTH_URL}/${i18n.language}/auth/oauth-callback` });
    redirectOAuth();
  };

  const handleSubscriptionPress = () => navigate({ pathname: "subscription", search: location.search });

  const hasCoachZoneAccess =
    data?.user?.userData.sportsClubMembership?.currentSportsClubTeam?.permissions.some((p) => p === FeaturePermission.TEAM_SETTINGS_WRITE) ?? false;
  
  const hasSubscription = data?.user?.premium.enabled ?? false;

  return (
    <PageContainer wide background={isXS ? undefined : <BackgroundImage src={MembershipBackground} />}>
      <Header>
        <HeaderText>
          <Avatar image={data?.user?.userData?.profileData?.picture?.uri} />
          {t("membership.hello")} {data?.user?.userData?.profileData?.firstname ?? "-"}
        </HeaderText>
        <HeaderLine />
      </Header>
      {isXS ? (
        <Collapse title={(<MobileStatsTitle>{t("membership.statistics.statsTitle")}</MobileStatsTitle>) as unknown as string} onChange={() => logger.log("onChange")}>
          {renderStats()}
          <B42ScoreWrapper>
            <B42Score totalScore={data?.user?.b42Score.total ?? 0} />
          </B42ScoreWrapper>
        </Collapse>
      ) : (
        <StatsBox>{renderStats()}</StatsBox>
      )}
      <Container>
        <MenuContainer>
          <MenuBox>
            {!isXS && <MenuTitle>{t("membership.menu.menu")}</MenuTitle>}
            <MenuItemsContainer>
              <MenuItem active={location.pathname.endsWith("profile")} to={{ pathname: "profile", search: location.search }}>
                {t("membership.menu.profile")}
              </MenuItem>
              <MenuItem active={location.pathname.endsWith("account")} to={{ pathname: "account", search: location.search }}>
                {t("membership.menu.account")}
              </MenuItem>
              <MenuItem active={location.pathname.endsWith("subscription")} to={{ pathname: "subscription", search: location.search }}>
                {t("membership.menu.subscription")}
              </MenuItem>
            </MenuItemsContainer>
          </MenuBox>
          {hasCoachZoneAccess ? (
            <LinkContainer>
              <Button type="button" variant="primary" fullWidth onClick={handleCoachZonePress}>
                {t("common.toCoachZone")}
              </Button>
            </LinkContainer>
          ) : null}
          {hasSubscription === false ? (
            <LinkContainer>
              <Button type="button" variant="primary" fullWidth onClick={handleSubscriptionPress}>
                {t("common.startSubscription")}
              </Button>
            </LinkContainer>
          ) : null}
          {!isXS && (
            <MenuBox>
              <MenuTitle>{t("membership.b42Score")}</MenuTitle>
              <B42ScoreWrapper>{loading ? <Spinner /> : <B42Score totalScore={data?.user?.b42Score.total ?? 0} />}</B42ScoreWrapper>
            </MenuBox>
          )}
        </MenuContainer>
        <OutletContainer>
          <ContentBox>
            <Outlet />
          </ContentBox>
        </OutletContainer>
      </Container>
    </PageContainer>
  );
};

const MobileStatsTitle = styled.div`
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
`;

const B42ScoreWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Header = styled.div`
  margin: 54px 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin: 28px 0;
  }
`;

const HeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: 0.4px;
`;

const HeaderLine = styled.hr`
  border: 0;
  border-top: 4px solid ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  width: 40px;
`;

const StatisticsContainerRow = styled.div`
  flex-direction: row;
  justify-content: space-between;
  display: flex;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    display: block;
  }
`;

const StatisticsItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin-top: ${({ theme }) => theme.spacing(1)};
    margin-bottom: ${({ theme }) => theme.spacing(3)};
    justify-content: space-between;
  }
`;

const StatisticsItemLabel = styled.div`
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white80Percent};
  margin-right: 8px;
`;

const StatisticsItemValue = styled.div`
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 1.5px;
  line-height: 16px;
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 24px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    display: block;
  }
`;

const MenuContainer = styled.div`
  width: 253px;
  flex-shrink: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    width: 100%;
  }
`;

const OutletContainer = styled.div`
  flex: 3;
`;

const Box = styled.div`
  background-color: ${({ theme }) => theme.colors.black40Percent};
  border-radius: 24px;
  padding: 32px 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    border-radius: 16px;
  }
`;

const StatsBox = styled(Box)`
  width: 100%;
  margin-bottom: 24px;
  padding: 16px 24px;
`;

const ContentBox = styled(Box)`
  margin-left: 20px;
  flex: 1;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin-left: 0px;
  }
`;

const MenuBox = styled(Box)`
  margin-bottom: 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    padding: 16px 24px;
  }
`;

const MenuTitle = styled.div`
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white80Percent};
  margin-bottom: 28px;
`;

const MenuItemsContainer = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const MenuItem = styled(NavLink)<{ active: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0.5;
  text-transform: uppercase;
  text-decoration: ${({ theme, active }) => (active ? `underline ${theme.colors.primary}` : undefined)};
  text-underline-offset: 3px;
  text-decoration-thickness: 3px;
  margin-bottom: 28px;
  display: block;
  transition: all 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    text-underline-offset: 3px;
    text-decoration-thickness: 3px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin-bottom: 0px;
    font-size: 12px;
  }
`;

const LinkContainer = styled.div`
  margin-bottom: 24px;
`;

const MEMBERSHIP_USER_DATA_QUERY = gql`
  query MembershipUserData {
    user {
      _id
      premium {
        enabled
      }
      userData {
        profileData {
          firstname
          picture {
            uri
          }
        }
        singlePerformanceTestResults {
          _id
        }
        roadToRecoveryStates {
          _id
          completeDate
        }
        sportsClubMembership {
          currentSportsClubTeam {
            _id
            permissions
          }
        }
      }
      numberOfWorkouts {
        total
      }
      b42Score {
        total
      }
    }
  }
`;
