import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { PlayerPaymentBg } from "assets/backgroundImages";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { PageContainer } from "components/organisms/PageContainer";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { useLocation, useNavigate } from "react-router-dom";
import { ProgressBar } from "components/atoms/ProgressBar";
import { useMediaQuery } from "@geist-ui/core";
import { DOWNLOAD_APP_LINK } from "util/constants";
import { ArrowIcon } from "assets/icons";
import { OAuthContext } from '../../contexts/OAuthContext';

export const TeamThankYou: FunctionComponent = () => {
  const { t } = useTranslation();
  const isXS = useMediaQuery("xs");
  const navigate = useNavigate();
  const location = useLocation();
  const { isOAuthRequest, redirectOAuth } = useContext(OAuthContext);

  const navigateToMembership = () => {
    if (isOAuthRequest) {
      return redirectOAuth();
    } else {
      navigate({ pathname: "../../membership-area", search: location.search });
    }
  };

  const navigateToDownload = () => {
    window.open(DOWNLOAD_APP_LINK, "_blank");
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={PlayerPaymentBg} />}>
        {isXS ? <ProgressBar currentStep={5} totalSteps={5} /> : null}
        <OnboardingStep title={t("pages.thankYouTeam.heading")} subtitle={t("pages.thankYouTeam.subheading")}>
          <Button variant="primary-outline" fullWidth onClick={navigateToMembership} Icon={ArrowIcon}>
            {t(isOAuthRequest ? "pages.thankYouTeam.continue" : "pages.thankYouTeam.toMembershipArea")}
          </Button>
          <Button variant="primary" fullWidth onClick={navigateToDownload}>
            {t("pages.thankYouTeam.downloadApp")}
          </Button>
        </OnboardingStep>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={5} totalSteps={5} /> : null}
    </>
  );
};
