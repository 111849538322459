import styled from "@emotion/styled";
import { SignUpBg } from "assets/backgroundImages";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { ForgotPasswordErrorCodes, ForgotPasswordMutation } from "generated/graphql";
import { useForgotPassword } from "hooks/useForgotPassword";
import { useToast } from "hooks/useToast";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EMAIL_REGEX } from "util/constants";
import { logger } from "util/logger";

export const ForgotPassword: FunctionComponent = () => {
  const { t } = useTranslation();
  const showToast = useToast();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<{ email: string }>();

  const onCompleted = ({ forgotPassword }: ForgotPasswordMutation) => {
    if (forgotPassword.__typename === "ForgotPasswordSuccessResponse") {
      showToast({ text: t("forgotPasswordModal.success"), type: "success", delay: 7000 });
      reset();
    } else if (forgotPassword.__typename === "ForgotPasswordErrorResponse" && forgotPassword.code === ForgotPasswordErrorCodes.EMAIL_UNKNOWN) {
      showToast({ text: t("forgotPasswordModal.emailUnknown"), type: "error" });
    } else {
      showToast({ text: t("forgotPasswordModal.unknownError"), type: "error" });
    }
  };

  const onError = () => {
    showToast({ text: t("forgotPasswordModal.unknownError"), type: "error" });
  };

  const { forgotPassword } = useForgotPassword(onCompleted, onError);

  return (
    <>
      <PageContainer background={<BackgroundImage src={SignUpBg} />}>
        <OnboardingStep title={t("pages.forgotPassword.title")} subtitle={t("pages.forgotPassword.subtitle")}>
          <ForgotPasswordForm
            onSubmit={handleSubmit(
              ({ email }) => forgotPassword(email),
              (e) => logger.error("Submit error", e)
            )}
          >
            <Input
              error={errors.email?.message}
              type="email"
              autoComplete="email"
              placeholder={t("form.placeholder.email")}
              {...register("email", {
                required: { value: true, message: t("form.error.emailRequired") },
                pattern: { value: EMAIL_REGEX, message: t("form.error.emailInvalid") }
              })}
            />
            <Button variant="primary" type="submit" fullWidth>
              {t("signInModal.forgotPassword")}
            </Button>
          </ForgotPasswordForm>
        </OnboardingStep>
      </PageContainer>
    </>
  );
};

const ForgotPasswordForm = styled.form``;
