import styled from "@emotion/styled";
import { Grid, useMediaQuery } from "@geist-ui/core";
import { SignUpBg } from "assets/backgroundImages";
import { ArrowIcon } from "assets/icons";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";
import { ParamsBundleType } from "util/constants";

export const OnboardingBundleSelection: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setBundle } = useOnboardingStore();

  const goToSignUp = (bundle: "TEAM" | "PLAYER") => {
    setBundle(bundle)
    if (bundle === ParamsBundleType.TEAM) {
      navigate({ pathname: "../../sports-club-onboarding", search: location.search });
    } else if (bundle === ParamsBundleType.PLAYER) {
      navigate({ pathname: "../../onboarding", search: location.search });
    }
  }

  return (
    <>
      <PageContainer background={<BackgroundImage src={SignUpBg} />}>
        <OnboardingStep title={t("start.bundleSelection.title")} subtitle={t("start.bundleSelection.subTitle")}></OnboardingStep>
        <Grid.Container gap={2} direction={isXS ? "column" : "row"} justify="center" mb={2}>
          <Grid sm={12}>
            <BundleButton background="primary">
              <BundleButtonTitle>{t("start.bundleSelection.singlePlayer.title")}</BundleButtonTitle>
              <BundleButtonSubTitle>{t("start.bundleSelection.singlePlayer.subTitle")}</BundleButtonSubTitle>
              <Button type="button" variant="light" Icon={ArrowIcon} onClick={() => goToSignUp("PLAYER")}>
                {t("start.bundleSelection.singlePlayer.cta")}
              </Button>
            </BundleButton>
          </Grid>
          <Grid sm={12}>
            <BundleButton background="white">
              <BundleButtonTitle>{t("start.bundleSelection.team.title")}</BundleButtonTitle>
              <BundleButtonSubTitle>{t("start.bundleSelection.team.subTitle")}</BundleButtonSubTitle>
              <Button type="button" variant="primary" Icon={ArrowIcon} onClick={() => goToSignUp("TEAM")}>
                {t("start.bundleSelection.team.cta")}
              </Button>
            </BundleButton>
          </Grid>
        </Grid.Container>
      </PageContainer>
    </>
  );
};

const BundleButton = styled.div<{ background: "primary" | "white" }>`
  border-radius: ${({ theme }) => theme.borderRadius(3)};
  background-color: ${({ theme, background }) => theme.colors[background]};
  padding: ${({ theme }) => theme.spacing(5)} ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BundleButtonTitle = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 34px;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: 0.4px;
  text-align: center;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const BundleButtonSubTitle = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  opacity: 0.5;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;
