import styled from "@emotion/styled";
import { CalendarIcon } from "assets/icons";
import React, { FunctionComponent, useRef } from "react";

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  error?: boolean | string;
}

export const DateInput: FunctionComponent<Props> = (props) => {
  const dateRef = useRef<HTMLInputElement>(null);

  const handleDateFocus = () => {
    dateRef.current?.showPicker();
  };

  return (
    <OuterWrapper>
      <InnerWrapper>
        <Placeholder floating={true} error={!!props.error}>
          {props.placeholder}
        </Placeholder>
        <InputStyled
          {...props}
          ref={dateRef}
          error={!!props.error}
          onFocus={(e) => {
            props.onFocus?.(e);
            handleDateFocus();
          }}
          onBlur={(e) => {
            props.onBlur?.(e);
          }}
          onReset={(e) => {
            props.onReset?.(e);
          }}
          onChange={(e) => {
            props.onChange?.(e);
          }}
          placeholder={undefined}
          type={"date"}
        />
        <Icon as={CalendarIcon} onClick={handleDateFocus} />
      </InnerWrapper>
      {props.error && typeof props.error == "string" ? <ErrorLabel>{props.error}</ErrorLabel> : null}
    </OuterWrapper>
  );
};

const Icon = styled.svg<{ onClick?: () => void }>`
  fill: ${({ theme }) => theme.colors.light};
  position: absolute;
  right: ${({ theme }) => theme.spacing(2)};
  top: 16px;

  &:hover {
    cursor: pointer;
  }
`;

const OuterWrapper = styled.div`
  width: 100%;

  & + & {
    margin-top: ${({ theme }) => theme.spacing(2)};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
      margin-top: ${({ theme }) => theme.spacing(1)};
    }
  }
`;

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;

const InputStyled = styled.input<{ error?: boolean }>`
  border: 1px solid;
  width: 100%;
  padding: 20px 40px 8px 20px;
  color: ${({ theme }) => theme.colors.light};
  caret-color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.primary)};
  background-color: ${({ theme }) => theme.colors.dark};
  border-color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors["sonic-silver"])};
  border-radius: ${({ theme }) => theme.borderRadius(2.5)};
  font-size: 15px;
  line-height: 18px;
  height: 52px;
`;

const Placeholder = styled.label<{ floating: boolean; error?: boolean }>`
  position: absolute;
  top: 50%;
  left: 20px;
  font-size: 15px;
  font-weight: 500;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: left top;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.light50Percent};
  ${({ floating }) =>
    floating &&
    `
    transform: translateY(-80%) scale(0.65);
    text-transform: uppercase;
    font-style: italic;
    letter-spacing: 1.5px;
  `}
  pointer-events: none;
`;

const ErrorLabel = styled.label`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  padding: 0 20px;
`;
