import { SignInThirdPartyErrorCodes, ThirdPartySignInProviders } from "generated/graphql";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { logger } from "util/logger";
import { _useSignInThirdParty } from "./use-sign-in-third-party";
import { ISignInSignUpSuccessSuccessResponse } from "hooks/useSignIn";

interface Props {
  parent: HTMLElement | null;
  buttonText?: "signin_with" | "signup_with" | "continue_with" | "signin";
  buttonWidthPx?: number;
  onSuccess?: (data: ISignInSignUpSuccessSuccessResponse) => void;
  onError?: (e: SignInThirdPartyErrorCodes | Error) => void;
}

export const useGoogleSignIn = ({ parent, buttonWidthPx, buttonText = "signin_with", onSuccess, onError }: Props) => {
  const { signInThirdParty, loading } = _useSignInThirdParty({ onSuccess, onError });
  const { i18n } = useTranslation();

  useEffect(() => {
    try {
      if (!parent) {
        logger.log("Skipping Google SignIn initialization because parent element is `null`");
        return;
      }

      logger.log("Initialized Google SignIn...");
      google.accounts.id.initialize({
        client_id: "944801398778-cqq20fio4tdt92i877dcnmi7v5u2qdum.apps.googleusercontent.com", // TODO: Move to env
        callback: (idConfig) => {
          signInThirdParty({
            variables: {
              lang: i18n.language,
              token: idConfig.credential,
              type: ThirdPartySignInProviders.GOOGLE
            }
          });
        }
      });

      google.accounts.id.renderButton(parent, {
        type: "standard",
        size: "large",
        shape: "pill",
        width: `${buttonWidthPx}`,
        logo_alignment: "left",
        text: buttonText
      });
    } catch (e: any) {
      logger.log("Google SignIn got an error...", e);
    }
  }, [buttonWidthPx, buttonText, i18n.language, parent, signInThirdParty]);

  return { loading };
};
