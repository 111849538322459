import styled from "@emotion/styled/macro";
import React, { FunctionComponent } from "react";
import { Button, SvgComponent } from "./Button";

export const BUTTON_HEIGHT = 40;

interface Props {
  children?: React.ReactNode;
  Icon?: SvgComponent;
  onClick: () => void;
  disabled?: boolean
}

export const AuthButton: FunctionComponent<Props> = (props) => {
  return <StyledButton {...props} iconSize={28} fullWidth variant="light" iconPosition="absolute" iconSide="left" disabled={props.disabled ?? false} />;
};

const StyledButton = styled(Button)`
  border-radius: ${({ theme }) => theme.borderRadius(4)};
  height: ${BUTTON_HEIGHT}px;
`;
