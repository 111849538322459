import styled from "@emotion/styled";
import React, { FunctionComponent, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { XCircleFill as XCircleIcon } from "@geist-ui/react-icons";
import { Separator } from "./Separator";
import { Text } from "@geist-ui/core";
import { logger } from "util/logger";

interface Props {
  visible: boolean;
  title?: string;
  onClose: () => void;
  children: React.ReactNode;
}

export const Modal: FunctionComponent<Props> = ({ visible, title, onClose, children }) => {
  const el = useRef(document.createElement("div"));

  useEffect(() => {
    const parent = document.getElementById("modal-parent");
    const modalElement = el.current;
    if (!parent) {
      logger.warn("Parent container for modal not found!");
      return;
    }
    parent.appendChild(modalElement);

    return () => {
      parent.removeChild(modalElement);
    };
  }, []);

  return ReactDOM.createPortal(
    <Wrapper visible={visible} onClick={onClose}>
      <ModalContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {title && (
          <>
            <Text h4 b marginBottom={0} marginTop={0}>
              {title}
            </Text>
            <Separator marginBottom={50} />
          </>
        )}
        <ModalContent>{children}</ModalContent>
        <CloseIcon onClick={onClose} />
      </ModalContainer>
    </Wrapper>,
    el.current
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  max-width: 100%;
  flex: 1;
  overflow-y: auto;
`;

const Wrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.dark70Percent};
  display: ${({ visible }) => (visible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const ModalContainer = styled.div`
  position: relative;
  width: auto;
  background-color: ${({ theme }) => theme.colors.gray};
  border-radius: 24px;
  padding: 40px 100px;
  overflow: hidden;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    border-radius: 0px;
    padding: 40px 20px;
    width: 100%;
  }
`;

const CloseIcon = styled(XCircleIcon)`
  position: absolute;
  top: 18px;
  right: 18px;
  height: 20px;
  width: 20px;
  color: white;
  cursor: pointer;
`;
