import { useMediaQuery } from "@geist-ui/core";
import { PositionSelectionDiversBg } from "assets/backgroundImages";
import { StripeIcon } from "assets/icons";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { ProgressBar } from "components/atoms/ProgressBar";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { useSearchParam } from "hooks/useSearchParam";
import { useStripeCheckout } from "hooks/useStripeCheckout";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ParamsBundlePeriod } from "util/constants";

export const SignUpPreCheckout: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const { t } = useTranslation();

  const paymentPeriod = useSearchParam<ParamsBundlePeriod>("paymentPeriod", {
    defaultValue: ParamsBundlePeriod["1month"],
    allowedValues: Object.values(ParamsBundlePeriod)
  });

  const { createCheckoutSession, loading } = useStripeCheckout();

  const onContinueWithStripe = async () => {
    const checkoutUrl = await createCheckoutSession({ product: "singleplayer_premium", paymentPeriod });
    window.location.href = checkoutUrl;
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={PositionSelectionDiversBg} />}>
        {isXS ? <ProgressBar currentStep={4} totalSteps={5} /> : null}
        <OnboardingStep title={t("pages.signUpPreCheckout.heading")} subtitle={t("pages.signUpPreCheckout.subheading")}>
          <Button variant="primary" fullWidth onClick={onContinueWithStripe} Icon={StripeIcon} iconSize={76} iconSide="right">
            {t("pages.signUpPreCheckout.button")}
          </Button>
        </OnboardingStep>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={4} totalSteps={5} /> : null}
      {loading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
};
