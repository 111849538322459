import { FunctionComponent, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SignUpBg } from "assets/backgroundImages";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { PageContainer } from "components/organisms/PageContainer";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import styled from "@emotion/styled";
import { EMAIL_REGEX, ParamsBundleType } from "util/constants";
import { AppleIcon, FacebookIcon } from "assets/icons";
import { useToast } from "hooks/useToast";
import { useSearchParam } from "hooks/useSearchParam";
import { logger } from "util/logger";
import { OAuthContext } from "../../contexts/OAuthContext";
import { useOnboardingStore } from "stores/onboarding.store";
import { AuthButton } from "components/atoms/AuthButton";
import { useTheme } from "@emotion/react";
import { useForm } from "react-hook-form";
import { Text, useMediaQuery } from "@geist-ui/core";
import { useSignIn } from "hooks/useSignIn";
import { SignInErrorCodes } from "generated/graphql";
import { useLoginWithFacebook } from "misc/third-party/facebook-login";
import { useAppleSignin } from "misc/third-party/apple-signin";
import { useGoogleSignIn } from "misc/third-party/google-signin";
import { Input } from "components/atoms/Input";
import { ForgotPasswordModal } from "components/organisms/ForgotPasswordModal";

export const SignInEmail: FunctionComponent = () => {
  const [isForgotPasswordVisible, setIsForgotPasswordVisible] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<{ email: string; password: string }>();
  const isXS = useMediaQuery("xs");
  const googleButtonRef = useRef<HTMLDivElement>(null);
  const showToast = useToast();
  const navigate = useNavigate();
  const { isOAuthRequest, redirectOAuth } = useContext(OAuthContext);
  const { setBundle } = useOnboardingStore();

  const { signIn, loading } = useSignIn({
    onSuccess: () => onSignInSuccess(),
    onError: (e) => {
      if (e === SignInErrorCodes.EMAIL_UNKNOWN) {
        showToast({ text: t("form.error.unknownEmail"), type: "error" });
      } else if (e === SignInErrorCodes.INVALID_CREDENTIALS) {
        showToast({ text: t("form.error.invalidPassword"), type: "error" });
      } else {
        showToast({ text: t("form.error.loginFailed"), type: "error" });
      }
    }
  });

  const { loginWithFacebook, loading: loginWithFacebookLoading } = useLoginWithFacebook({
    onSuccess: () => onSignInSuccess(),
    onError: () => {
      showToast({ text: t("form.error.loginFailed"), type: "error" });
    }
  });

  const { signInWithApple, loading: signInWithAppleLoading } = useAppleSignin({
    onSuccess: () => onSignInSuccess(),
    onError: () => {
      showToast({ text: t("form.error.loginFailed"), type: "error" });
    }
  });

  const { loading: signInWithGoogleLoading } = useGoogleSignIn({
    parent: googleButtonRef.current,
    buttonWidthPx: isXS ? screen.width - 20 * 2 : 400,
    buttonText: "signin_with",
    onSuccess: () => onSignInSuccess(),
    onError: () => {
      showToast({ text: t("form.error.loginFailed"), type: "error" });
    }
  });

  const bundle = useSearchParam<ParamsBundleType>("bundle", {
    defaultValue: ParamsBundleType.NONE,
    allowedValues: Object.values(ParamsBundleType)
  });

  const sportsClubId = useSearchParam<string>("sportsClub", {
    defaultValue: ""
  });
  const teamId = useSearchParam<string>("team", {
    defaultValue: ""
  });

  const onSignInSuccess = () => {
    setBundle(bundle);

    if (sportsClubId && teamId) {
      // invite link was handled
      navigate({ pathname: "../invite", search: location.search });
    } else if (isOAuthRequest) {
      return redirectOAuth();
    } else if (bundle === ParamsBundleType.TEAM) {
      navigate({ pathname: "../sports-club-onboarding", search: location.search });
    } else if (bundle === ParamsBundleType.PLAYER) {
      navigate({ pathname: "../onboarding", search: location.search });
    } else {
      navigate({ pathname: "../membership-area", search: location.search });
    }
  };

  const signUp = () => {
    navigate({ pathname: "../sign-up", search: location.search });
  }

  return (
    <>
      <PageContainer background={<BackgroundImage src={SignUpBg} />}>
        <OnboardingStep title={t("signInModal.heading")} subtitle={""}>
          <ContentContainer>
            <AuthButton Icon={FacebookIcon} onClick={() => loginWithFacebook()}>
              {t("signInModal.signInWithFacebook")}
            </AuthButton>
            <GoogleButtonParent id="123" ref={googleButtonRef} />
            <AuthButton Icon={AppleIcon} onClick={() => signInWithApple()}>
              {t("signInModal.signInWithApple")}
            </AuthButton>
          </ContentContainer>
          <Text small marginTop="48px" marginBottom="15px" color={theme.colors.white80Percent}>
            {t("signInModal.signInWithEmail")}
          </Text>
          <SignInForm
            onSubmit={handleSubmit(
              ({ email, password }) => {
                signIn(email, password);
              },
              (e) => logger.error("Submit error", e)
            )}
          >
            <Input
              error={errors.email?.message}
              type="email"
              autoComplete="email"
              placeholder={t("form.placeholder.email")}
              {...register("email", {
                required: { value: true, message: t("form.error.emailRequired") },
                pattern: { value: EMAIL_REGEX, message: t("form.error.emailInvalid") }
              })}
            />
            <Input
              error={errors.password?.message}
              type="password"
              autoComplete="current-password"
              placeholder={t("form.placeholder.password")}
              {...register("password", { required: { value: true, message: t("form.error.passwordRequired") } })}
            />
            <ForgotPasswordText onClick={() => setIsForgotPasswordVisible(true)}>{t("signInModal.forgotPassword")}</ForgotPasswordText>
            <Button variant="primary" type="submit" fullWidth>
              {t("signInModal.continue")}
            </Button>
          </SignInForm>
          <OrContainer>
            <Separator />
            <OrText>{t("signInModal.or")}</OrText>
            <Separator />
          </OrContainer>
          <Button variant="primary-outline" fullWidth onClick={signUp}>
            {t("signInModal.noAccount")}
          </Button>
          <ForgotPasswordModal visible={isForgotPasswordVisible} onClose={() => setIsForgotPasswordVisible(false)} />
          {loading || loginWithFacebookLoading || signInWithAppleLoading || signInWithGoogleLoading ? <LoadingSpinnerOverlay /> : null}
        </OnboardingStep>
      </PageContainer>
    </>
  );
};

const SignInForm = styled.form``;

const GoogleButtonParent = styled.div`
  height: 40px;
  display: flex;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(2)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;

const ContentContainer = styled.div`
  max-width: 400px;
  width: 100%;
`;

const ForgotPasswordText = styled.p`
  text-align: right;
  font-size: 14px;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.white80Percent};

  &:hover {
    cursor: pointer;
  }
`;

const OrContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 16px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md.max}) {
    margin: 16px auto;
  }
`;

const OrText = styled.p`
  font-size: 13px;
  margin: 0 16px;
`;

const Separator = styled.div`
  min-width: 100%;
  width: 100%;
  flex: 1;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.white80Percent};
`;
