import { FunctionComponent, ReactNode } from "react";
import styled from "@emotion/styled";

const CircleContainer = styled.div`
  display: inline-block;
  border-radius: 100%;
  position: relative;
`;

const PercentageContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const StyledCircle = styled.circle`
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

type Props = {
  percentage: number;
  size?: number;
  strokeWidth?: number;
  strokeLinecap?: "inherit" | "round" | "square" | "butt";
  primaryColor?: string[];
  secondaryColor?: string;
  fill?: string;
  children?: ReactNode;
};

const GradientCircleProgressbar: FunctionComponent<Props> = ({
  percentage = 0,
  size = 160,
  strokeWidth = 8,
  primaryColor = ["#00BBFF", "#92d7f1"],
  secondaryColor = "rgba(0,0,0,0.3)",
  fill = "transparent",
  strokeLinecap = "round",
  children
}) => {
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = ((100 - percentage) / 100) * circumference;
  const gradientId = `${primaryColor[0]}${primaryColor[1]}`.replace(/#/g, "");

  return (
    <CircleContainer
      style={{
        height: `${size}px`,
        width: `${size}px`
      }}
    >
      <PercentageContainer>{children}</PercentageContainer>

      <svg width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor={primaryColor[0]} />
          <stop offset="100%" stopColor={primaryColor[1]} />
        </linearGradient>
        <circle strokeWidth={strokeWidth} fill="transparent" r={radius} cx={center} cy={center} stroke={secondaryColor} strokeDasharray={circumference} />
        <StyledCircle
          strokeWidth={strokeWidth}
          fill={fill}
          r={radius}
          cx={center}
          cy={center}
          stroke={`url(#${gradientId})`}
          strokeLinecap={strokeLinecap}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </svg>
    </CircleContainer>
  );
};

export default GradientCircleProgressbar;
