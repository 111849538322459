import { FunctionComponent, useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import { OAuthContext } from "../contexts/OAuthContext";
import { useLocalizedRoute } from "hooks/useLocalizedRoute";

interface Props {
  /** Route use should be redirected to if not authenticated. Default: `sign-up` */
  unauthenticatedTo?: string;
}

/** Allows access to route children only if authenticated. Redirects to `unauthenticated` otherwise */
export const AuthenticatedGuardOutlet: FunctionComponent<Props> = ({ unauthenticatedTo }) => {
  const authContext = useContext(AuthContext);
  const oAuthContext = useContext(OAuthContext);
  const localizedRoute = useLocalizedRoute();
  const location = useLocation();

  if (oAuthContext.hasOAuthAccessToken) {
    return <Navigate to={{ pathname: localizedRoute("o-auth"), search: location.search + `&forward_to=${location.pathname}`  }} replace />;
  }

  return authContext.isSignedIn ? <Outlet /> : <Navigate to={{ pathname: unauthenticatedTo || localizedRoute("sign-up"), search: location.search }} replace />;
};
