import styled from "@emotion/styled";
import { Grid, Select } from "@geist-ui/core";
import { SelectOption } from "components/atoms/Select";
import { AvailableLanguage, availableLanguages } from "i18n";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface Props {
  profilePath: string;
  signUpPath: string;
  isSignedIn: boolean;
  logout: () => void;
  changeLanguage: (lang: AvailableLanguage) => void;
  firstname?: string | null;
  pictureUri?: string;
  supportLink: string
}

export const NavbarContentDesktop: FunctionComponent<Props> = ({ signUpPath, isSignedIn, supportLink, logout, changeLanguage }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Grid xs={15} justify={"flex-end"} alignItems={"center"}>
        <a href={supportLink} target="_blank" rel="noreferrer" >
          <Link>
            <NavLinkText>{t("navbar.support")}</NavLinkText>
          </Link>
        </a>
      </Grid>
      <Grid xs={2} justify={"flex-end"} alignItems={"center"}>
        <Select placeholder={""} value={i18n.language} onChange={(value) => changeLanguage(value as AvailableLanguage)} style={{ border: 0, minWidth: 80 }}>
          {availableLanguages.map((lang) => (
            <SelectOption key={lang} value={lang}>
              {lang.toUpperCase()}
            </SelectOption>
          ))}
        </Select>
      </Grid>
      <Grid xs={3} justify={"flex-end"} alignItems={"center"}>
        {isSignedIn ? (
          <>
            <Link onClick={logout}>
              <NavLinkText>{t("navbar.logout")}</NavLinkText>
            </Link>
          </>
        ) : (
          <NavLinkStyled end to={signUpPath}>
            <NavLinkText>{t("navbar.registration")}</NavLinkText>
          </NavLinkStyled>
        )}
      </Grid>
    </>
  );
};

const NavLinkText = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const NavLinkStyled = styled(NavLink)`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing()};
  padding: 0px ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.colors.white};
  transition: color 0.3s;

  &.active {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Link = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing()};
  padding: 0px ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.colors.white};
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
