import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Spinner, useMediaQuery } from "@geist-ui/core";
import { EditIcon } from "assets/icons";
import { Button } from "components/atoms/Button";
import { DateInput } from "components/atoms/DateInput";
import { Input } from "components/atoms/Input";
import { RadioButton } from "components/atoms/RadioButton";
import { Select, SelectOption } from "components/atoms/Select";
import {
  CurrentUserDocument,
  Foot,
  Gender,
  GenderIdentity,
  Position,
  ProfileUserDocument,
  ProfileUserQuery,
  SaveUserProfileMutation,
  SaveUserProfileMutationVariables,
  UploadProfileImageMutation,
  UploadProfileImageMutationVariables
} from "generated/graphql";
import { useToast } from "hooks/useToast";
import { ChangeEvent, FunctionComponent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export const Profile: FunctionComponent = () => {
  const [profilePicture, setProfilePicture] = useState<string>();
  const [firstname, setFirstname] = useState<string>();
  const [lastname, setLastname] = useState<string>();
  const [gender, setGender] = useState<Gender>();
  const [genderIdentity, setGenderIdentity] = useState<GenderIdentity>();
  const [weight, setWeight] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [foot, setFoot] = useState<Foot>();
  const [position, setPosition] = useState<Position>();
  const [shirtNumber, setShirtNumber] = useState<number>();
  const [birthday, setBirthday] = useState<Date>()
  const [email, setEmail] = useState<string>()

  const showToast = useToast();
  const isXS = useMediaQuery("xs");
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<ProfileUserQuery>(PROFILE_USER_QUERY, {
    onCompleted: (res) => {
      const profileData = res?.user?.userData?.profileData;

      profileData?.firstname && setFirstname(profileData?.firstname);
      profileData?.lastname && setLastname(profileData?.lastname);
      profileData?.gender && setGender(profileData?.gender);
      profileData?.genderIdentity && setGenderIdentity(profileData?.genderIdentity);
      profileData?.weight && setWeight(profileData?.weight);
      profileData?.height && setHeight(profileData?.height);
      profileData?.foot && setFoot(profileData.foot);
      profileData?.position && setPosition(profileData?.position);
      profileData?.picture?.uri && setProfilePicture(profileData?.picture?.uri);
      profileData?.shirtNumber && setShirtNumber(profileData.shirtNumber);
      profileData?.birthday && setBirthday(new Date(profileData.birthday));
      res.user?.email && setEmail(res.user?.email);
    }
  });

  const [saveUserProfileData, {}] = useMutation<SaveUserProfileMutation, SaveUserProfileMutationVariables>(SAVE_USER_PROFILE, {
    onCompleted: () => {
      showToast({ text: t("membership.profile.savedSuccess") });
    },
    onError: () => {
      showToast({ text: t("membership.profile.savedSuccess"), type: "error" });
    }
  });

  const [uploadProfileImage, {}] = useMutation<UploadProfileImageMutation, UploadProfileImageMutationVariables>(UPLOAD_PROFILE_IMAGE, {
    onCompleted: () => {
      showToast({ text: t("membership.profile.savedSuccess") });
    },
    onError: () => {
      showToast({ text: t("membership.profile.savedSuccess"), type: "error" });
    },
    refetchQueries: [{ query: ProfileUserDocument }, { query: CurrentUserDocument }]
  });

  if (loading) {
    return (
      <>
        <Title>{t("membership.menu.profile")}</Title>
        <SubTitleContainer>
          <Spinner />
        </SubTitleContainer>
      </>
    );
  }

  if (error || !data?.user) {
    return (
      <>
        <Title>{t("membership.menu.profile")}</Title>
        <SubTitleContainer>
          <Text>{t("membership.loadingFailed")}</Text>
        </SubTitleContainer>
      </>
    );
  }

  const onSaveProfileDate = () => {
    saveUserProfileData({
      variables: {
        userProfileData: {
          firstname,
          lastname,
          gender,
          genderIdentity,
          weight: Number(weight),
          height: Number(height),
          foot,
          position,
          shirtNumber: Number(shirtNumber),
          birthday: birthday ? birthday.getTime() : undefined
        }
      }
    });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;

    uploadProfileImage({
      variables: {
        newLogo: event.target.files[0]
      }
    });
  };

  const handleUploadClick = () => {
    hiddenFileInput.current?.click();
  };

  const availablePositions = Object.values(Position).filter(
    (p) => ![Position.ATHLETIC_COACH, Position.COACH, Position.CO_COACH, Position.PLAYER_COACH, Position.PHYSIO].includes(p)
  );

  return (
    <>
      <Title>{t("membership.profile.profile")}</Title>
      <Row>
        <LabelColumn>
          <Label>{t("membership.profile.picture")}</Label>
        </LabelColumn>
        <InputColumn>
          <ProfilePictureContainer>
            <input type="file" ref={hiddenFileInput} onChange={handleFileChange} accept="image/*" style={{ display: "none" }} />
            {profilePicture ? (
              <ProfilePicture src={profilePicture} alt="Profile picture" onClick={handleUploadClick} />
            ) : (
              <ProfilePictureDummy>{firstname && firstname.length ? firstname.substring(0, 1) : "-"}</ProfilePictureDummy>
            )}
            <ProfilePictureChangeIcon onClick={handleUploadClick}>
              <Icon as={EditIcon} />
            </ProfilePictureChangeIcon>
          </ProfilePictureContainer>
        </InputColumn>
      </Row>
      <Separator />
      <Row>
        <LabelColumn>
          <Label>{t("membership.profile.name")}</Label>
        </LabelColumn>
        <InputColumn>
          <InputContainer>
            <Input value={firstname} onChange={(e) => setFirstname(e.target.value)} placeholder={t("membership.profile.firstname")} />
          </InputContainer>
          <InputContainer>
            <Input value={lastname} onChange={(e) => setLastname(e.target.value)} placeholder={t("membership.profile.lastname")} />
          </InputContainer>
        </InputColumn>
      </Row>
      <Row>
        <LabelColumn>
          <Label>{t("membership.account.email")}</Label>
        </LabelColumn>
        <InputColumn>
          <InputContainer>
            <Input value={email} placeholder={t("membership.account.email")} disabled />
          </InputContainer>
        </InputColumn>
      </Row>
      <Separator />
      <Row>
        <LabelColumn>
          <Label>{t("membership.profile.gender")}</Label>
        </LabelColumn>
        <InputColumn>
          <InputContainer>
            <RadioButton
              name="gender"
              checked={gender === Gender.MAN}
              label={t("membership.profile.male")}
              onChange={() => {
                setGender(Gender.MAN);
                setGenderIdentity(GenderIdentity.MAN);
              }}
            />
            <RadioButton
              name="gender"
              checked={gender === Gender.WOMAN}
              label={t("membership.profile.female")}
              onChange={() => {
                setGender(Gender.WOMAN);
                setGenderIdentity(GenderIdentity.WOMAN);
              }}
            />
            <RadioButton
              name="gender"
              checked={genderIdentity === GenderIdentity.DIVERS}
              label={t("membership.profile.divers")}
              onChange={() => setGenderIdentity(GenderIdentity.DIVERS)}
            />
          </InputContainer>
        </InputColumn>
      </Row>
      <Separator />
      <Row>
        <LabelColumn>
          <Label>{t("membership.profile.position")}</Label>
        </LabelColumn>
        <InputColumn>
          <InputHalfContainer>
            <Select placeholder={t("membership.profile.position")} value={position} onChange={(value) => setPosition(value as Position)}>
              {availablePositions.map((p) => (
                <SelectOption key={p} value={p}>
                  {t(`position.${p}`)}
                </SelectOption>
              ))}
            </Select>
          </InputHalfContainer>
        </InputColumn>
      </Row>
      <Separator />
      <Row>
        <LabelColumn>
          <Label>{t("membership.profile.shirtNumber")}</Label>
        </LabelColumn>
        <InputColumn>
          <InputHalfContainer>
            <Input
              value={shirtNumber}
              onChange={(e) => {
                const v = Number(e.target.value);
                setShirtNumber(v);
              }}
              placeholder={t("membership.profile.shirtNumber")}
              type="number"
            />
          </InputHalfContainer>
        </InputColumn>
      </Row>
      <Separator />
      <Row>
        <LabelColumn>
          <Label>{t("membership.profile.foot")}</Label>
        </LabelColumn>
        <InputColumn>
          <InputHalfContainer>
            <Select placeholder={t("membership.profile.foot")} value={foot} onChange={(value) => setFoot(value as Foot)}>
              {[Foot.RIGHT, Foot.LEFT, Foot.BOTH].map((f) => (
                <SelectOption key={f} value={f}>
                  {t(`foot.${f}`)}
                </SelectOption>
              ))}
            </Select>
          </InputHalfContainer>
        </InputColumn>
      </Row>
      <Separator />
      <Row>
        <LabelColumn>
          <Label>{t("membership.profile.height")}</Label>
        </LabelColumn>
        <InputColumn>
          <InputHalfContainer>
            <Input
              value={height}
              onChange={(e) => {
                const v = Number(e.target.value);
                setHeight(v);
              }}
              placeholder={t("membership.profile.height")}
              type="number"
            />
          </InputHalfContainer>
        </InputColumn>
      </Row>
      <Separator />
      <Row>
        <LabelColumn>
          <Label>{t("membership.profile.weight")}</Label>
        </LabelColumn>
        <InputColumn>
          <InputHalfContainer>
            <Input
              value={weight}
              onChange={(e) => {
                const v = Number(e.target.value);
                setWeight(v);
              }}
              placeholder={t("membership.profile.weight")}
              type="number"
            />
          </InputHalfContainer>
        </InputColumn>
      </Row>
      <Separator />
      <Row>
        <LabelColumn>
          <Label>{t("membership.profile.birthday")}</Label>
        </LabelColumn>
        <InputColumn>
          <InputHalfContainer>
            <DateInput
              type="date"
              placeholder={t("pages.onboarding.userDataSelection.birthday")}
              value={birthday ? birthday.toISOString().split("T")[0] : undefined}
              onChange={(e) => {
                setBirthday(new Date(e.target.value));
              }}
            />
          </InputHalfContainer>
        </InputColumn>
      </Row>
      <ActionRow>
        <Button onClick={onSaveProfileDate} fullWidth={isXS} small>
          {t("membership.profile.save")}
        </Button>
      </ActionRow>
    </>
  );
};

const ProfilePicture = styled.img`
  border-radius: 100%;
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  cursor: pointer;
`;

const ProfilePictureContainer = styled.div`
  position: relative;
  border-radius: 100%;
  width: 6rem;
  height: 6rem;
`;

const ProfilePictureDummy = styled.div`
  border-radius: 100%;
  width: 6rem;
  height: 6rem;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors["sonic-silver"]};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  font-size: 24px;
  color: white;
  font-weight: 700;
`;

const Title = styled.div`
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white80Percent};
  margin-bottom: 8px;
`;

const Text = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
`;

const Row = styled.div`
  margin: 24px 0;
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    display: block;
    margin: 16px 0;
  }
`;

const ActionRow = styled.div`
  margin: 24px 0 0;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    justify-content: flex-start;
    margin: 16px 0 0;
  }
`;

const LabelColumn = styled.div`
  flex: 1;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin-bottom: 16px;
  }
`;

const InputColumn = styled.div`
  flex: 2;
  display: flex;
  padding-top: 3px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    padding-top: 0;
    flex-direction: column;
  }
`;

const InputContainer = styled.div`
  flex: 1;
  display: flex;

  &:not(:last-child) {
    margin-right: 14px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

const InputHalfContainer = styled.div`
  flex: 0.5;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    flex: 1;
  }
`;

const Label = styled.div`
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
`;

const Separator = styled.hr`
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.white};
  opacity: 20%;
`;

const SubTitleContainer = styled.div`
  margin-top: 32px;
`;

const ProfilePictureChangeIcon = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  height: 16px;
  width: 16px;
  border-radius: ${({ theme }) => theme.borderRadius(2)};
  background-color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.svg`
  fill: ${({ color }) => color};
  height: 12px;
  width: 12px;

  &:hover {
    cursor: pointer;
  }
`;

const PROFILE_USER_QUERY = gql`
  query ProfileUser {
    user {
      _id
      email
      userData {
        profileData {
          firstname
          lastname
          gender
          genderIdentity
          position
          weight
          height
          foot
          picture {
            uri
          }
          shirtNumber
          birthday
        }
      }
    }
  }
`;

const SAVE_USER_PROFILE = gql`
  mutation SaveUserProfile($userProfileData: UserProfileDataInput!) {
    setUserProfileData(userProfileData: $userProfileData) {
      _id
      userData {
        profileData {
          firstname
          lastname
          gender
          genderIdentity
          position
          weight
          height
          foot
          shirtNumber
          birthday
        }
      }
    }
  }
`;

const UPLOAD_PROFILE_IMAGE = gql`
  mutation UploadProfileImage($newLogo: Upload!) {
    uploadProfileImage(file: $newLogo) {
      _id
    }
  }
`;
