import styled from "@emotion/styled";

interface Props {
  marginTop?: string | number;
  marginBottom?: string | number;
}

export const Separator = styled.div<Props>`
  width: 40px;
  height: 3px;
  margin: 8px 0;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
`;
