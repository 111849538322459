import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { logger } from "util/logger";

interface SearchParamConfig<T extends string> {
  defaultValue: T;
  allowedValues?: T[];
}

export const useSearchParam = <T extends string>(name: string, config: SearchParamConfig<T>): T => {
  const [searchParams] = useSearchParams();
  const [param, setParam] = useState<T>(config.defaultValue);
 
  useEffect(() => {
    const searchParam = searchParams.get(name);

    if (!searchParam) {
      setParam(config.defaultValue);
    } else {
      if (config.allowedValues && !config.allowedValues.includes(searchParam as T)) {
        logger.warn(`URL search param '${name}' isn't one of the allowed values! Using default if specified...`);
        setParam(config.defaultValue);
      } else {
        setParam(searchParam as T);
      }
    }
  }, [searchParams, name, config]);

  return param;
};
