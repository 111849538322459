import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import GradientCircleProgressbar from "components/atoms/GradientCircleProgressBar";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  totalScore: number;
};

export const B42Score: FunctionComponent<Props> = ({ totalScore }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <GradientCircleProgressbar percentage={totalScore} primaryColor={[theme.colors.primary, theme.colors.primary10Percent]}>
      <Container>
        <TotalScore>{totalScore}</TotalScore>
        <TotalScoreText>{t("membership.b42Score")}</TotalScoreText>
      </Container>
    </GradientCircleProgressbar>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  flex: 1;
`;

const TotalScore = styled.div`
  font-size: 48px;
  font-style: italic;
  font-weight: 900;
  line-height: 48px;
  margin-bottom: 8px;
`;

const TotalScoreText = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-transform: uppercase;
`;
