import styled from "@emotion/styled";
import { Grid, useMediaQuery } from "@geist-ui/core";
import { CoachZoneLogo, CoachZonePreview } from "assets";
import { PositionSelectionManBg } from "assets/backgroundImages";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { ProgressBar } from "components/atoms/ProgressBar";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { OAuthContext } from "contexts/OAuthContext";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";

export const TeamOnboardingFinish: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { redirectOAuth } = useContext(OAuthContext);
  const [, setSearch] = useSearchParams();
  const { clear } = useOnboardingStore();

  const handleCoachZoneClick = () => {
    clear();
    setSearch({ redirect_uri: `${process.env.REACT_APP_COACHZONE_OAUTH_URL}/${i18n.language}/auth/oauth-callback` });
    redirectOAuth();
  };

  const handleAccountClick = () => {
    clear();
    navigate({ pathname: "../../membership-area" });
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={PositionSelectionManBg} />} wide>
        {isXS ? <ProgressBar currentStep={14} totalSteps={14} /> : null}
        <OnboardingStep title={t("pages.teamOnboarding.finish.title")} subtitle={t("pages.teamOnboarding.finish.subTitle")}>
          <Grid.Container direction={isXS ? "column" : "row"} width={isXS ? undefined : 70}>
            <Grid md={17} xs={24}>
              <AppPreviewImage src={CoachZonePreview} />
            </Grid>
            <Grid md={7} xs={24} direction="column" justify={isXS ? "center" : undefined}>
              <B42LogoImage src={CoachZoneLogo} />
              <CoachZoneButton type="button" variant="primary" onClick={handleCoachZoneClick}>
                {t("pages.teamOnboarding.finish.loginCoachZone")}
              </CoachZoneButton>
              <OrText>{t("pages.teamOnboarding.finish.or")}</OrText>
              <AccountText onClick={handleAccountClick}>{t("pages.teamOnboarding.finish.loginAccount")}</AccountText>
            </Grid>
          </Grid.Container>
        </OnboardingStep>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={14} totalSteps={14} /> : null}
    </>
  );
};

const AppPreviewImage = styled.img`
  height: 100%;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin: 0 auto;
    min-height: 300px;
  }
`;

const B42LogoImage = styled.img`
  height: 112px;
  width: 112px;
  border-radius: 32px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin: 0 auto;
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

const OrText = styled.p`
  font-size: 13px;
  margin: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin: 0 auto;
  }
`;

const CoachZoneButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

const AccountText = styled.p`
  font-size: 13px;
  font-weight: 700;
  text-decoration-line: underline;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin: 0 auto;
  }
`;
