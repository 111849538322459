import styled from "@emotion/styled";
import { Grid, Spinner } from "@geist-ui/core";
import { AppleDownload, CheckCircle, GenderSelectionWoman, GooglePlayDownload } from "assets";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { SportsClubTeamRole, useInviteUserQuery } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useInviteStore } from "stores/invite.store";
import { useOnboardingStore } from "stores/onboarding.store";
import { DOWNLOAD_APP_LINK_ANDROID, DOWNLOAD_APP_LINK_IOS } from "util/constants";

export const InviteAccept = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { bundle } = useOnboardingStore();
  const { clear, role } = useInviteStore();
  const { data, loading } = useInviteUserQuery();

  const handleAppleClick = () => {
    clear();
    window.open(DOWNLOAD_APP_LINK_IOS, "_blank");
  };

  const handleGoogleClick = () => {
    clear();
    window.open(DOWNLOAD_APP_LINK_ANDROID + `&hl=${i18n.language}`, "_blank");
  };

  const handleNextClick = () => {
    if (role === SportsClubTeamRole.COACH) {
      if (data?.user?.userData.onboardingCompleted) {
        // TODO: replace this
        navigate({ pathname: "../../team-onboarding/finish", search: location.search });
      } else {
        navigate({ pathname: "../../onboarding/user-data-selection-lite", search: location.search });
      }
    } else if (bundle === "TEAM") {
      clear();
      navigate({ pathname: "../../sports-club-onboarding", search: location.search });
    } else if (bundle === "PLAYER") {
      clear();
      navigate({ pathname: "../../onboarding", search: location.search });
    } else {
      clear();
      navigate({ pathname: "../../membership-area", search: location.search });
    }
  };

  if (loading) {
    return (
      <Grid.Container direction="column" justify="center">
        <Grid sm={24} direction="column" justify="center">
          <Spinner mt={3} mb={3} />
        </Grid>
      </Grid.Container>
    );
  }

  return (
    <>
      <PageContainer background={<BackgroundImage src={GenderSelectionWoman} />}>
        <OnboardingStep title={t("pages.invite.accept.title")} subtitle={t("pages.invite.accept.subTitle")}></OnboardingStep>
        <Grid.Container direction="column">
          <Grid sm={24} direction="column" justify="center">
            <CheckCircleContainer>
              <CheckCircleImage src={CheckCircle} />
            </CheckCircleContainer>
            <Text>{t("pages.invite.accept.appTitle")}</Text>
            <Text>{t("pages.invite.accept.appDescription")}</Text>
            <DownloadButtonsContainer>
              <DownloadButtonImage onClick={handleAppleClick} src={AppleDownload} />
              <DownloadButtonImage onClick={handleGoogleClick} src={GooglePlayDownload} />
            </DownloadButtonsContainer>
            <SmallText>{t("pages.invite.accept.memberZoneDescription")}</SmallText>
            <ContinueButton type="button" variant="primary-outline" fullWidth onClick={handleNextClick}>
              {t("pages.invite.accept.memberZoneButton")}
            </ContinueButton>
          </Grid>
        </Grid.Container>
      </PageContainer>
    </>
  );
};

const CheckCircleContainer = styled.div`
  margin: 0 auto 16px;
  display: flex;
  justify-content: center;
`;

const CheckCircleImage = styled.img`
  height: 96px;
  width: 96px;
  margin: 0;
`;

const Text = styled.p`
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin: 0 0 4px;
`;

const SmallText = styled.p`
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  margin: 0 0 4px;
`;

const ContinueButton = styled(Button)`
  margin: 0 auto;
  max-width: 360px;
`;

const DownloadButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(4)} 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    flex-direction: column;
  }
`;

const DownloadButtonImage = styled.img`
  height: auto;
  width: 211px;
  margin: 0 ${({ theme }) => theme.spacing(2)};

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin: 0 auto;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;
