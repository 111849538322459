import { AuthContext } from "contexts/AuthContext";
import { SignInThirdPartyErrorCodes, useSignInThirdPartyMutation } from "generated/graphql";
import { ISignInSignUpSuccessSuccessResponse } from "hooks/useSignIn";
import { useContext } from "react";
import { logger } from "util/logger";

/** 
 * **Internal** usage only. Do not use directly" 
 */
export const _useSignInThirdParty = ({
  onSuccess,
  onError
}: { onSuccess?: (data: ISignInSignUpSuccessSuccessResponse) => void; onError?: (e: SignInThirdPartyErrorCodes | Error) => void } = {}) => {
  const authContext = useContext(AuthContext);
  const [signInThirdParty, { loading }] = useSignInThirdPartyMutation({
    onError: (e) => {
      logger.error("signInThirdParty mutation failed", e);
      onError && onError(e);
    },
    onCompleted: (d) => {
      if (d.signInThirdParty.__typename === "SignInThirdPartyErrorResponse") {
        onError && onError(d.signInThirdParty.code);
      } else {
        authContext.setUserIdAndToken(d.signInThirdParty);
        onSuccess && onSuccess(d.signInThirdParty);
      }
    }
  });

  return { signInThirdParty, loading };
};
