import { ApolloError, gql } from "@apollo/client";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "contexts/AuthContext";
import { SignInSignUpSuccessResponse, SignUpErrorCodes, useSignUpMutation } from "generated/graphql";

export const SIGN_UP_MUTATION = gql`
  mutation signUp($email: String!, $password: String!, $lang: String!) {
    signUp(email: $email, password: $password, lang: $lang) {
      ... on SignInSignUpSuccessResponse {
        token
        userId
        user {
          email
        }
      }
      ... on SignUpErrorResponse {
        code
      }
    }
  }
`;

export const useSignUp = ({
  onSuccess,
  onError
}: { onSuccess?: (res: Omit<SignInSignUpSuccessResponse, "user" | "signedUp">) => void; onError?: (error: SignUpErrorCodes | ApolloError) => void } = {}) => {
  const authContext = useContext(AuthContext);
  const { i18n } = useTranslation();

  const [signUpMutation, { loading }] = useSignUpMutation({
    fetchPolicy: "no-cache",
    onError: (e) => {
      if (onError) {
        onError(e);
      }
    },
    onCompleted: (d) => {
      if (d.signUp.__typename === "SignUpErrorResponse") {
        if (onError) {
          onError(d.signUp.code);
        }
      } else {
        authContext.setUserIdAndToken(d.signUp);
        if (onSuccess) {
          onSuccess(d.signUp);
        }
      }
    }
  });

  const signUp = useCallback(
    async (email: string, password: string) => {
      signUpMutation({
        variables: {
          email,
          password,
          lang: i18n.language
        }
      });
    },
    [i18n.language, signUpMutation]
  );

  return {
    signUp,
    loading
  };
};
