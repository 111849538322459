import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Drawer, Grid, Text } from "@geist-ui/core";
import { Menu as MenuIcon } from "@geist-ui/react-icons";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Separator } from "../../atoms/Separator";
import { useNavbarHeight } from "./useNavbarHeight";
import { AvailableLanguage, availableLanguages } from "i18n";

interface Props {
  profilePath: string;
  signUpPath: string;
  isSignedIn: boolean;
  logout: () => void;
  changeLanguage: (lang: AvailableLanguage) => void;
  firstname?: string | null;
  pictureUri?: string;
  supportLink: string
}

export const NavbarContentMobile: FunctionComponent<Props> = ({
  signUpPath,
  changeLanguage,
  isSignedIn,
  logout,
  supportLink
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navbarHeight = useNavbarHeight();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  return (
    <Grid xs alignItems="center" justify="flex-end">
      <MenuIconStyled color={theme.colors.primary} active={drawerVisible} onClick={() => setDrawerVisible(true)} />
      <DrawerStyled navbarHeight={navbarHeight} visible={drawerVisible} onClose={() => setDrawerVisible(false)} placement="right">
        <DrawerContentStyled>
          <a href={supportLink} target="_blank" rel="noreferrer" style={{ marginBottom: 16 }}>
            <Link>
              <Text b>{t("navbar.support")}</Text>
            </Link>
          </a>
          {availableLanguages.map((lang) => (
            <LangLink key={"lang-" + lang} active={i18n.language === lang} onClick={() => changeLanguage(lang)}>
              <Text b>{lang.toUpperCase()}</Text>
            </LangLink>
          ))}
          <SeparatorStyled />
          {isSignedIn ? (
            <>
              <Link onClick={logout}>
                <Text b>{t("navbar.logout")}</Text>
              </Link>
            </>
          ) : (
            <NavLinkStyled end to={signUpPath}>
              <Text b>{t("navbar.registration")}</Text>
            </NavLinkStyled>
          )}
        </DrawerContentStyled>
      </DrawerStyled>
    </Grid>
  );
};

const MenuIconStyled = styled(MenuIcon)<{ active: boolean }>`
  transition: transform 200ms ease;
  transform: ${({ active }) => `rotate(${active ? -90 : 0}deg)`};
`;

const DrawerStyled = styled(Drawer)<{ navbarHeight: number }>`
  top: ${({ navbarHeight }) => navbarHeight}px !important;
  border-radius: 0 !important;
  min-width: 200px;
`;

const DrawerContentStyled = styled(Drawer.Content)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const NavLinkStyled = styled(NavLink)`
  display: flex;
  color: #fff;
  margin-bottom: 8px;

  &.active {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Link = styled.a`
  color: #fff;
  margin-bottom: 8px;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const SeparatorStyled = styled(Separator)`
  margin: 24px 0;
`;

const LangLink = styled.a<{ active?: boolean }>`
  margin-right: 8px;
  color: #fff;
  text-decoration: ${({ theme, active }) => (active ? `underline ${theme.colors.primary}` : undefined)};
  text-decoration-thickness: 2px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: ${({ theme, active }) => (active ? `underline ${theme.colors.primary}` : undefined)};
  }
`;
