import styled from "@emotion/styled";
import { useMediaQuery } from "@geist-ui/core";
import React, { FunctionComponent } from "react";
import { useNavbarHeight } from "../molecules/Navbar";

interface Props {
  /** Best to use `BackgroundImage` component */
  background?: React.ReactNode;
  showBackgroundOnMobile?: boolean;
  children?: React.ReactNode;
  wide?: boolean;
}

export const PageContainer: FunctionComponent<Props> = ({ children, background, showBackgroundOnMobile = false, wide = false }) => {
  const navbarHeight = useNavbarHeight();
  const isXS = useMediaQuery("xs");

  return (
    <OuterContainer>
      <Container navbarHeight={navbarHeight} wide={wide}>
        {(!isXS || (isXS && showBackgroundOnMobile)) && background}
        <Content>{children}</Content>
      </Container>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;
`;

const Container = styled.div<{ navbarHeight: number; wide: boolean }>`
  max-width: ${({ wide }) => (wide ? "1020px" : "760px")};
  padding: 0 20px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
`;
