import styled from "@emotion/styled/macro";

interface Props<T> {
  value: T;
  label: string;
  backgroundImage: string;
  onSelectOption: (value: T) => void;
  selected: boolean
}

export const ImageSelectOption = <T,>({ value, label, backgroundImage, onSelectOption, selected }: Props<T>) => {
  return (
    <OptionContainer key={label} backgroundImage={backgroundImage} onClick={() => onSelectOption(value)} selected={selected}>
      <OptionOverlay />
      <OptionLabel>{label}</OptionLabel>
    </OptionContainer>
  );
};

const OptionOverlay = styled.div<{ active?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.dark50Percent};
  transition: all 0.3s;
`;

const OptionLabel = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  font-weight: 800;
  z-index: 1;
  position: relative;
  transition: all 0.3s;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    font-size: 24px;
    padding: 20px;
  }
`;

const OptionContainer = styled.div<{ backgroundImage: string; selected: boolean }>`
  position: relative;
  border-radius: 24px;
  background-image: ${({ backgroundImage }) => `url('${backgroundImage}')`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 160px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    height: 140px;
    justify-content: center;
    align-items: center;
    background-position: top center;
  }

  ${OptionOverlay} {
    background: ${({ selected, theme }) =>
      selected ? `linear-gradient(90deg, ${theme.colors.primary} 30%, ${theme.colors.primary70Percent} 60%, transparent 100%)` : undefined};
  }

  ${OptionLabel} {
    color: ${({ selected, theme }) => selected ? theme.colors.dark : undefined};
  }

  &:hover {
    ${OptionOverlay} {
      background: ${({ theme }) => `linear-gradient(90deg, ${theme.colors.primary} 30%, ${theme.colors.primary70Percent} 60%, transparent 100%)`};
    }

    ${OptionLabel} {
      color: ${({ theme }) => theme.colors.dark};
    }
  }
`;
