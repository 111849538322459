import styled from "@emotion/styled";
import { Grid } from "@geist-ui/core";
import { RoleSelectionWomanBg } from "assets/backgroundImages";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { SportsClubTeamRole, useCompleteOnboardingMutation, useRemoveSportsClubTeamTemporaryUserMutation, useSportsClubTeamTemporaryUsersQuery } from "generated/graphql";
import { useSearchParam } from "hooks/useSearchParam";
import { useSetProfileData } from "hooks/useSetProfileData";
import { useToast } from "hooks/useToast";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const SportsClubTeamPlayerSelection: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showToast = useToast();
  const { data } = useSportsClubTeamTemporaryUsersQuery();
  const [mutateRemoveTempUser] = useRemoveSportsClubTeamTemporaryUserMutation();

  const [setProfileData, { loading }] = useSetProfileData(() => showToast({ text: t("membership.profile.savedError"), type: "error" }));
  const [mutateOnboarding, { loading: onboardingLoading }] = useCompleteOnboardingMutation();
  const role = useSearchParam<SportsClubTeamRole>("role", {
    defaultValue: SportsClubTeamRole.PLAYER,
    allowedValues: Object.values(SportsClubTeamRole)
  });

  const [tmpId, setTmpId] = useState<string>();

  const teamId = data?.user?.userData.sportsClubMembership?.currentSportsClubTeam?._id;
  const temporaryUsers = data?.user?.userData.sportsClubMembership?.currentSportsClubTeam?.membersTemporary ?? [];

  const goToNextStep = async () => {
    if (tmpId && teamId) {
      const user = temporaryUsers.find((user) => user.tmpId === tmpId);

      await setProfileData({ variables: { profileData: { firstname: user?.firstname, lastname: user?.lastname } } });
      await mutateOnboarding();
      await mutateRemoveTempUser({ variables: { tmpId, teamId } });

      navigate({ pathname: "../../invite/accept" });

      return;
    }

    if (role === SportsClubTeamRole.COACH || role === SportsClubTeamRole.CO_COACH) {
      navigate({ pathname: "../user-data-selection-lite" });
    } else {
      navigate({ pathname: "../team-player-data" });
    }
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={RoleSelectionWomanBg} />}>
        <OnboardingStep title={t("pages.onboarding.playerSelection.title")} subtitle={t("pages.onboarding.playerSelection.title")}>
          <Container
            style={{
              scrollbarColor: "#707070 #1D1D1D",
              scrollbarWidth: "thin"
            }}
          >
            {temporaryUsers.map((user) => (
              <PlayerContainer key={user.tmpId} selected={tmpId === user.tmpId} onClick={() => setTmpId(user.tmpId)}>
                <PlayerAvatar>
                  <span>
                    {user.firstname.charAt(0)}
                    {user.lastname.charAt(0)}
                  </span>
                </PlayerAvatar>
                <PlayerName>
                  {user.firstname} {user.lastname}
                </PlayerName>
              </PlayerContainer>
            ))}
          </Container>
        </OnboardingStep>
        <Grid.Container justify="center" marginTop={2} marginBottom={1}>
          <Grid sm={12} xs={24}>
            <Button type="button" variant="primary" onClick={goToNextStep} fullWidth>
              {t("common.continue")}
            </Button>
          </Grid>
        </Grid.Container>
      </PageContainer>
      {loading || onboardingLoading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
};

const Container = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius(4)};
  padding: ${({ theme }) => theme.spacing(3)};
  background-color: ${({ theme }) => theme.colors.jet};
  max-height: 300px;
  overflow-y: scroll;
`;

const PlayerContainer = styled.div<{ selected: boolean }>`
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.borderRadius(3)};
  background-color: ${({ theme }) => theme.colors.dark};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing()};
  width: 300px;
  border: 1px solid ${({ theme, selected }) => (selected ? theme.colors.primary : theme.colors.dark)};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.dark50Percent};
  }
`;

const PlayerAvatar = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.timberwolf};
  color: ${({ theme }) => theme.colors.dark};
  font-size: 10px;
  line-height: 18px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PlayerName = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 18px;
  margin-left: ${({ theme }) => theme.spacing()};
`;
