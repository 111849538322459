import { useTheme } from "@emotion/react";
import { Collapse as CollapseComponent, CollapseProps } from "@geist-ui/core";
import { FunctionComponent } from "react";

export const Collapse: FunctionComponent<CollapseProps> = (props) => {
  const theme = useTheme();
  return (
    <CollapseComponent
      {...props}
      style={{
        width: "100%",
        border: 0,
        background: theme.colors.black40Percent,
        padding: "16px 24px",
        borderRadius: theme.spacing(2),
        marginBottom: theme.spacing(3),
        fontSize: 9,
        fontWeight: 700
      }}
    />
  );
};
