import styled from "@emotion/styled";
import { Grid, Spinner } from "@geist-ui/core";
import { SportsClubBg } from "assets";
import { CheckIcon } from "assets/icons";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { SportsClubOnboardingStep } from "components/molecules/SportsClubOnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { useCreateSportsClubMutation, useLeaveSportsClubMutation, useSetAttributionMutation, useTeamOnboardingUserQuery } from "generated/graphql";
import { useToast } from "hooks/useToast";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";

export const SportsClubName: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showToast = useToast();
  const { sportsClubName, setSportsClubName, setSportsClubId, attribution } = useOnboardingStore();
  const { data, loading: userLoading } = useTeamOnboardingUserQuery();

  const [mutateAttribution] = useSetAttributionMutation();
  const [mutateCreateSportsClub] = useCreateSportsClubMutation({
    onCompleted: async (data) => {
      setSportsClubId(data.createSportsClub._id);
      navigate({ pathname: "../user" });
    },
    onError: (error) => {
      console.log("error", error);
      showToast({ text: t("pages.teamOnboarding.sportsClubCreation.nameError"), type: "error" });
    }
  });
  const [mutateLeaveSportsClub] = useLeaveSportsClubMutation({});

  const [change, setChange] = useState(false);

  const sportsClub = data?.user?.userData.sportsClubMembership?.sportsClub;
  console.log("sportsClub", sportsClub);

  const goToNextStep = () => {
    if (!sportsClubName) {
      return;
    }

    mutateCreateSportsClub({ variables: { name: sportsClubName } });
    mutateAttribution({ variables: { input: { referredBy: attribution?.referredBy, refByAffiliateId: attribution?.refByAffiliateId } } });
  };

  const handleNextClick = async () => {
    if (change) {
      // leave and create new sportsclub
      await mutateLeaveSportsClub();
      return
    }

    navigate({ pathname: "../../membership-area" });
  }

  if (userLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (sportsClub) {
    return (
      <>
        <PageContainer background={<BackgroundImage src={SportsClubBg} />}>
          <OnboardingStep title={t("pages.teamOnboarding.sportsClubCreation.clubError.title")} subtitle={t("pages.teamOnboarding.sportsClubCreation.clubError.subTitle")}>
            <Grid.Container direction="column">
              <Grid sm={24} direction="column">
                <SportsClubContainer onClick={() => setChange(false)}>
                  <SportsClubLogoContainer>
                    {sportsClub.logo ? (
                      <SportsClubLogo src={sportsClub.logo?.uri} alt={"Logo - " + sportsClub.name} />
                    ) : null}
                    {change === false && (
                      <SportsClubLogoSelectedOverlay>
                        <SportsClubLogoSelectedOverlayIcon />
                      </SportsClubLogoSelectedOverlay>
                    )}
                  </SportsClubLogoContainer>
                  <SportsClubNameContainer>
                    <SportsClubNameText>{sportsClub.name}</SportsClubNameText>
                    <SportsClubNameSub>{t("pages.invite.change.stay")}</SportsClubNameSub>
                  </SportsClubNameContainer>
                </SportsClubContainer>
                <OrText>{t("pages.invite.change.or")}</OrText>
                <SportsClubContainer onClick={() => setChange(true)}>
                  <SportsClubLogoContainer>
                    {change && (
                      <SportsClubLogoSelectedOverlay>
                        <SportsClubLogoSelectedOverlayIcon />
                      </SportsClubLogoSelectedOverlay>
                    )}
                  </SportsClubLogoContainer>
                  <SportsClubNameText>{t("pages.invite.change.createNewTeam")}</SportsClubNameText>
                </SportsClubContainer>
              </Grid>
              <Grid sm={24} direction="column" mt={3} mb={2}>
                <ContinueButton type="button" variant="primary" fullWidth onClick={handleNextClick}>
                  {t("common.continue")}
                </ContinueButton>
              </Grid>
            </Grid.Container>
          </OnboardingStep>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <PageContainer background={<BackgroundImage src={SportsClubBg} />}>
        <SportsClubOnboardingStep title={t("pages.sportsClubOnboarding.title")} subTitle={t("pages.sportsClubOnboarding.name.title")} step={1}>
          <Grid.Container gap={2} direction={"column"} justify="center">
            <Grid sm={24} xs={24} width="100%">
              <Input placeholder={t("pages.sportsClubOnboarding.name.sportsClubName")} value={sportsClubName} onChange={(e) => setSportsClubName(e.target.value)} />
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={2} marginBottom={4}>
            <Grid sm={24} xs={24}>
              <Button type="button" variant={sportsClubName ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
                {t("common.continue")}
              </Button>
            </Grid>
          </Grid.Container>
        </SportsClubOnboardingStep>
      </PageContainer>
    </>
  );
};


const SportsClubContainer = styled.div`
  display: flex;
  max-width: 360px;
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.borderRadius(4)};
  flex-direction: row;
  align-items: center;
  margin: 0 auto ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.colors.gray};
  width: 100%;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.gray2};
  }
`;

const SportsClubLogoContainer = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: ${({ theme }) => theme.borderRadius(2.5)};
  background-color: ${({ theme }) => theme.colors["sonic-silver"]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing()};
`;

const SportsClubLogoSelectedOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => `linear-gradient(360deg, ${theme.colors.dark}00, ${theme.colors.primary})`};
  border-radius: ${({ theme }) => theme.borderRadius(2.5)};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SportsClubLogoSelectedOverlayIcon = styled(CheckIcon)`
  width: 24px;
  fill: ${({ theme }) => theme.colors.dark};
`;

const SportsClubLogo = styled.img`
  height: 20px;
  width: 20px;
`;

const SportsClubNameContainer = styled.div``;

const SportsClubNameText = styled.p`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px; /* 120% */
  margin: 0;
`;

const SportsClubNameSub = styled.p`
  font-size: 10px;
  line-height: 18px;
  margin: 0;
`;

const ContinueButton = styled(Button)`
  margin: 0 auto;
  max-width: 360px;
`;

const OrText = styled.p`
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  margin: ${({ theme }) => theme.spacing(2)} 0;
  text-align: center;
`;

