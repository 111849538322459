import React, { createContext, FunctionComponent, useCallback, useEffect, useReducer } from "react";

export interface AuthContextInterface {
  setUserIdAndToken: (payload: { userId: string; token: string }) => void;
  setOnboardingFinished: () => void;
  isSignedIn: boolean;
  isInitialized: boolean;
  isOnboardingFinished: boolean;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextInterface>({
  setUserIdAndToken: () => {},
  setOnboardingFinished: () => {},
  isSignedIn: false,
  isInitialized: false,
  isOnboardingFinished: false,
  logout: () => {}
});

let authTokenGlob = "";
export const authTokenGetter = () => authTokenGlob;
export let invalidateAuthToken: () => void = () => {};

const sessionStorageKey = "authState";
const onboardingSessionKey = "onboardingFinished";

type State = {
  initialized: boolean;
  userId: string;
  token: string;
  signedUp?: boolean;
  onboardingFinished?: boolean;
};

type SetAuthStateAction = {
  type: "set_auth_state";
  userId: string;
  token: string;
  signedUp?: boolean;
};

type SetOnboardingFinishedAction = {
  type: "finish_onboarding";
};

type Actions = SetAuthStateAction | SetOnboardingFinishedAction;

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "set_auth_state":
      authTokenGlob = action.token;
      return {
        initialized: true,
        userId: action.userId,
        token: action.token,
        signedUp: action.signedUp,
        onboardingFinished: false
      };
    case "finish_onboarding":
      return {
        ...state,
        onboardingFinished: true
      };
    default:
      return state;
  }
};

const initialState: State = {
  initialized: false,
  userId: "",
  token: "",
  signedUp: undefined
};

export const AuthContextProvider: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setUserIdAndToken = useCallback((newUserState: { userId: string; token: string }) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(newUserState));
    sessionStorage.setItem("tokenSet", "true");
    dispatch({ type: "set_auth_state", token: newUserState.token, userId: newUserState.userId });
  }, []);

  useEffect(() => {
    const storedUser = sessionStorage.getItem(sessionStorageKey);

    if (!storedUser) {
      dispatch({ type: "set_auth_state", token: "", userId: "" });
      return;
    }
    try {
      const parsed: State = JSON.parse(storedUser);
      dispatch({ type: "set_auth_state", token: parsed.token, userId: parsed.userId });
    } catch (e) {
      dispatch({ type: "set_auth_state", token: "", userId: "" });
    }
  }, []);

  useEffect(() => {
    invalidateAuthToken = () => {
      setUserIdAndToken({ token: "", userId: "" });
    };
  }, [setUserIdAndToken]);

  const isSignedIn = !!(state.token && state.userId);
  const isOnboardingFinished = isSignedIn && sessionStorage.getItem(onboardingSessionKey) === "true";

  const logout = () => {
    setUserIdAndToken({ token: "", userId: "" });
    sessionStorage.removeItem(sessionStorageKey);
    sessionStorage.removeItem(onboardingSessionKey);
    sessionStorage.removeItem("tokenSet");
  };

  const setOnboardingFinished = useCallback(() => {
    sessionStorage.setItem(onboardingSessionKey, "true");
    dispatch({ type: "finish_onboarding" });
  }, []);

  return (
    <AuthContext.Provider value={{ setUserIdAndToken, setOnboardingFinished, isSignedIn, isOnboardingFinished, isInitialized: state.initialized, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
