import { FunctionComponent } from "react";
import styled from "@emotion/styled";

export const Footer: FunctionComponent = () => {
  return (
    <StyledFooter>
      {"©"} {new Date().getFullYear()} {"SFY GmbH"}
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
`;
