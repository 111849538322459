import styled from "@emotion/styled";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { EMAIL_REGEX } from "util/constants";

type Props = {
  onSubmit: (values: { email: string; password: string }) => void;
};

export const SignUpForm: FunctionComponent<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<{ email: string; password: string; passwordConfirm: string }>();

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Input
        type="email"
        error={errors.email?.message}
        autoComplete="email"
        placeholder={t("form.placeholder.email")}
        {...register("email", {
          required: { value: true, message: t("form.error.emailRequired") },
          pattern: { value: EMAIL_REGEX, message: t("form.error.emailInvalid") }
        })}
      />
      <Input
        type="password"
        error={errors.password?.message}
        autoComplete="new-password"
        placeholder={t("form.placeholder.password")}
        {...register("password", { required: { value: true, message: t("form.error.passwordRequired") } })}
      />
      <Input
        type="password"
        error={errors.passwordConfirm?.message}
        autoComplete="new-password"
        placeholder={t("form.placeholder.passwordConfirm")}
        {...register("passwordConfirm", {
          required: { value: true, message: t("form.error.passwordConfirmRequired") },
          validate: (p) => (p !== getValues().password ? t("form.error.passwordsDontMatch") : undefined)
        })}
      />
      <SubmitButton type="submit" variant="primary" fullWidth>
        {t("pages.signUpEmail.signUpButton")}
      </SubmitButton>
    </FormContainer>
  );
};

const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubmitButton = styled(Button)`
  margin-top: 0;
`;
