import React, { FunctionComponent } from "react";
import styled from "@emotion/styled/macro";
import { H1 } from "components/atoms/Heading";

interface Props {
  title: string;
  subTitle: string;
  step: 1 | 2 | 3;
  children?: React.ReactNode;
  fullWidth?: boolean;
}

export const SportsClubOnboardingStep: FunctionComponent<Props> = ({ title, subTitle, step, children, fullWidth = false }) => {
  return (
    <Container>
      <H1 color="#fff">{title}</H1>
      <ProgressContainer>
        <ProgressCircle active={step >= 1}>
          <ProgressCircleText>1</ProgressCircleText>
        </ProgressCircle>
        <ProgressCircle active={step >= 2}>
          <ProgressCircleText>2</ProgressCircleText>
        </ProgressCircle>
        <ProgressCircle active={step >= 3}>
          <ProgressCircleText>3</ProgressCircleText>
        </ProgressCircle>
      </ProgressContainer>
      <Title>{subTitle}</Title>
      <Content fullWidth={fullWidth}>{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 0 30px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    width: 100%;
    padding: 30px 0;
    align-items: flex-start;
  }
`;

const Content = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "480px")};
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    align-items: flex-start;
  }
`;

const Title = styled.p`
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.4px;
  text-align: center;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ theme }) => theme.spacing(8)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    white-space: normal;
    font-size: 16px;
    line-height: 21px;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  flex: 1;
`;

const ProgressCircle = styled.div<{ active: boolean }>`
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 4px solid ${({ theme, active }) => (active ? theme.colors.white : theme.colors["sonic-silver"])};
  margin: 0 ${({ theme }) => theme.spacing(2)};
`;

const ProgressCircleText = styled.p`
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
`;
