import { SignInThirdPartyErrorCodes, ThirdPartySignInProviders } from "generated/graphql";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { logger } from "util/logger";
import { _useSignInThirdParty } from "./use-sign-in-third-party";
import { ISignInSignUpSuccessSuccessResponse } from "hooks/useSignIn";

window.fbAsyncInit = function () {
  initializeFBSDK()
};

let fb_initialized = false
function initializeFBSDK() {
  if (!fb_initialized) {
    logger.log("Initializing Facebook SDK...");
    if (typeof FB !== "undefined") {
      FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        autoLogAppEvents: false, // TODO: Think about reenabling this
        xfbml: true,
        version: "v13.0"
      });
      fb_initialized = true
    }
  }
}

export const useLoginWithFacebook = ({
  onSuccess,
  onError
}: { onSuccess?: (data: ISignInSignUpSuccessSuccessResponse) => void; onError?: (e: SignInThirdPartyErrorCodes | Error) => void } = {}) => {
  const { signInThirdParty, loading } = _useSignInThirdParty({ onSuccess, onError });
  const { i18n } = useTranslation();

  useEffect(() => {
    initializeFBSDK();
  }, []);

  const loginWithFacebook = async () => {
    try {
      // Check if already authenticated and connected
      let accessToken = await getAccessTokenIfAlreadyConnected();
      if (!accessToken) {
        accessToken = await loginAndGetAccessToken();
      }

      signInThirdParty({
        variables: {
          lang: i18n.language,
          token: accessToken,
          type: ThirdPartySignInProviders.FACEBOOK
        }
      });
    } catch (e) {
      logger.error("Failed to login with Facebook!", e as Error);
      onError && onError(e as Error);
    }
  };

  return {
    loginWithFacebook,
    loading
  };
};

function getAccessTokenIfAlreadyConnected(): Promise<string | null> {
  return new Promise((resolve) => {
    FB.getLoginStatus((res) => {
      logger.log("Facebook login status: ", res.status);
      if (res.status === "connected") {
        resolve(res.authResponse.accessToken);
      } else {
        resolve(null);
      }
    });
  });
}

function loginAndGetAccessToken(): Promise<string> {
  return new Promise((resolve, reject) => {
    FB.login(
      (res) => {
        if (res.status !== "connected") {
          logger.error("After login the status is still not 'connected'!", res.status);
          reject("Unable to login via Facebook!");
        } else {
          resolve(res.authResponse.accessToken);
        }
      },
      { scope: "public_profile,email" }
    );
  });
}
