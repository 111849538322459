import { FunctionComponent } from "react";
import styled from "@emotion/styled/macro";
import { Link, Text } from "@geist-ui/core";
import { PRIVACY_DE_LINK, PRIVACY_EN_LINK, TERMS_AND_CONDITIONS_DE_LINK, TERMS_AND_CONDITIONS_EN_LINK } from "util/constants";
import { useTranslation } from "react-i18next";
import { Checkbox } from "components/atoms/Checkbox";

type Props = {
  checkbox?: {
    checked: boolean;
    onCheckedChange: (checked: boolean) => void;
  };
  error?: boolean;
};

export const TermsAndConditionsText: FunctionComponent<Props> = ({ checkbox, error }) => {
  const { t, i18n } = useTranslation();
  const linkToPrivacy = i18n.language === "de" ? PRIVACY_DE_LINK : PRIVACY_EN_LINK;
  const linkToTermsAndConditions = i18n.language === "de" ? TERMS_AND_CONDITIONS_DE_LINK : TERMS_AND_CONDITIONS_EN_LINK;

  return (
    <TermsAndConditionsContainer>
      <Container error={error}>
        {checkbox ? (
          <Checkbox
            checked={checkbox.checked}
            onChange={(e) => checkbox.onCheckedChange(e.target.checked)}
            styledLabel={
              <TextStyled>
                {t("terms.0")}
                <Link color underline target="_blank" href={linkToTermsAndConditions}>
                  {t("terms.1")}
                </Link>
                {t("terms.2")}
              </TextStyled>
            }
          />
        ) : (
          <TextStyled>
            {t("terms.0")}
            <Link color underline target="_blank" href={linkToTermsAndConditions}>
              {t("terms.1")}
            </Link>
            {t("terms.2")}
          </TextStyled>
        )}

        <TextStyled hasCheckbox={!!checkbox}>
          {t("privacy.0")}
          <Link color underline target="_blank" href={linkToPrivacy}>
            {t("privacy.1")}
          </Link>
          {t("privacy.2")}
        </TextStyled>
        {error ? <ErrorText style={{ color: "red" }}>{t("termsError")}</ErrorText> : null}
      </Container>
    </TermsAndConditionsContainer>
  );
};

const TermsAndConditionsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

const Container = styled.div<{ error?: boolean }>`
  color: ${({ theme }) => theme.colors.white80Percent};
  padding: ${({ theme }) => theme.spacing(1)};
  border: 1px solid ${({ error }) => (error ? "red" : "transparent")};
  border-radius: 4px;
`;

const TextStyled = styled(Text, { shouldForwardProp: (prop) => prop !== "hasCheckbox" })<{ hasCheckbox?: boolean }>`
  margin: 0;
  margin-left: ${({ hasCheckbox, theme }) => (hasCheckbox ? theme.spacing(3.8) : "0")};
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 0;
`;

const ErrorText = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 0;
`;
