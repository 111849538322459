import { FunctionComponent, useContext, useEffect, useRef } from "react";
import { LoadingSpinnerOverlay } from "../components/molecules/LoadingSpinnerOverlay";
import { OAuthContext } from "../contexts/OAuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocalizedRoute } from "../hooks/useLocalizedRoute";

export const OAuth: FunctionComponent = () => {
  const oAuthContext = useContext(OAuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const localizedRoute = useLocalizedRoute();
  const loaded = useRef(false);

  useEffect(() => {
    (async () => {
      if (!loaded.current) {
        loaded.current = true;
        const success = await oAuthContext.accessTokenLogin();
        if (success) navigate({ pathname: searchParams.get("forward_to") || localizedRoute("membership-area") });
      }
    })();
  }, [localizedRoute, navigate, oAuthContext, searchParams]);

  return <LoadingSpinnerOverlay />;
}
