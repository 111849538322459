import { ApolloError } from "@apollo/client";
import { useCallback, useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import { SignInErrorCodes, useSignInMutation } from "generated/graphql";

export type ISignInSignUpSuccessSuccessResponse = {
  __typename: "SignInSignUpSuccessResponse";
  token: string;
  userId: string;
  user: {
    _id: string;
    email: string;
    userData: {
      sportsClubMembership: {
        sportsClub: { _id: string };
      } | null;
    };
  };
};

export const useSignIn = ({
  onSuccess,
  onError
}: { onSuccess?: (res: ISignInSignUpSuccessSuccessResponse) => void; onError?: (e: SignInErrorCodes | ApolloError) => void } = {}) => {
  const authContext = useContext(AuthContext);

  const [signInMutation, { loading }] = useSignInMutation({
    fetchPolicy: "no-cache",
    onError: (e) => onError && onError(e),
    onCompleted: (d) => {
      if (d.signIn.__typename === "SignInErrorResponse") {
        onError && onError(d.signIn.code);
      } else {
        authContext.setUserIdAndToken(d.signIn);

        if (onSuccess) {
          onSuccess(d.signIn);
        }
      }
    }
  });

  const signIn = useCallback(
    async (email: string, password: string) => {
      await signInMutation({
        variables: {
          email,
          password
        }
      });
    },
    [signInMutation]
  );

  return {
    signIn,
    loading
  };
};
