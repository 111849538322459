import { Grid, useMediaQuery } from "@geist-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { ProgressBar } from "components/atoms/ProgressBar";
import { PitchPositionSelection } from "components/molecules/PitchPositionSelection";
import { PageContainer } from "components/organisms/PageContainer";
import { useNavigate } from "react-router-dom";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { Button } from "components/atoms/Button";
import { RoleSelectionWomanBg } from "assets/backgroundImages";
import { useOnboardingStore } from "stores/onboarding.store";

export const PositionSelection: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { position, setPosition, role } = useOnboardingStore();

  const goToNextStep = async () => {
    if (!position) {
      return;
    }

    navigate({ pathname: "../fitness-status-selection" });
  };

  const skip = () => {
    navigate({ pathname: "../fitness-status-selection" });
  }

  return (
    <>
      <PageContainer background={<BackgroundImage src={RoleSelectionWomanBg} />}>
        {isXS ? <ProgressBar currentStep={role ? 7 : 2} totalSteps={role ? 14 : 5} /> : null}
        <OnboardingStep title={t("pages.onboarding.positionSelection.title")} subtitle={t("pages.onboarding.positionSelection.subtitle")}>
          <PitchPositionSelection position={position} onSelectPosition={setPosition} />
        </OnboardingStep>
        <Grid.Container justify="center" marginTop={2} marginBottom={1}>
          <Grid sm={12} xs={24}>
            <Button type="button" variant={position ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
              {t("common.continue")}
            </Button>
          </Grid>
        </Grid.Container>
        <Grid.Container justify="center" marginTop={1} marginBottom={2}>
          <Grid sm={12} xs={24}>
            <Button type="button" variant="primary-outline" onClick={skip} fullWidth>
              {t("common.later")}
            </Button>
          </Grid>
        </Grid.Container>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={role ? 7 : 2} totalSteps={role ? 14 : 5} /> : null}
    </>
  );
};
