import { FunctionComponent, useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import { useLocalizedRoute } from "hooks/useLocalizedRoute";
import { OAuthContext } from '../contexts/OAuthContext';

interface Props {
  /** Route use should be redirected to if authenticated. Default: `membership-area` */
  authenticatedTo?: string;
}

/** Allows access to route children only if not authenticated. Redirects to `authenticatedTo` otherwise */
export const UnauthenticatedGuardOutlet: FunctionComponent<Props> = ({ authenticatedTo }) => {
  const authContext = useContext(AuthContext);
  const { isOAuthRequest } = useContext(OAuthContext);
  const location = useLocation()
  const localizedRoute = useLocalizedRoute();

  return !authContext.isSignedIn || isOAuthRequest ? <Outlet /> : <Navigate to={{ pathname: authenticatedTo || localizedRoute("membership-area"), search: location.search }} replace />;
};
