import React, { FunctionComponent } from "react";
import styled from "@emotion/styled/macro";
import { H1 } from "components/atoms/Heading";
import { Separator } from "components/atoms/Separator";

interface Props {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  fullWidth?: boolean;
}

export const OnboardingStep: FunctionComponent<Props> = ({ title, subtitle, fullWidth = false, children }) => {
  return (
    <Container>
      <H1 color="#fff">{title}</H1>
      <SeparatorStyled />
      {subtitle && <Subtext>{subtitle}</Subtext>}
      <Content fullWidth={fullWidth}>{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 0 30px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    width: 100%;
    padding: 30px 0;
    align-items: flex-start;
  }
`;

const Content = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "480px")};
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    align-items: flex-start;
  }
`;

const SeparatorStyled = styled(Separator)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    align-self: flex-start;
  }
`;

const Subtext = styled.p`
  text-align: center;
  white-space: pre-wrap;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  margin: 4px 0 24px;
  letter-spacing: 0;
  max-width: 600px;
  color: ${({ theme }) => theme.colors.white80Percent};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    white-space: normal;
    margin-top: 0;
    text-align: left;
  }
`;
