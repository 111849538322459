import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";

interface Props extends React.HTMLAttributes<unknown> {
  children?: React.ReactNode;
}

export const H1: FunctionComponent<Props> = ({ children, ...props }) => <H1Text {...props}>{children}</H1Text>;

export const H2: FunctionComponent<Props> = ({ children, ...props }) => <H2Text {...props}>{children}</H2Text>;

const H1Text = styled.h1`
  font-size: 34px;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: 0.4px;
  white-space: pre-wrap;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    white-space: normal;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.4px;
    text-align: left;
    margin-bottom: 0;
  }
`;

const H2Text = styled.h2`
  font-weight: 800;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.14px;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    white-space: normal;
    font-size: 16px;
    line-height: 21px;
  }
`;
