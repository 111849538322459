export const APP_STORE_UNIVERSAL_LINK = "https://app.b-42.com";

export const DOWNLOAD_APP_LINK = "https://sfyfrontend.z6.web.core.windows.net/";

export const PRIVACY_DE_LINK = "https://www.b-42.com/datenschutz";
export const PRIVACY_EN_LINK = "https://www.b-42.com/en-us/datenschutz";

export const TERMS_AND_CONDITIONS_DE_LINK = "https://www.b-42.com/agb";
export const TERMS_AND_CONDITIONS_EN_LINK = "https://www.b-42.com/en-us/agb";

export const DOWNLOAD_APP_LINK_ANDROID = "https://play.google.com/store/apps/details?id=com.soccerFitYou.App";
export const DOWNLOAD_APP_LINK_IOS = "https://apps.apple.com/de/app/b42-pro-fussball-training/id1453267645";

export const WEBSITE_DE_LINK = "https://www.b-42.com";
export const WEBSITE_EN_LINK = "https://www.b-42.com/en-us";

export const SUPPORT_DE_LINK = "https://support.b-42.com/";
export const SUPPORT_EN_LINK = "https://support.b-42.com/en-us/";

// From https://emailregex.com/
export const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export enum ParamsBundleType {
  PLAYER = "PLAYER",
  TEAM = "TEAM",
  NONE = "NONE"
}

export enum ParamsBundlePeriod {
  "1month" = "1month",
  "12months" = "12months"
}

export enum ParamsRole {
  PLAYER = "PLAYER",
  COACH = "COACH",
  PLAYERCOACH = "PLAYER_COACH"
}
