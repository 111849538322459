import { gql, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Spinner } from "@geist-ui/core";
import { ArrowIcon } from "assets/icons";
import { Button } from "components/atoms/Button";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { CurrentUserPremiumQuery, ThirdPartySignInProviders } from "generated/graphql";
import { useStripeCheckout } from "hooks/useStripeCheckout";
import { t } from "i18next";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ParamsBundlePeriod } from "util/constants";

const SubscriptionSourceMap = {
  [ThirdPartySignInProviders.APPLE]: t("membership.subscription.provider.apple"),
  [ThirdPartySignInProviders.FACEBOOK]: t("membership.subscription.provider.facebook"),
  [ThirdPartySignInProviders.GOOGLE]: t("membership.subscription.provider.google"),
  [ThirdPartySignInProviders.FACEBOOK_GRAPH]: t("membership.subscription.provider.facebook")
};

export const Subscription: FunctionComponent = () => {
  const { data, loading, error } = useQuery<CurrentUserPremiumQuery>(CURRENT_USER_PREMIUM_QUERY);
  const { t } = useTranslation();
  const [paymentPeriod, setPaymentPeriod] = useState<ParamsBundlePeriod>(ParamsBundlePeriod["1month"]);
  const { createCheckoutSession, loading: stripeLoading } = useStripeCheckout();

  if (loading) {
    return (
      <>
        <Title>{t("membership.menu.subscription")}</Title>
        <SubTitleContainer>
          <Spinner />
        </SubTitleContainer>
      </>
    );
  }

  if (error || !data?.user) {
    return (
      <>
        <Title>{t("membership.menu.subscription")}</Title>
        <SubTitleContainer>
          <Text>{t("membership.loadingFailed")}</Text>
        </SubTitleContainer>
      </>
    );
  }

  const { enabled, expiryDate } = data.user.premium;

  const price = paymentPeriod === ParamsBundlePeriod["1month"] ? "2,49" : "1,99";

  const onContinueWithStripe = async () => {
    const checkoutUrl = await createCheckoutSession({ product: "singleplayer_premium", paymentPeriod: paymentPeriod });
    window.location.href = checkoutUrl;
  };

  return (
    <>
      <Title>{t("membership.menu.subscription")}</Title>
      <Row>
        <LabelColumn>
          <Label>{t("membership.subscription.status")}</Label>
        </LabelColumn>
        <InputColumn>
          {enabled && expiryDate && (
            <Text>
              {t("membership.subscription.subscriptionActiveUntil")} <BoldText>{new Date(expiryDate).toLocaleDateString()}</BoldText>
            </Text>
          )}
          {enabled && !expiryDate && <Text>{t("membership.subscription.subscriptionActive")}</Text>}
          {!enabled && <Text>{t("membership.subscription.subscriptionInactive")}</Text>}
        </InputColumn>
      </Row>
      {enabled && data.user.signupProvider && (
        <Row>
          <LabelColumn>
            <Label>{t("membership.subscription.providerLabel")}</Label>
          </LabelColumn>
          <InputColumn>
            <Text>{SubscriptionSourceMap[data.user.signupProvider]}</Text>
          </InputColumn>
        </Row>
      )}
      {!enabled ? (
        <BundleContainer>
          <PackageSelectionContainer>
            <PackageSelectionContainerTab onClick={() => setPaymentPeriod(ParamsBundlePeriod["1month"])} selected={paymentPeriod === ParamsBundlePeriod["1month"]}>
              <PackageSelectionContainerTabTitle>{t("membership.subscription.subscribe.monthly")}</PackageSelectionContainerTabTitle>
            </PackageSelectionContainerTab>
            <PackageSelectionContainerTab onClick={() => setPaymentPeriod(ParamsBundlePeriod["12months"])} selected={paymentPeriod === ParamsBundlePeriod["12months"]}>
              <PackageSelectionContainerTabTitle>{t("membership.subscription.subscribe.annual")}</PackageSelectionContainerTabTitle>
              <PackageSavingsBadge>{"-17%"}</PackageSavingsBadge>
            </PackageSelectionContainerTab>
          </PackageSelectionContainer>
          <PackagePriceContainer>
            <PackagePriceOptions>{t("membership.subscription.subscribe.from")}</PackagePriceOptions>
            <PackagePrice>{price}</PackagePrice>
            <PackagePriceOptions>
              {"/"}
              {t("membership.subscription.subscribe.week")}
            </PackagePriceOptions>
          </PackagePriceContainer>
          <PackageButtonContainer>
            <Button variant="light" Icon={ArrowIcon} onClick={onContinueWithStripe}>
              {t("membership.subscription.subscribe.subscribe")}
            </Button>
          </PackageButtonContainer>
          <PackageDescriptionText>{t("membership.subscription.subscribe.test")}</PackageDescriptionText>
        </BundleContainer>
      ) : null}
      {stripeLoading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
};

const Title = styled.div`
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white80Percent};
  margin-bottom: 8px;
`;

const Row = styled.div`
  margin: 24px 0;
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    display: block;
  }
`;

const LabelColumn = styled.div`
  flex: 1;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin-bottom: 16px;
  }
`;

const InputColumn = styled.div`
  flex: 2;
  display: flex;
  padding-top: 3px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    padding-top: 0px;
    display: block;
  }
`;

const Label = styled.div`
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
`;

const Text = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
`;

const BoldText = styled.div`
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
`;

const SubTitleContainer = styled.div`
  margin-top: 32px;
`;

const CURRENT_USER_PREMIUM_QUERY = gql`
  query CurrentUserPremium {
    user {
      _id
      premium {
        enabled
        expiryDate
      }
      signupProvider
    }
  }
`;

const BundleContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.borderRadius(3)};
`;

const PackageSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: ${({ theme }) => theme.borderRadius(3)};
  padding: 4px;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.colors.gray};
  gap: 4px;
`;

const PackageSelectionContainerTab = styled.div<{ selected: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius(3)};
  padding: 2px ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme, selected }) => (selected ? theme.colors.light : "transparent")};
  color: ${({ theme, selected }) => (selected ? theme.colors.dark : theme.colors.white)};
  transition: all 0.2s ease-in-out;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const PackageSelectionContainerTabTitle = styled.p`
  font-size: 16px;
  line-height: 18px;
  font-weight: 800;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0;
`;

const PackageSavingsBadge = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius(3)};
  padding: 4px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 800;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.dark};
  margin-left: ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PackagePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PackagePrice = styled.p`
  font-size: 64px;
  line-height: 102px;
  letter-spacing: -1.6px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.dark};
  margin: 0 8px;
`;

const PackagePriceOptions = styled.p`
  font-size: 18px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.gray2};
  margin: 0;
`;

const PackageButtonContainer = styled.div`
  margin: 0 auto 16px;
  justify-content: center;
  flex: 1;
  display: flex;
`;

const PackageDescriptionText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray2};
  margin: 0;
  text-align: center;
`;
