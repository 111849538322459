import styled from "@emotion/styled";
import React, { FunctionComponent } from "react";

type Props = {
  currentStep: number;
  totalSteps: number;
  /** If specified ProgressBar gets renders absolute at 90% of viewport height */
  renderOnBottom?: boolean;
  style?: React.CSSProperties;
};

export const ProgressBar: FunctionComponent<Props> = ({ currentStep, totalSteps, renderOnBottom, style }) => (
  <Wrapper renderOnBottom={renderOnBottom} style={style}>
    <Bar />
    <BarActive widthPct={((currentStep - 1) / (totalSteps - 1)) * 100} />
  </Wrapper>
);

const Wrapper = styled.div<{ renderOnBottom?: boolean }>`
  position: relative;
  width: ${({ renderOnBottom }) => (renderOnBottom ? "320px" : "100%")};
  margin: auto;
  margin-top: ${({ renderOnBottom }) => (renderOnBottom ? "0" : "30px")};
  margin-bottom: ${({ renderOnBottom }) => (renderOnBottom ? "52px" : "12px")};
`;

const Bar = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background-color: white;
  opacity: 0.1;
`;

const BarActive = styled.div<{ widthPct: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ widthPct }) => `calc(${widthPct}% + ${12 * (1 - widthPct / 100)}px)`};
  height: 6px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.primary};
`;
