import { gql } from "@apollo/client";
import { useSetProfileDataMutation } from "generated/graphql";
import { logger } from "util/logger";

export const useSetProfileData = (onError?: () => void) => {
  return useSetProfileDataMutation({
    onError: (e) => {
      logger.error("useSetProfileDataMutation failed", e);
      onError?.();
    }
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SET_PROFILE_DATA_MUTATION = gql`
  mutation setProfileData($profileData: UserProfileDataInput!) {
    setUserProfileData(userProfileData: $profileData) {
      _id
      userData {
        profileData {
          firstname
          fitness
          fitnessStatus
          injury
          prevention
          picture {
            id
            uri
          }
          positionGroup
          position
          playerCoachPosition
        }
      }
    }
  }
`;
