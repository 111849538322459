import styled from "@emotion/styled";
import { Grid } from "@geist-ui/core";
import { SportsClubBg } from "assets";
import { CheckIcon } from "assets/icons";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { SportsClubOnboardingStep } from "components/molecules/SportsClubOnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { OAuthContext } from "contexts/OAuthContext";
import { SportsClubAcquisitionSource, useUpdateSportsClubAcquisitionMutation } from "generated/graphql";
import { useToast } from "hooks/useToast";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";

export const SportsClubAcquisition: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const { acquisitionSource, acquisitionDescription, setSportsClubAcquisitionSource, setSportsClubAcquisitionDescription, sportsClubName, clear } = useOnboardingStore();
  const showToast = useToast();
  const [, setSearch] = useSearchParams();
  const { redirectOAuth } = useContext(OAuthContext);

  const [mutateAcquisition] = useUpdateSportsClubAcquisitionMutation({
    onCompleted: async () => {
      clear();
      setSearch({ redirect_uri: `${process.env.REACT_APP_COACHZONE_OAUTH_URL}/${i18n.language}/auth/oauth-callback` });
      redirectOAuth();
    },
    onError: (error) => {
      console.log("error", error);
      showToast({ text: t("pages.teamOnboarding.sportsClubCreation.error"), type: "error" });
    }
  });

  const goToNextStep = async () => {
    if (!acquisitionSource || !sportsClubName) {
      return;
    }

    await mutateAcquisition({ variables: { input: { source: acquisitionSource, description: acquisitionDescription } } });
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={SportsClubBg} />}>
        <SportsClubOnboardingStep title={t("pages.sportsClubOnboarding.title")} subTitle={t("pages.sportsClubOnboarding.acquisition.title")} step={3}>
          <Grid.Container gap={2} direction={"column"} justify="center">
            <Grid sm={24} xs={24} width="100%" direction="column">
              <CheckContainer
                selected={acquisitionSource === SportsClubAcquisitionSource.GOOGLE_SEARCH}
                onClick={() => setSportsClubAcquisitionSource(SportsClubAcquisitionSource.GOOGLE_SEARCH)}
              >
                <CheckCircle selected={acquisitionSource === SportsClubAcquisitionSource.GOOGLE_SEARCH}>
                  {acquisitionSource === SportsClubAcquisitionSource.GOOGLE_SEARCH && <CheckCircleIcon />}
                </CheckCircle>
                <CheckText>{t("pages.sportsClubOnboarding.acquisition.GOOGLE_SEARCH")}</CheckText>
              </CheckContainer>
              <CheckContainer
                selected={acquisitionSource === SportsClubAcquisitionSource.SOCIAL_MEDIA}
                onClick={() => setSportsClubAcquisitionSource(SportsClubAcquisitionSource.SOCIAL_MEDIA)}
              >
                <CheckCircle selected={acquisitionSource === SportsClubAcquisitionSource.SOCIAL_MEDIA}>
                  {acquisitionSource === SportsClubAcquisitionSource.SOCIAL_MEDIA && <CheckCircleIcon />}
                </CheckCircle>
                <CheckText>{t("pages.sportsClubOnboarding.acquisition.SOCIAL_MEDIA")}</CheckText>
              </CheckContainer>
              <CheckContainer
                selected={acquisitionSource === SportsClubAcquisitionSource.RECOMMENDATION}
                onClick={() => setSportsClubAcquisitionSource(SportsClubAcquisitionSource.RECOMMENDATION)}
              >
                <CheckCircle selected={acquisitionSource === SportsClubAcquisitionSource.RECOMMENDATION}>
                  {acquisitionSource === SportsClubAcquisitionSource.RECOMMENDATION && <CheckCircleIcon />}
                </CheckCircle>
                <CheckText>{t("pages.sportsClubOnboarding.acquisition.RECOMMENDATION")}</CheckText>
              </CheckContainer>
              <CheckContainer
                selected={acquisitionSource === SportsClubAcquisitionSource.B42_APP}
                onClick={() => setSportsClubAcquisitionSource(SportsClubAcquisitionSource.B42_APP)}
              >
                <CheckCircle selected={acquisitionSource === SportsClubAcquisitionSource.B42_APP}>
                  {acquisitionSource === SportsClubAcquisitionSource.B42_APP && <CheckCircleIcon />}
                </CheckCircle>
                <CheckText>{t("pages.sportsClubOnboarding.acquisition.B42_APP")}</CheckText>
              </CheckContainer>
              <CheckContainer
                selected={acquisitionSource === SportsClubAcquisitionSource.ITK}
                onClick={() => setSportsClubAcquisitionSource(SportsClubAcquisitionSource.ITK)}
              >
                <CheckCircle selected={acquisitionSource === SportsClubAcquisitionSource.ITK}>
                  {acquisitionSource === SportsClubAcquisitionSource.ITK && <CheckCircleIcon />}
                </CheckCircle>
                <CheckText>{"ITK"}</CheckText>
              </CheckContainer>
              <CheckContainer
                selected={acquisitionSource === SportsClubAcquisitionSource.OTHER}
                onClick={() => setSportsClubAcquisitionSource(SportsClubAcquisitionSource.OTHER)}
              >
                <CheckCircle selected={acquisitionSource === SportsClubAcquisitionSource.OTHER}>
                  {acquisitionSource === SportsClubAcquisitionSource.OTHER && <CheckCircleIcon />}
                </CheckCircle>
                <CheckText>{t("pages.sportsClubOnboarding.acquisition.OTHER")}</CheckText>
              </CheckContainer>
              {acquisitionSource === SportsClubAcquisitionSource.OTHER ? (
                <Input
                  placeholder={t("pages.sportsClubOnboarding.acquisition.description")}
                  value={acquisitionDescription}
                  onChange={(e) => setSportsClubAcquisitionDescription(e.target.value)}
                />
              ) : null}
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={2} marginBottom={4}>
            <Grid sm={24} xs={24}>
              <Button type="button" variant={acquisitionSource ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
                {t("pages.sportsClubOnboarding.start")}
              </Button>
            </Grid>
          </Grid.Container>
        </SportsClubOnboardingStep>
      </PageContainer>
    </>
  );
};

const CheckContainer = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  border-radius: 24px;
  border: 1px solid ${({ selected, theme }) => (selected ? theme.colors.primary : "transparent")};
  background-color: ${({ theme }) => theme.colors.dark};
  padding: ${({ theme }) => theme.spacing(1.5)};

  :hover {
    cursor: pointer;
  }
`;

const CheckText = styled.p`
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
`;

const CheckCircle = styled.div<{ selected: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${({ theme, selected }) => (selected ? theme.colors.primary : theme.colors.white)};
  margin-right: ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, selected }) => (selected ? theme.colors.primary : "transparent")};
`;

const CheckCircleIcon = styled(CheckIcon)`
  width: 16px;
  fill: ${({ theme }) => theme.colors.dark};
`;
