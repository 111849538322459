import styled from "@emotion/styled/macro";
import React from "react";
import { ReactComponent as Check } from "assets/icons/icon-check.svg";

interface Props extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "defaultValue"> {
  error?: boolean | string;
  checked?: boolean;
  label?: string;
}

export const RadioButton = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <Container>
      <Label>
        <InputStyled {...props} ref={ref} type="radio" checked={props.checked} error={!!props.error} onChange={(e) => props.onChange?.(e)} placeholder={undefined} />
        <RadioToggler>{props.checked && <ActiveCircle />}</RadioToggler>
        {props.label && <LabelText>{props.label}</LabelText>}
      </Label>
    </Container>
  );
});

RadioButton.displayName = "RadioButton";

const ActiveCircle = styled(Check)``;

const Container = styled.div`
  & + & {
    margin-left: 33px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
      margin-left: 20px;
    }
  }
`;

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LabelText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
`;

const RadioToggler = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 16px;
  transition: all 0.1s;
  position: relative;

  &:before {
    content: "";
    background: ${({ theme }) => theme.colors.primary};
    position: absolute;
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    margin-left: -5px;
    margin-top: -5px;
    border-radius: 10px;
    opacity: 0;
  }
`;

const InputStyled = styled.input<{ error?: boolean }>`
  display: none;

  &:checked + ${RadioToggler}:before {
    opacity: 1;
  }
`;
