import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { EyeIcon } from "assets/icons";
import React, { useEffect, useMemo, useState } from "react";
import { theme } from "theme";

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  error?: boolean | string;
}

export const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [value, setValue] = useState(props.value ?? "");
  const [isActive, setIsActive] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const theme = useTheme();

  const placeholderFloating = useMemo(() => {
    return isActive || !!value || (typeof value === "string" && value.length > 0) || props.type === "date" || props.type === "time" || props.type === "datetime-local";
  }, [value, isActive, props.type]);

  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);

  const togglePasswordAppearance = () => {
    setPasswordShown((show) => !show);
  };

  return (
    <OuterWrapper>
      <InnerWrapper>
        <Placeholder floating={placeholderFloating} error={!!props.error}>
          {props.placeholder}
        </Placeholder>
        <InputStyled
          {...props}
          ref={ref}
          error={!!props.error}
          onFocus={(e) => {
            props.onFocus?.(e);
            setIsActive(true);
          }}
          onBlur={(e) => {
            props.onBlur?.(e);
            setIsActive(false);
          }}
          onReset={(e) => {
            props.onReset?.(e);
          }}
          onChange={(e) => {
            props.onChange?.(e);
            setValue(e.target.value);
          }}
          placeholder={undefined}
          type={props.type === "password" ? (passwordShown ? "text" : "password") : props.type}
        />
        {props.type === "password" ? <Icon color={passwordShown ? theme.colors.primary : theme.colors.light} as={EyeIcon} onClick={togglePasswordAppearance} /> : null}
      </InnerWrapper>
      {props.error && typeof props.error == "string" ? <ErrorLabel>{props.error}</ErrorLabel> : null}
    </OuterWrapper>
  );
});
Input.displayName = "Input";

const Icon = styled.svg<{ onClick?: () => void; color: string }>`
  fill: ${({ color }) => color};
  position: absolute;
  right: ${({ theme }) => theme.spacing(2)};
  top: 11px;

  ${({ onClick }) =>
    onClick
      ? `
        cursor: pointer;
        transition: fill 0.3s;

        &:hover {
          fill: ${theme.colors.primaryHover};
        }
      `
      : null};
`;

const OuterWrapper = styled.div`
  width: 100%;

  & + & {
    margin-top: ${({ theme }) => theme.spacing(2)};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
      margin-top: ${({ theme }) => theme.spacing(1)};
    }
  }
`;

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;

const InputStyled = styled.input<{ error?: boolean }>`
  border: 1px solid;
  width: 100%;
  padding: 20px 40px 8px 20px;
  color: ${({ theme }) => theme.colors.light};
  caret-color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.primary)};
  background-color: ${({ theme }) => theme.colors.dark};
  border-color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors["sonic-silver"])};
  border-radius: ${({ theme }) => theme.borderRadius(2.5)};
  font-size: 15px;
  line-height: 18px;
  height: 52px;
`;

const Placeholder = styled.label<{ floating: boolean; error?: boolean }>`
  position: absolute;
  top: 50%;
  left: 20px;
  font-size: 15px;
  font-weight: 500;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: left top;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.light50Percent};
  ${({ floating }) =>
    floating &&
    `
    transform: translateY(-80%) scale(0.65);
    text-transform: uppercase;
    font-style: italic;
    letter-spacing: 1.5px;
  `}
  pointer-events: none;
`;

const ErrorLabel = styled.label`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  padding: 0 20px;
`;
