import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { authTokenGetter } from "./contexts/AuthContext";
import { createUploadLink } from "apollo-upload-client";

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URI ?? "https://b42-backend-v3.azurewebsites.net/graphql"
})

const authLink = setContext((query, { headers }) => {
  const token = authTokenGetter();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "x-apollo-operation-name": query.operationName
    }
  };
});

export const apolloClient = new ApolloClient({
  link: from([authLink, uploadLink]),
  cache: new InMemoryCache()
});
