import "react-phone-input-2/lib/style.css";
import { Grid } from "@geist-ui/core";
import { SportsClubBg } from "assets";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { SportsClubOnboardingStep } from "components/molecules/SportsClubOnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { useCompleteOnboardingMutation, useUpdateSportsClubDataMutation } from "generated/graphql";
import { useSetProfileData } from "hooks/useSetProfileData";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";
import PhoneInput from "react-phone-input-2";
import de from "react-phone-input-2/lang/de.json";

export const SportsClubUser: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { firstname, setFirstname, lastname, setLastname, setSportsClubPhone, sportsClubAddress } = useOnboardingStore();
  const [setProfileData] = useSetProfileData();
  const [mutateSportsClubData] = useUpdateSportsClubDataMutation();
  const [mutateOnboarding] = useCompleteOnboardingMutation();

  const phoneNumber = sportsClubAddress.phone;

  const goToNextStep = async () => {
    if (!firstname) {
      return;
    }

    await setProfileData({
      variables: {
        profileData: {
          firstname,
          lastname
        }
      }
    });

    if (phoneNumber) {
      mutateSportsClubData({ variables: { input: { phone: "+" + sportsClubAddress.phone } } });
    }

    await mutateOnboarding();

    navigate({ pathname: "../acquisition" });
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={SportsClubBg} />}>
        <SportsClubOnboardingStep title={t("pages.sportsClubOnboarding.title")} subTitle={t("pages.sportsClubOnboarding.user.title")} step={2} fullWidth>
          <Grid.Container gap={2} direction={"row"} justify="center">
            <Grid sm={12} xs={24} width="100%" direction="column">
              <Input placeholder={t("pages.sportsClubOnboarding.user.firstname")} value={firstname} onChange={(e) => setFirstname(e.target.value)} />
            </Grid>
            <Grid sm={12} xs={24} width="100%" direction="column">
              <Input placeholder={t("pages.sportsClubOnboarding.user.lastname")} value={lastname} onChange={(e) => setLastname(e.target.value)} />
            </Grid>
            <Grid sm={24} xs={24} width="100%" direction="column">
              <PhoneInput
                country="de"
                localization={de}
                value={phoneNumber}
                onChange={setSportsClubPhone}
                preferredCountries={["de", "us", "ca", "au"]}
                containerStyle={{ borderRadius: 16, border: "1px solid #707070", width: "100%", height: "48px", padding: "0 16px" }}
                inputStyle={{ width: "100%", height: "100%", border: "none", outline: "none", fontSize: "16px", backgroundColor: "transparent" }}
                dropdownStyle={{ backgroundColor: "#1d1d1d", borderRadius: 16, boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)", marginTop: 0 }}
                buttonStyle={{ backgroundColor: "transparent", border: "none", outline: "none" }}
                searchStyle={{ backgroundColor: "transparent", border: "none", outline: "none" }}
              />
              {/* <Input placeholder={t("pages.sportsClubOnboarding.user.phone")} value={phoneNumber} onChange={(e) => setSportsClubPhone(e.target.value)} /> */}
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={2} marginBottom={4}>
            <Grid sm={12} xs={24}>
              <Button type="button" variant={firstname ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
                {t("common.continue")}
              </Button>
            </Grid>
          </Grid.Container>
        </SportsClubOnboardingStep>
      </PageContainer>
    </>
  );
};
