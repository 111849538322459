import { Grid, useMediaQuery } from "@geist-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "components/atoms/ProgressBar";
import { PageContainer } from "components/organisms/PageContainer";
import { Foot, useCompleteOnboardingMutation } from "generated/graphql";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { PlayerPaymentBg } from "assets/backgroundImages";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { useToast } from "hooks/useToast";
import { useSetProfileData } from "hooks/useSetProfileData";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { Select, SelectOption } from "components/atoms/Select";
import { useOnboardingStore } from "stores/onboarding.store";
import { DateInput } from "components/atoms/DateInput";

export const UserDataSelection: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const showToast = useToast();
  const [setProfileData, { loading }] = useSetProfileData(() => showToast({ text: t("membership.profile.savedError"), type: "error" }));
  const {
    firstname,
    lastname,
    height,
    weight,
    foot,
    birthday,
    fitnessStatus,
    gender,
    genderIdentity,
    position,
    setFirstname,
    setLastname,
    setHeight,
    setWeight,
    setFoot,
    setBirthday,
    role,
  } = useOnboardingStore();
  const [mutateOnboarding] = useCompleteOnboardingMutation();

  const saveUserData = async () => {
    await setProfileData({
      variables: {
        profileData: {
          gender: gender,
          genderIdentity: genderIdentity,
          position: position,
          fitnessStatus: fitnessStatus,
          foot,
          height,
          weight,
          firstname,
          lastname,
          birthday: birthday ? birthday.getTime() : undefined
        }
      }
    });
    await mutateOnboarding();

    navigate({ pathname: "../finish" });
  };

  const goToNextStep = async () => {
    if (!firstname) {
      return;
    }

    await saveUserData();
    await mutateOnboarding();
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={PlayerPaymentBg} />}>
        {isXS ? <ProgressBar currentStep={role ? 9 : 4} totalSteps={role ? 14 : 5} /> : null}
        <OnboardingStep fullWidth title={t("pages.onboarding.userDataSelection.title")} subtitle={t("pages.onboarding.userDataSelection.description")}>
          <Grid.Container gap={2} direction={isXS ? "column" : "row"}>
            <Grid sm={12}>
              <Input placeholder={t("pages.onboarding.userDataSelection.firstname")} value={firstname} onChange={(e) => setFirstname(e.target.value)} />
            </Grid>
            <Grid sm={12}>
              <Input placeholder={t("pages.onboarding.userDataSelection.lastname")} value={lastname} onChange={(e) => setLastname(e.target.value)} />
            </Grid>
            <Grid sm={12}>
              <Input
                type="number"
                placeholder={t("pages.onboarding.userDataSelection.height")}
                value={height}
                onChange={(e) => {
                  if (Number.isNaN(e)) {
                    return;
                  }

                  setHeight(Number(e.target.value));
                }}
              />
            </Grid>
            <Grid sm={12}>
              <Input
                type="number"
                placeholder={t("pages.onboarding.userDataSelection.weight")}
                value={weight}
                onChange={(e) => {
                  if (Number.isNaN(e)) {
                    return;
                  }

                  setWeight(Number(e.target.value));
                }}
              />
            </Grid>
            <Grid sm={24}>
              <Select placeholder={t("pages.onboarding.userDataSelection.foot")} value={foot} onChange={(value) => setFoot(value as Foot)}>
                {[Foot.RIGHT, Foot.LEFT, Foot.BOTH].map((f) => (
                  <SelectOption key={f} value={f}>
                    {t(`foot.${f}`)}
                  </SelectOption>
                ))}
              </Select>
            </Grid>
            <Grid sm={24}>
              <DateInput
                type="date"
                placeholder={t("pages.onboarding.userDataSelection.birthday")}
                value={birthday ? birthday.toISOString().split("T")[0] : undefined}
                onChange={(e) => {
                  setBirthday(new Date(e.target.value));
                }}
              />
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={2} marginBottom={1}>
            <Grid sm={12} xs={24}>
              <Button type="button" variant={firstname ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
                {t("common.continue")}
              </Button>
            </Grid>
          </Grid.Container>
        </OnboardingStep>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={role ? 9 : 4} totalSteps={role ? 14 : 5} /> : null}
      {loading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
};
