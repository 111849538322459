import { useMediaQuery } from "@geist-ui/core";

export const useNavbarHeight = () => {
  const isXS = useMediaQuery("xs");

  if (isXS) {
    return 40;
  } else {
    return 87;
  }
};
