import { useEffect, useState } from "react";

/** Returns a state that contains an object with UTM parameters */
export const useUTMParameters = () => {
  const [utmParams, setUtmParams] = useState<{ source: string | null; medium: string | null; campaign: string | null }>({
    source: null,
    medium: null,
    campaign: null
  });

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    setUtmParams({
      source: urlParams.get("utm_source"),
      medium: urlParams.get("utm_medium"),
      campaign: urlParams.get("utm_campaign")
    });
  }, []);

  return utmParams;
};
