import styled from "@emotion/styled/macro";
import { FunctionComponent } from "react";
import { ReactComponent as User } from "assets/icons/icon-user.svg";

interface Props {
  image?: string;
  size?: number;
  className?: string;
}

export const Avatar: FunctionComponent<Props> = ({ image, size = 50, className }) => {
  return (
    <Container size={size} className={className}>
      {image ? (
        <Image src={image}></Image>
      ) : (
        <DefaultImage>
          <IconUser />
        </DefaultImage>
      )}
    </Container>
  );
};

const Container = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin-right: ${({ theme }) => theme.spacing(1.5)};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const DefaultImage = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const IconUser = styled(User)`
  width: 70%;
`;
