import styled from "@emotion/styled";
import { FunctionComponent } from "react";
import { PitchBackground } from "assets";
import { Position } from "generated/graphql";
import { useTranslation } from "react-i18next";

type Props = {
  position?: Position
  onSelectPosition: (position: Position) => void;
};

export const PitchPositionSelection: FunctionComponent<Props> = ({ position, onSelectPosition }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Pitch />
      <PositionMarker top="calc(50% - 20px)" left="17px" onClick={() => onSelectPosition(Position.GK)} selected={position === Position.GK}>
        {t("position.GK")}
      </PositionMarker>
      <PositionMarker top="78px" left="80px" onClick={() => onSelectPosition(Position.CB)} selected={position === Position.CB}>
        {t("position.CB")}
      </PositionMarker>
      <PositionMarker bottom="78px" left="80px" onClick={() => onSelectPosition(Position.CB)} selected={position === Position.CB}>
        {t("position.CB")}
      </PositionMarker>
      <PositionMarker top="17px" left="120px" onClick={() => onSelectPosition(Position.LB)} selected={position === Position.LB}>
        {t("position.LB")}
      </PositionMarker>
      <PositionMarker bottom="17px" left="120px" onClick={() => onSelectPosition(Position.RB)} selected={position === Position.RB}>
        {t("position.RB")}
      </PositionMarker>
      <PositionMarker top="25px" left="230px" onClick={() => onSelectPosition(Position.LM)} selected={position === Position.LM}>
        {t("position.LM")}
      </PositionMarker>
      <PositionMarker top="calc(50% - 20px)" left="230px" onClick={() => onSelectPosition(Position.CM)} selected={position === Position.CM}>
        {t("position.CM")}
      </PositionMarker>
      <PositionMarker bottom="25px" left="230px" onClick={() => onSelectPosition(Position.RM)} selected={position === Position.RM}>
        {t("position.RM")}
      </PositionMarker>
      <PositionMarker top="25px" right="45px" onClick={() => onSelectPosition(Position.LW)} selected={position === Position.LW}>
        {t("position.LW")}
      </PositionMarker>
      <PositionMarker top="calc(50% - 20px)" right="30px" onClick={() => onSelectPosition(Position.ST)} selected={position === Position.ST}>
        {t("position.ST")}
      </PositionMarker>
      <PositionMarker bottom="25px" right="45px" onClick={() => onSelectPosition(Position.RW)} selected={position === Position.RW}>
        {t("position.RW")}
      </PositionMarker>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 433px;
  height: 281px;
  align-self: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    transform: rotate(-90deg);
    margin-top: 70px;
    margin-bottom: 70px;
  }
`;

const Pitch = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white20Percent};
  mask-image: url(${PitchBackground});
`;

const PositionMarker = styled.div<{ top?: string; bottom?: string; right?: string; left?: string; selected: boolean }>`
  position: absolute;
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: ${({ theme, selected }) => selected ? theme.colors.dark : theme.colors.white};
  background-color: ${({ theme, selected }) => selected ? theme.colors.primary : theme.colors.gray2};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;
  text-transform: uppercase;

  &:hover {
    color: ${({ theme }) => theme.colors.dark};
    background-color: ${({ theme }) => theme.colors.primary};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    transform: rotate(90deg);
  }
`;
