import { Grid, useMediaQuery } from "@geist-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "components/atoms/ProgressBar";
import { PageContainer } from "components/organisms/PageContainer";
import { Gender, GenderIdentity, useSetAttributionMutation } from "generated/graphql";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import styled from "@emotion/styled/macro";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { DiversIcon, MenIcon, WomenIcon } from "assets/icons";
import { Button } from "components/atoms/Button";
import { useOnboardingStore } from "stores/onboarding.store";
import { RoleSelectionBg } from "assets";

export const GenderSelection: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { genderIdentity, setGenderIdentity, role, attribution } = useOnboardingStore()

  const [mutateAttribution] = useSetAttributionMutation();

  const goToNextStep = () => {
    if (!genderIdentity) {
      return;
    }

    mutateAttribution({ variables: { input: { referredBy: attribution?.referredBy, refByAffiliateId: attribution?.refByAffiliateId } } });

    navigate({ pathname: "../position-selection" });
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={RoleSelectionBg} />}>
        {isXS ? <ProgressBar currentStep={role ? 6 : 1} totalSteps={role ? 14 : 5} /> : null}
        <OnboardingStep fullWidth title={t("pages.onboarding.genderSelection.title")} subtitle={t("pages.onboarding.genderSelection.subtitle")}>
          <Grid.Container gap={2} direction={isXS ? "column" : "row"}>
            <Grid sm={8}>
              <GenderButton onClick={() => setGenderIdentity(GenderIdentity.MAN, Gender.MAN)} selected={genderIdentity === GenderIdentity.MAN}>
                <GenderIcon as={MenIcon} selected={genderIdentity === GenderIdentity.MAN} />
                {t("onboarding.gender.men")}
              </GenderButton>
            </Grid>
            <Grid sm={8}>
              <GenderButton onClick={() => setGenderIdentity(GenderIdentity.WOMAN, Gender.WOMAN)} selected={genderIdentity === GenderIdentity.WOMAN}>
                <GenderIcon as={WomenIcon} selected={genderIdentity === GenderIdentity.WOMAN} />
                {t("onboarding.gender.women")}
              </GenderButton>
            </Grid>
            <Grid sm={8}>
              <GenderButton onClick={() => setGenderIdentity(GenderIdentity.DIVERS)} selected={genderIdentity === GenderIdentity.DIVERS}>
                <GenderIcon as={DiversIcon} selected={genderIdentity === GenderIdentity.DIVERS} />
                {t("onboarding.gender.divers")}
              </GenderButton>
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={2}>
            <Grid sm={12} xs={24}>
              <Button type="button" variant={genderIdentity ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
                {t("common.continue")}
              </Button>
            </Grid>
          </Grid.Container>
        </OnboardingStep>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={role ? 6 : 1} totalSteps={role ? 14 : 5} /> : null}
    </>
  );
};

const GenderIcon = styled.svg<{ selected: boolean }>`
  fill: ${({ theme, selected }) => (selected ? theme.colors.dark : theme.colors.primary)};
  margin-right: ${({ theme }) => theme.spacing(2)};
  transition: all 0.3s;
`;

const GenderButton = styled.button<{ selected: boolean }>`
  width: 100%;
  border: none;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  background: ${({ theme, selected }) => (selected ? theme.colors.primary : theme.colors.gray)};
  color: ${({ theme, selected }) => (selected ? theme.colors.dark : theme.colors.white)};
  border-radius: ${({ theme }) => theme.borderRadius(3)};
  padding: ${({ theme }) => theme.spacing(1)};
  font-weight: 800;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  transition: all 0.3s;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.dark};

    ${GenderIcon} {
      fill: ${({ theme }) => theme.colors.dark};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    height: 140px;
    font-size: 24px;
  }
`;
