import styled from "@emotion/styled";
import { Grid, Spinner } from "@geist-ui/core";
import { GenderSelectionWoman } from "assets";
import { CheckIcon } from "assets/icons";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { useLeaveSportsClubMutation, useTeamOnboardingUserQuery } from "generated/graphql";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const ExistingSportsClubSelection: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [mutateLeave, { loading: changeLoading }] = useLeaveSportsClubMutation({
    onCompleted: () => navigate({ pathname: "../../sports-club-onboarding", search: location.search }),
    onError: () => navigate({ pathname: "../../sports-club-onboarding", search: location.search })
  });

  const { data, error, loading } = useTeamOnboardingUserQuery();

  const [change, setChange] = useState(false);

  const handleNextClick = () => {
    if (change) {
      mutateLeave();
    } else {
      navigate({ pathname: "../../membership-area", search: location.search });
    }
  };

  if (loading) {
    return (
      <Grid.Container direction="column" justify="center">
        <Grid sm={24} direction="column" justify="center">
          <Spinner mt={3} mb={3} />
        </Grid>
      </Grid.Container>
    );
  }

  if (!data?.user?.userData.sportsClubMembership || error) {
    return (
      <Grid.Container>
        <Grid sm={24} direction="column" justify="center">
          <ErrorText>{t("pages.invite.error.message")}</ErrorText>
          <Button type="button" variant="primary" fullWidth onClick={handleNextClick}>
            {t("common.continue")}
          </Button>
        </Grid>
      </Grid.Container>
    );
  }

  const currentMembership = data.user.userData.sportsClubMembership;

  return (
    <>
      <PageContainer background={<BackgroundImage src={GenderSelectionWoman} />}>
        <OnboardingStep title={t("pages.invite.change.title")} subtitle={t("pages.invite.change.subTitle")}></OnboardingStep>
        <Grid.Container direction="column">
          <Grid sm={24} direction="column">
            <SportsClubContainer onClick={() => setChange(false)}>
              <SportsClubLogoContainer>
                {currentMembership.sportsClub.logo ? (
                  <SportsClubLogo src={currentMembership.sportsClub.logo?.uri} alt={"Logo - " + currentMembership.sportsClub.name} />
                ) : null}
                {change === false && (
                  <SportsClubLogoSelectedOverlay>
                    <SportsClubLogoSelectedOverlayIcon />
                  </SportsClubLogoSelectedOverlay>
                )}
              </SportsClubLogoContainer>
              <SportsClubNameContainer>
                <SportsClubName>{currentMembership.sportsClub.name}</SportsClubName>
                <SportsClubNameSub>{t("pages.invite.change.stay")}</SportsClubNameSub>
              </SportsClubNameContainer>
            </SportsClubContainer>
            <OrText>{t("pages.invite.change.or")}</OrText>
            <SportsClubContainer onClick={() => setChange(true)}>
              <SportsClubLogoContainer>
                {change && (
                  <SportsClubLogoSelectedOverlay>
                    <SportsClubLogoSelectedOverlayIcon />
                  </SportsClubLogoSelectedOverlay>
                )}
              </SportsClubLogoContainer>
              <SportsClubName>{t("pages.invite.change.createNewTeam")}</SportsClubName>
            </SportsClubContainer>
          </Grid>
          <Grid sm={24} direction="column" mt={3} mb={2}>
            <ContinueButton type="button" variant="primary" fullWidth onClick={handleNextClick}>
              {t("common.continue")}
            </ContinueButton>
          </Grid>
        </Grid.Container>
        {changeLoading ? <LoadingSpinnerOverlay /> : null}
      </PageContainer>
    </>
  );
};

const SportsClubContainer = styled.div`
  display: flex;
  max-width: 360px;
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.borderRadius(4)};
  flex-direction: row;
  align-items: center;
  margin: 0 auto ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.colors.gray};
  width: 100%;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.gray2};
  }
`;

const SportsClubLogoContainer = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: ${({ theme }) => theme.borderRadius(2.5)};
  background-color: ${({ theme }) => theme.colors["sonic-silver"]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing()};
`;

const SportsClubLogoSelectedOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => `linear-gradient(360deg, ${theme.colors.dark}00, ${theme.colors.primary})`};
  border-radius: ${({ theme }) => theme.borderRadius(2.5)};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SportsClubLogoSelectedOverlayIcon = styled(CheckIcon)`
  width: 24px;
  fill: ${({ theme }) => theme.colors.dark};
`;

const SportsClubLogo = styled.img`
  height: 20px;
  width: 20px;
`;

const SportsClubNameContainer = styled.div``;

const SportsClubName = styled.p`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px; /* 120% */
  margin: 0;
`;

const SportsClubNameSub = styled.p`
  font-size: 10px;
  line-height: 18px;
  margin: 0;
`;

const ContinueButton = styled(Button)`
  margin: 0 auto;
  max-width: 360px;
`;

const ErrorText = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 120% */
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const OrText = styled.p`
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  margin: ${({ theme }) => theme.spacing(2)} 0;
  text-align: center;
`;
