import { Grid, useMediaQuery } from "@geist-ui/core";
import { CoachManImage, FunctionaryImage, PlayerCoachManImage, PlayerManImage } from "assets";
import { PositionSelectionManBg } from "assets/backgroundImages";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { ProgressBar } from "components/atoms/ProgressBar";
import { ImageSelectOption } from "components/molecules/ImageSelectOption";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { SportsClubTeamRole, useJoinSportsClubTeamMutation } from "generated/graphql";
import { useToast } from "hooks/useToast";
import { t } from "i18next";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";

export const RoleSelection: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const navigate = useNavigate();
  const toast = useToast();

  const { role, setRole, sportsClubId, sportsClubTeamId } = useOnboardingStore();

  const [mutateJoin] = useJoinSportsClubTeamMutation({
    onCompleted: () => {
      if (role === "PLAYER" || role === "PLAYER_COACH") {
        navigate({ pathname: "../gender-selection" });
      } else {
        navigate({ pathname: "../user-data-selection-lite" });
      }
    },
    onError: () => {
      toast({ text: t("pages.teamOnboarding.sportsClubTeamCreation.error"), type: "error" });
      if (role === "PLAYER" || role === "PLAYER_COACH") {
        navigate({ pathname: "../gender-selection" });
      } else {
        navigate({ pathname: "../user-data-selection-lite" });
      }
    }
  });

  const goToNextStep = () => {
    if (!role) {
      return;
    }

    if (sportsClubId && sportsClubTeamId)
      mutateJoin({
        variables: {
          sportsClubId,
          sportsClubTeamId,
          role: role === "PLAYER" ? SportsClubTeamRole.PLAYER : SportsClubTeamRole.COACH
        }
      });
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={PositionSelectionManBg} />}>
        {isXS ? <ProgressBar currentStep={5} totalSteps={14} /> : null}
        <OnboardingStep fullWidth title={t("pages.onboarding.roleSelection.title")} subtitle={t("pages.onboarding.roleSelection.subtitle")}></OnboardingStep>
        <Grid.Container gap={2} direction={isXS ? "column" : "row"} justify="center">
          <Grid sm={12} xs={24} width="100%">
            <ImageSelectOption
              label={t("pages.onboarding.roleSelection.coach")}
              value={"COACH"}
              backgroundImage={CoachManImage}
              onSelectOption={setRole}
              selected={role === "COACH"}
            />
          </Grid>
          <Grid sm={12} xs={24} width="100%">
            <ImageSelectOption
              label={t("pages.onboarding.roleSelection.player")}
              value={"PLAYER"}
              backgroundImage={PlayerManImage}
              onSelectOption={setRole}
              selected={role === "PLAYER"}
            />
          </Grid>
          <Grid sm={12} xs={24} width="100%">
            <ImageSelectOption
              label={t("pages.onboarding.roleSelection.playerCoach")}
              value={"PLAYER_COACH"}
              backgroundImage={PlayerCoachManImage}
              onSelectOption={setRole}
              selected={role === "PLAYER_COACH"}
            />
          </Grid>
          <Grid sm={12} xs={24} width="100%">
            <ImageSelectOption
              label={t("pages.onboarding.roleSelection.other")}
              value={"OTHER"}
              backgroundImage={FunctionaryImage}
              onSelectOption={setRole}
              selected={role === "OTHER"}
            />
          </Grid>
        </Grid.Container>
        <Grid.Container justify="center" marginTop={2} marginBottom={4}>
          <Grid sm={12} xs={24}>
            <Button type="button" variant={role ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
              {t("common.continue")}
            </Button>
          </Grid>
        </Grid.Container>
        {!isXS ? <ProgressBar renderOnBottom currentStep={5} totalSteps={14} /> : null}
      </PageContainer>
    </>
  );
};
