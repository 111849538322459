import styled from "@emotion/styled";
import { Spinner } from "@geist-ui/core";
import { FunctionComponent } from "react";

export const LoadingSpinnerOverlay: FunctionComponent = () => {
  return (
    <OverlayWrapper>
      <LoadingSpinner color="red" />
    </OverlayWrapper>
  );
};

const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.dark70Percent};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const LoadingSpinner = styled(Spinner)``;
