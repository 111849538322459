import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SignUpBg } from "assets/backgroundImages";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Separator } from "components/atoms/Separator";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { PageContainer } from "components/organisms/PageContainer";
import { SignUpErrorCodes, useUpdateUserLanguageMutation } from "generated/graphql";
import { useSignUp } from "hooks/useSignUp";
import { SignUpForm } from "./components/SignUpForm";
import { TermsAndConditionsText } from "components/molecules/TermsAndConditionsText";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import styled from "@emotion/styled";
import { ParamsBundleType } from "util/constants";
import { H2 } from "components/atoms/Heading";
import { useToast } from "hooks/useToast";
import { useSearchParam } from "hooks/useSearchParam";
import { logger } from "util/logger";
import { useOnboardingStore } from "stores/onboarding.store";

export const SignUpEmail: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showToast = useToast();
  const searchParams = new URLSearchParams(window.location.search);
  const { setBundle } = useOnboardingStore();
  const [mutateLanguage] = useUpdateUserLanguageMutation();

  const bundle = useSearchParam<ParamsBundleType>("bundle", {
    defaultValue: ParamsBundleType.NONE,
    allowedValues: Object.values(ParamsBundleType)
  });

  const sportsClubId = useSearchParam<string>("sportsClub", {
    defaultValue: ""
  });
  const teamId = useSearchParam<string>("team", {
    defaultValue: ""
  });

  const { signUp, loading: signUpLoading } = useSignUp({
    onSuccess: () => {
      // set language code
      const language = getPathLanguage()
      if (language) {
        mutateLanguage({ variables: { code: language } })
      }

      setBundle(bundle);
      if (sportsClubId && teamId) {
        // invite link was handled
        navigate({ pathname: "../invite", search: location.search });
      } else if (bundle === ParamsBundleType.NONE) {
        navigate({ pathname: "../onboarding/bundle", search: location.search });
      } else if (bundle === ParamsBundleType.TEAM) {
        navigate({ pathname: "../sports-club-onboarding", search: searchParams.toString() });
      } else {
        navigate({ pathname: "../onboarding", search: searchParams.toString() });
      }
    },
    onError: (e) => {
      if (e === SignUpErrorCodes.EMAIL_TAKEN_ALREADY) {
        showToast({ text: t("form.error.accountAlreadyExists"), type: "error" });
      } else {
        logger.error("Sign up error", e);
        showToast({ text: t("form.error.signUpFailed"), type: "error" });
      }
    }
  });

  const handleSignIn = () => navigate({ pathname: "../sign-in-email", search: location.search });

  return (
    <>
      <PageContainer background={<BackgroundImage src={SignUpBg} />}>
        <OnboardingStep title={t("pages.signUpEmail.heading")} subtitle={t("pages.signUpEmail.subheading")}>
          <ContentContainer>
            <SignUpForm onSubmit={(values) => signUp(values.email, values.password)} />
            <TermsAndConditionsText />
            <Title>{t("pages.signUpEmail.alreadyHasAccount")}</Title>
            <Separator />
            <Button variant="primary-outline" fullWidth onClick={() => handleSignIn()}>
              {t("pages.signUpEmail.toSignIn")}
            </Button>
          </ContentContainer>
        </OnboardingStep>
      </PageContainer>
      {signUpLoading ? <LoadingSpinnerOverlay /> : undefined}
    </>
  );
};

const getPathLanguage = () => {
  const pathname = location.pathname.split("/")

  if (pathname.length < 2) {
    return undefined
  }

  const language = pathname[1]

  if (language.length !== 2) {
    return undefined
  }

  return language
}

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    align-items: flex-start;
  }
`;

const Title = styled(H2)`
  margin-top: ${({ theme }) => theme.spacing(8)};
  margin-bottom: 0;
`;
