import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

export type AvailableLanguage = "de" | "en" | "es" | "fr" | "it" | "pt" | "tr" | "ko" | "ja";


let language = "de";

export const availableLanguages: AvailableLanguage[] = ["de", "en", "es", "fr", "it", "pt", "tr", "ko", "ja"];

availableLanguages.forEach((lang) => {
  if (window.location.pathname.includes("/" + lang + "/")) {
    language = lang;
  }
});

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    returnNull: false,
    fallbackLng: "en",
    lng: language,
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage

    backend: {
      // for all available options read the backend's repository readme file
      loadPath: "/locales/{{lng}}.json"
    },

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });


export default i18n.t;
