import { Theme, useTheme } from "@emotion/react";
import styled from "@emotion/styled/macro";
import React, { FunctionComponent } from "react";

export const BUTTON_HEIGHT = 52;
export const BUTTON_SMALL_HEIGHT = 40;

export type SvgComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

type ButtonVariant = "primary" | "primary-outline" | "light" | "light-outline" | "transparent" | "disabled";
type IconPosition = "relative" | "absolute";
type IconSide = "right" | "left";

interface StyleProps {
  variant?: ButtonVariant;
  italic?: boolean;
  fullWidth?: boolean;
  small?: boolean;
}

type ButtonStyles = {
  textColor: string;
  textColorHover: string;
  backgroundColor: string;
  backgroundColorHover: string;
  borderColor: string;
  borderColorHover: string;
};

const stylesForVariantMap: Record<ButtonVariant, (theme: Theme) => ButtonStyles> = {
  light: (theme) => ({
    textColor: theme.colors.dark,
    textColorHover: theme.colors.dark,
    borderColor: theme.colors.white,
    borderColorHover: theme.colors.whiteHover,
    backgroundColor: theme.colors.white,
    backgroundColorHover: theme.colors.whiteHover
  }),
  "light-outline": (theme) => ({
    textColor: theme.colors.white,
    textColorHover: theme.colors.whiteHover,
    borderColor: theme.colors.white,
    borderColorHover: theme.colors.whiteHover,
    backgroundColor: "transparent",
    backgroundColorHover: "transparent"
  }),
  transparent: (theme) => ({
    textColor: theme.colors.primary,
    textColorHover: theme.colors.primaryHover,
    borderColor: "transparent",
    borderColorHover: "transparent",
    backgroundColor: "transparent",
    backgroundColorHover: "transparent"
  }),
  primary: (theme) => ({
    textColor: theme.colors.dark,
    textColorHover: theme.colors.dark,
    borderColor: theme.colors.primary,
    borderColorHover: theme.colors.primaryHover,
    backgroundColor: theme.colors.primary,
    backgroundColorHover: theme.colors.primaryHover
  }),
  "primary-outline": (theme) => ({
    textColor: theme.colors.primary,
    textColorHover: theme.colors.primaryHover,
    borderColor: theme.colors.primary,
    borderColorHover: theme.colors.primaryHover,
    backgroundColor: "transparent",
    backgroundColorHover: "transparent"
  }),
  disabled: (theme) => ({
    backgroundColor: theme.colors.lightGray,
    backgroundColorHover: theme.colors.lightGray,
    borderColor: theme.colors.lightGray,
    borderColorHover: theme.colors.lightGray,
    textColor: theme.colors.light,
    textColorHover: theme.colors.light
  })
};

interface Props extends StyleProps, React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  onClick?: () => void;
  children?: React.ReactNode;
  Icon?: SvgComponent;
  /** Used to set a size of icon component, default: 32 */
  iconSize?: number;
  /** Used to set a side position of icon component, default: left */
  iconSide?: IconSide;
  /** Used to set a position of icon component, default: relative */
  iconPosition?: IconPosition;
  /** Used to render highly individualized content */
  render?: () => React.ReactNode;
  className?: string;
}

export const Button: FunctionComponent<Props> = ({
  children,
  onClick,
  Icon,
  iconSize = 32,
  small = false,
  iconSide = "left",
  iconPosition = "relative",
  variant = "primary",
  render,
  ...props
}) => {
  const theme = useTheme();

  const variantColors = stylesForVariantMap[variant](theme);
  return (
    <StyledButton variant={variant} onClick={onClick} {...variantColors} hasIcon={!!Icon} iconPosition={iconPosition} iconSide={iconSide} small={small} {...props}>
      {Icon && iconSide === "left" ? <StyledIcon as={Icon} side={iconSide} position={iconPosition} width={iconSize} /> : null}
      {children}
      {render?.() || null}
      {Icon && iconSide === "right" ? <StyledIcon as={Icon} side={iconSide} position={iconPosition} width={iconSize} /> : null}
    </StyledButton>
  );
};

const StyledIcon = styled.svg<{ side: IconSide; position: IconPosition; width: number }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ width }) => `${width}px`};
  transition: all 200ms;
  position: ${({ position }) => position};
  margin-left: ${({ position, side, theme }) => (position === "relative" && side === "right" ? theme.spacing(1) : null)};
  margin-right: ${({ position, side, theme }) => (position === "relative" && side === "left" ? theme.spacing(1) : null)};

  left: ${({ position, side, theme }) => (position === "absolute" && side === "left" ? theme.spacing(1) : null)};
  right: ${({ position, side, theme }) => (position === "absolute" && side === "right" ? theme.spacing(1) : null)};
  ${({ position, width }) => (position === "absolute" ? `top: 50%; margin-top: calc(-${width}px / 2);` : null)}

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    left: ${({ position, side, theme }) => (position === "absolute" && side === "left" ? theme.spacing(1) : null)};
    right: ${({ position, side, theme }) => (position === "absolute" && side === "right" ? theme.spacing(1) : null)};
  }
`;

type StyledButtonProps = StyleProps & ButtonStyles & { hasIcon: boolean; iconSide: IconSide; iconPosition: IconPosition };

const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  box-sizing: border-box;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  border-radius: ${({ theme }) => theme.borderRadius(2)};
  height: ${({ small }) => (small ? `${BUTTON_SMALL_HEIGHT}px` : `${BUTTON_HEIGHT}px`)};
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  color: ${({ textColor }) => textColor};
  text-transform: uppercase;
  font-style: ${({ italic }) => (italic ? "italic" : "normal")};
  font-size: 15px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: background-color 200ms ease 0ms, border 200ms ease 0ms, color 200ms ease 0ms;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  flex-shrink: 0;

  * + & {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  ${StyledIcon} {
    fill: ${({ textColor }) => textColor};
  }

  &:hover {
    color: ${({ textColorHover }) => textColorHover};
    background-color: ${({ backgroundColorHover }) => backgroundColorHover};
    border-color: ${({ borderColorHover }) => borderColorHover};

    ${StyledIcon} {
      fill: ${({ textColorHover }) => textColorHover};
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    padding: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme, hasIcon, iconSide, iconPosition }) =>
      hasIcon && iconPosition === "absolute" && iconSide === "left" ? theme.spacing(5) : theme.spacing(1)};

    * + & {
      margin-top: ${({ theme }) => theme.spacing(1)};
    }
  }
`;
