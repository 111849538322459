import styled from "@emotion/styled";
import { Grid, useMediaQuery } from "@geist-ui/core";
import { RoleSelectionManBg } from "assets/backgroundImages";
import { UploadIcon } from "assets/icons";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { ProgressBar } from "components/atoms/ProgressBar";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { useUploadSportsClubLogoMutation } from "generated/graphql";
import { useToast } from "hooks/useToast";
import { ChangeEvent, FunctionComponent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const SportsClubLogo: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showToast = useToast();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const [mutate, { loading }] = useUploadSportsClubLogoMutation({
    onCompleted: (data) => setLogo(data.changeSportsClubLogo.logo?.uri),
    onError: () => showToast({ text: t("pages.teamOnboarding.sportsClubLogo.error") }),
  });

  const [logo, setLogo] = useState<string>();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;

    mutate({
      variables: { newLogo: event.target.files[0] }
    });
  };

  const handleUploadClick = () => hiddenFileInput.current?.click();

  const goToNextStep = () => navigate({ pathname: "../sports-club-data" });

  const skip = () => navigate({ pathname: "../sports-club-data" });

  return (
    <>
      <PageContainer background={<BackgroundImage src={RoleSelectionManBg} />}>
        {isXS ? <ProgressBar currentStep={2} totalSteps={14} /> : null}
        <OnboardingStep title={t("pages.teamOnboarding.sportsClubLogo.title")} subtitle={t("pages.teamOnboarding.sportsClubLogo.subTitle")}>
          <Grid.Container gap={2} direction={isXS ? "column" : "row"}>
            {logo ? (
              <Grid sm={24} xs={24}>
                <input type="file" ref={hiddenFileInput} onChange={handleFileChange} accept="image/*" style={{ display: "none" }} />
                <LogoContainer onClick={handleUploadClick}>
                  <LogoImage src={logo} />
                </LogoContainer>
              </Grid>
            ) : (
              <Grid sm={24} xs={24} justify="center">
                <input type="file" ref={hiddenFileInput} onChange={handleFileChange} accept="image/*" style={{ display: "none" }} />
                <UploadLogoButton onClick={handleUploadClick}>
                  <UploadLogoButtonIcon as={UploadIcon}></UploadLogoButtonIcon>
                  <UploadLogoButtonTitle>{t("pages.teamOnboarding.sportsClubLogo.description")}</UploadLogoButtonTitle>
                </UploadLogoButton>
              </Grid>
            )}
          </Grid.Container>
          <Grid.Container justify="center" marginTop={2} marginBottom={1}>
            <Grid sm={24} xs={24}>
              <Button type="button" variant={logo ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
                {t("common.continue")}
              </Button>
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={1} marginBottom={4}>
            <Grid sm={24} xs={24}>
              <Button type="button" variant="primary-outline" onClick={skip} fullWidth>
                {t("common.later")}
              </Button>
            </Grid>
          </Grid.Container>
        </OnboardingStep>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={2} totalSteps={14} /> : null}
      {loading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
};

const UploadLogoButton = styled.button`
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.borderRadius(2)};
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.light};
  justify-content: center;
  color: ${({ theme }) => theme.colors.light};
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
`;

const UploadLogoButtonIcon = styled.svg`
  fill: ${({ theme }) => theme.colors.light};
`;

const UploadLogoButtonTitle = styled.p`
  color: ${({ theme }) => theme.colors.light};
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`;

const LogoContainer = styled.button`
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.borderRadius(2)};
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.white};
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: transparent;
  min-height: 112px;
  min-width: 112px;

  &:hover {
    cursor: pointer;
  }
`;

const LogoImage = styled.img`
  height: 80px;
  width: 80px;
`;
