import { gql, useMutation, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Spinner, useMediaQuery } from "@geist-ui/core";
import { Select, SelectOption } from "components/atoms/Select";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import {
  AccountUserQuery,
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  UpdateLanguageCodeMutation,
  UpdateLanguageCodeMutationVariables
} from "generated/graphql";
import { FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ArrowIcon } from "assets/icons";
import { useToast } from "hooks/useToast";
import { logger } from "util/logger";

export const Account: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset: resetForm,
    getValues
  } = useForm<{ oldPassword: string; newPassword: string; newPasswordRepeat: string }>();

  const isXS = useMediaQuery("xs");
  const [languageCode, setLanguageCode] = useState<string>();

  const showToast = useToast();
  const [updateLanguage] = useMutation<UpdateLanguageCodeMutation, UpdateLanguageCodeMutationVariables>(UPDATE_LANGUAGE_CODE_MUTATION, {
    onCompleted: () => {
      showToast({ text: t("membership.account.savedSuccess") });
    },
    onError: () => {
      showToast({ text: t("membership.account.savedSuccess"), type: "error" });
    }
  });
  const [changePassword] = useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(CHANGE_PASSWORD_MUTATION, {
    onCompleted: (r) => {
      if (r) {
        resetForm();
        showToast({ text: t("membership.account.savedSuccess") });
      }
    },
    onError: () => {
      showToast({ text: t("membership.account.savedSuccess"), type: "error" });
    }
  });
  const { data, loading, error } = useQuery<AccountUserQuery>(ACCOUNT_USER_QUERY, {
    onCompleted: (r) => {
      setLanguageCode(r.user?.languageCode ?? undefined);
    }
  });

  if (loading) {
    return (
      <>
        <Title>{t("membership.menu.account")}</Title>
        <SubTitleContainer>
          <Spinner />
        </SubTitleContainer>
      </>
    );
  }

  if (error || !data?.user) {
    return (
      <>
        <Title>{t("membership.menu.account")}</Title>
        <SubTitleContainer>
          <Text>{t("membership.loadingFailed")}</Text>
        </SubTitleContainer>
      </>
    );
  }

  const changeLanguage = async (code: string) => {
    if (code !== languageCode) {
      setLanguageCode(code);
      await updateLanguage({
        variables: {
          code
        }
      });
    }
  };

  const savePassword = async (oldPassword: string, newPassword: string) => {
    await changePassword({
      variables: {
        oldPassword,
        newPassword
      }
    });
  };

  return (
    <>
      <Title>{t("membership.menu.account")}</Title>
      <Row>
        <LabelColumn>
          <Label>{t("membership.account.email")}</Label>
        </LabelColumn>
        <InputColumn>
          <ValueText>{data.user.email}</ValueText>
        </InputColumn>
      </Row>
      <Separator />
      <Row>
        <LabelColumn>
          <Label>{t("membership.account.language")}</Label>
        </LabelColumn>
        <InputColumn>
          <SelectWrapper>
            <Select placeholder={t("membership.account.language")} value={languageCode} onChange={async (value) => changeLanguage(value as string)}>
              <SelectOption key={"de"} value={"de"}>
                {t("membership.account.german")}
              </SelectOption>
              <SelectOption key={"en"} value={"en"}>
                {t("membership.account.english")}
              </SelectOption>
              <SelectOption key={"es"} value={"es"}>
                {t("membership.account.spanish")}
              </SelectOption>
              <SelectOption key={"fr"} value={"fr"}>
                {t("membership.account.french")}
              </SelectOption>
              <SelectOption key={"it"} value={"it"}>
                {t("membership.account.italian")}
              </SelectOption>
              <SelectOption key={"tr"} value={"tr"}>
                {t("membership.account.turkish")}
              </SelectOption>
              <SelectOption key={"ja"} value={"ja"}>
                {t("membership.account.japanese")}
              </SelectOption>
              <SelectOption key={"ko"} value={"ko"}>
                {t("membership.account.korean")}
              </SelectOption>
            </Select>
          </SelectWrapper>
        </InputColumn>
      </Row>
      <Separator />
      <ChangePasswordForm
        onSubmit={handleSubmit(
          ({ oldPassword, newPassword }) => {
            savePassword(oldPassword, newPassword);
          },
          (e) => logger.error("Submit error", e)
        )}
      >
        <Row>
          <LabelColumn>
            <Label>{t("membership.account.password")}</Label>
          </LabelColumn>
          <InputColumnContent>
            <InputRowsWrapper>
              <InputRow>
                <Input
                  error={errors.oldPassword?.message}
                  type="password"
                  placeholder={t("membership.account.currentPassword")}
                  {...register("oldPassword", {
                    required: { value: true, message: t("membership.account.errors.passwordMissing") }
                  })}
                />
              </InputRow>
              <InputRow>
                <Input
                  error={errors.newPassword?.message}
                  type="password"
                  autoComplete="new-password"
                  placeholder={t("membership.account.password")}
                  {...register("newPassword", { required: { value: true, message: t("membership.account.errors.passwordMissing") } })}
                />
              </InputRow>
              <InputRow>
                <Input
                  error={errors.newPasswordRepeat?.message}
                  type="password"
                  autoComplete="new-password-repeat"
                  placeholder={t("membership.account.passwordRepeat")}
                  {...register("newPasswordRepeat", {
                    required: { value: true, message: t("membership.account.errors.passwordMissing") },
                    validate: (value) => value === getValues("newPassword") || t("membership.account.errors.passwordsDontMatch")
                  })}
                />
              </InputRow>
            </InputRowsWrapper>
            <Button small variant="primary" type="submit" fullWidth={isXS} Icon={ArrowIcon}>
              {t("membership.account.save")}
            </Button>
          </InputColumnContent>
        </Row>
      </ChangePasswordForm>
    </>
  );
};

const InputRowsWrapper = styled.div`
  width: 100%;
`;

const SelectWrapper = styled.div`
  max-width: 157px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    max-width: 100%;
  }
`;

const Title = styled.div`
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white80Percent};
  margin-bottom: 8px;
`;

const Text = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
`;

const Row = styled.div`
  margin: 24px 0;
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    display: block;
    margin: 16px 0;
  }
`;

const LabelColumn = styled.div`
  flex: 1;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin-bottom: 16px;
  }
`;

const InputRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
      margin-bottom: 10px;
    }
  }
`;

const InputColumn = styled.div`
  flex: 2;
  display: flex;
  padding-top: 3px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    padding-top: 0;
    display: block;
  }
`;

const InputColumnContent = styled(InputColumn)`
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled.div`
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
`;

const Separator = styled.hr`
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.white};
  opacity: 20%;
`;

const ValueText = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
`;

const ChangePasswordForm = styled.form`
  width: 100%;
`;

const SubTitleContainer = styled.div`
  margin-top: 32px;
`;

const ACCOUNT_USER_QUERY = gql`
  query AccountUser {
    user {
      _id
      email
      languageCode
    }
  }
`;

const UPDATE_LANGUAGE_CODE_MUTATION = gql`
  mutation UpdateLanguageCode($code: String!) {
    setLanguageCode(code: $code) {
      _id
      languageCode
    }
  }
`;

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePasswordForUser(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;
