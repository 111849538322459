import { Grid, useMediaQuery } from "@geist-ui/core";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "components/atoms/ProgressBar";
import { PageContainer } from "components/organisms/PageContainer";
import { FitnessStatus } from "generated/graphql";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import styled from "@emotion/styled/macro";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { GenderSelectionBg } from "assets/backgroundImages";
import { Button } from "components/atoms/Button";
import { MuscleIcon, RehaIcon } from "assets/icons";
import { useOnboardingStore } from "stores/onboarding.store";

export const FitnessSelection: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fitnessStatus, setFitnessStatus, role } = useOnboardingStore();

  const goToNextStep = () => {
    if (!fitnessStatus) {
      return;
    }

    navigate({ pathname: "../user-data-selection" });
  };

  return (
    <>
      <PageContainer background={<BackgroundImage src={GenderSelectionBg} />}>
        {isXS ? <ProgressBar currentStep={role ? 8 : 3} totalSteps={role ? 14 : 5} /> : null}
        <OnboardingStep fullWidth title={t("pages.onboarding.fitnessStatus.title")} subtitle={""}>
          <Grid.Container gap={2} direction={isXS ? "column" : "row"}>
            <Grid sm={12}>
              <FitnessStatusButton
                onClick={() => setFitnessStatus(FitnessStatus.FIT)}
                selected={fitnessStatus === FitnessStatus.FIT}
                color="primary"
                selectedColor="black"
              >
                <FitIcon selected={fitnessStatus === FitnessStatus.FIT} />
                <FitnessStatusButtonTitle>{t("pages.onboarding.fitnessStatus.fit.title")}</FitnessStatusButtonTitle>
                <FitnessStatusButtonDescription>{t("pages.onboarding.fitnessStatus.fit.description")}</FitnessStatusButtonDescription>
              </FitnessStatusButton>
            </Grid>
            <Grid sm={12}>
              <FitnessStatusButton
                onClick={() => setFitnessStatus(FitnessStatus.INJURED)}
                selected={fitnessStatus === FitnessStatus.INJURED}
                color="purple"
                selectedColor="white"
              >
                <InjuredIcon />
                <FitnessStatusButtonTitle>{t("pages.onboarding.fitnessStatus.injured.title")}</FitnessStatusButtonTitle>
                <FitnessStatusButtonDescription>{t("pages.onboarding.fitnessStatus.injured.description")}</FitnessStatusButtonDescription>
              </FitnessStatusButton>
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={2}>
            <Grid sm={12} xs={24}>
              <Button type="button" variant={fitnessStatus ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
                {t("common.continue")}
              </Button>
            </Grid>
          </Grid.Container>
        </OnboardingStep>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={role ? 8 : 3} totalSteps={role ? 14 : 5} /> : null}
    </>
  );
};

const FitnessStatusButton = styled.button<{ color: "primary" | "purple"; selected: boolean; selectedColor: "black" | "white" }>`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.borderRadius(3)};
  border-color: ${({ theme, color }) => theme.colors[color]};
  border-width: 1px;
  background-color: ${({ theme, selected, color }) => (selected ? theme.colors[color] : "transparent")};
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  color: ${({ theme, selectedColor, selected }) => (selected ? theme.colors[selectedColor] : theme.colors.white)};

  &:hover {
    background: ${({ theme, color }) => theme.colors[color]};
    color: ${({ theme, selectedColor }) => theme.colors[selectedColor]};
  }
`;

const FitIcon = styled(MuscleIcon)<{ selected: boolean }>`
  fill: ${({ theme, selected }) => (selected ? theme.colors.black : theme.colors.white)};
  width: 36px;
`;

const InjuredIcon = styled(RehaIcon)`
  fill: ${({ theme }) => theme.colors.white};
  width: 36px;
`;

const FitnessStatusButtonTitle = styled.p`
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: italic;
  font-weight: 800;
  line-height: 21px; /* 105% */
  letter-spacing: 0.14px;
  text-transform: uppercase;
`;

const FitnessStatusButtonDescription = styled.p`
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 120% */
`;
