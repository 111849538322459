import React, { createContext, FunctionComponent, useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGenerateAccessTokenMutation, useSignInWithAccessTokenMutation } from "../generated/graphql";
import { useToast } from "../hooks/useToast";
import { useTranslation } from "react-i18next";
import { AuthContext } from "./AuthContext";
import { useLocalizedRoute } from "../hooks/useLocalizedRoute";

export interface OAuthContextInterface {
  isOAuthRequest: boolean
  hasOAuthAccessToken: boolean
  OAuthLoading: boolean
  redirectOAuth: () => void
  accessTokenLogin: () => Promise<boolean>
}

export const OAuthContext = createContext<OAuthContextInterface>({
  isOAuthRequest: false,
  hasOAuthAccessToken: false,
  OAuthLoading: false,
  redirectOAuth: () => {},
  accessTokenLogin: async () => false,
});

export const OAuthContextProvider: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [OAuthLoading, setOAuthLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const showToast = useToast();
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const localizedRoute = useLocalizedRoute();

  const redirectURI = searchParams.get("redirect_uri");
  const isOAuthRequest = !!redirectURI;

  const accessToken = searchParams.get("access_token");
  const hasOAuthAccessToken = !!accessToken;

  const [generateAccessTokenMutation] = useGenerateAccessTokenMutation({
    onCompleted: (data) => {
      if (data.generateAccessToken.__typename === "AccessTokenErrorResponse") {
        showToast({ text: t("OAuth.error.generalError"), type: "error" });
      } else {
        window.location.href = `${redirectURI}?access_token=${data.generateAccessToken.token}`;
      }
    },
    onError: () => showToast({ text: t("OAuth.error.generalError"), type: "error" }),
  });

  const [signInWithAccessTokenMutation] = useSignInWithAccessTokenMutation({
    onCompleted: (data) => {
      if (data.signInWithAccessToken.__typename === "AccessTokenErrorResponse") {
        showToast({ text: t("OAuth.error.generalError"), type: "error" });
        navigate({ pathname: localizedRoute("sign-up"), search: location.search });
      } else {
        authContext.setUserIdAndToken(data.signInWithAccessToken);
      }
    },
    onError: () => {
      showToast({ text: t("OAuth.error.generalError"), type: "error" });
      navigate({ pathname: localizedRoute("sign-up"), search: location.search });
    }
  })

  const redirectOAuth = async () => {
    setOAuthLoading(true);
    // wait for localstorage to have jwt token
    await new Promise(r => setTimeout(r, 250));
    await generateAccessTokenMutation();
    setOAuthLoading(false);
  }

  const accessTokenLogin = async (): Promise<boolean> => {
    setOAuthLoading(true);
    const result = await signInWithAccessTokenMutation({ variables: { token: accessToken ?? "" } });
    // wait for localstorage to have jwt token
    await new Promise(r => setTimeout(r, 250));
    setOAuthLoading(false);
    return !result.errors?.length
  }

  return (
    <OAuthContext.Provider value={{
      isOAuthRequest,
      hasOAuthAccessToken,
      OAuthLoading,
      redirectOAuth,
      accessTokenLogin
    }}>
      {children}
    </OAuthContext.Provider>
  );
};
