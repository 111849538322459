import { Grid, useMediaQuery } from "@geist-ui/core";
import { GenderSelectionBg } from "assets/backgroundImages";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { ProgressBar } from "components/atoms/ProgressBar";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { useUpdateSportsClubDataMutation } from "generated/graphql";
import { useToast } from "hooks/useToast";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";

const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const SportsClubData: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { sportsClubAddress, setSportsClubWebsite, setSportsClubStreet, setSportsClubLocation, setSportsClubPostcode, setSportsClubPhone } = useOnboardingStore();

  const [mutate, { loading }] = useUpdateSportsClubDataMutation({
    onCompleted: () => navigate({ pathname: "../sports-club-team" }),
    onError: () => toast({ text: t("pages.teamOnboarding.sportsClubData.error") })
  });

  const { location, street, postcode, website, phone } = sportsClubAddress;

  const enabled = (phone && phoneRegex.test(phone));

  const goToNextStep = () => {
    if ((phone && phoneRegex.test(phone))) {
      mutate({
        variables: {
          input: {
            website: website,
            location: location,
            street: street,
            postcode: postcode,
            phone: phone
          }
        }
      });
    }
  };

  // const skip = () => navigate({ pathname: "../sports-club-team" });

  return (
    <>
      <PageContainer background={<BackgroundImage src={GenderSelectionBg} />}>
        {isXS ? <ProgressBar currentStep={3} totalSteps={14} /> : null}
        <OnboardingStep title={t("pages.teamOnboarding.sportsClubData.title")} subtitle={t("pages.teamOnboarding.sportsClubData.subTitle")}>
          <Grid.Container gap={2} direction={isXS ? "column" : "row"} justify="center">
            <Grid sm={24} xs={24} width="100%">
              <Input placeholder={t("pages.teamOnboarding.sportsClubData.phone") + "*"} value={phone} onChange={(e) => setSportsClubPhone(e.target.value)} />
            </Grid>
            <Grid sm={24} xs={24} width="100%">
              <Input placeholder={t("pages.teamOnboarding.sportsClubData.website")} value={website} onChange={(e) => setSportsClubWebsite(e.target.value)} />
            </Grid>
            <Grid sm={24} xs={24} width="100%">
              <Input placeholder={t("pages.teamOnboarding.sportsClubData.street")} value={street} onChange={(e) => setSportsClubStreet(e.target.value)} />
            </Grid>
            <Grid sm={6} xs={24} width="100%">
              <Input placeholder={t("pages.teamOnboarding.sportsClubData.postcode")} value={postcode} onChange={(e) => setSportsClubPostcode(e.target.value)} />
            </Grid>
            <Grid sm={18} xs={24} width="100%">
              <Input placeholder={t("pages.teamOnboarding.sportsClubData.location")} value={location} onChange={(e) => setSportsClubLocation(e.target.value)} />
            </Grid>
          </Grid.Container>
          <Grid.Container justify="center" marginTop={2} marginBottom={1}>
            <Grid sm={24} xs={24}>
              <Button type="button" variant={enabled ? "primary" : "disabled"} onClick={goToNextStep} fullWidth>
                {t("common.continue")}
              </Button>
            </Grid>
          </Grid.Container>
          {/* <Grid.Container justify="center" marginTop={1} marginBottom={4}>
            <Grid sm={24} xs={24}>
              <Button type="button" variant="primary-outline" onClick={skip} fullWidth>
                {t("common.later")}
              </Button>
            </Grid>
          </Grid.Container> */}
        </OnboardingStep>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={3} totalSteps={14} /> : null}
      {loading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
};
