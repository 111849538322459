import styled from "@emotion/styled";
import { Grid, Spinner } from "@geist-ui/core";
import { GenderSelectionWoman } from "assets";
import { CheckIcon } from "assets/icons";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { SportsClubTeamRole, useBasicSportsClubTeamLazyQuery, useJoinSportsClubTeamMutation, useLeaveSportsClubMutation } from "generated/graphql";
import { useSearchParam } from "hooks/useSearchParam";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";

export const InviteChange = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { bundle } = useOnboardingStore();
  const showToast = useToast();

  const [mutateJoin, { loading: joinLoading }] = useJoinSportsClubTeamMutation({
    onCompleted: () => navigate({ pathname: "../accept" }),
    onError: () => {
      showToast({ text: t("pages.invite.error.toast"), type: "error" });

      if (bundle === "TEAM") {
        navigate({ pathname: "../../sports-club-onboarding", search: location.search });
      } else if (bundle === "PLAYER") {
        navigate({ pathname: "../../onboarding", search: location.search });
      } else {
        navigate({ pathname: "../../membership-area", search: location.search });
      }
    }
  });

  const [mutateLeave, { loading: changeLoading }] = useLeaveSportsClubMutation({
    onCompleted: () => mutateJoin({ variables: { sportsClubId, sportsClubTeamId, role } }),
    onError: () => {
      showToast({ text: t("pages.invite.error.toast"), type: "error" });

      if (bundle === "TEAM") {
        navigate({ pathname: "../../sports-club-onboarding", search: location.search });
      } else if (bundle === "PLAYER") {
        navigate({ pathname: "../../onboarding", search: location.search });
      } else {
        navigate({ pathname: "../../membership-area", search: location.search });
      }
    }
  });

  const [query, { data, loading, error }] = useBasicSportsClubTeamLazyQuery();

  const sportsClubId = useSearchParam<string>("sportsClub", {
    defaultValue: ""
  });
  const sportsClubTeamId = useSearchParam<string>("team", {
    defaultValue: ""
  });
  const role = useSearchParam<SportsClubTeamRole>("role", {
    defaultValue: SportsClubTeamRole.PLAYER,
    allowedValues: Object.values(SportsClubTeamRole)
  });

  const [change, setChange] = useState(false);

  useEffect(() => {
    if (sportsClubId && sportsClubTeamId) {
      query({ variables: { sportsClubId, sportsClubTeamId } });
    }
  }, [query, sportsClubId, sportsClubTeamId]);

    const handleNextClick = () => {
      if (change) {
        mutateLeave();
      } else if (bundle === "TEAM") {
        navigate({ pathname: "../../sports-club-onboarding", search: location.search });
      } else if (bundle === "PLAYER") {
        navigate({ pathname: "../../onboarding", search: location.search });
      } else {
        navigate({ pathname: "../../membership-area", search: location.search });
      }
    };

  if (!sportsClubId || !sportsClubTeamId || loading) {
    return (
      <Grid.Container direction="column" justify="center">
        <Grid sm={24} direction="column" justify="center">
          <Spinner mt={3} mb={3} />
        </Grid>
      </Grid.Container>
    );
  }

  if (!sportsClubId || !sportsClubTeamId || !data?.basicSportsClubTeam || !data.user?.userData.sportsClubMembership || error) {
    return (
      <Grid.Container>
        <Grid sm={24} direction="column" justify="center">
          <ErrorText>{t("pages.invite.error.message")}</ErrorText>
          <Button type="button" variant="primary" fullWidth onClick={handleNextClick}>
            {t("common.continue")}
          </Button>
        </Grid>
      </Grid.Container>
    );
  }

  const basicTeam = data.basicSportsClubTeam;
  const currentMembership = data.user.userData.sportsClubMembership;

  return (
    <>
      <PageContainer background={<BackgroundImage src={GenderSelectionWoman} />}>
        <OnboardingStep title={t("pages.invite.change.title")} subtitle={t("pages.invite.change.subTitle")}></OnboardingStep>
        <Grid.Container direction="column">
          <Grid sm={24} direction="column">
            <SportsClubContainer onClick={() => setChange(false)}>
              <SportsClubLogoContainer>
                {currentMembership.sportsClub.logo ? <SportsClubLogo src={currentMembership.sportsClub.logo?.uri} alt={"Logo - " + currentMembership.sportsClub.name} /> : null}
                {change === false && (
                  <SportsClubLogoSelectedOverlay>
                    <SportsClubLogoSelectedOverlayIcon />
                  </SportsClubLogoSelectedOverlay>
                )}
              </SportsClubLogoContainer>
              <SportsClubName>
                {currentMembership.sportsClub.name} {"-"} {currentMembership.currentSportsClubTeam?.name}
              </SportsClubName>
            </SportsClubContainer>
            <SportsClubContainer onClick={() => setChange(true)}>
              <SportsClubLogoContainer>
                {basicTeam.logo ? <SportsClubLogo src={basicTeam.logo?.uri} alt={"Logo - " + basicTeam.sportsClubName} /> : null}
                {change && (
                  <SportsClubLogoSelectedOverlay>
                    <SportsClubLogoSelectedOverlayIcon />
                  </SportsClubLogoSelectedOverlay>
                )}
              </SportsClubLogoContainer>
              <SportsClubName>
                {basicTeam.sportsClubName} {"-"} {basicTeam.teamName}
              </SportsClubName>
            </SportsClubContainer>
          </Grid>
          <Grid sm={24} direction="column" mt={3} mb={2}>
            <ContinueButton type="button" variant="primary" fullWidth onClick={handleNextClick}>
              {t("common.continue")}
            </ContinueButton>
          </Grid>
        </Grid.Container>
        {changeLoading || joinLoading ? <LoadingSpinnerOverlay /> : null}
      </PageContainer>
    </>
  );
};

const SportsClubContainer = styled.div`
  display: flex;
  max-width: 360px;
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.borderRadius(4)};
  flex-direction: row;
  align-items: center;
  margin: 0 auto ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.colors.gray};
  width: 100%;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.gray2};
  }
`;

const SportsClubLogoContainer = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: ${({ theme }) => theme.borderRadius(2.5)};
  background-color: ${({ theme }) => theme.colors["sonic-silver"]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing()};
`;

const SportsClubLogoSelectedOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => `linear-gradient(360deg, ${theme.colors.dark}00, ${theme.colors.primary})`};
  border-radius: ${({ theme }) => theme.borderRadius(2.5)};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SportsClubLogoSelectedOverlayIcon = styled(CheckIcon)`
  width: 24px;
  fill: ${({ theme }) => theme.colors.dark};
`;

const SportsClubLogo = styled.img`
  height: 20px;
  width: 20px;
`;

const SportsClubName = styled.p`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px; /* 120% */
`;

const ContinueButton = styled(Button)`
  margin: 0 auto;
  max-width: 360px;
`;

const ErrorText = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 120% */
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
