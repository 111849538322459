import { ForgotPasswordMutation, useForgotPasswordMutation } from "generated/graphql";
import { logger } from "util/logger";

export const useForgotPassword = (onCompleted?: (data: ForgotPasswordMutation) => void, onError?: () => void) => {
  const [forgotPasswordMutation, query] = useForgotPasswordMutation({
    onCompleted,
    onError: (e) => {
      logger.error("useForgotPasswordMutation failed", e);
      onError?.();
    }
  });

  const forgotPassword = (email: string) => forgotPasswordMutation({ variables: { email } });

  return { forgotPassword, ...query };
};
