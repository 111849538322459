import styled from "@emotion/styled";
import { Grid, useMediaQuery } from "@geist-ui/core";
import { AppLogo, AppPreview, AppleDownload, GooglePlayDownload } from "assets";
import { RoleSelectionDiversBg } from "assets/backgroundImages";
import { ArrowIcon } from "assets/icons";
import { BackgroundImage } from "components/atoms/BackgroundImage";
import { Button } from "components/atoms/Button";
import { ProgressBar } from "components/atoms/ProgressBar";
import { LoadingSpinnerOverlay } from "components/molecules/LoadingSpinnerOverlay";
import { OnboardingStep } from "components/molecules/OnboardingStep";
import { PageContainer } from "components/organisms/PageContainer";
import { useOnboardingUserQuery } from "generated/graphql";
import { useStripeCheckout } from "hooks/useStripeCheckout";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "stores/onboarding.store";
import { DOWNLOAD_APP_LINK_ANDROID, DOWNLOAD_APP_LINK_IOS, ParamsBundlePeriod } from "util/constants";

export const PlayerOnboardingFinish: FunctionComponent = () => {
  const isXS = useMediaQuery("xs");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { role, bundle, clear } = useOnboardingStore();
  const { createCheckoutSession, loading } = useStripeCheckout();
  const { data } = useOnboardingUserQuery();
  const [paymentPeriod, setPaymentPeriod] = useState<ParamsBundlePeriod>(ParamsBundlePeriod["1month"]);

  const isUserPremium = data?.user?.premium.enabled === true;

  const onContinueWithStripe = async () => {
    const checkoutUrl = await createCheckoutSession({ product: "singleplayer_premium", paymentPeriod: paymentPeriod});
    window.location.href = checkoutUrl;
  };

  const handleAppleClick = () => {
    clear();
    window.open(DOWNLOAD_APP_LINK_IOS, "_blank");
  };

  const handleGoogleClick = () => {
    clear();
    window.open(DOWNLOAD_APP_LINK_ANDROID + `&hl=${i18n.language}`, "_blank");
  };

  const handleAccountClick = () => {
    clear();
    navigate({ pathname: "../../membership-area" });
  };

  const price = paymentPeriod === ParamsBundlePeriod["1month"] ? "2,49" : "1,99";

  return (
    <>
      <PageContainer background={<BackgroundImage src={RoleSelectionDiversBg} />} wide>
        {isXS ? <ProgressBar currentStep={role ? 14 : 5} totalSteps={role ? 14 : 5} /> : null}
        <OnboardingStep title={t("pages.onboarding.finish.title")} subtitle={t("pages.onboarding.finish.subTitle")}>
          <Grid.Container gap={2} direction={isXS ? "column" : "row"} justify="center" width={isXS ? undefined : 50}>
            <Grid sm={12} xs={24}>
              <AppPreviewImage src={AppPreview} />
            </Grid>
            <Grid sm={12} xs={24} direction="column" justify={isXS ? "center" : undefined}>
              <B42LogoImage src={AppLogo} />
              <DownloadButtonImage onClick={handleAppleClick} src={AppleDownload} />
              <DownloadButtonImage onClick={handleGoogleClick} src={GooglePlayDownload} />
              <OrText>{t("pages.onboarding.finish.or")}</OrText>
              <AccountText onClick={handleAccountClick}>{t("pages.onboarding.finish.loginAccount")}</AccountText>
            </Grid>
          </Grid.Container>
          {bundle && isUserPremium === false ? (
            <Grid.Container>
              <Grid xs={24} sm={24} justify="center">
                <BundleContainer>
                  <PackageSelectionContainer>
                    <PackageSelectionContainerTab
                      onClick={() => setPaymentPeriod(ParamsBundlePeriod["1month"])}
                      selected={paymentPeriod === ParamsBundlePeriod["1month"]}
                    >
                      <PackageSelectionContainerTabTitle>{t("membership.subscription.subscribe.monthly")}</PackageSelectionContainerTabTitle>
                    </PackageSelectionContainerTab>
                    <PackageSelectionContainerTab
                      onClick={() => setPaymentPeriod(ParamsBundlePeriod["12months"])}
                      selected={paymentPeriod === ParamsBundlePeriod["12months"]}
                    >
                      <PackageSelectionContainerTabTitle>{t("membership.subscription.subscribe.annual")}</PackageSelectionContainerTabTitle>
                      <PackageSavingsBadge>{"-17%"}</PackageSavingsBadge>
                    </PackageSelectionContainerTab>
                  </PackageSelectionContainer>
                  <PackagePriceContainer>
                    <PackagePriceOptions>{t("membership.subscription.subscribe.from")}</PackagePriceOptions>
                    <PackagePrice>{price}</PackagePrice>
                    <PackagePriceOptions>
                      {"/"}
                      {t("membership.subscription.subscribe.week")}
                    </PackagePriceOptions>
                  </PackagePriceContainer>
                  <PackageButtonContainer>
                    <Button variant="light" Icon={ArrowIcon} onClick={onContinueWithStripe}>
                      {t("membership.subscription.subscribe.subscribe")}
                    </Button>
                  </PackageButtonContainer>
                  <PackageDescriptionText>{t("membership.subscription.subscribe.test")}</PackageDescriptionText>
                </BundleContainer>
              </Grid>
            </Grid.Container>
          ) : null}
        </OnboardingStep>
      </PageContainer>
      {!isXS ? <ProgressBar renderOnBottom currentStep={role ? 14 : 5} totalSteps={role ? 14 : 5} /> : null}
      {loading ? <LoadingSpinnerOverlay /> : null}
    </>
  );
};

const AppPreviewImage = styled.img`
  height: 100%;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin: 0 auto;
    min-height: 590px;
  }
`;

const B42LogoImage = styled.img`
  height: 112px;
  width: 112px;
  border-radius: 32px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin: 0 auto;
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

const DownloadButtonImage = styled.img`
  height: auto;
  width: 211px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin: 0 auto;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

const OrText = styled.p`
  font-size: 13px;
  margin: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin: 0 auto;
  }
`;

const AccountText = styled.p`
  font-size: 13px;
  font-weight: 700;
  text-decoration-line: underline;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xs.max}) {
    margin: 0 auto;
  }
`;



const BundleContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.borderRadius(3)};
`;

const PackageSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: ${({ theme }) => theme.borderRadius(3)};
  padding: 4px;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.colors.gray};
  gap: 4px;
`;

const PackageSelectionContainerTab = styled.div<{ selected: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius(3)};
  padding: 2px ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme, selected }) => (selected ? theme.colors.light : "transparent")};
  color: ${({ theme, selected }) => (selected ? theme.colors.dark : theme.colors.white)};
  transition: all 0.2s ease-in-out;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const PackageSelectionContainerTabTitle = styled.p`
  font-size: 16px;
  line-height: 18px;
  font-weight: 800;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0;
`;

const PackageSavingsBadge = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius(3)};
  padding: 4px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 800;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.dark};
  margin-left: ${({ theme }) => theme.spacing(2)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PackagePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PackagePrice = styled.p`
  font-size: 64px;
  line-height: 102px;
  letter-spacing: -1.6px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.dark};
  margin: 0 8px;
`;

const PackagePriceOptions = styled.p`
  font-size: 18px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.gray2};
  margin: 0;
`;

const PackageButtonContainer = styled.div`
  margin: 0 auto 16px;
  justify-content: center;
  flex: 1;
  display: flex;
`;

const PackageDescriptionText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray2};
  margin: 0;
  text-align: center
`;

